import { METER_TO_FEET } from 'src/app.constants';
import { store } from '../../store';
import {
  meterToAny,
  metresToHeightUnits,
  postBulkUpdates,
  temperatureFromKelvin,
} from '../../utils/useful_functions';
import { setBulkEditSelectedRows } from '../mainframe/mainframe.reducer';
import {
  defaultColumn,
  numberColumn,
  valueGetter,
  makeHeightColumn,
  throughputColumn,
  reliabilityColumn,
  unavailabilityColumn,
  clutterTypeGetter,
} from 'src/components/controls/lpgrid/common/columnTypes';
import { get, set } from 'lodash';

function tooltip(params: any) {
  return params.data.warnings_hover || null;
}

export const getMeshLinksColumns = (prefs, projectId) => {
  const postNDUpdates = (
    projectId,
    ids,
    objectPath,
    value,
    gridApi,
    heightUnits = ''
  ) => {
    postBulkUpdates(
      projectId,
      ids,
      objectPath,
      value,
      gridApi,
      'mesh',
      heightUnits
    );
  };

  const cellValueChangeHandler = (rowData, objectPath) => {
    let { data, newValue, oldValue, api, context } = rowData;
    if (parseFloat(oldValue) !== newValue) {
      let selectedIds = [data.id];
      const { prefs } = context;
      const heightPaths = ['local.antenna_height', 'remote.antenna_height'];
      let selectedRows = api?.getSelectedRows();
      if (!selectedRows?.length) selectedRows = [data];
      if (api !== null && selectedRows.length) {
        selectedIds = [];
        selectedRows.forEach((row) => {
          if (heightPaths.includes(objectPath)) {
            if (prefs.heightUnits === 'ft') {
              row[objectPath] = parseFloat(newValue) / METER_TO_FEET;
            } else {
              row[objectPath] = parseFloat(newValue);
            }
          } else {
            set(row, objectPath, newValue);
          }
          row.updated = false;
          selectedIds.push(row.id);
        });
        api.applyTransaction({ update: selectedRows });
      }
      if (
        heightPaths.includes(objectPath) &&
        parseFloat(newValue) !== parseFloat(oldValue) &&
        api != null
      ) {
        store.dispatch(
          setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
        );
        postNDUpdates(
          projectId,
          selectedIds,
          objectPath,
          newValue,
          api,
          context.prefs.heightUnits
        );
      } else {
        newValue =
          newValue === 'Yes'
            ? true
            : newValue === 'No'
            ? false
            : isNaN(Number(newValue))
            ? newValue
            : Number(newValue);
        store.dispatch(
          setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
        );
        postNDUpdates(projectId, selectedIds, objectPath, newValue, api);
      }
    }
  };

  const editableNumberCell = (field) => {
    return {
      cellEditor: 'numberEditor',
      cellClass: ['number-cell'],
      onCellValueChanged: (rowData) => cellValueChangeHandler(rowData, field),
    };
  };

  const makeEndCols = (endName) => {
    const endLabel = endName === 'local' ? 'Left' : 'Right';
    return [
      {
        field: `${endName}.name`,
        sort: 'asc',
        minWidth: 120,
        headerName: `${endLabel} Name`,
        cellClass: ['ag-grid-cell-truncate-text'],
        tooltipValueGetter: tooltip,
        cellRenderer: 'linkRenderer',
        cellRendererParams: {
          getHref: (data) => {
            const id = get(data, `${endName}.id`);
            return `/aps/${id}`;
          },
        },
        isComparatorRequired: true,
      },
      {
        field: `${endName}.sector`,
        headerName: `${endLabel} Sector`,
        minWidth: 80,
        tooltipValueGetter: tooltip,
      },
      makeHeightColumn(`${endName}.antenna_height`, `${endLabel} Height`),
      makeHeightColumn(`${endName}.max_height`, `${endLabel} Max Height`),
      makeHeightColumn(`${endName}.ground_height`, `${endLabel} Ground Height`),
      {
        ...numberColumn,
        field: `${endName}.rx_antenna_gain`,
        headerName: `${endLabel} Gain (dBi)`,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        ...defaultColumn,
        field: `${endName}.antenna`,
        headerName: `${endLabel} Antenna`,
        minWidth: 280,
      },
      {
        ...numberColumn,
        field: `${endName}.bearing`,
        headerName: `${endLabel} Bearing, True N (deg)`,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
        minWidth: 120,
      },
      {
        ...numberColumn,
        field: `${endName}.tilt`,
        headerName: `${endLabel} Antenna Tilt (deg)`,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: `${endName}.latitude`,
        headerName: `${endLabel} Latitude`,
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: `${endName}.longitude`,
        headerName: `${endLabel} Longitude`,
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: `${endName}.mac_address`,
        headerName: `${endLabel} MAC Address`,
        minWidth: 100,
        tooltipValueGetter: tooltip,
        cellRenderer: 'macRenderer',
      },
      {
        ...numberColumn,
        field: `${endName}.throughput_reliability`,
        headerName: `${endLabel} Min IP Availability Predicted (%)`,
        valueGetter: (params) => valueGetter(params, 4, '0'),
      },
      {
        ...numberColumn,
        field: `${endName}.mean_data_rate_predicted`,
        headerName: `${endLabel} Mean IP Predicted (Mbps)`,
        valueGetter: (params) => valueGetter(params, 3, '0'),
      },
      {
        ...numberColumn,
        field: `${endName}.percentage_of_required_data_rate`,
        headerName: `${endLabel} % of Required IP`,
        valueGetter: (params) => valueGetter(params, 3),
      },
      {
        ...numberColumn,
        field: `${endName}.power`,
        headerName: `${endLabel} Max Power (dBm)`,
        cellRenderer: 'fixedRenderer',
      },
      {
        ...numberColumn,
        field: `${endName}.eirp`,
        headerName: `${endLabel} Max EIRP (dBm)`,
        cellRenderer: 'fixedRenderer',
      },
      {
        ...numberColumn,
        field: `${endName}.user_eirp`,
        headerName: `${endLabel} EIRP Limit (dBm)`,
        cellRenderer: 'fixedRenderer',
      },
      {
        ...defaultColumn,
        field: `${endName}.use_user_eirp`,
        headerName: `${endLabel} User EIRP Limit?`,
        valueGetter: (params) => {
          return get(params, `data.${endName}.use_user_eirp`, '') === true ||
            get(params, `data.${endName}.use_user_eirp`, '') === 'Yes'
            ? 'Yes'
            : 'No';
        },
      },
      {
        ...defaultColumn,
        field: `${endName}.use_noise`,
        headerName: `${endLabel} Interference?`,
        valueGetter: (params) => {
          return get(params, `data.${endName}.use_noise`, '') === true ||
            get(params, `data.${endName}.use_noise`, '') === 'Yes'
            ? 'Yes'
            : 'No';
        },
      },
      {
        ...numberColumn,
        field: `${endName}.noise`,
        headerName: `${endLabel} Interference (dBm in b/w)`,
        cellRenderer: 'fixedRenderer',
      },
      {
        ...throughputColumn,
        ...editableNumberCell(`${endName}.mean_data_rate_requirement`),
        // cellEditorParams: {
        //   precision: 3,
        //   min: 0,
        //   max: 100,
        //   step: 0.001,
        // },
        field: `${endName}.mean_data_rate_requirement`,
        headerName: `${endLabel} Mean IP Required (Mbps)`,
      },
      {
        ...throughputColumn,
        ...editableNumberCell(`${endName}.minimum_data_rate_requirement`),
        // cellEditorParams: {
        //   precision: 3,
        //   min: 0,
        //   max: 100,
        //   step: 0.001,
        // },
        field: `${endName}.minimum_data_rate_requirement`,
        headerName: `${endLabel} Min IP Required (Mbps)`,
      },
      {
        ...reliabilityColumn,
        ...editableNumberCell(`${endName}.minimum_reliability_requirement`),
        cellEditorParams: {
          precision: 4,
          min: 0,
          max: 100,
          step: 0.0001,
        },
        field: `${endName}.minimum_reliability_requirement`,
        headerName: `${endLabel} Min IP Availability Required %`,
      },
      {
        ...defaultColumn,
        field: `${endName}.transmit_frequency`,
        headerName: `${endLabel} Tx Frequency (MHz)`,
        valueGetter: (params) => valueGetter(params, 2, ''),
      },
      {
        ...defaultColumn,
        field: `${endName}.receive_frequency`,
        headerName: `${endLabel} Rx Frequency (MHz)`,
        valueGetter: (params) => valueGetter(params, 2, ''),
      },
      {
        ...defaultColumn,
        field: `${endName}.link_golay`,
        headerName: `${endLabel} Golay`,
        cellEditor: 'agSelectCellEditor',
        cellClass: ['select-cell'],
        onCellValueChanged: (rowData) =>
          cellValueChangeHandler(rowData, `${endName}.link_golay`),
        cellEditorParams: {
          values: ['Same as DN', 'Auto', '1', '2', '3'],
        },
      },
      {
        ...defaultColumn,
        field: `${endName}.polarity`,
        headerName: `${endLabel} Polarity`,
      },
      {
        ...defaultColumn,
        field: `${endName}.predicted_receive_power`,
        headerName: `${endLabel} Predicted Receive Power`,
      },
      {
        field: `${endName}.clutter_type`,
        headerName: `${endLabel} Clutter Type`,
        minWidth: 180,
        valueGetter: clutterTypeGetter,
      },
      {
        field: `${endName}.user_power`,
        headerName: `${endLabel} Power Limit (dBm)`,
        minWidth: 180,
      },
      {
        field: `${endName}.use_user_power`,
        headerName: `${endLabel} User Power Limit?`,
        cellRenderer: 'yesNoNaRenderer',
        minWidth: 180,
      },
    ];
  };

  const columns = [
    {
      field: 'link_name',
      sort: 'asc',
      minWidth: 120,
      headerName: 'Link Name',
      pinned: 'left',
      cellClass: ['ag-grid-cell-truncate-text'],
      tooltipValueGetter: tooltip,
      valueGetter: ({ data }) => {
        return `${data.local.name} to ${data.remote.name}`;
      },
      cellRenderer: 'linkRenderer',
      cellRendererParams: {
        getHref: (data) => `/mesh/${data.id}`,
      },
      isComparatorRequired: true,
    },
    ...makeEndCols('local'),
    ...makeEndCols('remote'),

    {
      ...defaultColumn,
      field: 'range_m',
      minWidth: 90,
      headerName: 'Range',
      headerValueGetter: ({ context }) => {
        return `Range (${context.prefs.rangeUnits})`;
      },
      cellRenderer: 'rangeRenderer',
      cellRendererParams: {
        precision: 3,
      },
      valueGetter: (params) => {
        return meterToAny(
          params.data.range_m,
          params.context.prefs.rangeUnits,
          3
        );
      },
    },
    {
      ...defaultColumn,
      field: 'band',
      headerName: 'Band',
      isComparatorRequired: true,
    },
    {
      ...defaultColumn,
      field: 'product',
      headerName: 'Product',
      isComparatorRequired: true,
    },
    {
      ...defaultColumn,
      field: 'regulation',
      headerName: 'Regulation',
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'link_availability',
      headerName: 'Link Availability',
      valueGetter: (params) => valueGetter(params, 4),
    },
    {
      ...numberColumn,
      field: 'system_fade_margin',
      headerName: 'System Gain Margin (dB)',
      valueGetter: (params) => valueGetter(params, 1),
    },
    {
      ...numberColumn,
      field: 'npoints',
      headerName: '# Points in Profile',
    },
    {
      ...defaultColumn,
      field: 'profile_type',
      headerName: 'Profile Type',
    },
    {
      ...numberColumn,
      field: 'dn_dh',
      headerName: 'dN/dH (N units/km)',
    },
    {
      ...numberColumn,
      field: 'area_roughness',
      headerName: 'Area Roughness(m)',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...defaultColumn,
      field: 'geo_climatic_factor',
      headerName: 'Geoclimatic Factor',
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'fade_occurrence_factor',
      headerName: 'Fade Occurrence Factor (Po)',
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'inclination',
      headerName: 'Inclination (mr)',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...defaultColumn,
      field: 'terrain_roughness',
      headerName: 'Terrain Roughness',
      valueGetter: ({ context, data }) => {
        return metresToHeightUnits(
          data.terrain_roughness,
          context.prefs.heightUnits
        );
      },
      filterParams: {
        valueGetter: ({ context, data }) => {
          return metresToHeightUnits(
            data.terrain_roughness,
            context.prefs.heightUnits
          );
        },
      },
      getQuickFilterText: ({ context, data }) => {
        return metresToHeightUnits(
          data.terrain_roughness,
          context.prefs.heightUnits
        );
      },
    },
    {
      ...defaultColumn,
      field: 'climatic_factor',
      headerName: 'Climatic Factor',
      valueGetter: (params) => valueGetter(params, 1),
    },
    {
      ...defaultColumn,
      field: 'c_factor',
      headerName: 'C Factor',
    },
    {
      ...defaultColumn,
      field: 'temperature',
      headerName: 'Temperature',
      valueGetter: ({ context, data }) => {
        // The value from the backend is in Kelvin.
        return temperatureFromKelvin(
          data.temperature,
          context.prefs.heightUnits
        );
      },
      filterParams: {
        valueGetter: ({ context, data }) => {
          // The value from the backend is in Kelvin.
          return temperatureFromKelvin(
            data.temperature,
            context.prefs.heightUnits
          );
        },
      },
      getQuickFilterText: ({ context, data }) => {
        return temperatureFromKelvin(
          data.temperature,
          context.prefs.heightUnits
        );
      },
    },
    {
      ...numberColumn,
      field: 'worst_earth_ke',
      headerName: 'Worst Earth (ke)',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...numberColumn,
      field: 'free_space_path_loss',
      headerName: 'Free Space Path Loss (dB)',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...numberColumn,
      field: 'gaseous_obsorption_loss',
      headerName: 'Gaseous Absorption Loss (dB)',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...numberColumn,
      field: 'excess_path_loss',
      headerName: 'Excess Path Loss (dB)',
      valueGetter: (params) => valueGetter(params, 1, '0'),
    },
    {
      ...numberColumn,
      field: 'excess_path_loss_at_ke',
      headerName: 'Excess Path Loss at ke (dB)',
      valueGetter: (params) => valueGetter(params, 1, '0'),
    },
    {
      ...numberColumn,
      field: 'total_path_loss',
      headerName: 'Link Loss (dB)',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...defaultColumn,
      field: 'rain_rate',
      headerName: '0.01% Rain Rate',
      valueGetter: (params) => valueGetter(params, 2),
      isComparatorRequired: true,
    },
    {
      ...defaultColumn,
      field: 'rain_attenuation',
      headerName: 'Rain Attenuation (dB/km)',
      valueGetter: (params) => valueGetter(params, 1),
    },
    {
      ...numberColumn,
      field: 'rain_availability',
      headerName: 'Rain Availability %',
      valueGetter: (params) => valueGetter(params, 4),
    },
    {
      ...unavailabilityColumn,
      field: 'rain_unavailability',
      headerName: 'Rain Unavailability',
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'annual_2_way_availability',
      headerName: 'Annual 2-way Availability %',
      valueGetter: (params) => valueGetter(params, 4),
    },
    {
      ...numberColumn,
      field: 'annual_2_way_availability_with_rain',
      headerName: 'Annual 2-way Availability Including Rain %',
    },
    {
      ...unavailabilityColumn,
      field: 'annual_2_way_unavailability',
      headerName: 'Annual 2-way Unavailability',
      isComparatorRequired: true,
    },
    {
      ...unavailabilityColumn,
      field: 'annual_2_way_unavailability_with_rain',
      headerName: 'Annual 2-way Unavailability Including Rain',
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'left_annual1_way_availability',
      headerName: 'Left Annual 1-way Availability %',
      valueGetter: (params) => valueGetter(params, 4, '0'),
    },
    {
      ...numberColumn,
      field: 'right_annual1_way_availability',
      headerName: 'Right Annual 1-way Availability %',
      valueGetter: (params) => valueGetter(params, 4, '0'),
    },
    {
      ...defaultColumn,
      minWidth: 150,
      field: 'remote_product',
      headerName: 'Remote Product',
    },
    {
      ...defaultColumn,
      minWidth: 150,
      field: 'master_end',
      headerName: 'Master End',
    },
    {
      ...numberColumn,
      field: 'bandwidth',
      headerName: 'Bandwidth (MHz)',
    },
    {
      ...defaultColumn,
      field: 'dl_ul_ratio',
      headerName: 'DL/UL Ratio',
    },
    {
      ...defaultColumn,
      field: 'max_mod_mode',
      headerName: 'Maximum Mod Mode',
    },
    {
      ...numberColumn,
      field: 'fresnel_zone_clearance',
      headerName: 'Fresnel Zone Clearance',
      headerValueGetter: ({ context }) => {
        return `Fresnel Zone Clearance (${context.prefs.heightUnits})`;
      },

      filterParams: {
        valueGetter: ({ context, data }) => {
          if (context.prefs.heightUnits === 'ft')
            return Number(data.fresnel_zone_clearance * METER_TO_FEET).toFixed(
              1
            );
          else return Number(data.fresnel_zone_clearance).toFixed(1);
        },
      },
      getQuickFilterText: ({ context, data }) => {
        if (context.prefs.heightUnits === 'ft')
          return Number(data.fresnel_zone_clearance * METER_TO_FEET).toFixed(1);
        else return Number(data.fresnel_zone_clearance).toFixed(1);
      },
      cellRenderer: 'heightRenderer',
      cellRendererParams: {
        precision: 1,
      },
    },
  ];

  return columns;
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWithAuth, postWithAuth, loadGeom } from '../../api';

const initialState = {
  accessPoints: [],
  clutterDetails: {
    U: { label: 'Unclassified', color: '#FFFFFF', height: 0 },
    H: { label: 'Suburban', color: '#FFCCD5', height: 9 },
    C: { label: 'Light Trees - Shrubs', color: '#CCFFCC', height: 5 },
    B: { label: 'Water', color: '#0000FF', height: 0 },
    I: { label: 'Dense Suburban', color: '#FF4D6A', height: 12 },
    D: { label: 'Deciduous Forest', color: '#66FF66', height: 15 },
    A: { label: 'Rural - Open', color: '#A22A2A', height: 0 },
    J: { label: 'Urban', color: '#FF8080', height: 20 },
    E: { label: 'Evergreen Forest', color: '#00E600', height: 20 },
    G: { label: 'Sparse Houses', color: '#EAAEAE', height: 5 },
    K: { label: 'Dense Urban', color: '#FF0000', height: 25 },
    F: { label: 'Tropical Forest', color: '#004D00', height: 20 },
    M: { label: 'Industrial Zone', color: '#808080', height: 20 },
    L: { label: 'High Rise Urban', color: '#990000', height: 35 },
  },
  equipmentConfig: {
    data: {},
    loading: false,
    error: null,
  },
  smsPerAp: {
    items: [],
    getInProgress: false,
    listErrorMessage: null,
    selectedItems: [],
  },
  selectedItems: [],
  accessPointListError: null,
  errorMessage: null,
  // Populates when we click on sm in main menu
  smsPerProject: {
    items: null,
    // for filter in smlist panel
    selectedItems: null,
    loading: false,
    errorMessage: null,
  },
  current: {
    accessPoint: {
      name: null,
      // represents options which cant be configured in the eq config
      equipment: null,
      antenna: null,
      power: null,
      frequency: null,
    },
    config: {
      id: null,
    },
    choices: {
      equipment: null,
      antenna: null,
      power: null,
      frequency: null,
    },
    modified: false,
    loading: false,
    saving: false,
  },
};

//Fetch only the config not all antenna, choices etc;
export const getEquipmentConfig = createAsyncThunk(
  'pmp/getEquipmentConfig',
  async ({ projectId, id }) => {
    return await getWithAuth(`/equipment/${projectId}/${id}`);
  }
);

// To get SM's for a given project
export const fetchSMs = createAsyncThunk('pmp/fetchSMs', async (projectId) => {
  return await getWithAuth(`/project/${projectId}/subscriber_modules`);
});

export const fetchAccessPoints = createAsyncThunk(
  'pmp/fetchAccessPoints',
  async (projectId) => {
    return await loadGeom(projectId, 'access_point');
  }
);

export const fetchAccessPoint = createAsyncThunk(
  'pmp/fetchAccessPoint',
  async ({ projectId, apId }) => {
    return await getWithAuth(`project/${projectId}/access_point/${apId}`);
  }
);


export const saveAccessPoint = createAsyncThunk(
  'pmp/saveAccessPoint',
  async ({ projectId, apId }, { getState }) => {
    const currentConfig = getState().pmp.current;
    const ap = currentConfig.accessPoint;
    const configId = currentConfig.config.id;

    return await postWithAuth(`/access_point/${projectId}/${apId}`, {
      config_id: configId,
      ...ap,
    });
  }
);

export const deleteSelectedAps = createAsyncThunk(
  'pmp/deleteSelectedAps',
  async (_, { getState }) => {
    const state = getState();
    const { projectId } = state.mainFrame;
    const { selectedItems } = state.pmp;

    return await postWithAuth(
      `/access_points/${projectId}`,
      selectedItems,
      'DELETE'
    );
  }
);

export const deletePMPLinks = createAsyncThunk(
  'pmp/pmp_links',
  async ({ projectId, ids }) => {
    return await postWithAuth(`project/${projectId}/pmp_links`, ids, 'DELETE');
  }
);

export const deleteMeshLinks = createAsyncThunk(
  'mesh/mesh_links',
  async ({ projectId, ids }) => {
    return await postWithAuth(`project/${projectId}/mesh_links`, ids, 'DELETE');
  }
);

export const getSubscriberModules = createAsyncThunk(
  'pmp/getSubscriberModules',
  async ({ projectId, apId }) => {
    return await getWithAuth(
      `project/${projectId}/access_points/${apId}/subscribers`
    );
  }
);

export const getSubscriberGeometry = createAsyncThunk(
  'pmp/getSubscriberGeometry',
  async (projectId) => {
    return await getWithAuth(`project/${projectId}/subscribers/geometry`);
  }
);

export const getMeshLinksGeometry = createAsyncThunk(
  'mesh/getMeshLinksGeometry',
  async (projectId) => {
    return await getWithAuth(`project/${projectId}/meshlinks/geometry`);
  }
);

const pmpSlice = createSlice({
  name: 'pmp',
  initialState,
  reducers: {
    setSelectedSMsAp: (state, action) => {
      state.smsPerAp.selectedItems = action.payload;
    },
    updateAttr: (state, action) => {
      const { section, attr, value } = action.payload;
      if (section === null) {
        state.current.accessPoint[attr] = value;
      } else {
        state.current.accessPoint[section][attr] = value;
      }
      state.current.modified = true;
    },
    setSelectedSMsProject: (state, action) => {
      state.smsPerProject.selectedItems = action.payload;
    },
    updateConfig: (state, action) => {
      const { attr, value } = action.payload;
      state.current.config[attr] = value;
      state.current.modified = true;
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
  },
  extraReducers: {
    [getEquipmentConfig.fulfilled]: (state, action) => {
      state.equipmentConfig.loading = false;
      if (action.payload.status === 'success') {
        state.equipmentConfig.data = action.payload;
      } else {
        state.equipmentConfig.error = action.payload.message;
      }
    },
    [getEquipmentConfig.rejected]: (state, action) => {
      state.equipmentConfig.error = action.error.message;
      state.equipmentConfig.loading = false;
    },
    [getEquipmentConfig.pending]: (state) => {
      state.equipmentConfig.loading = true;
    },
    [getSubscriberModules.fulfilled]: (state, action) => {
      state.smsPerAp.getInProgress = false;
      if (action.payload && action.payload.status === 'success')
        state.smsPerAp.items = action.payload.subscriber_modules;
    },
    [getSubscriberModules.rejected]: (state, action) => {
      state.smsPerAp.getInProgress = false;
      state.smsPerAp.listErrorMessage = action.error.message;
    },
    [getSubscriberModules.pending]: (state) => {
      state.smsPerAp.getInProgress = true;
    },
    [fetchSMs.pending]: (state) => {
      state.smsPerProject.loading = true;
    },
    [fetchSMs.rejected]: (state, action) => {
      state.smsPerProject.loading = false;
      state.smsPerProject.errorMessage = action.error.message;
    },
    [fetchSMs.fulfilled]: (state, action) => {
      if (action.payload && action.payload.status === 'success') {
        state.smsPerProject.loading = false;
        state.smsPerProject.items = action.payload.sms;
      } else {
        state.smsPerProject.errorMessage = action.payload.message;
      }
    },
    [fetchAccessPoint.pending]: (state) => {
      state.current.loading = true;
    },
    [fetchAccessPoint.rejected]: (state, action) => {
      state.current.loading = false;
      state.errorMessage = action.error.message;
    },
    [fetchAccessPoint.fulfilled]: (state, action) => {
      state.current.loading = false;
      Object.assign(state.current.accessPoint, action.payload);
    },
    [saveAccessPoint.pending]: (state) => {
      state.current.saving = true;
    },
    [saveAccessPoint.rejected]: (state, action) => {
      state.current.saving = false;
      state.errorMessage = action.error.message;
    },
    [saveAccessPoint.fulfilled]: (state, action) => {
      state.current.saving = false;
      if (action.payload.status === 'success') {
        state.current.modified = false;
      } else {
        state.errorMessage = action.payload.message;
      }
    },
    [deleteSelectedAps.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    [deleteSelectedAps.fulfilled]: (state, action) => {
      if (action.payload.status === 'success') {
        state = initialState;
      } else {
        state.errorMessage = action.payload.message;
      }
    },
  },
});

export const {
  updateAttr,
  updateConfig,
  setSelectedItems,
  setSelectedSMsAp,
  setSelectedSMsProject,
  setSelectedSMs,
} = pmpSlice.actions;
export default pmpSlice.reducer;

import React from 'react';
import {useFormContext} from 'react-hook-form';
import {getLkKind} from './utils';

// link kinds whose paths are referred to as Link A and Link B
const linkKindsAB = [
  'co_polar',
  'cross_polar',
  'xpic',
  'sd_2plus0',
  'unlicensed_2plus0',
  'co_polar_2plus2',
  'cross_polar_2plus2',
  'xpic_2plus2',
  'sd_2plus2',
];

// link kinds whose paths are referred to as Primary to Primary and
// Secondary to Secondary
const linkKindsPS = [
  'unlicensed_1plus1',
];

// link kinds whose paths are referred to as:
// - Primary to Primary
// - Primary to Secondary
// - Secondary to Primary
// - Secondary to Secondary
const linkKindsPPSS = [
  'hot_standby',
];

// link kinds whose paths are referred to as Link A - D
const linkKindsABCD = [
  'cross_polar_4plus0',
  'co_polar_4plus0',
  'xpic_4plus0',
];

const allPathNames = {
  ab: ['Link A', 'Link B'],
  abcd: ['Link A', 'Link B', 'Link C', 'Link D'],
  ps: ['Primary to Primary', 'Secondary to Secondary'],
  ppss: [
    'Primary to Primary',
    'Primary to Secondary',
    'Secondary to Primary',
    'Secondary to Secondary',
  ],
};

function getSummaryLabels(pathAggregation: string | null, pathNames: string[]) {
  if (pathNames === allPathNames.ab || pathNames === allPathNames.abcd) {
    return ['Aggregate', ...pathNames];
  } else {
    return [...pathNames];
  }
}

function getLicensedHotStandbyDiverseHeights(lk, getValues) {
  if (lk !== 'hot_standby') {
    return null;
  }

  // "lh": local height, "rh": remote height
  // "la": local antenna, "ra": remote antennas
  const [
    laProt,
    raProt,
    lhPrimary,
    lhSecondary,
    rhPrimary,
    rhSecondary
  ] = getValues([
    'local.radios.0.antennas.0.config.antenna_protection',
    'remote.radios.0.antennas.0.config.antenna_protection',
    'local.radios.0.antennas.0.height',
    'local.radios.2.antennas.0.height',
    'remote.radios.0.antennas.0.height',
    'remote.radios.1.antennas.0.height',
  ]);

  const isDiverse = (
    laProt === 'Spatial Diversity' || raProt === 'Spatial Diversity'
  );

  const getHeights = (prot, heightPrimary, heightSecondary) => {
    if (prot === 'Spatial Diversity') {
      return [heightPrimary, heightSecondary];
    } else {
      return [heightPrimary, heightPrimary];
    }
  };

  if (isDiverse) {
    return [
      getHeights(laProt, lhPrimary, lhSecondary),
      getHeights(raProt, rhPrimary, rhSecondary),
    ];
  }

  return null;
}

function getPTP700DiverseHeights(getValues, isComplex) {
  if (!isComplex) {
    return null;
  }

  const product = getValues('local.radios.0.equipment.product');
  const isPtp700 =
    product === 'PTP 700 C+I'
      || product === 'PTP 700 BSA'
      || product === 'PTP 700 C-Only';

  if (!isPtp700) {
    return null;
  }

  // "lh": local height, "rh": remote height
  // "la": local antenna, "ra": remote antennas
  const [
    laProt,
    raProt,
    lhLinkA,
    lhLinkB,
    rhLinkA,
    rhLinkB
  ] = getValues([
    'local.radios.0.antennas.0.config.antenna_protection',
    'remote.radios.0.antennas.0.config.antenna_protection',
    'local.radios.0.antennas.0.height',
    'local.radios.1.antennas.0.height',
    'remote.radios.0.antennas.0.height',
    'remote.radios.1.antennas.0.height',
  ]);

  const isDiverse = (
    laProt === 'Spatial Diversity'
    || laProt === 'Redundant Antennas'
    || raProt === 'Spatial Diversity'
    || raProt === 'Redundant Antennas'
  );

  const getHeights = (prot, heightLinkA, heightLinkB) => {
    if (prot === 'Spatial Diversity' || prot === 'Redundant Antennas') {
      return [heightLinkA, heightLinkB];
    } else {
      return [heightLinkA, heightLinkA];
    }
  };

  if (isDiverse) {
    return [
      getHeights(laProt, lhLinkA, lhLinkB),
      getHeights(raProt, rhLinkA, rhLinkB),
    ];
  }

  return null;
}

function ptp700DiverseProfileOptions(pathNames) {
  // NOTE only gets used if getPTP700DiverseHeights() returns non-null.
  // NOTE see ProfileEditorModal.tsx
  const mainMain = 0;
  const diverseDiverse = 3;

  return [
    { text: pathNames[0], key: 0, value: mainMain },
    { text: pathNames[1], key: 1, value: diverseDiverse },
  ];
}

export function useLinkKind() {
  const { getValues } = useFormContext();
  const [lk, pathAggregation] = getValues([
    'local.radios.0.equipment.link_protection',
    'local.radios.0.equipment.path_aggregation',
  ]);

  const isComplex = !(lk == null || lk === 'no_protection');

  let pathNames = [];
  if (linkKindsAB.includes(lk)) {
    pathNames = allPathNames.ab;
  } else if (linkKindsPS.includes(lk)) {
    pathNames = allPathNames.ps;
  } else if (linkKindsPPSS.includes(lk)) {
    pathNames = allPathNames.ppss;
  } else if (linkKindsABCD.includes(lk)) {
    pathNames = allPathNames.abcd;
  }

  let supportsDiverseProfiles = lk === 'sd_2plus0' || lk === 'sd_2plus2';
  let diverseHeights = null;

  if (supportsDiverseProfiles) {
    const [localMain, localDiverse, remoteMain, remoteDiverse] = getValues([
      'local.radios.0.antennas.0.height',
      'local.radios.0.antennas.0.diverse_antenna.height',
      'remote.radios.0.antennas.0.height',
      'remote.radios.0.antennas.0.diverse_antenna.height',
    ]);
    diverseHeights = [[localMain, localDiverse], [remoteMain, remoteDiverse]];
  }

  const ptp700DiverseHeights = getPTP700DiverseHeights(getValues, isComplex);
  let ptp700DiverseOptions = null;
  if (ptp700DiverseHeights != null) {
    supportsDiverseProfiles = true;
    diverseHeights = ptp700DiverseHeights;
    ptp700DiverseOptions = ptp700DiverseProfileOptions(pathNames);
  }

  const hotStandbyDiverseHeights = getLicensedHotStandbyDiverseHeights(lk, getValues);
  let hotStandbyDiverseOptions = null;
  if (hotStandbyDiverseHeights != null) {
    supportsDiverseProfiles = true;
    diverseHeights = hotStandbyDiverseHeights;
    hotStandbyDiverseOptions = [
      { text: 'Primary to Primary', key: 0, value: 0 },
      { text: 'Primary to Secondary', key: 1, value: 1 },
      { text: 'Secondary to Primary', key: 2, value: 2 },
      { text: 'Secondary to Secondary', key: 3, value: 3 },
    ];
  }

  let endPanelNames;
  if (lk === 'hot_standby') {
    endPanelNames = {
      local: [['Primary', 0], ['Secondary', 2]],
      remote: [['Primary', 0], ['Secondary', 1]],
    };
  } else {
    endPanelNames = {
      local: pathNames.map((n, i) => [n, i]),
      remote: pathNames.map((n, i) => [n, i]),
    };
  }

  return {
    lk,
    kind: getLkKind(lk),
    summaryLabels: getSummaryLabels(pathAggregation, pathNames),
    pathNames,
    endPanelNames,
    isComplex,
    showAggregateDetails: lk !== 'unlicensed_2plus0',
    supportsDiverseProfiles,
    diverseHeights,
    ptp700DiverseOptions,
    hotStandbyDiverseOptions,
  };
}

import PTPEquipmentPanel from '../PTPEquipmentPanel';
import {
  PTPFormPanel,
  PTPPanelConfigImpl,
  PTPShowProps,
  PTPChoices,
  syncFormState,
} from '../utils';
import {
  genericEndConfig,
  genericEndPerformanceSummary,
  genericLinkSummary,
} from './config-utils';

const equipment: PTPFormPanel<PTPShowProps> = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'equipment_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'band',
      getter: 'local.radios.0.equipment.band',
    },
    {
      attrName: 'product',
      getter: 'local.radios.0.equipment.product',
    },
    {
      attrName: 'regulation',
      getter: 'local.radios.0.equipment.regulation',
      info({ choices }): string {
        if (choices.local.end.equipment_warning?.length > 0) {
          return choices.local.end.equipment_warning;
        } else if (choices.remote.end.equipment_warning?.length > 0) {
          return choices.remote.end.equipment_warning;
        }
      },
      infoColor: 'red',
    },
    {
      attrName: 'precise_network_timing',
      getter: 'local.radios.0.equipment.precise_network_timing',
    },
  ],
};

const config: PTPFormPanel<any> = {
  kind: 'config',
  title: 'Product Configuration',
  name: 'equipment_config_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'local.radios.0.equipment.bandwidth',
    },
    {
      attrName: 'tdm',
      getter: 'local.radios.0.equipment.tdm',
      show({ choices }): boolean {
        return !choices.equipment.precise_network_timing.value;
      },
      info({ choices }): string {
        // LPWEB-641 - was observed that the warning could appear even if the
        // setting was NONE, so enforce that behaviour in the UI too
        if (choices.equipment.tdm.value === 'NONE') {
          return null;
        }

        if (choices.local.end.e1t1_warning) {
          return choices.local.end.e1t1_warning;
        } else if (choices.remote.end.e1t1_warning) {
          return choices.remote.end.e1t1_warning;
        }
      },
    },
    {
      attrName: 'optimisation',
      getter: 'local.radios.0.equipment.optimisation',
    },
    {
      attrName: 'sync',
      getter: 'local.radios.0.equipment.sync',
    },
    {
      attrName: 'symmetry',
      getter: 'local.radios.0.equipment.symmetry',
    },
    {
      attrName: 'dual_payload',
      getter: 'local.radios.0.equipment.dual_payload',
    },
    {
      attrName: 'highest_mod_mode',
      getter: 'local.radios.0.equipment.highest_mod_mode',
    },
    {
      attrName: 'lowest_ethernet_mode',
      getter: 'local.radios.0.equipment.lowest_ethernet_mode',
    },
    {
      attrName: 'lowest_telecoms_mode',
      getter: 'local.radios.0.equipment.lowest_telecoms_mode',
    },
    {
      attrName: 'master',
      getter: 'local.radios.0.equipment.master',
    },
  ],
};

const endPerformanceOpts = {
  showE1T1: true,
};

const ptp670PanelConfig: PTPPanelConfigImpl = {
  syncFormState,
  equipment: [equipment, config],
  performance: {
    summary: {
      link: [genericLinkSummary({
        showE1T1: true,
      })],
      local: genericEndPerformanceSummary('local', endPerformanceOpts),
      remote: genericEndPerformanceSummary('remote', endPerformanceOpts),
    },
  },
  ends: {
    title: 'Configuration at Each End',
    local: genericEndConfig('local', {
      spatialDiversity: true,
      cableChoices: true,
    }),
    remote: genericEndConfig('remote', {
      spatialDiversity: true,
      cableChoices: true,
    }),
  },
};

export default ptp670PanelConfig;

import { PMPFormPanel, syncFormState } from 'src/pages/pmp/nd/utils';
import NDEquipmentPanel from '../NDEquipmentPanel';
import NDAntennaPanel from '../NDAntennaPanel';
import {
  antennaHeightND,
  antennaSelection,
  cableLoss,
  equipmentPanelFields,
  framePeriod,
  smRegistrationLimit,
  smRangeFields,
  smReceiveTargetLevel,
  ndInterference,
  smDefaultInterference,
  bandwidth,
  modeledBeamwidth,
  isPMP450V,
  azimuth,
  tilt,
  isPMP450m,
  POWER_PANEL_FIELDS_WIDTH,
  eirpRowConfig,
  getNDUserPower,
  getUserEIRP,
} from './config-utils';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import NDSectorPanel from '../NDSectorPanel';
import NDPowerPanel from '../NDPowerPanel';
import { PMP450V } from 'src/app.constants';
import NDComponentCarriersPanel from '../NDComponentCarriersPanel';
import { eirp, gasGroup, powerConfig } from '../../config-utils';
import { powerFieldInfoFormatter } from '../../utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'nd_equipment',
  component: NDEquipmentPanel,
  fields: [
    ...equipmentPanelFields,
    {
      attrName: 'sync_input',
      getter: 'radios.0.equipment.sync_input',
    },
    {
      attrName: 'aes_encryption',
      getter: 'radios.0.equipment.aes_encryption',
    },
    {
      attrName: 'operating_mode',
      getter: 'radios.0.equipment.operating_mode',
    },
    {
      attrName: 'carrier_qty',
      getter: 'radios.0.equipment.carrier_qty',
      label: 'Number of Carriers',
      refreshesChoices: true,
      afterOnChange(newValue, formMethods, newChoices) {
        const { setValue } = formMethods;
        const { equipment } = newChoices;
        if (newValue == 2) {
          setValue('radios.1.equipment.band', equipment.band_carrier_2);
        }
      },
      show({ formGetter }) {
        return isPMP450V(formGetter);
      },
    },
    {
      attrName: 'band_carrier_2',
      getter: 'radios.0.equipment.band_carrier_2',
      refreshesChoices: true,
      show({ formGetter }) {
        const carrierQty = formGetter('radios.0.equipment.carrier_qty');
        return carrierQty === 2;
      },
    },
  ],
};

const product: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'nd_Product',
  component: NDEquipmentPanel,
  fields: [
    bandwidth,
    {
      attrName: 'color_code',
      getter: 'radios.0.equipment.color_code',
    },
    {
      attrName: 'adjacent_channel_support',
      getter: 'radios.0.equipment.adjacent_channel_support',
    },
    ...smRangeFields,
    framePeriod,
    smRegistrationLimit,
    {
      attrName: 'downlink_data',
      getter: 'radios.0.equipment.downlink_data',
    },
    {
      attrName: 'control_slots',
      getter: 'radios.0.equipment.control_slots',
    },
    {
      attrName: 'effective_control_slots',
      getter: 'radios.0.equipment.effective_control_slots',
    },
    {
      attrName: 'broadcast_repeat_count',
      getter: 'radios.0.equipment.broadcast_repeat_count',
    },
    {
      attrName: 'total_virtual_circuits',
      label: 'Total Virtual Circuits',
      getter: 'radios.0.equipment.total_virtual_circuits',

      component: GenericScaledField,
      componentProps() {
        return {};
      },
      editable: false,
    },
  ],
};

const antenna: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'nd_antenna',
  component: NDAntennaPanel,
  fields: [
    antennaSelection,
    modeledBeamwidth,
    antennaHeightND,
    azimuth,
    tilt,
    cableLoss,
  ],
};

const isPMP450VTwoCarrier = (formGetter): boolean => {
  const product = formGetter('radios.0.equipment.product');
  const carrierQty = formGetter('radios.0.equipment.carrier_qty');
  if (product === PMP450V && carrierQty == 2) {
    return true;
  } else {
    return false;
  }
};

const power: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'nd_power',
  show({ formGetter }) {
    return !isPMP450VTwoCarrier(formGetter);
  },
  component: NDPowerPanel,
  fields: [
    gasGroup,
    eirp,
    getUserEIRP(false),
    {
      ...powerConfig,
      show({ formGetter }) {
        return !isPMP450m(formGetter);
      },
    },
    getNDUserPower(false),
    smReceiveTargetLevel,
    ndInterference,
    smDefaultInterference,
  ],
};

const powerComponentProps = ({ choices, path }) => {
  const unitExtraTooltip = powerFieldInfoFormatter(choices, path);
  const cProps = {
    width: POWER_PANEL_FIELDS_WIDTH,
    unitExtraTooltip,
  };
  return cProps;
};

const pmp450VPower: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'nd_power',
  show({ formGetter }) {
    return isPMP450VTwoCarrier(formGetter);
  },
  component: NDSectorPanel,
  customClass: 'cnwave-power',
  sectorTitles: ['Carrier 1', 'Carrier 2'],
  fieldLayout: [[0, 1], [2, 3], [4, 5], [6]],
  fields: [
    ...eirpRowConfig,
    {
      ...powerConfig,
      componentProps({ choices }) {
        return powerComponentProps({ choices, path: '0.power.maximum_power' });
      },
    },
    {
      ...powerConfig,
      getter: 'radios.1.power.power',
      nextValue(currentValue, newChoices, formGetter, attr) {
        return newChoices.power[1].power;
      },
      componentProps({ choices }) {
        return powerComponentProps({ choices, path: '1.power.maximum_power' });
      },
    },
    {
      ...getNDUserPower(true),
    },
    {
      ...getNDUserPower(true, 1),
    },
    {
      ...smReceiveTargetLevel,
      componentProps({ ap }) {
        return { width: 14 };
      },
      afterOnChange(newValue, { getValues, setValue }, newChoices) {
        const carrierQty = getValues('radios.0.equipment.carrier_qty');
        if (carrierQty == 2) {
          setValue('radios.1.power.receive_target_level', newValue);
        }
      },
    },
  ],
};

const componentCarrier: PMPFormPanel = {
  title: 'Component Carriers',
  kind: 'component_carrier',
  name: 'nd_component_carrier',
  component: NDComponentCarriersPanel,
  // Fields are derived dynamically in NDComponentCarrierPanel
  fields: [],
};

export const pmp450ViewConfig = {
  syncFormState,
  equipment,
  product,
  antenna,
  power,
  sectorPower: pmp450VPower,
  componentCarrier,
};

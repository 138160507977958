import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Header, Segment, Popup, Loader } from 'semantic-ui-react';
import {
  onSelectItem,
  runWithConfirmation,
  makePlural,
} from '../../utils/useful_functions';
import additionalMessages from '../../messages';
import FilteredList from '../../components/FilteredList';
import LPGrid from '../../components/controls/lpgrid/LPGrid';
import { store } from '../../store';
import { fetchAccessPoints } from './pmp.reducer';
import {
  uiConfirmAction,
  panelNeedsRefresh,
  bulkUpdateRowRemove,
  setBulkEditSelectedRows,
} from '../mainframe/mainframe.reducer';
import { postWithAuth, getWithAuth } from 'src/api';
import CreateAccessPointModal from './create-access-points/CreateAccessPointModal';
import messages from '../../messages';
import debounce from 'lodash/debounce';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getAccessPointColumns,
  getAdditionalAccessPointColumns,
} from './pmp_nd_columns';
import ConditionalPopup from 'src/components/ConditionalPopup';
import { DELETE_ACTION_TOOLBAR } from 'src/app.constants';

const AccessPointsPanel = (props) => {
  const { formatMessage } = props.intl;
  const accessPoints = useSelector(
    (state) =>
      state.mainFrame.accessPoints?.features.map((i) => i.properties) || []
  );
  const {
    projectId,
    projectName,
    permissionWrite,
    networkSites,
    accessPointsCount,
    bulkEditSelectedRows,
    userLimits,
  } = useSelector((state) => state.mainFrame);
  const navigate = useNavigate();
  const location = useLocation();
  const showTableView = location.hash === '#table';
  const tableRef = useRef();
  const tableRefreshCb = useCallback(
    debounce(() => tableRef?.current?.refresh(), 3000, {
      maxWait: 20000,
      leading: true,
      trailing: true,
    }),
    [tableRef.current]
  );

  const needsRefresh = useSelector(
    (state) => state.mainFrame.needsRefresh.pmpNDPanel
  );

  const getLinksLimitAndError = () => {
    const accessPointsLimit = userLimits?.pmp_network_devices;
    const exceeded = accessPointsCount >= accessPointsLimit;
    return { limit: accessPointsLimit, exceeded: exceeded };
  };

  useEffect(() => {
    if (needsRefresh) {
      tableRefreshCb();
      store.dispatch(
        panelNeedsRefresh({ panels: ['pmpNDPanel'], status: false })
      );
    }
  }, [needsRefresh]);

  useEffect(() => {
    if (bulkEditSelectedRows.length) {
      bulkEditSelectedRows.forEach((data) => {
        if (data.updated) {
          tableRef.current?.api
            ?.getRowNode(data.id)
            ?.setData(JSON.parse(JSON.stringify(data)));
          store.dispatch(bulkUpdateRowRemove(data));
        }
      });
    }
  }, [bulkEditSelectedRows]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const deleteSelection = (
    formatMessage,
    projectId,
    selectedItems,
    tableRef
  ) => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(additionalMessages.deleteAccessPoints),
        message: formatMessage(additionalMessages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(
            `project/${projectId}/access_points`,
            selectedItems,
            'DELETE'
          )
            .then((res) => {
              if (tableRef) {
                tableRef.current?.refresh();
              }
              if (res) {
                store.dispatch(fetchAccessPoints(projectId));
                setSelectedItems([]);
              } else {
                console.warning('Failed to delete access points');
              }
            })
            .catch(console.error);
        },
      })
    );
  };

  let actionsInit = [
    Object.assign({
      icon: 'trash alternate',
      label: formatMessage(messages.delete),
      onClick: (event, data, params) => {
        const selectedRows = params.gridApi.getSelectedRows();
        const selectedSiteIds = selectedRows.map((row) => row.id);
        deleteSelection(formatMessage, projectId, selectedSiteIds, tableRef);
      },
      disabled: true,
      ...DELETE_ACTION_TOOLBAR,
    }),
  ];
  // eslint-disable-next-line
  const [actions, setActions] = useState(actionsInit);
  const disableCreate = !(
    permissionWrite &&
    networkSites &&
    networkSites.features.length
  );

  let createButton = (
    <div>
      {/* @ts-expect-error Server Component */}
      <ConditionalPopup
        showPopup={getLinksLimitAndError().exceeded}
        message={formatMessage(additionalMessages.maximumNumberError, {
          entityName: 'Access Point',
          limit: getLinksLimitAndError().limit,
        })}
      >
        <Button
          icon="plus"
          disabled={disableCreate || getLinksLimitAndError().exceeded}
          onClick={() => setShowCreateModal(true)}
          title={formatMessage(additionalMessages.createAccessPoint)}
        />
      </ConditionalPopup>
    </div>
  );
  if (disableCreate) {
    let createButtonHelp;
    if (!permissionWrite) {
      createButtonHelp = formatMessage(additionalMessages.readOnly);
    } else if (!networkSites || !networkSites.features.length) {
      createButtonHelp = formatMessage(
        additionalMessages.atleastOneNetworkSiteMsg
      );
    }
    createButton = <Popup trigger={createButton} content={createButtonHelp} />;
  }

  const listView = showTableView ? null : (
    <FilteredList
      items={accessPoints}
      displayShowInMap
      selectedItems={selectedItems}
      clickHandler={(data) => navigate(`/aps/${data.id}`)}
      tooltip={(data) => {
        const count = data.connected_subscribers + data.unconnected_subscribers;
        const text = makePlural(count, 'module', 'modules');
        let result = `${count} subscriber ${text}`;
        const warnings = data.warnings?.hover;
        if (warnings?.length > 0) {
          result = `${result} - Reason for error: ${warnings.join(', ')}`;
        }
        return result;
      }}
      onSelectItem={onSelectItem(selectedItems, setSelectedItems, false)}
    />
  );

  const tableListIcon = showTableView ? 'list' : 'table';
  const tableListTitle = showTableView
    ? formatMessage(additionalMessages.listView)
    : formatMessage(additionalMessages.tableView);

  const defaultColumns = getAccessPointColumns(projectId).map((col) =>
    col.hasOwnProperty('getQuickFilterText')
      ? { ...col }
      : {
          ...col,
          getQuickFilterText: (params) => {
            return (
              params.value &&
              params.value.toString().toLowerCase().replaceAll(' ', '')
            );
          },
        }
  );

  const additionalColumns = getAdditionalAccessPointColumns(projectId);
  const dropdownOptionsData = [];
  for (const { id, name } of accessPoints) {
    dropdownOptionsData.push({
      key: id,
      value: id,
      flag: id,
      text: name,
    });
  }
  dropdownOptionsData?.sort((a, b) => (a.text > b.text ? 1 : -1));
  const tableView = showTableView ? (
    <Segment basic style={{ width: '100%' }}>
      {bulkEditSelectedRows.length > 0 && (
        <Header as="h3">
          <Loader active inline size="tiny" />{' '}
          {formatMessage(messages.calculatingResults)}
        </Header>
      )}
      <div style={{ position: 'relative' }}>
        <div
          className="overlay"
          style={{
            display: `${bulkEditSelectedRows.length > 0 ? 'block' : 'none'}`,
          }}
        ></div>
        <LPGrid
          url={`project/${projectId}/access_points`}
          gridRef={tableRef}
          addStatusColor={true}
          actions={actions}
          //   refreshOn={needsRefresh}
          defaultCsvExportParams={{
            fileName: `${projectName}_pmp_network_devices.csv`,
          }}
          getRowNodeId={(data) => {
            return `${data.id}-${data.radio_number}`;
          }}
          columnDefs={[...defaultColumns, ...additionalColumns]}
          enableBrowserTooltips
          table_id="pmp_nd_table"
          isTableColConfigure={true}
          dropdownConfig={{
            optionsData: dropdownOptionsData,
            placeHolderTxt: formatMessage(
              additionalMessages.dropdownPlaceHolderTxt
            ),
            helpText: formatMessage(additionalMessages.copyFromHelp, {
              kind: 'PMP network device',
            }),
            changeHandler: (event, data, gridApi, setDropdownValue) => {
              const rows = gridApi.getSelectedRows();
              const ids = rows.map((row) => row.id);
              const apId = data.value;
              const selectedNDName = data.options.find(
                (e) => e.value === data.value
              );

              const qtyAndKind =
                rows.length === 1
                  ? 'selected PMP network device'
                  : `${rows.length} selected PMP network devices`;
              runWithConfirmation({
                message: `${formatMessage(
                  additionalMessages.copyFromConfirmation,
                  { qtyAndKind }
                )} ${selectedNDName.text}`,
                onConfirm: () => {
                  const url = `project/${projectId}/access_point/${apId}`;
                  rows.forEach((row) => {
                    row.updated = false;
                  });
                  store.dispatch(
                    setBulkEditSelectedRows(JSON.parse(JSON.stringify(rows)))
                  );
                  getWithAuth(url)
                    .then((apData) => {
                      const payload = {
                        ids,
                        data: apData,
                        bulk: 'copy',
                      };
                      postWithAuth(
                        `project/${projectId}/access_points/bulk`,
                        payload,
                        'PATCH'
                      ).then((res) => {
                        //tableRefreshCb();
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                },
                onCancel: () => {
                  setDropdownValue(null);
                },
              });
            },
          }}
        ></LPGrid>
      </div>
    </Segment>
  ) : null;

  const enableDelete =
    permissionWrite && !showTableView && selectedItems.length > 0;

  return (
    <Segment basic>
      {showCreateModal && (
        <CreateAccessPointModal
          showModal={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          projectId={projectId}
          intl={props.intl}
        />
      )}
      <Header>
        <FormattedMessage
          id="common.accessPoints"
          defaultMessage="PMP Network Devices"
        />
        {` (${accessPointsCount})`}
      </Header>
      <div className="detailWrapper">
        <div>
          <Button.Group vertical size="big">
            <Button
              icon={tableListIcon}
              onClick={(e) => {
                if (showTableView) {
                  navigate('/aps');
                } else {
                  navigate('/aps#table');
                }
              }}
              title={tableListTitle}
            />

            {createButton}
            {showTableView ? null : (
              <Button
                icon="trash alternate"
                disabled={!enableDelete}
                title={formatMessage(additionalMessages.deleteSelectedItems)}
                onClick={() =>
                  deleteSelection(formatMessage, projectId, selectedItems)
                }
              />
            )}
          </Button.Group>
        </div>

        {listView}
        {tableView}
      </div>
    </Segment>
  );
};

export default injectIntl(AccessPointsPanel);

import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ValueFormatterParams,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
import {
  getWithAuth,
  setUserSelectedColumns,
  getUserSelectedColumns,
} from '../../../api';
import { useSelector } from 'react-redux';
import {
  encodeLatLong,
  formatMacAddress,
  getCellStyle,
  meterToAny,
  useDragColumnChange,
} from '../../../utils/useful_functions';
import LinkRenderer, {
  CallbackLinkRenderer,
} from './cellrenderers/LinkRenderer';
import YesNoRenderer from './cellrenderers/YesNoRenderer';
import YesNoNaRenderer from './cellrenderers/YesNoNaRenderer';
import NoneStringRenderer from './cellrenderers/NoneStringRenderer';
import MacRenderer from './cellrenderers/MacRenderer';
import { LatRenderer, LongRenderer } from './cellrenderers/CoordinateRenderer';
import {
  fixedRenderer,
  HeightRenderer,
  RangeRenderer,
} from './cellrenderers/UnitRenderer';
import Toolbar, { ToolbarType } from '../Toolbar';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from '../../../messages';
import {
  DOWNLOAD_ACTION_TOOLBAR,
  GREY_COLOR,
  GRID_EDIT_SINGLE_CLICK,
  FIELD_COMPARATOR,
  DEFAULT_TABLE_PAGE_SIZE,
  INITIAL_MAX_TABLE_PAGE_SIZE,
  defaultColumns,
} from 'src/app.constants';
import {
  lpGridActions,
  lpGridSwitchActionIds,
  processCellCallback,
} from './LPGridUtils';
import { Checkbox, Input, Dropdown, Popup } from 'semantic-ui-react';
import NumberEditor from './celleditors/NumberEditor';
import ColumnSelect from './columnselect/ColumnSelect';
import NaStringRenderer from './cellrenderers/NaStringRenderer';
import { RootStateOrAny } from 'src/store';

export type LPGridType = {
  url?: string;
  parser?: Function;
  actions?: ToolbarType[];
  refreshOn?: boolean;
  gridRef?: any;
  autoSize?: boolean;
  autoLoad?: boolean;
  callback?: any; // callback calls whenever grid refreshes
  addStatusColor?: boolean;
  selectAll?: boolean;
  dropdownConfig?: any;
  isTableColConfigure?: boolean;
  table_id?: string;
  extraActions?: ToolbarType[];
  toolbarSlot?: React.FC;
} & GridOptions &
  WrappedComponentProps;

function LPGrid(props: LPGridType) {
  const {
    url,
    parser,
    refreshOn,
    intl,
    autoSize = true,
    gridRef,
    addStatusColor = false,
    callback,
    dropdownConfig,
    isTableColConfigure = false,
    extraActions,
    toolbarSlot,
    ...gridProps
  } = props;
  const autoLoad = props.autoLoad == null ? true : props.autoLoad;
  const { formatMessage } = intl;
  const { prefs, permissionWrite, accessPoints } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const anyDirtyAPs = accessPoints?.features.some(
    (f) => f.properties.strokeColor === GREY_COLOR // this is dirty gray color
  );
  const { created } = useSelector((state: RootStateOrAny) => state?.bestServer);
  const isCreatingLinks = created !== null;
  const {
    columnDefs,
    defaultCsvExportParams,
    pagination = true,
    table_id = '',
    ...rest
  } = gridProps;
  const displayColumnList = useRef(defaultColumns[table_id]);
  let showCheckBox = false;
  if (props.actions?.length > 0 || extraActions?.length > 0) {
    showCheckBox = true;
  }
  const [pageSize, setPageSize] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const [showAllRows, setShowAllRows] = useState(false);
  const [isDeselectAllRows, setIsDeselectAllRows] = useState(false);
  const [isShowAllRowsToggled, setIsShowAlRowsToggled] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [actions, setActions] = useState(props.actions || []);
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [filter, setFilter] = useState<string>();
  const [rowData, setRowData] = useState(gridProps.rowData || []);
  const [columns, setColumns] = useState<ColDef[]>([]);
  const [heightColumnExists, setHeightColumnExists] = useState(false);
  const [latLngColumnExists, setLatLngColumnExists] = useState(false);
  const [rangeColumnExists, setRangeColumnExists] = useState(false);
  const [macColumnExists, setMacColumnsExists] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [isColumnsUpdate, setIsColumnsUpdate] = useState({ status: false });
  // eslint-disable-next-line
  const [error, setError] = useState<String | null>(null);
  const downloadCommand: ToolbarType = {
    ...DOWNLOAD_ACTION_TOOLBAR,
    disabled: !Boolean(rowData?.length),
  };
  const DEFAULT_TOOL_BAR_ACTIONS: ToolbarType[] = [downloadCommand];
  const actionsRef = useRef(actions.concat(DEFAULT_TOOL_BAR_ACTIONS));
  useEffect(() => {
    setActions(actionsRef.current);
    if (gridRef?.current)
      gridRef.current.refresh = (refresh = true) => {
        fetchData(gridApi, refresh);
      };
    if (isTableColConfigure) {
      getUserSelectedColumns(table_id).then((res) => {
        if (res.length) {
          displayColumnList.current = res;
          setColumns(getColumnDef(res));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isColumnsUpdate.status) {
      displayColumnList.current = columns.map((column) => column.field);
      setUserSelectedColumns(table_id, displayColumnList.current);
    }
  }, [isColumnsUpdate]);

  useEffect(() => {
    // May be we can merge all different conditions (heightColumnExists, latLngColumnExists etc)
    // in to single state prefsExists, setPrefsExists
    if (
      heightColumnExists ||
      latLngColumnExists ||
      rangeColumnExists ||
      macColumnExists
    ) {
      gridRef.current.gridOptions.context = { prefs: prefs };
      gridApi.refreshCells({ force: true });
      gridApi.refreshHeader();
    }
  }, [
    prefs.heightUnits,
    prefs.latLngFormat,
    prefs.rangeUnits,
    prefs.macAddressFormat,
  ]);

  useEffect(() => {
    if (rowData?.length && gridApi) {
      let columnArr: ColDef[] = isTableColConfigure
        ? getColumnDef(displayColumnList.current)
        : getColumnDef(Object.keys(rowData[0]));
      gridRef.current.api?.setColumnDefs(columnArr);
      gridApi.refreshCells({ force: true });
      gridApi.refreshHeader();
      setColumns(columnArr);
      doAutoSize();
    }
  }, [permissionWrite]);

  // If data already exists in client
  useEffect(() => {
    if (!url) {
      setRowData(get(gridProps, 'rowData', []));
    }
    if (gridProps.rowData?.length) {
      let columnArr: ColDef[] = isTableColConfigure
        ? getColumnDef(displayColumnList.current)
        : getColumnDef(Object.keys(gridProps.rowData[0]));
      setColumns(columnArr);
      doAutoSize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridProps.rowData, gridProps.columnDefs]);

  useEffect(() => {
    if (!gridApi) return;

    if (isDeselectAllRows && !isShowAllRowsToggled) {
      gridApi.deselectAll();
      return;
    }

    // Get the total number of data in the table
    const totalTableDataLength = gridApi.getModel()?.getRowCount();

    // If all table rows are selected and "Show All Rows" is not checked,
    // select only the rows on the current page and deselect other rows
    if (selectedNodes.length === totalTableDataLength && !showAllRows) {
      // Calculate the start and end row indices for the current page
      const currentPage = gridApi.paginationGetCurrentPage();
      const pageSize = gridApi.paginationGetPageSize();
      const startRow = currentPage * pageSize;
      const endRow = startRow + pageSize;

      let count = 0;
      // Iterate over all rows in the order they are displayed in the table
      gridApi.forEachNodeAfterFilterAndSort((node) => {
        // If the row is on the current page, select it; otherwise, deselect it
        node.setSelected(count >= startRow && count < endRow);
        count++;
      });

      return;
    }

    // If the total of selected rows is equal to defualt table page size or total table data length,
    // and "Show All Rows" is checked, select all rows on the table
    if (
      [DEFAULT_TABLE_PAGE_SIZE, totalTableDataLength].includes(
        selectedNodes.length
      ) &&
      showAllRows
    ) {
      gridApi.selectAll();
    }
  }, [
    selectedNodes.length,
    showAllRows,
    isDeselectAllRows,
    isShowAllRowsToggled,
  ]);

  const getFormatter = (name: string) => {
    let formatter = ({ value }: ValueFormatterParams) => value;
    if (name.includes('latitude') || name.includes('longitude')) {
      setLatLngColumnExists(true);
      formatter = ({ value, colDef, context }: ValueFormatterParams) => {
        const name = colDef.field || colDef.headerName;
        return encodeLatLong(
          value,
          context.prefs.latLngFormat,
          name.includes('latitude') ? true : false
        );
      };
    } else if (
      name.includes('height') ||
      name.includes('fresnel_clearence_m')
    ) {
      setHeightColumnExists(true);
      formatter = (data: ValueFormatterParams) => {
        const { value, context } = data;
        const { prefs } = context;
        let formattedValue: string | number = meterToAny(
          value,
          prefs.heightUnits
        );
        // we need to do unit conversion only on init, but formatter get
        // called on init, after edit as well.. during init value is number
        // and from edit it is string so if it is string we are returing same number
        // else we are calling the meterToAny
        if (typeof value === 'string') formattedValue = value;
        return formattedValue;
      };
    } else if (name === 'range_m') {
      // removed include condition for to work accesspoint sm range
      setRangeColumnExists(true);
      formatter = (data: ValueFormatterParams) => {
        const { value, context } = data;
        const { prefs } = context;
        let formattedValue: string | number = meterToAny(
          value,
          prefs.rangeUnits
        );
        // we need to do unit conversion only on init, but formatter get
        // called on init, after edit as well.. during init value is number
        // and from edit it is string so if it is string we are returing same number
        // else we are calling the meterToAny
        if (typeof value === 'string') formattedValue = value;
        return formattedValue;
      };
    } else if (name === 'mac_address') {
      setMacColumnsExists(true);
      formatter = (data: ValueFormatterParams) => {
        const { value, context } = data;
        const { prefs } = context;
        let formattedValue: string | number = formatMacAddress(
          value,
          prefs.macAddressFormat
        );
        return formattedValue;
      };
    }
    return formatter;
  };

  /*  function autosizeHeaders(gridApi) {
    const MIN_HEIGHT = 16;
    if (gridApi != null && gridApi.finished !== false) {
      gridApi.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll('.ag-header-cell-label');
      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      gridApi.setHeaderHeight(minHeight + 16 + 20); // 16 is top and bottom padding and 20 buffer space
    }
  } */

  function isFirstColumn(params) {
    var displayedColumns = params.columnApi?.getAllDisplayedColumns();
    var thisIsFirstColumn =
      get(displayedColumns, '0.colId') === params.column.colId;
    return thisIsFirstColumn;
  }

  function enableCheckbox(params) {
    if (params.data.radio_number !== undefined) {
      // AP table
      return isFirstColumn(params) && params.data.radio_number !== 2;
    }

    return (
      isFirstColumn(params) &&
      (params.data.path_index == null || params.data.path_index === 0)
    );
  }

  const getColumnDef = (names: string[]): ColDef[] => {
    const columnArr: ColDef[] = [];
    const colDefMap = columnDefs.reduce(
      (obj, item: ColDef) => Object.assign(obj, { [item.field]: item }),
      {}
    );
    // Ensure that any obsolete columns are not displayed.
    // Only column names that are available in the current definitions
    // will be included
    const allowedColumnNames = gridProps.columnDefs.map((c: ColDef) => c.field);
    let fields = names.filter((n) => allowedColumnNames.indexOf(n) >= 0);
    if (!isTableColConfigure) fields = Object.keys(colDefMap);
    fields.forEach((columnName: string, index: number) => {
      const formatter = getFormatter(columnName);
      const extraDef = colDefMap[columnName];
      const def: ColDef = {
        field: columnName,
        valueFormatter: formatter,
        cellClass: ['vertical-lines', ...get(extraDef, 'cellClass', [])],
      };
      if (
        permissionWrite &&
        extraDef &&
        (extraDef['cellEditor'] || extraDef['cellEditorSelector'])
      ) {
        if (extraDef['editable']) {
          def.editable = extraDef['editable'];
        } else {
          def.editable = true;
        }
        def.singleClickEdit = GRID_EDIT_SINGLE_CLICK;
      }
      if (extraDef) {
        delete extraDef.cellClass;
        if (typeof extraDef['editable'] !== 'function') {
          delete extraDef.editable;
        }
      }
      if (addStatusColor) {
        def['cellStyle'] = getCellStyle;
      }
      columnArr.push({ ...def, ...extraDef });
    });
    return columnArr;
  };

  const fetchData = (gridApi, refresh = false) => {
    gridApi?.showLoadingOverlay();
    getWithAuth(url)
      .then((data) => {
        if (!data || data.length === 0) {
          gridApi?.showNoRowsOverlay();
        }
        if (parser) {
          data = parser(data);
        }
        //During refresh we no need to generate column object agains
        if (!refresh) {
          // Read the column headings from the first result if possible.
          // If the request is empty then us an empty array for the column headings
          const columnHeadings = data.length ? Object.keys(data[0]) : [];
          let columnArr: ColDef[] = isTableColConfigure
            ? getColumnDef(displayColumnList.current)
            : getColumnDef(columnHeadings);
          setColumns(columnArr);
          doAutoSize();
        }
        setRowData(data);
        const pageSize =
          data.length < INITIAL_MAX_TABLE_PAGE_SIZE
            ? data.length
            : DEFAULT_TABLE_PAGE_SIZE;
        setPageSize(pageSize);
        callback && callback(data);
      })
      .catch((err) => {
        setError(err);
        //Need to display error
      })
      .finally(() => {
        gridApi?.hideOverlay();
      });
  };

  useEffect(() => {
    if (url) {
      fetchData(gridApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOn, url]);

  const doAutoSize = () => {
    //autosizeHeaders(gridApi);
    if (autoSize && gridColumnApi) {
      setTimeout(() => {
        gridColumnApi.autoSizeAllColumns(true);
      }, 100);
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    //autosizeHeaders(params);
    if (!autoLoad) {
      params.api.showNoRowsOverlay();
    }
    //params.api.sizeColumnsToFit();
    //doAutoSize(params);
  };

  if (gridRef?.current) {
    if (showAllRows) {
      gridRef.current.api?.paginationSetPageSize(Number(rowData.length));
    } else {
      gridRef.current.api?.paginationSetPageSize(Number(pageSize));
    }
  }

  const { onDragStarted, onDragStopped } = useDragColumnChange(
    (e, updatedDisplaycolumnList) => {
      if (isTableColConfigure) {
        displayColumnList.current = updatedDisplaycolumnList;
        setUserSelectedColumns(table_id, updatedDisplaycolumnList).then(
          (res) => {
            if (res) {
              const columnArr: ColDef[] = getColumnDef(
                displayColumnList.current
              );
              setColumns(columnArr);
              doAutoSize();
            }
          }
        );
      }
    }
  );

  const selectedRows = gridApi?.getSelectedRows();
  const dropdownDisabled = selectedRows?.length === 0 ?? true;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Toolbar
            actions={actions}
            extraActions={extraActions}
            clickHandlerParams={{ gridApi: gridApi }}
            rowDataLength={rowData?.length || 0}
          ></Toolbar>
          {dropdownConfig && permissionWrite && (
            <Popup
              content={dropdownConfig.helpText}
              position="right center"
              trigger={
                <span>
                  <Dropdown
                    button
                    fluid
                    search
                    selection
                    options={dropdownConfig.optionsData}
                    disabled={dropdownDisabled}
                    className="icon sm-nd-dropdown-options"
                    onChange={(event, data) => {
                      dropdownConfig.changeHandler(
                        event,
                        data,
                        gridApi,
                        setDropdownValue
                      );
                    }}
                    floating
                    labeled
                    icon="paste"
                    text={dropdownConfig.placeHolderTxt}
                    value={dropdownValue}
                  />
                </span>
              }
            />
          )}
          {toolbarSlot ?? null}
        </div>
        <div className="d-flex">
          {isTableColConfigure && (
            <ColumnSelect
              columns={displayColumnList.current}
              setColumns={setColumns}
              allColumns={columnDefs}
              setIsColumnsUpdate={setIsColumnsUpdate}
              getColumnDef={getColumnDef}
              displayColumnList={displayColumnList}
              doAutoSize={doAutoSize}
            />
          )}
          <Input
            className="mb-2 grid-filter"
            style={{ height: '23px' }}
            placeholder={formatMessage(messages.filter)}
            icon={{
              name: 'filter',
              onClick: (e) => {
                setFilter('');
                gridApi.setQuickFilter('');
              },
              link: true,
              title: formatMessage(messages.clearFilter),
            }}
            value={filter}
            onChange={(e, { value }) => {
              setFilter(value);
              gridApi.setQuickFilter(
                value.toString().toLowerCase().replace(' ', '')
              );
            }}
            autoFocus
            disabled={anyDirtyAPs || isCreatingLinks || !rowData?.length}
          />
        </div>
      </div>
      <div className="ag-theme-alpine generic-grid p-relative">
        <AgGridReact
          ref={gridRef}
          stopEditingWhenCellsLoseFocus
          stopEditingWhenGridLosesFocus
          pagination={pagination}
          paginationPageSize={pageSize}
          suppressDragLeaveHidesColumns={true}
          domLayout={'autoHeight'}
          suppressRowClickSelection={true}
          onDragStarted={onDragStarted}
          onDragStopped={onDragStopped}
          rowHeight={28}
          context={{
            prefs: prefs,
            actions: actionsRef.current,
          }}
          //headerHeight={25}
          rowData={rowData}
          onFirstDataRendered={doAutoSize}
          rowMultiSelectWithClick={true}
          //suppressColumnVirtualisation={true}
          rowSelection="multiple"
          onGridReady={onGridReady}
          onRowDataChanged={({ api }) => {
            doAutoSize();
            const modifiedActions = actionsRef.current.map(
              (action: ToolbarType) => {
                if (action.label === formatMessage(messages.downloadCSV)) {
                  action.disabled =
                    api.getModel().getRowCount() > 0 ? false : true;
                } else if (lpGridSwitchActionIds.includes(action.id)) {
                  action.disabled =
                    api.getSelectedRows().length > 0 ? false : true;
                }
                return action;
              }
            );
            setActions(modifiedActions);
          }}
          //onRowDataUpdated={doAutoSize}
          onCheckboxChanged={(event) => {
            const isDeselectAll =
              event.previousValue === undefined && event.selected;
            if (isDeselectAll) {
              setIsDeselectAllRows(true);
            } else {
              setIsDeselectAllRows(false);
              setIsShowAlRowsToggled(false);
            }
          }}
          onSelectionChanged={(event) => {
            const updatedSelectedNodes = gridApi.getSelectedNodes();
            setSelectedNodes(updatedSelectedNodes);
            const newActions = lpGridActions(
              event.api,
              actions,
              permissionWrite
            );
            setActions(newActions);
          }}
          defaultCsvExportParams={{
            ...defaultCsvExportParams,
            processCellCallback,
          }}
          frameworkComponents={{
            linkRenderer: LinkRenderer,
            yesNoRenderer: YesNoRenderer,
            yesNoNaRenderer: YesNoNaRenderer,
            callbackLinkRenderer: CallbackLinkRenderer,
            latRenderer: LatRenderer,
            longRenderer: LongRenderer,
            fixedRenderer: fixedRenderer,
            noneStringRenderer: NoneStringRenderer,
            heightRenderer: HeightRenderer,
            rangeRenderer: RangeRenderer,
            numberEditor: NumberEditor,
            macRenderer: MacRenderer,
            naStringRenderer: NaStringRenderer,
          }}
          defaultColDef={{
            flex: 1,
            minWidth: 70,
            sortable: true,
            resizable: true,
            filter: true,
            //wrapText: true,
            //autoHeight: true,
            headerCheckboxSelection: isFirstColumn,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: enableCheckbox,
          }}
          applyColumnDefOrder={true}
          //paginationAutoPageSize={true}
          {...rest}
        >
          {columns.map((column) =>
            column.hasOwnProperty('isComparatorRequired') &&
            FIELD_COMPARATOR.hasOwnProperty(column.field) ? (
              <AgGridColumn
                {...column}
                comparator={FIELD_COMPARATOR[column.field]}
                key={column.field}
              />
            ) : (
              <AgGridColumn {...column} key={column.field} />
            )
          )}
        </AgGridReact>

        {pagination &&
        rowData.length > INITIAL_MAX_TABLE_PAGE_SIZE &&
        rowData.length >= pageSize ? (
          <div className="showAllRows">
            <Checkbox
              label="Show All Rows"
              checked={showAllRows}
              onChange={() => {
                setShowAllRows((prevShowAllRows) => !prevShowAllRows);
                setIsShowAlRowsToggled(true);
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export default injectIntl(React.memo(LPGrid));

import React, { useEffect, useState } from 'react';
import TitleAndActions from '../TitleAndActions';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { get, set } from 'lodash';
import { Link } from 'react-router-dom';
import messages from 'src/messages';
import { Accordion } from 'semantic-ui-react';
import NDGeneralPanel from './NDGeneralPanel';
import { postWithAuth } from 'src/api';
import NDEquipmentPanel from './NDEquipmentPanel';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getProductPanels } from './utils';
import NDAntennaPanel from './NDAntennaPanel';
import { useSelector } from 'react-redux';
import { getProductFamily } from 'src/pages/pmp/PMPProductFamilyMap';
import NDPowerPanel from './NDPowerPanel';
import NDSectorPanel from './NDSectorPanel';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import NDComponentCarriersPanel from './NDComponentCarriersPanel';
import StoredAccordion from 'src/components/StoredAccordion';
import SubscriberModulesTable from '../aplayout/SubscriberModulesTable';
import MeshLinksTable from 'src/pages/mesh/MeshLinksTable';
import AccessPointPerformance from '../aplayout/AccessPointPerformance';
import { PMP450V } from 'src/app.constants';
import { updatePostData } from './configs/config-utils';
import { queryCacheKeys } from './useNDData';
import WarningAccordion from '../WarningAccordion';
import { RootStateOrAny } from 'src/store';

export const getChoicePayload = (formValues) => {
  const { radios, ...rest } = formValues;
  const band = get(formValues, 'radios.0.equipment.band');
  const product = get(formValues, 'radios.0.equipment.product');
  const carrierQty = get(formValues, 'radios.0.equipment.carrier_qty');
  // when we switch from multi radio products to single radio products
  // (eg: V5000 to PMP450i etc) we see the stale radio exists in form state
  // exist which may cause issues. So we send only the required radio
  if (band === '60 GHz' || (product === 'PMP 450v 4x4' && carrierQty == 2)) {
    const radio1 = formValues['radios'][1];
    if (radio1 != null && band === '60 GHz') {
      radio1['equipment']['band'] = band;
      radio1['equipment']['product'] = product;
      radio1['equipment']['bandwidth'] = get(
        formValues,
        'radios.0.equipment.bandwidth'
      );
    }
    return formValues;
  } else {
    return {
      ...rest,
      radios: [radios[0]],
    };
  }
};

function NDForm({ projectId, id, ap, choices, formatMessage }) {
  const [modified, setModified] = useState<boolean>(false);
  const needsRefresh = useSelector(
    (state: RootStateOrAny) => state.mainFrame.needsRefresh.accessPointPanel
  );
  const prefs = useSelector((state: RootStateOrAny) => state.mainFrame.prefs);
  const permissionWrite = useSelector(
    (state: RootStateOrAny) => state.mainFrame.permissionWrite
  );
  const predictionModel = useSelector(
    (state: RootStateOrAny) => state.mainFrame.predictionModel
  );
  const formMethods = useForm({ defaultValues: ap });
  const { handleSubmit, getValues, setValue, reset, formState } = formMethods;
  const { isSubmitting, errors } = formState;
  const [name, product] = getValues(['name', 'radios.0.equipment.product']);
  let family = getProductFamily(product);
  const siteId = get(ap, 'site.id');
  const qc = useQueryClient();
  const panels = getProductPanels(product, false);
  const { key, choicesKey } = queryCacheKeys(projectId, false, id);
  const clearCache = () => {
    reset();
    qc.invalidateQueries({
      queryKey: key,
    });
  };

  // we need to clear the react query cache on component
  // unmount to avoid issues like rendering the stale data
  // when we search from menu and navigate to the page
  useEffect(() => {
    return () => {
      reset();
      qc.removeQueries({
        queryKey: key,
      });
    };
  }, [projectId, id]);

  const onSubmit = async (formData: FieldValues) => {
    if (
      ap.site.latitude == get(formData, 'radios.0.antennas.0.latitude') &&
      ap.site.longitude == get(formData, 'radios.0.antennas.0.longitude')
    ) {
      set(formData, 'radios.0.antennas.0.latitude', ap.site.latitude);
      set(formData, 'radios.0.antennas.0.longitude', ap.site.longitude);
    }
    formData = updatePostData(formData);
    //const finalData = merge(ap, formData);
    await postWithAuth(
      `project/${projectId}/access_point/${id}`,
      formData,
      'PUT'
    );
    setModified(false);
  };

  const refreshChoices = ({ field }) => {
    if (!field) {
      // should not occur in production (programmer error)
      throw new Error('Refresh choices requested without field info');
    }

    const mergedFormData = getValues(); //merge(ap, getValues());
    // request new choices based on the updated form state (i.e.
    // in response to a user action: onChange)
    updateChoices.mutate({
      ap: mergedFormData,
      attr: field.attrName,
    });
  };

  const updateChoices = useMutation(
    ({ ap, attr }: { ap: any; attr: string }) => {
      const payload = getChoicePayload(ap);
      const choicesPayload = {
        project_id: projectId,
        ap: payload,
        changed: attr,
      };
      if (attr !== 'product') {
        const prevProduct = get(ap, 'radios.0.equipment.product');
        setValue('product.prev', prevProduct);
      }
      return Promise.allSettled([
        postWithAuth(`equipment/access_point/choices`, choicesPayload),
        Promise.resolve(attr),
      ]);
    },
    {
      onSuccess: (updateChoicesResponse) => {
        const equipmentChoicesResponse = (
          updateChoicesResponse[0] as PromiseFulfilledResult<any>
        ).value;
        const attr = (updateChoicesResponse[1] as PromiseFulfilledResult<any>)
          .value;
        // TODO after changing the backend api we can remove the below code
        // from 84 to 88 which changes equipment choices array to object
        const equipmentChoiceMap = {};
        equipmentChoicesResponse['equipment'].forEach((attr) => {
          equipmentChoiceMap[attr.attr_name] = attr;
        });
        equipmentChoicesResponse['equipment'] = equipmentChoiceMap;
        // update choices query data to cause form to rerender
        // with updated correct choices
        qc.setQueryData(choicesKey, () => ({ ...equipmentChoicesResponse }));

        // update react hook form internal state based on
        // new choices on a field by field basis (e.g. when form
        // values fall out of sync with choices like changing product
        // updating the antenna choices)
        const product = equipmentChoicesResponse.equipment['product']['value'];
        const newPanels = getProductPanels(product, false);
        newPanels.syncFormState(
          getValues,
          setValue,
          newPanels,
          equipmentChoicesResponse,
          attr
        );
      },
    }
  );

  return (
    <Segment className="detailPanel aplayout" floated="left" basic compact>
      <FormProvider {...formMethods}>
        <Form method="post" onSubmit={handleSubmit(onSubmit)}>
          <Grid stackable columns={2}>
            <Grid.Column id="ap-details" width={5}>
              <TitleAndActions
                localProduct={product}
                projectId={projectId}
                kind={'access_point'}
                siteId={siteId}
                powerFromSwitch={ap.properties.power_from_switch}
                modified={modified}
                isSubmitting={isSubmitting}
                id={id}
                name={name}
                dirty={ap.dirty}
                errors={errors}
                formatMessage={formatMessage}
              >
                <Link
                  title={`${formatMessage(messages.viewSite)}`}
                  to={`/network_sites/${siteId}`}
                >
                  {name}
                </Link>
              </TitleAndActions>
              <Accordion
                exclusive={false}
                fluid
                className="product-family-panels"
              >
                <WarningAccordion obj={ap} />
                <NDGeneralPanel
                  ap={ap}
                  title={'General'}
                  setModified={setModified}
                ></NDGeneralPanel>
                <NDEquipmentPanel
                  ap={ap}
                  modified={modified}
                  setModified={setModified}
                  refreshChoices={refreshChoices}
                  choices={choices}
                  panel={panels.equipment}
                ></NDEquipmentPanel>
                {panels.sector && (
                  <NDSectorPanel
                    ap={ap}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.sector}
                  ></NDSectorPanel>
                )}
                {panels.product && (
                  <NDEquipmentPanel
                    ap={ap}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.product}
                  ></NDEquipmentPanel>
                )}
                <NDAntennaPanel
                  ap={ap}
                  modified={modified}
                  panel={panels.antenna}
                  choices={choices}
                  setModified={setModified}
                  refreshChoices={refreshChoices}
                ></NDAntennaPanel>
                {panels.power && (
                  <NDPowerPanel
                    ap={ap}
                    modified={modified}
                    panel={panels.power}
                    choices={choices}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                  ></NDPowerPanel>
                )}
                {panels.sectorPower && (
                  <NDSectorPanel
                    ap={ap}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.sectorPower}
                  ></NDSectorPanel>
                )}
              </Accordion>
            </Grid.Column>
            <Grid.Column id="ap-performance" width={11}>
              <Accordion
                exclusive={false}
                className="product-page-right-panels"
                fluid
              >
                {product === PMP450V && (
                  <NDComponentCarriersPanel
                    ap={ap}
                    isTemplate={false}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.componentCarrier}
                  ></NDComponentCarriersPanel>
                )}
                {family === 'cnWave' && (
                  <StoredAccordion
                    name="MeshLinksModules"
                    title={formatMessage(messages.meshLinks)}
                  >
                    <MeshLinksTable
                      projectId={projectId}
                      prefs={prefs}
                      apId={id}
                      needsRefresh={needsRefresh}
                      disabled={modified}
                      permissionWrite={permissionWrite}
                      apsName={name}
                    ></MeshLinksTable>
                  </StoredAccordion>
                )}
                <StoredAccordion
                  name={'LinksToSubscriberModules'}
                  title={formatMessage(messages.apLinksToSM)}
                >
                  <SubscriberModulesTable
                    projectId={projectId}
                    prefs={prefs}
                    apId={id}
                    needsRefresh={needsRefresh}
                    disabled={modified}
                    permissionWrite={permissionWrite}
                    apsName={name}
                  ></SubscriberModulesTable>
                </StoredAccordion>
                {family !== 'cnReach' && (
                  <StoredAccordion
                    name="AccessPointPerformance"
                    title={`${formatMessage(
                      messages.apPanelPerformance
                    )} (${predictionModel})`}
                  >
                    <AccessPointPerformance
                      projectId={projectId}
                      apId={id}
                      needsRefresh={needsRefresh}
                      disabled={modified}
                    />
                  </StoredAccordion>
                )}
              </Accordion>
            </Grid.Column>
          </Grid>
        </Form>
      </FormProvider>
      <RouteLeavingGuard
        when={modified}
        shouldBlockNavigation={() => modified}
        yes="Yes"
        no="No"
        title={`Network Device: ${name}`}
        content={formatMessage(messages.warning)}
        callback={() => {
          clearCache();
        }}
      />
    </Segment>
  );
}

export default NDForm;

import React, { useEffect } from 'react';
import { Dimmer, Form, Grid, Loader, Popup } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import { NetworkPlan } from './terragraph.modal';
import { injectIntl } from 'react-intl';
import messages from 'src/messages';

function NetworkPlanPanel(props: {
  networkPlanFormData: NetworkPlan;
  networkPlanFormDataChange: Function;
  loading: boolean;
  setValidate: Function;
  intl: any;
}) {
  let { networkPlanFormData, networkPlanFormDataChange, setValidate } = props;

  const { formatMessage } = props.intl;

  const {
    getValues,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    defaultValues: networkPlanFormData,
  });

  const onFormValueChange = () => {
    networkPlanFormDataChange(getValues());
    validateForm();
  };

  useEffect(() => {
    validateForm();
  });

  const validateForm = () => {
    setValidate(true);
  };

  if (props.loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted inline>
          Loading
        </Loader>
      </Dimmer>
    );
  }
  return (
    <>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{ height: 'calc(100vh - 230px)' }}
      >
        <Grid.Row>
          <Grid.Column verticalAlign="middle">
            <Form
              onChange={onFormValueChange}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Form.Field style={{ display: 'inline-flex' }}>
                <Controller
                  name="networkPlan"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Popup
                      content={formatMessage(
                        messages.distributionNetworkHelpText
                      )}
                      position="right center"
                      trigger={
                        <Form.Radio
                          {...rest}
                          name={'networkPlan'}
                          label={formatMessage(messages.Distribution)}
                          onChange={async () => {
                            setValue('networkPlan', 'Distribution');
                            onFormValueChange();
                          }}
                          checked={getValues('networkPlan') === 'Distribution'}
                          error={
                            errors.networkPlan && {
                              content: errors.networkPlan.message,
                            }
                          }
                        />
                      }
                    />
                  )}
                />
              </Form.Field>

              <Form.Field style={{ display: 'inline-flex' }}>
                <Controller
                  name="networkPlan"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Popup
                      content={formatMessage(
                        messages.meshOnlyNetworkCostModelHelpText
                      )}
                      position="right center"
                      trigger={
                        <Form.Radio
                          style={{ marginLeft: '64px' }}
                          {...rest}
                          name={'networkPlan'}
                          label={`${formatMessage(messages.Mesh)} - ${
                            networkPlanFormData.networkSubPlan
                          }`}
                          onChange={async () => {
                            setValue('networkPlan', 'Mesh');
                            onFormValueChange();
                          }}
                          checked={getValues('networkPlan') === 'Mesh'}
                          error={
                            errors.networkPlan && {
                              content: errors.networkPlan.message,
                            }
                          }
                        />
                      }
                    />
                  )}
                />
              </Form.Field>

              {/* {networkPlanFormData.networkPlan === 'Mesh' ? (
                <>
                  <Grid
                    columns={1}
                    textAlign="left"
                    className="m-center-network"
                    centered
                    verticalAlign="middle"
                  >
                    <Grid.Column>
                      <Form.Field className="mb-2">
                        <Controller
                          name="networkSubPlan"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <Popup
                              content={formatMessage(
                                messages.uniformDemanModelHelpText
                              )}
                              position="right center"
                              trigger={
                                <Form.Radio
                                  {...rest}
                                  name={'networkSubPlan'}
                                  label={formatMessage(
                                    messages.uniformDemandModel
                                  )}
                                  onChange={async () => {
                                    setValue(
                                      'networkSubPlan',
                                      'Uniform Demand Model'
                                    );
                                    onFormValueChange();
                                  }}
                                  checked={
                                    getValues('networkSubPlan') ===
                                    'Uniform Demand Model'
                                  }
                                  error={
                                    errors.networkSubPlan && {
                                      content: errors.networkSubPlan.message,
                                    }
                                  }
                                />
                              }
                            />
                          )}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <Controller
                          name="networkSubPlan"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <Popup
                              content={formatMessage(
                                messages.costModelHelpText
                              )}
                              position="right center"
                              trigger={
                                <Form.Radio
                                  {...rest}
                                  name={'model'}
                                  label={formatMessage(messages.costModel)}
                                  onChange={async () => {
                                    setValue('networkSubPlan', 'Cost Model');
                                    onFormValueChange();
                                  }}
                                  checked={
                                    getValues('networkSubPlan') === 'Cost Model'
                                  }
                                  error={
                                    errors.networkSubPlan && {
                                      content: errors.networkSubPlan.message,
                                    }
                                  }
                                />
                              }
                            />
                          )}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </>
              ) : (
                ''
              )} */}
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default injectIntl(NetworkPlanPanel);

import produce from 'immer';
import _ from 'lodash';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { batch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Grid,
  Header,
  Message,
  Modal,
  Radio,
  Segment,
  // Statistic,
  // StatisticGroup,
} from 'semantic-ui-react';
import { getWithAuth, postWithAuth } from '../../api';
import ProductFamilyPanel from './ProductFamilyPanel';
import { store } from 'src/store';
import messages from 'src/messages';
import ResultsTable from './ResultsTable';
import {
  resetResults,
  setResults,
  startCalculation,
  startCreatingLinks,
  calculationComplete,
  setTotalSites,
  settingsChanged,
  setWorkerError,
} from './best_server.reducer';
import {
  longTaskStarted,
  longTaskComplete,
  setProjectModified,
  uiConfirmAction,
} from '../mainframe/mainframe.reducer';
import HeightControl from '../../components/controls/HeightControl';
import WarningsPanel from '../equipment/common/WarningsPanel';
import about from 'src/About';
import { toast } from 'react-toastify';
import {
  BEST_SERVER_PROGRESS_MESSAGE,
  BEST_SERVER_PROGRESS_SUBMESSAGE,
} from 'src/app.constants';
import { useDispatch } from 'react-redux';

const SITE_MAX_HEIGHT = 'site_max_height';
const HEIGHT_AGL = 'height_agl';
const MIN_HEIGHT_CLUTTER = 'min_height_clutter';

function saveSettings(projectId, state, hasError) {
  const saveState = {
    hasError: hasError,
    heights: state.heights,
    omitConnectedSites: state.omitConnectedSites,
    productFamilies: state.productFamilies.values,
    smProductPrefs: state.smProductPrefs,
  };

  postWithAuth(`project/${projectId}/best_server/settings`, saveState).catch(
    (err) => {
      console.error(err);
    }
  );

  store.dispatch(settingsChanged());
}

function SubscriberHeightsPanel(props) {
  const { useClutter, prefs, permissionWrite } = useSelector(
    (state) => state.mainFrame
  );
  const { state, setState } = props;

  useEffect(() => {
    if (prefs.heightUnits === 'm') {
      // keep display heights in sync with prefs/internal values
      setState((state) => ({
        ...state,
        heights: {
          ...state.heights,
          heightAgl: state.heights.internalHeightAgl,
          minHeightClutter: state.heights.internalMinHeightClutter,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefs.heightUnits]);

  const handleChange = (attr) => {
    return (e, { value, unScaledVal }) => {
      setState((state) =>
        produce(state, (draft) => {
          if (attr === 'heightAgl') {
            draft.heights.heightAgl = parseFloat(value);
            draft.heights.internalHeightAgl = parseFloat(
              unScaledVal.toFixed(2)
            );
          } else if (attr === 'minHeightClutter') {
            draft.heights.minHeightClutter = parseFloat(value);
            draft.heights.internalMinHeightClutter = parseFloat(
              unScaledVal.toFixed(2)
            );
          } else {
            draft.heights[attr] = value;
          }
        })
      );
    };
  };

  const disabledProps = (condition) => {
    if (condition) {
      return { disabled: true, style: { opacity: 0.5 } };
    }
    return {};
  };

  return (
    <Segment>
      <Header as="h4">Subscriber Heights</Header>
      <Grid columns="equal">
        <Grid.Column>
          <Form.Field>
            <Radio
              label="Use subscriber site maximum height?"
              name="subscriberHeightType"
              value={SITE_MAX_HEIGHT}
              checked={state.heights.type === SITE_MAX_HEIGHT}
              onChange={handleChange('type')}
              disabled={!permissionWrite}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Use height above ground?"
              name="subscriberHeightType"
              value={HEIGHT_AGL}
              checked={state.heights.type === HEIGHT_AGL}
              onChange={handleChange('type')}
              disabled={!permissionWrite}
            />
          </Form.Field>
          {useClutter && (
            <Form.Field>
              <Radio
                label="Use minimum height above clutter?"
                name="subscriberHeightType"
                value={MIN_HEIGHT_CLUTTER}
                checked={state.heights.type === MIN_HEIGHT_CLUTTER}
                onChange={handleChange('type')}
                disabled={!permissionWrite}
              />
            </Form.Field>
          )}
        </Grid.Column>
        <Grid.Column>
          <HeightControl
            label="Antenna Height Above Ground"
            value={state.heights.heightAgl}
            onChange={handleChange('heightAgl')}
            min={0}
            max={3000}
            width={7}
            valueType="decimal"
            precision={1}
            step={0.1}
            strictMode={true}
            defaultValue={0}
            {...disabledProps(
              state.heights.type === SITE_MAX_HEIGHT || !permissionWrite
            )}
          />
          {useClutter && (
            <HeightControl
              label="Min Antenna Height Above Clutter"
              value={state.heights.minHeightClutter}
              onChange={handleChange('minHeightClutter')}
              min={0}
              max={3000}
              width={7}
              valueType="decimal"
              precision={1}
              step={0.1}
              strictMode={true}
              defaultValue={0}
              {...disabledProps(
                state.heights.type !== MIN_HEIGHT_CLUTTER || !permissionWrite
              )}
            />
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

function ResultsSummary() {
  const { ableToConnect, notMeetingRequirements, outOfRange } = useSelector(
    (state) => state.bestServer
  );
  const { pmpLinksCount, subscriberSiteCount } = useSelector(
    (state) => state.mainFrame
  );

  return (
    <div className="statistic-group">
      <div className="statistic">
        <div className="statistic-value">{subscriberSiteCount}</div>
        <div className="statistic-label">Subscriber Sites</div>
      </div>
      <div className="statistic">
        <div className="statistic-value">{pmpLinksCount}</div>
        <div className="statistic-label">Connected Sites</div>
      </div>
      <div className="statistic">
        <div className="statistic-value">{ableToConnect}</div>
        <div className="statistic-label">Able to Connect</div>
      </div>
      <div className="statistic">
        <div className="statistic-value">{notMeetingRequirements}</div>
        <div className="statistic-label">Not meeting requirements (NMR)</div>
      </div>
      <div className="statistic">
        <div className="statistic-value">{outOfRange}</div>
        <div className="statistic-label">Out of range (OOR)</div>
      </div>
    </div>
  );
}

function destructureFamilies(productFamilies) {
  const choices = Object.fromEntries(
    Object.entries(productFamilies).map(([family, data]) => [
      family,
      data.mode_options || [],
    ])
  );

  let values = {};
  for (const [family, data] of Object.entries(productFamilies)) {
    values[family] = {};
    const { ctrls, mode_options } = data;
    if (ctrls.includes('target_sm_mode')) {
      values[family].targetSmMode = mode_options[0].value;
    }
    if (ctrls.includes('target_sm_fade_margin')) {
      values[family].targetSmFadeMargin = 0;
    }
  }
  return { choices, values };
}

const initialState = {
  productFamilies: {
    choices: null,
    values: {},
  },
  smProductPrefs: {
    choices: {},
    selected: {},
  },
  omitConnectedSites: true,
  heights: {
    type: SITE_MAX_HEIGHT,
    heightAgl: 3,
    internalHeightAgl: 3,
    minHeightClutter: 3,
    internalMinHeightClutter: 3,
  },
};

function BestServerPanel(props) {
  const { formatMessage } = props.intl;
  const dispatch = useDispatch();
  const { projectId, permissionWrite, pmpLinksCount } = useSelector(
    (state) => state.mainFrame
  );
  const {
    complete,
    totalSites,
    results,
    calculating,
    hideCreateButton,
    created,
    ableToConnect,
    workerError,
  } = useSelector((state) => state.bestServer);
  const [state, setState] = useState(initialState);
  const [error, setError] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    getWithAuth(`project/${projectId}/best_server/initial`).then((res) => {
      let newState = {
        ...state,
        productFamilies: {
          ...state.productFamilies,
          ...destructureFamilies(res.product_families),
        },
        smProductPrefs: {
          ...state.smProductPrefs,
          choices: res.sm_product_prefs,
        },
      };

      if (res.settings != null) {
        store.dispatch(setWorkerError(res.settings.hasError));
        newState = {
          ...newState,
          productFamilies: {
            ...newState.productFamilies,
            values: {
              ...newState.productFamilies.values,
              ...res.settings.productFamilies,
            },
          },
          smProductPrefs: {
            selected: {
              ...newState.smProductPrefs.selected,
              ...res.settings.smProductPrefs.selected,
            },
            choices: {
              ...newState.smProductPrefs.choices,
              ...res.settings.smProductPrefs.choices,
            },
          },
          heights: res.settings.heights,
          omitConnectedSites: res.settings.omitConnectedSites,
        };
      } else {
        store.dispatch(setWorkerError(false));
      }
      setState(newState);
      store.dispatch(setResults(res.results));

      // track when initial results have been set (used for preventing saving settings)
      if (!mounted.current) {
        mounted.current = true;
      }
    });

    return () => (mounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveSettingsCb = useCallback(_.debounce(saveSettings, 500), []);

  useEffect(() => {
    if (mounted.current) {
      saveSettingsCb(projectId, state, workerError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.productFamilies,
    state.smProductPrefs,
    state.omitConnectedSites,
    state.heights,
  ]);

  const submit = () => {
    setState((state) => ({ ...state, hasInitialResults: false }));
    setError(false);

    batch(() => {
      if (workerError) {
        store.dispatch(setWorkerError(false));
      }
      store.dispatch(resetResults());
      store.dispatch(startCalculation());
      store.dispatch(
        longTaskStarted({
          heading: BEST_SERVER_PROGRESS_MESSAGE,
          message: BEST_SERVER_PROGRESS_SUBMESSAGE,
        })
      );
    });

    postWithAuth(`project/${projectId}/best_server/calculate`, {
      product_families: state.productFamilies.values,
      sm_product_prefs: state.smProductPrefs.selected,
      sm_product_prefs_order: state.smProductPrefs.choices,
      height_type: state.heights.type,
      height_agl: state.heights.internalHeightAgl,
      min_height_clutter: state.heights.internalMinHeightClutter,
      omit_connected_sites: state.omitConnectedSites,
    })
      .then((response) => {
        batch(() => {
          if (response === 0) {
            store.dispatch(calculationComplete());
            store.dispatch(longTaskComplete());
          } else {
            store.dispatch(setTotalSites(response));
            store.dispatch(setProjectModified(true));
          }
        });
      })
      .catch((err) => {
        setError(true);
        batch(() => {
          store.dispatch(calculationComplete());
          store.dispatch(longTaskComplete());
        });
      });
  };

  const stop = () => {
    dispatch(
      uiConfirmAction({
        header: 'Stop Best Server',
        message: 'Are you sure that you want to stop the best server process?',
        size: 'mini',
        onConfirm: () => {
          postWithAuth(`project/${projectId}/best_server/cancel`, {}).finally(
            (response) => {
              batch(() => {
                store.dispatch(calculationComplete({ complete: false }));
                store.dispatch(longTaskComplete());
                store.dispatch(resetResults());
              });
            }
          );
        },
      })
    );
  };

  const createLinks = (updateMethod) => {
    postWithAuth(`project/${projectId}/best_server/create`, {
      update_method: updateMethod,
    })
      .then((res) => {
        batch(() => {
          store.dispatch(startCreatingLinks(true));
          store.dispatch(
            longTaskStarted({
              heading: BEST_SERVER_PROGRESS_MESSAGE,
              message:
                'Creating links from best server, editing the project is temporarily disabled',
            })
          );
        });
      })
      .catch((err) => {
        toast(<Message error>{err.detail}</Message>, {
          autoClose: false,
        });
      });
  };

  const checkCreateLinks = () => {
    if (!state.omitConnectedSites && pmpLinksCount !== 0) {
      // need to confirm how user wants to create links
      setCreateModalOpen(true);
    } else {
      createLinks('ignore');
    }
  };

  const goButtonValid =
    state.productFamilies?.choices &&
    // negate implies not all families have a selection
    !Object.keys(state.productFamilies.choices).every((family) => {
      // determines if a family has a selection
      const sel = state.smProductPrefs.selected;
      return family in sel && sel[family].length > 0;
    });

  const disableButton =
    complete || !permissionWrite || calculating || goButtonValid;

  const onCloseHandler = () => {
    setCreateModalOpen(false);
  };

  return (
    <>
      <Modal
        open={createModalOpen}
        size="tiny" 
        onClose={onCloseHandler}
      >
        <Modal.Header>
          Update Subscriber Modules
          <Button
            circular
            icon="close"
            title={formatMessage(messages.close)}
            floated="right"
            onClick={onCloseHandler}
          />
        </Modal.Header>
        <Modal.Content>
          <Header>Choose how you would like to create new PMP links</Header>
          <p>
            Click 'Delete Existing' to delete the existing subscriber modules.
            Use this option when redesigning the entire subscriber module
            network.
          </p>
          <p>
            Click 'Update Existing' to update the existing subscriber modules
            with the latest settings. This option may connect a site to a new
            access point, which can result in multiple subscriber modules on a
            single subscriber site that connect to different access points.
          </p>
          <p>Click 'Cancel' to abort.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={onCloseHandler}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            onClick={() => {
              setCreateModalOpen(false);
              createLinks('delete');
            }}
          >
            Delete Existing
          </Button>
          <Button
            onClick={() => {
              setCreateModalOpen(false);
              createLinks('update');
            }}
          >
            Update Existing
          </Button>
        </Modal.Actions>
      </Modal>
      <Segment basic className="best-server-panel">
        <Header>
          <FormattedMessage
            id="common.bestServer"
            defaultMessage="Best Server"
          />
          {calculating ? (
            <Button style={{ float: 'right' }} onClick={stop}>
              {formatMessage(messages.stop)}
            </Button>
          ) : null}
        </Header>

        <div className="detailWrapper">
          <div style={{ maxWidth: '400px' }}>
            <Form>
              <ProductFamilyPanel state={state} setState={setState} />
              <SubscriberHeightsPanel state={state} setState={setState} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '1rem',
                }}
              >
                <Checkbox
                  label="Omit connected sites?"
                  disabled={!permissionWrite}
                  checked={state.omitConnectedSites}
                  onChange={() => {
                    setState({
                      ...state,
                      omitConnectedSites: !state.omitConnectedSites,
                    });
                  }}
                />
                <Button.Group>
                  <Button color="blue" onClick={submit} disabled={disableButton}>
                    Go
                  </Button>
                  {!hideCreateButton &&
                    ableToConnect > 0 &&
                    !error &&
                    results?.length > 0 &&
                    !calculating && (
                      <Button color="blue" onClick={checkCreateLinks}>Create</Button>
                    )}
                </Button.Group>
                {calculating ? <Button onClick={stop}>Stop</Button> : null}
              </div>
            </Form>
          </div>
          <div className="detailPanel" style={{ flexGrow: 1 }}>
            {(error || workerError) && (
              <WarningsPanel
                warning={
                  <p>
                    An unknown error occurred while running the best server
                    calculation. Any results shown may not represent a complete
                    analysis.
                    <br />
                    <a
                      href={`mailto:${about.email}?subject=An error occurred with Best Server Analysis&body=Project id: ${projectId}`}
                    >
                      Click here
                    </a>{' '}
                    to contact the LINKPlanner team.
                  </p>
                }
              />
            )}
            <ResultsSummary />
            <Divider hidden />
            <ResultsTable results={results} />
            {complete ? (
              <p>Analysis complete</p>
            ) : totalSites != null ? (
              <p>
                Processed {results.length} / {totalSites} sites.
              </p>
            ) : null}
            {created != null && <p>Created {created} links</p>}
          </div>
        </div>
      </Segment>
    </>
  );
}

export default injectIntl(BestServerPanel);

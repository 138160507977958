export const getName = (o) => {
  if (o) {
    if (o.username) {
      return o.username;
    } else if (o.data && o.data.username) {
      return o.data.username;
    } else if (o.kind === 'access_point') {
      return o.default_name ? o.default_name : o.name;
    }
    return o.name;
  }
  return 'Undefined';
};

export const accessPointName = (access_point, site, template) => {
  return `${getName(site)} - ${template.equipment.band.name} - ${
    access_point.azimuth
  }\xB0`;
};

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form, Message, FormGroup } from 'semantic-ui-react';
import { encodeLatLong } from 'src/utils/useful_functions';
import { store } from 'src/store';
import { setAfcCoordinates, setDefaultHeight } from '../map.reducer';
import { getCanvas } from 'src/utils/mapUtils';
import { parseCoords } from '../SiteModePanel';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { get, isEmpty } from 'lodash';
import { batch } from 'react-redux';
import messages from 'src/messages';
import { queryClient } from 'src/query-client';

const AFCModePanel = (props) => {
  const { formatMessage } = useIntl();
  const { prefs, projectId } = useSelector((state) => state.mainFrame);
  const { defaultHeight, afcCoords } = useSelector((state) => state.map);
  const [stateHack, setStateHack] = useState(0);
  const afcCoordString = `${encodeLatLong(
    afcCoords.lat,
    prefs.latLngFormat,
    true
  )}, ${encodeLatLong(afcCoords.lng, prefs.latLngFormat, false)}`;
  const [modified, setModified] = useState(false);
  const formMethods = useForm({
    mode: 'onChange',
    values: {
      coordinates: afcCoordString,
      antennaHeight: defaultHeight,
    },
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = formMethods;

  const onSubmit = (afcInputs) => {
    const height = get(afcInputs, 'antennaHeight');
    const coords = get(afcInputs, 'coordinates');
    if (afcCoords) {
      queryClient.removeQueries({
        queryKey: ['afc', projectId, afcCoords.lat, afcCoords.lng],
      });
    }
    const { lat, lng } = parseCoords(coords);
    batch(() => {
      store.dispatch(setAfcCoordinates({ lat, lng }));
      store.dispatch(setDefaultHeight(height));
    });
    const canvas = getCanvas();
    canvas.map.fitBounds([
      [lat, lng],
      [lat, lng],
    ]);
    canvas.map.setZoom(15);
    canvas.setAfcMarkerOnMap(lat, lng);
    setModified(false);
  };

  return (
    <FormProvider {...formMethods}>
      <Form size="large" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup
          style={{ marginBottom: 0, alignItems: 'center', padding: '8px' }}
        >
          <Controller
            control={control}
            name="coordinates"
            rules={{
              required: true,
              validate: {
                coordinates: (value) => {
                  const { valid } = parseCoords(value);
                  return valid;
                },
              },
            }}
            render={({ field: { ref, onChange, ...rest } }) => (
              <Form.Input
                width={3}
                error={errors['coordinates'] != null}
                {...rest}
                label={'Coordinates'}
                onChange={(e, { value }) => {
                  setModified(true);
                  onChange(value);
                }}
              ></Form.Input>
            )}
          />

          <Form.Field width={2} name="antennaHeight">
            <GenericScaledField
              key="antennaHeight"
              defaultValue={defaultHeight}
              label="Antenna Height"
              min={0}
              max={3000}
              onAcceptChange={(e) => {
                const height = Math.max(0, getValues('antennaHeight'));
                store.dispatch(setDefaultHeight(height));
                setValue('antennaHeight', height, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setStateHack((h) => h + 1);
              }}
              watch={stateHack}
              width={10}
              units={prefs['heightUnits']}
              setModified={setModified}
              getter="antennaHeight"
              precision={1}
              disabled={false}
            />
          </Form.Field>
          <Form.Button
            color="blue"
            type="submit"
            style={{ marginTop: '2.1em' }}
            disabled={!isEmpty(errors) || !modified}
          >
            <FormattedMessage id="mapToolbar.submit" defaultMessage="Submit" />
          </Form.Button>
          <Form.Field>
            <Message info style={{ marginTop: 0 }}>
              {formatMessage(messages.afcModeHelpMessage)}
            </Message>
          </Form.Field>
        </FormGroup>
      </Form>
    </FormProvider>
  );
};

export default AFCModePanel;

import React, { useState } from 'react';
import { Header, Icon, Input } from 'semantic-ui-react';
import messages from '../messages';
import sortBy from 'lodash/sortBy';
import { injectIntl } from 'react-intl';

type ExtrasItem = {
  id: string;
  desc: string;
  is_obsolete: boolean;
};

type ExtrasProps = {
  extras: Record<string, ExtrasItem[]>;
  selectedExtras: Set<string>;
  setSelectedExtras: (arg: any) => any;
  intl: any;
  title?: string;
};

type SectionItemsProps = {
  items: ExtrasItem[];
  isFiltered: boolean;
  showIds: Set<string>;
  selectedExtras: Set<string>;
  setSelectedExtras: (arg: any) => any;
};

function SectionItems(props: SectionItemsProps) {
  return (
    <>
      {sortBy(
        props.items.filter((item) => {
          if (props.isFiltered) {
            return props.showIds.has(item.id);
          }
          return true;
        }),
        ['is_obsolete', (e) => e.desc.toLowerCase()]
      ).map((item) => (
        <li
          key={item.id}
          className={item.is_obsolete ? 'obsolete-extra' : ''}
          onClick={() =>
            props.setSelectedExtras((prev) => {
              if (prev.has(item.id)) {
                prev.delete(item.id);
                return new Set([...prev]);
              } else {
                return new Set([...prev, item.id]);
              }
            })
          }
        >
          {props.selectedExtras.has(item.id) ? (
            <Icon name="check square outline" />
          ) : (
            <Icon name="square outline" />
          )}{' '}
          {item.desc} [{item.id}]
        </li>
      ))}
    </>
  );
}

function Extras(props: ExtrasProps) {
  const { extras, selectedExtras, setSelectedExtras, title } = props;
  const { formatMessage } = props.intl;
  const [hiddenSections, setHiddenSections] = useState<Set<string>>(
    new Set(Object.keys(extras))
  );
  const [filter, setFilter] = useState('');

  const isFiltered = filter.length > 0;
  let showSections = new Set<string>();
  let showIds = new Set<string>();
  if (isFiltered) {
    for (const [key, items] of Object.entries(extras)) {
      const matching = items.filter(
        (item) =>
          item.id.toLowerCase().includes(filter) ||
          item.desc.toLowerCase().includes(filter)
      );
      if (matching.length > 0) {
        showSections.add(key);
        matching.forEach((item) => showIds.add(item.id));
      }
    }
  }

  return (
    <>
      {title !== null && <Header>{title}</Header>}
      <div className="extras-heading">
        <h4>Optional Extras</h4>
        <div>
          <Input
            value={filter}
            placeholder={formatMessage(messages.filter)}
            icon={{
              name: 'filter',
              onClick: () => {
                setFilter('');
              },
              link: true,
              title: formatMessage(messages.clearFilter),
            }}
            style={{ width: '100%' }}
            onChange={(e, { value }) => {
              setFilter(value.toLowerCase());
            }}
          />
        </div>
      </div>
      <div className="extras-box">
        {/* common items (no section) */}
        {extras.hasOwnProperty('Common') ? (
          <ul className="common-extras">
            <SectionItems
              items={extras.Common}
              isFiltered={isFiltered}
              showIds={showIds}
              selectedExtras={selectedExtras}
              setSelectedExtras={setSelectedExtras}
            />
          </ul>
        ) : null}

        {/* other sections */}
        {sortBy(
          Object.entries(extras).filter(([key, _]) => {
            if (key === 'Common') {
              return false;
            }
            if (isFiltered) {
              return showSections.has(key);
            }
            return true;
          }),
          (e) => e[0]
        ).map(([key, items]) => {
          const isHidden = hiddenSections.has(key);
          return (
            <React.Fragment key={key}>
              <p
                onClick={() =>
                  setHiddenSections((prev) => {
                    if (prev.has(key)) {
                      prev.delete(key);
                      return new Set([...prev]);
                    } else {
                      return new Set([...prev, key]);
                    }
                  })
                }
              >
                {isHidden ? (
                  <Icon name="plus square outline" />
                ) : (
                  <Icon name="minus square outline" />
                )}
                {key}
              </p>
              {(isFiltered || !isHidden) && (
                <ul>
                  <SectionItems
                    items={items}
                    isFiltered={isFiltered}
                    showIds={showIds}
                    selectedExtras={selectedExtras}
                    setSelectedExtras={setSelectedExtras}
                  />
                </ul>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}

export default injectIntl(Extras);

import {
  PTPChoices,
  PTPEndChoices,
  PTPShowProps,
  syncFormState,
} from '../utils';
import type { PTPPanelConfigImpl, PTPFormPanel } from '../utils';
import {
  genericEndPerformanceSummary,
  genericLinkSummary,
  remoteProductNextValue,
} from './config-utils';
import PTPEquipmentPanel from '../PTPEquipmentPanel';
import FormSelect from 'src/pages/equipment/common/controls/FormSelect';
import {
  genericAntennaControl,
  genericAntennaHeight,
  genericInterference,
  genericMacAddress,
  genericMaxEirp,
  genericMaxPower,
} from './end-config-utils';
import { displayMhzAsGhz, otherEndName, checkValueInChoices } from 'src/utils/useful_functions';
import { Button } from 'semantic-ui-react';

const equipment: PTPFormPanel<any> = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'equipment_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'band',
      getter: 'local.radios.0.equipment.band',
    },
    {
      attrName: 'product',
      getter: 'local.radios.0.equipment.product',
    },
    {
      attrName: 'remote_product',
      getter: 'remote.radios.0.equipment.product',
      nextValue: remoteProductNextValue,
    },
    {
      attrName: 'regulation',
      getter: 'local.radios.0.equipment.regulation',
    },
  ],
};

const config: PTPFormPanel<any> = {
  kind: 'config',
  // TODO might need to change this as the desktop uses the
  // current product name
  title: 'Product Configuration',
  name: 'equipment_config_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'local.radios.0.equipment.bandwidth',
    },
    {
      attrName: 'dl_ul_ratio',
      getter: 'local.radios.0.equipment.dl_ul_ratio',
    },
    {
      attrName: 'max_mod_mode',
      getter: 'local.radios.0.equipment.max_mod_mode',
    },
    {
      attrName: 'min_mod_mode',
      getter: 'local.radios.0.equipment.min_mod_mode',
    },
    {
      attrName: 'master',
      getter: 'local.radios.0.equipment.master',
    },
  ],
};

function invalidFrequency(
  endName: string,
  choices: PTPChoices,
  formGetter: any
) {
  const value = formGetter(`${endName}.radios.0.frequency.tx_frequency`);
  if (value == null) {
    return false;
  }

  const choiceValues = choices[endName].end.tx_freq_choices.map((c) => c.value);
  return !choiceValues.includes(value);
}

function cnWaveEndConfig(endName: string): PTPFormPanel<PTPShowProps> {
  return {
    name: `${endName}_end_config`,
    fields: [
      genericAntennaControl(endName),
      genericAntennaHeight(endName),
      { label: 'DIVIDER' },
      genericMaxEirp(endName),
      genericMaxPower(endName),
      {
        label: 'Tx Frequency',
        attrName: `${endName}_tx_freq`,
        getter: `${endName}.radios.0.frequency.tx_frequency`,
        component: FormSelect,
        componentProps: ({ choices, formGetter }) => {
          let placeholder: string;
          if (invalidFrequency(endName, choices, formGetter)) {
            placeholder = '<Invalid Selection>';
          } else {
            placeholder = '<No Selection>';
          }

          return {
            width: 5,
            placeholder,
            choices: [
              { text: '<No Selection>', value: null, key: '<No Selection>' },
              ...(choices[endName] as PTPEndChoices).end.tx_freq_choices,
            ],
          };
        },
        afterOnChange(newValue, { setValue }) {
          // mirror choice on other end
          setValue(
            `${otherEndName(endName)}.radios.0.frequency.tx_frequency`,
            newValue
          );
        },
        nextValueNullOk: true,
        nextValue(currentValue, newChoices) {
          const choices = newChoices[endName].end.tx_freq_choices;
          if (!checkValueInChoices(choices, currentValue)) {
            // reset frequency when changing from another band
            return null;
          }
          if (currentValue === undefined || currentValue === false) {
            return null;
          }
          return currentValue;
        },
        warning({ formGetter, choices }) {
          if (invalidFrequency(endName, choices, formGetter)) {
            const value = formGetter(
              `${endName}.radios.0.frequency.tx_frequency`
            );
            return `Invalid frequency selected (${displayMhzAsGhz(value)}).`;
          }
          return false;
        },
      },
      {
        label: 'Tx Golay',
        attrName: `${endName}_golay`,
        getter: `${endName}.radios.0.frequency.tx_golay`,
        component: FormSelect,
        componentProps: ({ choices }) => {
          return {
            width: 5,
            choices: (choices[endName] as PTPEndChoices).end.golay_choices,
          };
        },
        nextValue(currentValue, newChoices) {
          const golayChoices = newChoices[endName].end.golay_choices;
          if (golayChoices != null) {
            const values = golayChoices.map((c) => c.value);
            if (!values.includes(currentValue)) {
              return values[0];
            }
          }
        },
        afterOnChange(newValue, { setValue }) {
          // mirror choice on other end
          setValue(
            `${otherEndName(endName)}.radios.0.frequency.tx_golay`,
            newValue
          );
        },
      },
      {
        label: 'Polarity',
        attrName: `${endName}_polarity`,
        getter: `${endName}.radios.0.frequency.polarity`,
        component: FormSelect,
        componentProps: ({ choices }) => {
          return {
            width: 5,
            choices: (choices[endName] as PTPEndChoices).end.polarity_choices,
          };
        },
        nextValue(currentValue, newChoices) {
          const polarityChoices = newChoices[endName].end.polarity_choices;
          if (polarityChoices != null) {
            const values = polarityChoices.map((c) => c.value);
            if (!values.includes(currentValue)) {
              return values[0];
            }
          }
        },
        afterOnChange(newValue, { setValue }) {
          // sync new choice with other end
          let value: string;
          if (newValue === 'Even') {
            value = 'Odd';
          } else if (newValue === 'Odd') {
            value = 'Even';
          } else {
            value = 'Auto';
          }

          setValue(
            `${otherEndName(endName)}.radios.0.frequency.polarity`,
            value
          );
        },
      },
      { label: 'DIVIDER' },
      genericInterference(endName),
      { label: 'DIVIDER' },
      genericMacAddress(endName),
    ],
  };
}

const PTPcnWaveViewConfig: PTPPanelConfigImpl = {
  syncFormState,
  equipment: [equipment, config],
  performance: {
    summary: {
      link: [
        genericLinkSummary({
          showAggregateThroughput: false,
          popupAttr: 'summary.0.link.availability_report',
        }),
      ],
      local: genericEndPerformanceSummary('local', {
        dataRateUnits: 'Gbps',
        receivePowerLabel: 'Operational Power',
        meanIpPredictedPrecision: 3,
        meanIpRequiredPrecision: 2,
        minIpRequiredPrecision: 2,
        helpUrl: '/doc/performance_summary.html#performance-to-each-site',
      }),
      remote: genericEndPerformanceSummary('remote', {
        dataRateUnits: 'Gbps',
        receivePowerLabel: 'Operational Power',
        meanIpPredictedPrecision: 3,
        meanIpRequiredPrecision: 2,
        minIpRequiredPrecision: 2,
      }),
    },
  },
  ends: {
    title: 'Configuration at Each End',
    local: cnWaveEndConfig('local'),
    remote: cnWaveEndConfig('remote'),
  },
};

export default PTPcnWaveViewConfig;

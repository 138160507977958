import React from 'react';
import { Header, Segment, Container } from 'semantic-ui-react';
import about from '../About';
import MainFrame from '../pages/mainframe/MainFrame';

const AboutPanel = () => {
  return (
    <MainFrame className="App">
      <Container>
        <Segment vertical>
          <Header as="h1">{about.name}</Header>
          <p>
            LINKPlanner allows you to model "what if" scenarios - based on
            geography, distance, antenna height, transmit power, and other
            factors – to optimize system performance before purchase. Quickly
            design networks for optimal deployment and cost effectiveness with
            ease.
          </p>
          <p>
            LINKPlanner is a free, user-friendly link-design tool. LINKPlanner
            leads the industry as the most trusted and intuitive RF link
            planning tool, with tens of thousands of links deployed successfully
            worldwide.
          </p>
        </Segment>
        <Segment vertical>
          If you have any questions about the LINKPlanner or for additional
          support please contact{' '}
          {/* note that we have to hard-code the email address in here */}
          <a href="mailto:linkplanner@cambiumnetworks.com">{about.email}</a>.
        </Segment>
        <Segment vertical>
          Share your expertise or request additional help from other LINKPlanner
          users on the{' '}
          <a
            href="http://community.cambiumnetworks.com/t5/LINK-Planner/bd-p/forums_management_link_planner"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cambium Community Forum
          </a>
          .
        </Segment>

        <Segment vertical>{about.copyright}</Segment>
      </Container>
    </MainFrame>
  );
};

export default AboutPanel;

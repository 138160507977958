import { get } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import LimitControl from 'src/pages/ptp/LimitControl';
import { getDisplayValue } from 'src/utils/useful_functions';
import { getWarningLabel } from './NDAntennaPanel';

function NDSectorFormField(props) {
  const { field, choices, ap, refreshChoices, modified, setModified } = props;
  const { equipment } = choices;
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const { control, getValues, setValue } = useFormContext();
  const {
    attrName,
    getter,
    component,
    max,
    min,
    show,
    units,
    refreshesChoices,
    reCalculate = false,
    afterOnChange,
    componentProps,
    editable = true,
    warning,
  } = field;
  const choiceObj = equipment[attrName];
  if (
    show != null &&
    !show({
      choices,
      formGetter: getValues,
      ap,
      formSetter: setValue,
    })
  ) {
    return null;
  }
  const level = choiceObj;
  let editableField = editable;
  let maxValue = max;
  let minValue = min;
  if (typeof max === 'function') {
    maxValue = max({ choices, formGetter: getValues, ap });
  }
  if (typeof min === 'function') {
    minValue = min({ choices, formGetter: getValues, ap });
  }
  const warningContent =
    warning && warning({ ap, choices, formGetter: getValues });
  let cprops: Record<string, any> = componentProps;
  if (typeof componentProps === 'function') {
    cprops = componentProps({
      ap,
      choices,
      formGetter: getValues,
      refreshChoices: refreshChoices,
      formSetter: setValue,
    });
  }
  let ctrl: any = null;
  if (component === GenericScaledField) {
    ctrl = (
      <GenericScaledField
        key={getter}
        {...field}
        disabled={!editableField || !permissionWrite}
        {...cprops}
        units={units}
        recalc={reCalculate && modified}
        onAcceptChange={(e) => {
          if (afterOnChange) {
            afterOnChange(getValues(getter), { getValues, setValue }, choices);
          }
        }}
        defaultValue={get(ap, getter)}
        min={minValue}
        max={maxValue}
        setModified={setModified}
      ></GenericScaledField>
    );
  } else if (component === LimitControl) {
    ctrl = (
      <LimitControl
        key={getter}
        choices={choices}
        type="PMP"
        disabled={!permissionWrite}
        modified={modified}
        min={minValue}
        max={maxValue}
        path={ap}
        {...field}
        setModified={setModified}
        refreshChoices={refreshChoices}
      ></LimitControl>
    );
  }
  if (choiceObj == null && component == null) {
    return null;
  }
  if (level != null && level.choices != null && level.choices.length > 1) {
    ctrl = (
      <Controller
        control={control}
        name={getter}
        render={({ field: { ref, onChange, ...rest } }) => (
          <>
            <Form.Select
              options={level.choices}
              {...rest}
              disabled={!permissionWrite}
              onChange={(e, data) => {
                onChange(data.value);
                setModified(true);
                if (refreshesChoices) {
                  refreshChoices({ field });
                }
                if (afterOnChange) {
                  afterOnChange(data.value, { getValues, setValue }, choices);
                }
              }}
            />
            {warningContent && getWarningLabel(warningContent, modified)}
          </>
        )}
      />
    );
  } else if (
    level != null &&
    level.choices != null &&
    level.choices.length == 1
  ) {
    ctrl = <p>{getDisplayValue(field, level)}</p>;
  }
  return (
    <Form.Field key={field.getter}>
      <label>{level != null && level.display_name}</label>
      {ctrl}
      {/* {message} */}
    </Form.Field>
  );
}

export default NDSectorFormField;

import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import useDebounce from '../hooks/useDebounce';
import { Form, Message } from 'semantic-ui-react';
import { Field, useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { getName } from '../utils/naming';
import additionalMessages from '../messages';

import filterWorker from '../workers/filter.worker';

function SiteSelection(props) {
  const flex = props.flex || false;
  const { formatMessage } = props.intl;
  const networkOnly = props.networkOnly || false;

  const { validateSiteIds } = props;

  const labelText = networkOnly
    ? additionalMessages.networkSites
    : additionalMessages.sites;

  const { errors } = useFormikContext();

  const [filter, setFilter] = useState('');
  const [filteredItems, setFilteredItems] = useState(props.sites);
  const debounceFilter = useDebounce(filter, 500);

  const [selectedSites, setSelectedSites] = useState([]);

  useEffect(() => {
    const worker = new filterWorker();

    worker.onmessage = (m) => {
      setFilteredItems(JSON.parse(m.data));
    };

    worker.postMessage(
      JSON.stringify({
        command: 'filter',
        items: props.sites,
        filter: debounceFilter,
      })
    );
    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, [debounceFilter, props.sites]);

  const sitesOptions = filteredItems.map((site) => {
    return (
      <option key={site.id} value={site.id}>
        {getName(site)}
      </option>
    );
  });

  const style = flex ? {display: "flex", flexDirection: "column", flex: "1 1 0%"} : {};
  const selectStyle = flex ? {flex: "1 1 0%"} : {};

  return (
    <div style={style}>
      {filteredItems.length > 0 ? (
        <>
          <Form.Input
            className="flex-grow-0"
            label={formatMessage(labelText)}
            placeholder={formatMessage(additionalMessages.filter)}
            fluid
            icon={{
              name: 'filter',
              link: true,
              title: formatMessage(additionalMessages.clearFilter),
            }}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            autoFocus
          />
          <Field as="select" name="siteIds" validate={validateSiteIds} multiple style={selectStyle}>
            {sitesOptions}
          </Field>
          {errors?.siteIds ? (
            <Message className="flex-grow-0" negative>
              <p>{errors.siteIds}</p>
            </Message>
          ) : null}
        </>
      ) : networkOnly ? (
        <Link
          to="/networks_sites"
          title={formatMessage(additionalMessages.networkSites)}
        >
          Create Network Sites
        </Link>
      ) : (
        <p>No sites available</p>
      )}
    </div>
  );
}

export default injectIntl(SiteSelection);

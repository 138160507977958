import React from 'react';
import { injectIntl } from 'react-intl';
import { Card, FormCheckbox } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import messages from 'src/messages';
import { Props } from './FeederLossField';
import { Controller, useFormContext } from 'react-hook-form';
import './FeederLossContent.css';

function FeederLossPopupContent(props: any) {
  const { control } = useFormContext();
  const { id } = useParams();
  const { formatMessage } = props.intl;
  const {
    totalLoss,
    calculatedFeederLosses,
    useWaveguideLoss,
    useWaveguideLossGetter,
    otherLossesGetter,
    hasCoupler,
    requiresDiplexer,
  } = props;

  const flexWaveguideLossValue = useWaveguideLoss
    ? calculatedFeederLosses?.flex_waveguide_loss
    : 0.0;

  return (
    <>
      <Card.Meta className="warning feeder-loss-warning">
        {formatMessage(messages.ptpFeederLossWarningMessage)}
      </Card.Meta>

      <div className="feeder-loss-grid">
        <span>Flexible Waveguide Loss</span>

        <div className="waveguide-row">
          <LossValue value={flexWaveguideLossValue} precision={2} />

          <Controller
            control={control}
            name={useWaveguideLossGetter}
            render={({ field: { ref, value, onChange, ...rest } }) => {
              return (
                <FormCheckbox
                  {...rest}
                  checked={value}
                  onChange={(_, data) => {
                    const { checked } = data;
                    onChange(checked);
                  }}
                />
              );
            }}
          />
        </div>

        {hasCoupler ? (
          <>
            <span>Mediation Device Loss</span>
            <LossValue
              value={calculatedFeederLosses?.coupler_loss}
              precision={2}
            />
          </>
        ) : null}

        {requiresDiplexer && calculatedFeederLosses?.diplexer_loss > 0 ? (
          <>
            <span>Diplexer Loss</span>
            <LossValue
              value={calculatedFeederLosses?.diplexer_loss}
              precision={2}
            />
          </>
        ) : null}

        <span>Other Losses</span>
        <GenericScaledField
          label={null}
          getter={otherLossesGetter}
          units="dB"
          min={-999}
          max={999}
          precision={1}
          watch={id}
        />

        <span>Total Feeder Loss</span>
        <LossValue value={totalLoss} precision={1} />
      </div>
    </>
  );
}

function LossValue(props: any) {
  let value;
  if (props.value == null) {
    value = 'Loading...';
  } else if (typeof props.value === 'string') {
    value = props.value;
  } else {
    value = props.value.toFixed(props.precision);
  }

  return (
    <div className="field">
      <div className="fields">
        <div className="disabled field no-border">
          <div className="ui disabled input">
            <input disabled={true} value={value} />
          </div>
        </div>
        <div className="ui label">dB</div>
      </div>
    </div>
  );
}

export default injectIntl(FeederLossPopupContent);

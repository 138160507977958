import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormField, Table, TableCell } from 'semantic-ui-react';
import { PMPPanelProps } from './utils';
import LimitControl from 'src/pages/ptp/LimitControl';
import ComponentCarrierBandwidth from './ComponentCarrierBandwidth';
import TxFrequencyField from './TxFrequencyField';
import { useFormContext, useWatch } from 'react-hook-form';
import messages from 'src/messages';
import { smDefaultInterferenceChangeHandler } from './configs/config-utils';
import { bands } from 'src/model/BandDefinition';
import { interferenceChangeHandler } from '../config-utils';
import { getDefaultNoise } from '../utils';
import { useIsMount } from 'src/hooks/useIsMount';

export type ComponentCarrierType = {
  index: number;
  intl: any;
  isTemplate: boolean;
} & PMPPanelProps;

function ComponentCarrier(props: ComponentCarrierType) {
  const { index, setModified, isTemplate, refreshChoices, choices } = props;
  const { formatMessage } = useIntl();
  let band = useWatch({ name: `radios.${index}.equipment.band` });
  const { setValue, getValues } = useFormContext();
  const isMount = useIsMount();

  useEffect(() => {
    // for index 0 which is single carrier
    // regular flow (nextvalue) takes care of setting the
    // noise density
    // we no need to calculate the default noise on initial load
    // because if custom noise is set it will get overide, so to avoid
    // we check isMount flag
    if (band != null && index == 1 && !isMount) {
      let bandwidth = getValues(`radios.${index}.equipment.bandwidth`);
      const defaultNoiseDensity = bands[band]['noiseDensity'];
      const noise = getDefaultNoise(band, bandwidth);
      setValue(`radios.${index}.power.noise_density`, defaultNoiseDensity);
      setValue(`radios.${index}.power.noise_density_sm`, defaultNoiseDensity);
      setValue(`radios.${index}.power.noise`, noise);
      setValue(`radios.${index}.power.noise_sm`, noise);
    }
  }, [band, index]);

  return (
    <Table.Row className={isTemplate ? 'template' : ''}>
      <TableCell width={1}>
        <label>{index + 1}</label>
      </TableCell>
      <TableCell width={2}>
        <label>
          {formatMessage(messages.carrier)} {index + 1}
        </label>
      </TableCell>
      <TableCell width={2}>{band}</TableCell>
      <TableCell width={2}>
        <ComponentCarrierBandwidth {...props}></ComponentCarrierBandwidth>
      </TableCell>
      {!isTemplate && (
        <TableCell width={3}>
          <Form.Field>
            <FormField inline>
              <TxFrequencyField {...props}></TxFrequencyField>
            </FormField>
          </Form.Field>
        </TableCell>
      )}
      <TableCell width={3}>
        <Form.Field className="interference-controls">
          <LimitControl
            setModified={setModified}
            componentProps={({ formGetter, formSetter, choices }) => {
              return {
                watch: [
                  choices.equipment.band.value,
                  // bandwidth doesnt exist in cnreach product, with ISM setting so safety check
                  choices.equipment.bandwidth?.value,
                ],
                onAcceptChange: () => {
                  interferenceChangeHandler({
                    formGetter,
                    formSetter,
                    idx: index,
                  });
                },
              };
            }}
            choices={choices}
            attrName="ap_interference"
            checkboxGetter={`radios.${index}.power.use_noise`}
            valueGetter={`radios.${index}.power.noise`}
            precision={1}
            defaultValue={({ formGetter }) => {
              return formGetter('radios.0.power.noise');
            }}
            min={-144}
            max={-40}
            refreshChoices={refreshChoices}
          ></LimitControl>
        </Form.Field>
      </TableCell>
      <TableCell width={3}>
        <Form.Field className="interference-controls">
          <LimitControl
            setModified={setModified}
            choices={choices}
            attrName="default_sm_interference"
            checkboxGetter={`radios.${index}.power.use_noise_sm`}
            valueGetter={`radios.${index}.power.noise_sm`}
            min={-144}
            componentProps={({ formGetter, formSetter, choices }) => {
              return {
                watch: [
                  choices.equipment.band.value,
                  // bandwidth doesnt exist in cnreach product, with ISM setting so safety check
                  choices.equipment.bandwidth?.value,
                ],
                onAcceptChange: () => {
                  smDefaultInterferenceChangeHandler({
                    formGetter,
                    formSetter,
                    idx: index,
                  });
                },
              };
            }}
            defaultValue={({ formGetter }) => {
              return formGetter('radios.0.power.noise_sm');
            }}
            precision={1}
            max={-40}
            refreshChoices={refreshChoices}
          ></LimitControl>
        </Form.Field>
      </TableCell>
    </Table.Row>
  );
}

export default ComponentCarrier;

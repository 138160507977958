import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Segment, Header, Popup, Loader } from 'semantic-ui-react';
import LPGrid from '../../components/controls/lpgrid/LPGrid';
import FilteredList from '../../components/FilteredList';
import {
  onSelectItem,
  runWithConfirmation,
} from '../../utils/useful_functions';
import { store } from '../../store';
import { deletePMPLinks } from './pmp.reducer';
import {
  getPMPLinksColumns,
  getAdditionalPMPLinksColumns,
} from './pmp_link_columns';
import additionalMessages from '../../messages';
import {
  uiConfirmAction,
  setSelectedPMPLinks,
  panelNeedsRefresh,
  bulkUpdateRowRemove,
  setBulkEditSelectedRows,
} from '../mainframe/mainframe.reducer';
import AttachSubscribersProject from './AttachSubscribersDialog';
import { postWithAuth } from 'src/api';
import debounce from 'lodash/debounce';
import { useLocation, useNavigate } from 'react-router-dom';
import ConditionalPopup from 'src/components/ConditionalPopup';
import { validateSubscriberSites } from 'src/utils/mapUtils';
import { DELETE_ACTION_TOOLBAR } from 'src/app.constants';

function PMPListPanel(props) {
  const { formatMessage } = props.intl;
  const [showAttachSM, setShowAttachSM] = useState(false);
  const {
    projectId,
    projectName,
    permissionWrite,
    accessPointsCount,
    pmpLinks,
    pmpLinksCount,
    selectedPMPItems,
    prefs,
    bulkEditSelectedRows,
    userLimits,
  } = useSelector((state) => state.mainFrame);
  const needsRefresh = useSelector(
    (state) => state.mainFrame.needsRefresh.pmpLinksPanel
  );

  const navigate = useNavigate();
  const location = useLocation();
  const showTableView = location.hash === '#table';

  const tableRef = useRef();
  const tableRefreshCb = useCallback(
    debounce(() => tableRef?.current?.refresh(), 3000, {
      maxWait: 20000,
      leading: true,
      trailing: true,
    }),
    [tableRef.current]
  );

  const getLinksLimitAndError = () => {
    const pmpLinksLimit = userLimits?.pmp_links;
    const exceeded = pmpLinksCount >= pmpLinksLimit;
    return { limit: pmpLinksLimit, exceeded: exceeded };
  };

  useEffect(() => {
    if (needsRefresh) {
      tableRefreshCb();
      store.dispatch(
        panelNeedsRefresh({ panels: ['pmpLinksPanel'], status: false })
      );
    }
  }, [needsRefresh]);

  useEffect(() => {
    if (bulkEditSelectedRows.length) {
      bulkEditSelectedRows.forEach((data) => {
        if (data.updated) {
          tableRef.current?.api?.getRowNode(data.id)?.setData({ ...data });
          store.dispatch(bulkUpdateRowRemove(data));
        }
      });
    }
  }, [bulkEditSelectedRows]);

  let items = [];
  if (pmpLinks) {
    items = pmpLinks.features.map((link) => {
      return link.properties;
    });
  }
  let actionsInit = [
    Object.assign({
      icon: 'trash alternate',
      label: formatMessage(additionalMessages.delete),
      onClick: (event, data, params) => {
        const selectedRows = params.gridApi.getSelectedRows();
        const selectedSiteIds = selectedRows.map((row) => row.id);
        deleteHandler(formatMessage, projectId, selectedSiteIds, tableRef);
      },
      disabled: true,
      ...DELETE_ACTION_TOOLBAR,
    }),
  ];
  // eslint-disable-next-line
  const [actions, setActions] = useState(actionsInit);

  const clickHandler = (sm) => {
    navigate(`/subscriber/${sm.sm_id}`);
  };

  const listView = showTableView ? null : (
    <FilteredList
      items={items || []}
      displayShowInMap
      clickHandler={clickHandler}
      tooltip={(data) => {
        if (
          !data.warnings ||
          !data.warnings.hover ||
          data.warnings.hover.length === 0
        ) {
          return null;
        }
        return `${data.warnings?.hover}`;
      }}
      selectedItems={selectedPMPItems || []}
      onSelectItem={onSelectItem(selectedPMPItems || [], setSelectedPMPLinks)}
    />
  );

  const tableListIcon = showTableView ? 'list' : 'table';
  const tableListTitle = showTableView
    ? formatMessage(additionalMessages.listView)
    : formatMessage(additionalMessages.tableView);

  const defaultColumns = getPMPLinksColumns(prefs, projectId, true).map((col) =>
    col.hasOwnProperty('getQuickFilterText')
      ? { ...col }
      : {
          ...col,
          getQuickFilterText: (params) => {
            return (
              params.value &&
              params.value.toString().toLowerCase().replaceAll(' ', '')
            );
          },
        }
  );
  const additionalColumns = getAdditionalPMPLinksColumns(projectId);

  const dropdownOptionsData = [];
  for (const { sm_id, name } of items) {
    dropdownOptionsData.push({
      key: sm_id,
      value: sm_id,
      flag: sm_id,
      text: name,
    });
  }
  dropdownOptionsData?.sort((a, b) => (a.text > b.text ? 1 : -1));
  const tableView = showTableView ? (
    <Segment basic style={{ width: '100%' }}>
      {bulkEditSelectedRows.length > 0 && (
        <Header as="h3">
          <Loader active inline size="tiny" />{' '}
          {formatMessage(additionalMessages.calculatingResults)}
        </Header>
      )}
      <div style={{ position: 'relative' }}>
        <div
          className="overlay"
          style={{
            display: `${bulkEditSelectedRows.length > 0 ? 'block' : 'none'}`,
          }}
        ></div>
        <LPGrid
          url={`project/${projectId}/pmp_links`}
          gridRef={tableRef}
          actions={actions}
          addStatusColor={true}
          defaultCsvExportParams={{ fileName: `${projectName}_pmp_links.csv` }}
          columnDefs={[...defaultColumns, ...additionalColumns]}
          enableBrowserTooltips
          getRowStyle={(params) => {
            if (!!params.data.strokeColor) {
              return { color: params.data.strokeColor };
            }
          }}
          table_id="pmp_links_table"
          isTableColConfigure={true}
          getRowNodeId={(data) => {
            return data.id;
          }}
          dropdownConfig={{
            optionsData: dropdownOptionsData,
            placeHolderTxt: formatMessage(
              additionalMessages.dropdownPlaceHolderTxt
            ),
            helpText: formatMessage(additionalMessages.copyFromHelp, {
              kind: 'link',
            }),
            changeHandler: (event, data, gridApi, setDropdownValue) => {
              const rows = gridApi.getSelectedRows();
              const ids = rows.map((row) => row.remote_end_id);
              const smId = data.value;
              const selectedSMName = data.options.find(
                (e) => e.value === data.value
              );
              const qtyAndKind =
                rows.length === 1
                  ? 'selected link'
                  : `${rows.length} selected links`;
              runWithConfirmation({
                message: `${formatMessage(
                  additionalMessages.copyFromConfirmation,
                  { qtyAndKind }
                )} ${selectedSMName.text}`,
                onConfirm: () => {
                  const payload = {
                    ids,
                    copy_id: smId,
                    data: {},
                    bulk: 'copy',
                  };
                  rows.forEach((row) => {
                    row.updated = false;
                  });
                  store.dispatch(
                    setBulkEditSelectedRows(JSON.parse(JSON.stringify(rows)))
                  );
                  postWithAuth(
                    `project/${projectId}/subscribers/bulk`,
                    payload,
                    'PATCH'
                  ).then((res) => {
                    //tableRefreshCb();
                  });
                },
                onCancel: () => {
                  setDropdownValue(null);
                },
              });
            },
          }}
        ></LPGrid>
      </div>
    </Segment>
  ) : null;

  const deleteHandler = (
    formatMessage,
    projectId,
    selectedPMPItems,
    tableRef
  ) => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(additionalMessages.deletePMPLinks),
        message: formatMessage(additionalMessages.confirm),
        size: 'mini',
        onConfirm: () => {
          store
            .dispatch(
              deletePMPLinks({
                projectId,
                ids: selectedPMPItems,
              })
            )
            .then((res) => {
              if (tableRef) {
                tableRef.current?.refresh();
              }
              store.dispatch(setSelectedPMPLinks([]));
            })
            .catch((err) => {
              //TODO display error
              console.log('Error', err);
            });
        },
      })
    );
  };

  const enableDelete =
    permissionWrite &&
    !showTableView &&
    (selectedPMPItems?.length ? true : false);
  const enableAdd = permissionWrite && accessPointsCount;

  let addBtn = (
    <div>
      {/* @ts-expect-error Server Component */}
      <ConditionalPopup
        showPopup={getLinksLimitAndError().exceeded}
        message={formatMessage(additionalMessages.maximumNumberError, {
          entityName: 'PMP Link',
          limit: getLinksLimitAndError().limit,
        })}
      >
        <Button
          icon="plus"
          disabled={!enableAdd || getLinksLimitAndError().exceeded}
          onClick={() => {
            setShowAttachSM(true);
          }}
          title={formatMessage(additionalMessages.attachSP)}
        />
      </ConditionalPopup>
    </div>
  );

  // Similar tooltip text is there in AP panel..
  // we can make generic function
  if (!enableAdd) {
    let createApBtnHelp = '';
    if (!permissionWrite) {
      createApBtnHelp = formatMessage(additionalMessages.readOnly);
    } else if (!accessPointsCount) {
      createApBtnHelp = formatMessage(additionalMessages.atleastOneAPMsg);
    }
    addBtn = <Popup trigger={addBtn} content={createApBtnHelp} />;
  }

  return (
    <Segment basic>
      {showAttachSM && (
        <AttachSubscribersProject
          intl={props.intl}
          onClose={() => setShowAttachSM(false)}
          validateSubscriberSites={validateSubscriberSites}
        ></AttachSubscribersProject>
      )}
      <Header>
        <FormattedMessage id="common.pmpLinks" defaultMessage="PMP Links" />
        {` (${pmpLinksCount})`}
      </Header>
      <div className="detailWrapper">
        <div>
          <Button.Group vertical size="big">
            <Button
              icon={tableListIcon}
              onClick={(e) => {
                if (showTableView) {
                  navigate('/pmp');
                } else {
                  navigate('/pmp#table');
                }
              }}
              title={tableListTitle}
            />

            {addBtn}
            {showTableView ? null : (
              <Button
                icon="trash alternate"
                disabled={!enableDelete}
                onClick={() =>
                  deleteHandler(formatMessage, projectId, selectedPMPItems)
                }
                title={formatMessage(additionalMessages.deleteSelectedItems)}
              />
            )}
          </Button.Group>
        </div>

        {listView}
        {tableView}
      </div>
    </Segment>
  );
}

export default injectIntl(PMPListPanel);

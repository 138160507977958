import React, { Component } from 'react';
import { List, Input, Button, Loader, Grid, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getName } from '../utils/naming';
import { store, history } from '../store';
import additionalMessages from '../messages';
import { uiSet } from '../pages/mainframe/mainframe.reducer';
import { getCanvas } from '../utils/mapUtils';
import { getStatusColor } from '../utils/useful_functions';

import filterWorker from '../workers/filter.worker';
import { useNavigate } from 'react-router-dom';

const DEFAULT_STATE = {
  filter: '',
  offset: 0,
  filteredItems: null,
  projectId: null,
  loading: false,
};

function getIconName(icon) {
  if (icon == null) {
    return "link";
  }

  if (icon === "link_unlicensed_2plus0") {
    return "link_co_polar";
  } else if (icon === "link_unlicensed_1plus1") {
    return "link_hot_standby";
  }

  return icon;
}

function ListRow(props) {
  const { ref, item, tooltipText, includeIcon } = props;

  let extraStyle = {};
  let icon = null;
  if (includeIcon) {
    extraStyle = { display: "flex", gap: "1rem" };
    const iconName = getIconName(item.icon);
    const iconAlt = item.icon_alt ?? "PTP Link";
    icon = (
      <img
        src={`/assets/icons/16/${iconName}.png`}
        alt={`PTP Link (${iconAlt})`}
        title={iconAlt}
        width="12"
        height="12"
      />
    );
  }

  return (
    <div
      ref={ref}
      style={{
        color: getStatusColor(item.strokeColor),
        ...extraStyle,
      }}
    >
      <span style={{ flex: "1 1 0%" }}>
        {tooltipText != null ? (
          <abbr title={tooltipText}>{getName(item)}</abbr>
        ) : (
          getName(item)
        )}
      </span>
      {icon}
    </div>
  );
}

class FilteredListContainer extends Component {
  constructor(props) {
    super(props);
    this._asyncFilter = null;
    this.state = DEFAULT_STATE;
    this.selectedElement = null;
    this.worker = new filterWorker();
    this.worker.onmessage = (m) => {
      const results = JSON.parse(m.data);
      this.setState({
        filteredItems: results,
        loading: false,
      });
    };
  }

  _filterData = (props, state) => {
    if (!state.loading) {
      this.worker.postMessage(
        JSON.stringify({
          command: 'filter',
          items: props.items,
          filter: state.filter,
          mapBounds: props.filterOnMapBounds ? props.mapBounds : null,
          // It's too slow to pass the drawn features through the store
          // so get them directly from the canvas. The mapBounds will
          // change in the store, which triggers the list refresh.
          drawnFeatures: props.filterOnMapBounds
            ? Object.keys(getCanvas().drawnFeatures)
            : null,
        })
      );
      // this.setState({
      //   loading: true,
      // });
    }
  };

  componentDidMount() {
    this._filterData(this.props, this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props || prevState.filter !== this.state.filter) {
      this._filterData(this.props, this.state);
    }
    // this.ensureActiveItemVisible();
  }

  ensureActiveItemVisible() {
    const selectedId = this.props.selectedId;
    if (selectedId && this.state.filteredItems) {
      const domNode = this.selectedElement;
      if (domNode) {
        domNode.current.scrollIntoView();
      }
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (state.projectId !== props.projectId) {
      return {
        ...state,
        projectId: props.projectId,
        filteredItems: null,
        loading: false,
        // forceFilter: false
      };
    }
    return null;
  };

  componentWillUnmount = () => {
    if (this.worker) {
      this.worker.terminate();
    }
  };

  /*
   * Center the map on the item in the list
   */
  centerMap = (item) => {
    const centerOn = (e) => {
      getCanvas().centerOn(item);
    };
    return centerOn;
  };

  /*
   * Wrapper method around the onSelectItem property
   * to allow the event to be triggered for each item
   * in the list.
   */
  selectItem = (item) => {
    return (e) => {
      this.props.onSelectItem(item);
    };
  };

  onSelectAll = (e) => {
    const checkAll =
      this.state.filteredItems.length !== this.props.selectedItems.length;
    this.props.onSelectItem(this.state.filteredItems, checkAll);
  };

  onClearFilter = (e) => {
    if (this.state.filter) {
      this.setState({
        filter: '',
        offset: 0,
        filteredItems: null,
        //   forceFilter: true
      });
    }
  };

  onChange = (e, { attr, value }) => {
    this.setState({
      filter: value,
      filteredItems: null,
      offset: 0,
    });
  };

  onScroll = (e) => {
    const div = e.target;
    // if (div.scrollTop < 1) {
    //   this.setState({ offset: Math.max(0, this.state.offset - 200) });
    // } else if (
    if (Math.abs(div.scrollTop + div.clientHeight - div.scrollHeight) < 2) {
      this.setState({ offset: this.state.offset + 200 });
      //   this._filterData(this.props, this.state);
    }
  };

  onToggleMapFilter = (e) => {
    store.dispatch(
      uiSet({
        filterOnMapBounds: !this.props.filterOnMapBounds,
      })
    );
    // this.setState({ forceFilter: true });
  };

  render() {
    const { filter, offset } = this.state;
    const {
      selectedItems,
      displayShowInMap,
      filterOnMapBounds,
      selectedId,
      clickHandler,
      actions,
      tooltip,
      includeIcon,
    } = this.props;
    const { formatMessage } = this.props.intl;
    this.selectedElement = null;
    let startIdx = 0;
    if (selectedId && this.state.filteredItems) {
      const itemIdx = this.state.filteredItems.findIndex(
        (i) => i.id === selectedId
      );
      startIdx = Math.max(0, itemIdx - 50);
    }
    // if there is a selected item then move it to the top of the list
    const filteredItems = this.state.filteredItems
      ? this.state.filteredItems.slice(startIdx, 100 + offset)
      : [];

    let listOrLoader = null;

    if (!this.state.loading) {
      if (filteredItems.length === 0) {
        listOrLoader = (
          <Message
            size="mini"
            header={formatMessage(additionalMessages.noItems)}
          />
        );
      } else {
        const listItems = filteredItems.map((i, index) => {
          const isSelected = selectedItems.indexOf(i.id) >= 0;
          const isActive = selectedId === i.id;
          let icon = 'square outline';
          if (isSelected) {
            icon = 'checkmark box';
          }
          let showInMapIcon;
          if (displayShowInMap) {
            showInMapIcon = (
              <List.Icon
                name="map outline"
                title={formatMessage(additionalMessages.showInMap)}
                onClick={this.centerMap(i)}
              />
            );
          }
          let renderActions;
          if (actions) {
            renderActions = actions.map((action, index) => (
              <List.Icon
                key={index}
                className={action.icon}
                title={formatMessage(action.label)}
                onClick={() => action.handler(i)}
              />
            ));
          }
          const ref = isActive ? React.createRef() : null;
          if (ref) {
            this.selectedElement = ref;
          }

          let tooltipText = null;
          if (tooltip != null) {
            tooltipText = tooltip(i);
          }

          return (
            <List.Item key={i.id} active={isActive} data-testid={`list-item-${index}`}>
              <List.Icon
                name={icon}
                onClick={this.selectItem(i)}
                title={formatMessage(additionalMessages.selectItem)}
              />
              {showInMapIcon}
              <List.Content
                as="a"
                onClick={() => {
                  if (clickHandler) clickHandler(i);
                }}
              >
                <ListRow
                  ref={ref}
                  item={i}
                  tooltipText={tooltipText}
                  includeIcon={includeIcon}
                />
              </List.Content>
              {renderActions}
            </List.Item>
          );
        });

        listOrLoader = (
          <List
            celled
            selection
            className="filteredlist"
            onScroll={this.onScroll}
          >
            {listItems}
          </List>
        );
      }
    } else {
      listOrLoader = (
        <Loader active={true} indeterminate content="Loading..." />
      );
    }

    const checkAll = filteredItems.length !== selectedItems.length;
    const noSelection = this.props.selectedItems.length === 0;
    let selectIcon = 'square outline';
    let selectMsg = formatMessage(additionalMessages.selectAll);
    if (noSelection) {
      selectIcon = 'check square outline';
    } else if (checkAll) {
      selectIcon = 'square';
    } else {
      selectMsg = formatMessage(additionalMessages.selectNone);
    }

    // Removing the search control for the beta
    // release since it will be difficult for the
    // user to create 1000s of sites anyway :)
    return (
      <Grid>
        <Grid.Column columns={1}>
          <Grid.Row>
            <Input
              placeholder={formatMessage(additionalMessages.filter)}
              fluid
              icon={{
                name: 'filter',
                onClick: this.onClearFilter,
                link: true,
                title: formatMessage(additionalMessages.clearFilter),
              }}
              value={filter}
              onChange={this.onChange}
              autoFocus
            />
          </Grid.Row>

          <Grid.Row>
            <Button
              basic
              label={selectMsg}
              icon={selectIcon}
              title={selectMsg}
              onClick={this.onSelectAll}
            />
          </Grid.Row>

          {displayShowInMap && (
            <Grid.Row>
              <Button
                toggle
                basic
                label={formatMessage(additionalMessages.mapFilter)}
                icon="world"
                title={formatMessage(additionalMessages.mapFilterList)}
                active={filterOnMapBounds}
                onClick={this.onToggleMapFilter}
              />
            </Grid.Row>
          )}

          <Grid.Row>{listOrLoader}</Grid.Row>
        </Grid.Column>
      </Grid>
    );
  }
}

const FilteredList = React.memo(
  connect((state, ownProps) => {
    const { mapBounds, filterOnMapBounds, projectId } = state.mainFrame;
    return {
      mapBounds,
      filterOnMapBounds,
      projectId,
      selectedId: null,
      ...ownProps,
    };
  })(FilteredListContainer)
);

function WrapFilteredList(props) {
  const navigate = useNavigate();
  return <FilteredList {...props} navigate={navigate} />;
}

export default injectIntl(React.memo(WrapFilteredList));

import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Form, Grid, Modal, Segment, Loader } from 'semantic-ui-react';
import { useFormContext } from 'react-hook-form';
import {
  type PTPChoices,
  type PTPFormField,
  valuesFromEqChoices,
  getEquipmentFromChoices,
} from './utils';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import messages from 'src/messages';
import { postWithAuth } from 'src/api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PTPPath } from './ptp-link-type';
import TxFrequencyEndList from './TxFrequencyEndList';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLinkKind } from './hooks';
import {
  fetchFrequencyLists,
  checkInvalidFreqs,
  listMissingValue,
  trSpacingWillReset,
  getCalculatedList,
  buildFrequencyList,
  initializeLists,
  useFrequencyState,
  getOppositeValue,
  selectionHandler,
  getWarning,
  missingValue,
  checkSelected,
  clearAllValues,
} from './TxFrequencyField.service';
import { identity } from 'src/utils/useful_functions';
import { RootStateOrAny } from 'src/store';

type Props = PTPFormField<any> & {
  modified: boolean;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  choices: PTPChoices;
  disabled: boolean;
  intl: any;
  endName: string;
  precision: number;
  units: string;
  label: string;
  path: PTPPath;
};

function applyStandardFrequencies(state, setValue, radios) {
  for (let i = 0; i < radios.length; i++) {
    setValue(
      `local.radios.${i}.frequency.tx_frequency`,
      state.paths[i].local.value,
      {
        shouldDirty: true,
      }
    );
    setValue(
      `remote.radios.${i}.frequency.tx_frequency`,
      state.paths[i].remote.value,
      {
        shouldDirty: true,
      }
    );
  }
}

function dedupPathFreqs(endFreqs) {
  // awful hack because of the poor state management of frequency
  // values for 4+0 XPIC. Sometimes 0 and 1 are the correct values
  // and sometimes 0 and 2 are the correct values, depending on
  // whether the path was already saved as XPIC and whether the user
  // has edited the frequencies
  if (endFreqs[0] !== endFreqs[1]) {
    return [endFreqs[0], endFreqs[1]];
  } else {
    return [endFreqs[0], endFreqs[2]];
  }
}

function applyXpic4plus0Frequencies(state, setValue) {
  const apply = (endName, value, to) => {
    setValue(
      `${endName}.radios.${to}.frequency.tx_frequency`,
      value,
      { shouldDirty: true },
    );
  };

  for (const endName of ['local', 'remote']) {
    const endFreqs = dedupPathFreqs(state.paths.map((path) => path[endName].value));
    apply(endName, endFreqs[0], 0);
    apply(endName, endFreqs[0], 1);
    apply(endName, endFreqs[1], 2);
    apply(endName, endFreqs[1], 3);
  }
}

function applyFrequenciesToForm(state, setValue, radios, lk) {
  if (lk === 'xpic_4plus0') {
    applyXpic4plus0Frequencies(state, setValue);
  } else {
    applyStandardFrequencies(state, setValue, radios);
  }
}

function FrequencyModalContent(props) {
  const {
    state,
    pathIdx,
    pathFrequencyData,
    methods,
    hilo,
    warning,
    disabled,
  } = props;
  const { setBothValues } = methods;
  // state from reducer - used to get selected values
  const statePath = state.paths[pathIdx];

  return (
    <>
      <Card.Meta className="px-2">{pathFrequencyData.label}</Card.Meta>
      <Grid verticalAlign="middle" padded>
        <Grid.Column width={8}>
          <TxFrequencyEndList
            list={pathFrequencyData.local.list}
            defaultValue={statePath.local.value}
            label={pathFrequencyData.local.label}
            disabled={disabled}
            handleValuesSelection={(e) => {
              const [local, remote] = selectionHandler(
                e,
                state,
                'local',
                pathIdx,
                pathFrequencyData,
                hilo
              );
              setBothValues(pathIdx, local, remote);
            }}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <TxFrequencyEndList
            list={pathFrequencyData.remote.list}
            defaultValue={statePath.remote.value}
            label={pathFrequencyData.remote.label}
            disabled={disabled}
            handleValuesSelection={(e) => {
              const [local, remote] = selectionHandler(
                e,
                state,
                'remote',
                pathIdx,
                pathFrequencyData,
                hilo
              );
              setBothValues(pathIdx, local, remote);
            }}
          />
        </Grid.Column>
      </Grid>

      <div style={{ display: 'flex', placeContent: 'center' }}>
        <Button
          onClick={() => setBothValues(pathIdx, null, null)}
          type="button"
        >
          Clear Selection
        </Button>
      </div>

      {warning != null ? (
        <Segment inverted color="red" style={{ marginTop: '1rem' }}>
          {warning}
        </Segment>
      ) : null}
    </>
  );
}

function TxFrequencyField(props: Props) {
  const {
    setModified,
    endName,
    precision,
    units,
    choices,
    disabled,
    label,
    intl: { formatMessage },
    getter,
    path,
    pathIndex,
  } = props;

  const [state, methods] = useFrequencyState(path);
  const { togglePopup, closePopup, setAllValues } = methods;

  const { id } = useParams();
  const { isComplex } = useLinkKind();
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const { getValues, setValue } = useFormContext();

  const [
    band,
    product,
    linkProtection,
    regulation,
    hilo,
    trSpacing,
    bandwidth,
  ] = valuesFromEqChoices(getEquipmentFromChoices(choices), [
    'band',
    'product',
    'link_protection',
    'regulation',
    'hi',
    'tr_spacing',
    'bandwidth',
  ]);

  const localFreqKeys = state.paths.map(
    (path, i) =>
      path.local.value ?? getValues(`local.radios.${i}.frequency.tx_frequency`)
  );
  const remoteFreqKeys = state.paths.map(
    (path, i) =>
      path.remote.value ??
      getValues(`remote.radios.${i}.frequency.tx_frequency`)
  );

  let queryKey = [
    projectId,
    'ptp',
    id,
    'frequencies',
    band,
    product,
    linkProtection,
    regulation,
    hilo,
    trSpacing,
    bandwidth,
    ...localFreqKeys,
    ...remoteFreqKeys,
  ];

  const {
    data: frequencyListData,
    isLoading,
    isPreviousData,
    isFetching,
  } = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: () =>
      fetchFrequencyLists(
        projectId,
        id,
        localFreqKeys,
        remoteFreqKeys,
        ...getValues(['local.radios', 'remote.radios'])
      ),
  });

  if (isLoading) {
    return (
      <Form.Field>
        <p>Loading...</p>
      </Form.Field>
    );
  }

  const frequencyLists = initializeLists(frequencyListData);
  return (
    <TxFrequencyDisplay
      state={state}
      methods={methods}
      endName={endName}
      pathIndex={pathIndex}
      isComplex={isComplex}
      frequencyLists={frequencyLists}
      hilo={hilo}
      trSpacing={trSpacing}
      label={label}
      getter={getter}
      units={units}
      precision={precision}
      choices={choices}
      disabled={disabled}
      isPreviousData={isPreviousData}
      isFetching={isFetching}
    />
  );
}

/**
 * Split the components so that we can run useEffect on the api list data
 */
function TxFrequencyDisplay(props) {
  const {
    state,
    methods,
    endName,
    pathIndex,
    isComplex,
    frequencyLists,
    hilo,
    trSpacing,
    label,
    getter,
    units,
    precision,
    choices,
    disabled,
    isPreviousData,
    isFetching,
  } = props;
  const { setAllValues, closePopup, togglePopup } = methods;

  const { id } = useParams();
  const { lk } = useLinkKind();
  const qc = useQueryClient();
  const {
    getValues,
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const anyFixed = frequencyLists
    .map((list) => [list.local.fixed, list.remote.fixed])
    .flat()
    .some(identity);

  useEffect(() => {
    // reset selected frequencies when tr spacing changes
    // but dont try to reset when the lists are "fixed" (e.g. L6)
    if (trSpacing !== 'Any' && !anyFixed) {
      const radios = getValues('local.radios');
      if (trSpacingWillReset(getValues, radios, trSpacing)) {
        clearAllValues(getValues, setValue, setAllValues);
      }
    }
  }, [trSpacing, anyFixed]);

  useEffect(() => {
    // don't want this useEffect to interfere with
    // user selecting values in lists
    if (!state.showPopup && !isEmpty(dirtyFields)) {
      for (const endName of ['local', 'remote']) {
        let index = pathIndex;
        if (lk === 'xpic_4plus0') {
          if (pathIndex === 0 || pathIndex === 1) {
            index = 0;
          } else {
            index = 1;
          }
        }

        if (pathIndex >= frequencyLists.length) {
          // XPIC has 2 paths but only one set of freqs
          break;
        }

        const { list } = frequencyLists[index][endName];
        const txPath = `${endName}.radios.${pathIndex}.frequency.tx_frequency`;
        if (listMissingValue(list, getValues(txPath))) {
          clearAllValues(getValues, setValue, setAllValues);
          break;
        }
      }
    }
  }, [
    state.showPopup,
    pathIndex,
    JSON.stringify(dirtyFields),
    JSON.stringify(frequencyLists[pathIndex]?.local.list ?? {}),
    JSON.stringify(frequencyLists[pathIndex]?.remote.list ?? {}),
  ]);

  useEffect(() => {
    // due to network delay, its sometimes possible to have duplicates
    // selected - if thats the case just clear everything
    if (!(isComplex && frequencyLists.length === 1)) {
      // 4+0 xpic intentionally duplicates A,B and C,D
      if (lk !== 'xpic_4plus0') {
        const localValues = state.paths
          .map((p) => p.local.value)
          .filter((v) => v != null);
        const remoteValues = state.paths
          .map((p) => p.remote.value)
          .filter((v) => v != null);
        const ls = new Set(localValues);
        const rs = new Set(remoteValues);


        if (ls.size !== localValues.length || rs.size !== remoteValues.length) {
          clearAllValues(getValues, setValue, setAllValues);
        }
      }
    }
  }, [isComplex, frequencyLists.length, JSON.stringify(state.paths)]);

  function closeModal() {
    setAllValues(null);
    closePopup();
  }

  // when the "clear all" button is pressed
  function handleClearAll() {
    if (isComplex) {
      // for complex (multiple paths), removing the query gives better
      // responsiveness in the ui when pressing "clear all"
      // for non-complex it will cause a flicker since we don't persist
      // the query whilst refetching - therefore only remove when complex
      qc.removeQueries({
        predicate: (query) => {
          const key = query.queryKey;
          return key.includes('frequencies') && key.includes('ptp');
        },
      });
    }
    clearAllValues(getValues, setValue, setAllValues);
  }

  function handleOk() {
    applyFrequenciesToForm(state, setValue, getValues('local.radios'), lk);
    closeModal();
  }

  const numLists = frequencyLists.length;
  const isDirty = get(dirtyFields, getter, false);

  const warnings = frequencyLists.map(getWarning(state));
  const anyWarning = warnings.some((w) => w != null);
  const anyMissing = [...Array(state.paths.length).keys()].some(
    missingValue(state)
  );
  const onlySomeSelected = checkSelected(state, frequencyLists);

  return (
    <Form.Field>
      <GenericScaledField
        label={label}
        getter={getter}
        units={units}
        precision={precision}
        choices={choices}
        disabled={disabled}
        recalc={isDirty}
        watch={id}
      >
        <Button
          color="blue"
          className="ml-1"
          type="button"
          onClick={togglePopup}
        >
          Select...
        </Button>
      </GenericScaledField>

      <Modal
        size={numLists === 1 ? 'mini' : 'small'}
        open={state.showPopup}
        onClose={closeModal}
      >
        <Modal.Header>
          {isPreviousData || isFetching ? (
            <div style={{ display: 'flex' }}>
              <span style={{ flex: '1 1 0%' }}>
                Select Transmit Frequencies
              </span>
              <span>Updating list...</span>
            </div>
          ) : (
            <>Select Transmit Frequencies</>
          )}
          <Button
            circular
            icon="close"
            title="Close"
            onClick={closeModal}
            floated="right"
          />
        </Modal.Header>

        <Modal.Content>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${Math.min(
                2,
                numLists
              )}, minmax(0, 1fr))`,
            }}
          >
            {frequencyLists.map((freqList, i) => {
              if (state.paths[i] == null) {
                // without this, the UI will crash sometimes
                // when changing Link Type
                return <Loader active />;
              }

              return (
                <div key={`freq-modal-${i}`}>
                  <FrequencyModalContent
                    pathIdx={i}
                    state={state}
                    pathFrequencyData={freqList}
                    methods={methods}
                    hilo={hilo}
                    warning={warnings[i]}
                    disabled={isFetching || isPreviousData}
                  />
                </div>
              );
            })}
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button type="button" onClick={handleClearAll}>
            Clear All
          </Button>
          <Button type="button" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="button"
            color="blue"
            disabled={anyWarning || anyMissing || onlySomeSelected}
            onClick={handleOk}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </Form.Field>
  );
}

export default injectIntl(TxFrequencyField);

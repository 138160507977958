import { get } from 'lodash';
import { Form } from 'semantic-ui-react';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import NDEquipmentPanel from '../../nd/NDEquipmentPanel';
import { PMPFormPanel, PMPShowProps } from '../../nd/utils';
import NDAntennaPanel from '../../nd/NDAntennaPanel';
import {
  antennaSelection,
  azimuth,
  cableLoss,
  tilt,
} from '../../nd/configs/config-utils';

import { syncFormState } from '../utils';
import { round } from 'src/utils/useful_functions';
import NDPowerPanel from '../../nd/NDPowerPanel';
import {
  band,
  product,
  gasGroup,
  regulation,
  smRangeBaseConfig,
  antennaHeight,
  antennaHeightComponentProps,
  calculateLoss,
  cableChoices,
  cableLength,
} from '../../config-utils';
import CustomTextField from 'src/components/controls/rhf/CustomTextField';
import {
  smEirpConfig,
  smInterferenceFields,
  smPowerConfig,
  smUserPowerFields,
} from '../configs/config-utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'ap_radio_number',
      getter: 'radios.0.equipment.ap_radio_number',
      refreshesChoices: true,
      afterOnChange(newValue, formMethods, newChoices) {
        const { setValue } = formMethods;
        setValue('properties.user_ap_radio_number', newValue);
      },
    },
    band,
    product,
    {
      label: 'Capacity',
      attrName: 'capacity',
      getter: 'radios.0.equipment.capacity',
    },
    regulation,
    {
      attrName: 'aes_encryption',
      getter: 'radios.0.equipment.aes_encryption',
    },
  ],
};

const productPanel: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'sm_product',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'radios.0.equipment.bandwidth',
    },
    {
      attrName: 'color_code',
      getter: 'radios.0.equipment.color_code',
    },
    {
      attrName: 'data_channel',
      getter: 'radios.0.equipment.data_channel',
    },
    {
      ...smRangeBaseConfig,
      editable: false,
    },
    {
      attrName: 'frame_period',
      getter: 'radios.0.equipment.frame_period',
    },
    {
      attrName: 'downlink_data',
      getter: 'radios.0.equipment.downlink_data',
    },
    {
      attrName: 'control_slots',
      getter: 'radios.0.equipment.control_slots',
    },
    {
      attrName: 'effective_control_slots',
      getter: 'radios.0.equipment.effective_control_slots',
    },
    {
      attrName: 'dual_payload',
      getter: 'radios.0.equipment.dual_payload',
      refreshesChoices: true,
    },
    {
      attrName: 'dl_max_multiplier',
      getter: 'radios.0.equipment.dl_max_multiplier',
      refreshesChoices: true,
    },
    {
      attrName: 'ul_max_multiplier',
      getter: 'radios.0.equipment.ul_max_multiplier',
      refreshesChoices: true,
    },
  ],
};

export const antenna: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'sm_antenna',
  component: NDAntennaPanel,
  fields: [
    {
      ...antennaSelection,
      warning({ sm }) {
        return get(sm, 'radios.0.warnings.antenna');
      },
    },
    {
      ...antennaHeight,
      componentProps({ sm, formGetter }) {
        return antennaHeightComponentProps(sm, formGetter);
      },
    },
    {
      label: 'Positioner',
      attrName: `use_positioner`,
      getter: `radios.0.antennas.0.config.use_positioner`,
      component: Form.Checkbox,
      show({ choices }) {
        return choices.cabling.internal && choices.cabling.supports_positioner;
      },
      componentProps: ({ formGetter }: PMPShowProps) => {
        return {
          checked: formGetter(`radios.0.antennas.0.config.use_positioner`),
        };
      },
    },
    calculateLoss,
    cableChoices,
    cableLength,
    cableLoss,
    {
      ...azimuth,
      editable: false,
      precision: 1,
      componentProps({ formGetter }) {
        return {
          unitExtraTooltip: `${round(
            formGetter('radios.0.antennas.0.bearing_from_boresight'),
            1
          )}\u00b0 from boresight`,
        };
      },
    },
    {
      ...tilt,
      editable: false,
      component: CustomTextField,
      reCalculate: true,
      componentProps() {
        return {
          formatter: ({ value }) => {
            let tiltType = 'uptilt';
            if (value < 0) {
              tiltType = 'downtilt';
            }
            return `${round(value, 1)}\u00b0 (${tiltType})`;
          },
        };
      },
      warning({ formGetter }) {
        return formGetter(`radios.0.warnings.tilt_has_warning`);
      },
    },
    {
      label: 'ND Antenna Gain',
      reCalculate: true,
      attrName: 'ap_antenna_gain',
      getter: 'radios.0..antennas.0.ap_antenna_gain',
      warning({ formGetter }) {
        return formGetter(`radios.0.warnings.gain_warning`);
      },
      component: GenericScaledField,
      editable: false,
      precision: 1,
      units: 'dBi',
    },
  ],
};

export const power: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'sm_power',
  component: NDPowerPanel,
  fields: [
    gasGroup,
    smEirpConfig,
    smPowerConfig,
    smUserPowerFields,
    smInterferenceFields,
  ],
};

export const pmp450ViewConfig = {
  syncFormState,
  equipment,
  product: productPanel,
  antenna,
  power,
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  Image,
  Icon,
  Header,
  Popup,
  Input,
  Modal,
  Button,
  Dropdown,
  Label,
  Divider,
} from 'semantic-ui-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BreadcrumbCtrl from './Breadcrumbs.jsx';
import logo from '../../images/CN_logo_horizontal_blueIcon_blackName.png';
import about from '../../About';
import Preferences from '../user/Preferences';
import AdminTools from '../user/AdminTools';
import config from '../../config';
import { logoutUser, getWithAuth } from '../../api';
import additionalMessages from '../../messages';
import { store } from '../../store';
import LayerList from './LayerList';
import { runWithConfirmation } from '../../utils/useful_functions';
import { discardProjectChanges, saveAsProject, sortProjects } from '../../api';
import {
  uiSet,
  unloadProject,
  projectsLoaded,
  updateProjectsList,
  getUserLimits,
} from './mainframe.reducer';
import { getCanvas } from '../../utils/mapUtils.jsx';

const DEFAULT_FILTER_WIDTH = '8vw';
async function getProjectsList() {
  const projects = await getWithAuth('projects');
  return projects;
}
class HeaderPanelContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      filterWidth: DEFAULT_FILTER_WIDTH,
      saveAs: false,
      showPreferences: false,
      showAdminTools: false,
      modifiedName: null,
    };
  }

  onClearFilter = (e) => {
    this.setState({ filter: '' });
    getCanvas().setFilter('');
  };

  componentDidMount = () => {
    store.dispatch(getUserLimits());
  };

  discardChanges = (e) => {
    const { props } = this;
    const { projectId } = props;
    const { formatMessage } = props.intl;
    const name = this.state.modifiedName || this.props.projectName;
    runWithConfirmation({
      header: `${formatMessage(additionalMessages.discardChanges)}: ${name}`,
      onConfirm: () => {
        store.dispatch(uiSet({ locked: true }));
        discardProjectChanges(projectId)
          .then(() => {
            store.dispatch(unloadProject());
            //fix for LPWEB-420, we need explicitly load the projects
            // when we discard changes from /projects page
            if (this.props.pathname === '/projects') {
              getProjectsList().then((projects) => {
                if (projects) {
                  // Sort the projects so that the recently accessed projects
                  // are at the start of the list
                  projects.sort(sortProjects);
                  store.dispatch(projectsLoaded(projects));
                }
              });
            } else {
              this.props.navigate('/projects');
            }
          })
          .catch((err) => {
            console.error(err);
            store.dispatch(uiSet({ locked: false }));
          });
      },
    });
  };

  onChange = (e, { attr, value }) => {
    this.setState({ filter: value });
    getCanvas().setFilter(value);
  };

  onNameChange = (e, { value }) => {
    this.setState({ modifiedName: value });
  };

  showPreferencesHandler = (e) => {
    e.preventDefault();
    this.setState({ showPreferences: true });
  };
  onPreferenceClose = () => {
    this.setState({ showPreferences: false });
  };
  showAdminToolsHandler = (e) => {
    e.preventDefault();
    this.setState({ showAdminTools: true });
  };
  onAdminToolsClose = () => {
    this.setState({ showAdminTools: false });
  };

  onNameKey = (e) => {
    if (
      e.key === 'Enter' &&
      (this.state.modifiedName || this.props.projectName)
    ) {
      this.doSave();
    }
  };

  saveProject = () => {
    this.setState({ saveAs: true, modifiedName: null });
  };

  doSave = () => {
    const { projectId } = this.props;
    const name = this.state.modifiedName || this.props.projectName;
    store.dispatch(uiSet({ locked: true }));
    saveAsProject(projectId, name)
      .then((res) => {
        store.dispatch(
          uiSet({
            projectId: res.id,
            projectName: res.name,
            projectModified: false,
          })
        );
        store.dispatch(
          updateProjectsList({
            projectName: res.name,
          })
        );
      })
      .catch((err) => {
        console.error(err);
        store.dispatch(uiSet({ locked: false }));
      });
    this.setState({ saveAs: false, modifiedName: null });
  };

  cancelSave = () => {
    this.setState({ saveAs: false, modifiedName: null });
  };

  render = () => {
    const { filter, filterWidth, showPreferences, showAdminTools } = this.state;
    const { props } = this;
    const { formatMessage } = props.intl;
    const {
      loggedIn,
      firstName,
      lastName,
      permissionWrite,
      locked,
      projectId,
      projectName,
      projectModified,
      pathname,
      superuser,
    } = props;
    const name = firstName && lastName ? `${firstName} ${lastName}` : firstName;
    const responsiveWidth = 600;
    let login = null;
    let logout = null;
    let preferences = null;
    let adminTools = null;
    let preferencesContent = null;
    let adminToolsContent = null;
    let iconColour = 'grey';

    if (permissionWrite && projectId) {
      if (projectModified) {
        // TODO: Handle when the project has been modified
        iconColour = 'red';
      } else if (loggedIn) {
        iconColour = 'black';
      }
    }

    const saveIcon =
      projectId && !locked ? (
        <Popup
          trigger={
            <Icon
              link
              name="save"
              color={iconColour}
              title={formatMessage(additionalMessages.saveProject)}
              size="large"
              onClick={this.saveProject}
              disabled={!(loggedIn && permissionWrite)}
            />
          }
          hoverable
          position="bottom left"
        >
          {formatMessage(additionalMessages.saveProject)}
        </Popup>
      ) : null;
    const discardIcon =
      projectId && !locked ? (
        <Popup
          trigger={
            <Icon
              link
              name="trash alternate"
              color={iconColour}
              size="large"
              title={formatMessage(additionalMessages.discardChanges)}
              onClick={this.discardChanges}
              disabled={!(loggedIn && permissionWrite)}
            />
          }
          hoverable
          position="bottom left"
        >
          {formatMessage(additionalMessages.discardChanges)}
        </Popup>
      ) : null;
    if (showPreferences) {
      preferencesContent = (
        <Preferences
          intl={props.intl}
          onClose={this.onPreferenceClose}
        ></Preferences>
      );
    }
    if (showAdminTools) {
      adminToolsContent = (
        <AdminTools
          intl={props.intl}
          onClose={this.onAdminToolsClose}
        ></AdminTools>
      );
    }
    if (loggedIn) {
      preferences = (
        <Dropdown.Item
          as={Link}
          to="/preferences"
          onClick={this.showPreferencesHandler}
          title={formatMessage(additionalMessages.preferenceTooltip)}
        >
          <FormattedMessage
            id="header.preferences"
            defaultMessage="Preferences"
          />
        </Dropdown.Item>
      );
      adminTools = (
        <Dropdown.Item
          as={Link}
          to="/user/info"
          onClick={this.showAdminToolsHandler}
          title={formatMessage(additionalMessages.adminTooltip)}
        >
          <FormattedMessage
            id="header.adminTools"
            defaultMessage="Admin Tools"
          />
        </Dropdown.Item>
      );
      logout = (
        <Dropdown.Item
          as={Link}
          to="#"
          onClick={logoutUser}
          title={formatMessage(additionalMessages.logout)}
        >
          <FormattedMessage id="header.logout" defaultMessage="Logout" />
        </Dropdown.Item>
      );
    } else if (pathname !== '/login') {
      login = (
        <Link to="/login" title={formatMessage(additionalMessages.login)}>
          <FormattedMessage id="header.login" defaultMessage="Login" />
        </Link>
      );
    }

    const displayProjectName =
      this.state.modifiedName === null ? projectName : this.state.modifiedName;
    const saveDialog = (
      <Modal open={this.state.saveAs} onClose={this.cancelSave} size="mini">
        <Modal.Header>
          {formatMessage(additionalMessages.saveProject)}
        </Modal.Header>
        <Modal.Content>
          <Input
            label="Name"
            value={displayProjectName}
            onChange={this.onNameChange}
            onKeyPress={this.onNameKey}
            fluid
            autoFocus
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.cancelSave}>
            {formatMessage(additionalMessages.cancel)}
          </Button>
          <Button
            onClick={this.doSave}
            color="blue"
            disabled={!displayProjectName}
          >
            {formatMessage(additionalMessages.ok)}
          </Button>
        </Modal.Actions>
      </Modal>
    );

    const branch = window.runtime.branch ? (
      <>
        <Header>Branch</Header>
        {window.runtime.branch}
      </>
    ) : null;
    const changeset = window.runtime.changeset ? (
      <>
        <Header>Changeset</Header>
        {window.runtime.changeset}
      </>
    ) : null;
    const tag = (
      <Menu.Item fitted>
        <Header className="App-title">
          <Popup
            //   size="large"
            hoverable
            content={
              <>
                <Header>Release Date</Header>
                {window.runtime.date}
                {changeset}
                {branch}
              </>
            }
            trigger={
              <Label
                color="blue"
                size="large"
                style={{ marginTop: '0', marginLeft: '1rem' }}
              >
                {window.runtime.tag
                  ? window.runtime.tag
                  : `v${window.runtime.version}`}
              </Label>
            }
          />
        </Header>
      </Menu.Item>
    );
    return (
      <Fragment>
        {saveDialog}
        {preferencesContent}
        {adminToolsContent}
        <Menu fluid borderless style={{ marginTop: '0px' }}>
          <Menu.Item fitted>
            <Image
              src={logo}
              as="a"
              size="small"
              //verticalAlign="middle"
              href={about.cambiumHome}
              title={formatMessage(additionalMessages.cambium)}
              target="_blank"
              rel="noopener noreferrer"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
          </Menu.Item>

          <Menu.Item fitted>
            <Header className="App-title">LINKPlanner</Header>
          </Menu.Item>

          {tag}

          <Menu.Item>
            {saveIcon}
            {discardIcon}
          </Menu.Item>
          {!locked ? (
            <Menu.Item>
              <BreadcrumbCtrl />
            </Menu.Item>
          ) : null}

          <Menu.Item position="right">
            {projectId ? (
              <>
                <Popup
                  trigger={
                    <Icon
                      link
                      className="layers-icon"
                      title="Select visible features in the map"
                    />
                  }
                  on="click"
                  inverted
                  position="bottom center"
                >
                  <LayerList />
                </Popup>
                <Input
                  style={{ width: filterWidth }}
                  title={formatMessage(additionalMessages.filterInMap)}
                  placeholder={formatMessage(additionalMessages.filterInMap)}
                  icon={{
                    name: 'filter',
                    onClick: this.onClearFilter,
                    link: true,
                    title: formatMessage(additionalMessages.clearFilter),
                  }}
                  value={filter}
                  onChange={this.onChange}
                  onFocus={() => this.setState({ filterWidth: '15vw' })}
                  onBlur={() =>
                    setTimeout(() => {
                      this.setState({ filterWidth: DEFAULT_FILTER_WIDTH });
                    }, 1)
                  }
                />
              </>
            ) : null}
            &nbsp; &nbsp; &nbsp; &nbsp;
            <Dropdown
              direction="left"
              basic
              icon="help"
              title={formatMessage(additionalMessages.help)}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  as="a"
                  href={config.userGuide}
                  title={formatMessage(additionalMessages.userGuide)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage(additionalMessages.userGuide)}
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  href={config.support}
                  title={formatMessage(additionalMessages.support)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage(additionalMessages.support)}
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  href={config.releaseNotes}
                  title={formatMessage(additionalMessages.releaseNotes)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage(additionalMessages.releaseNotes)}
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/about"
                  title={formatMessage(additionalMessages.aboutLINKPlanner)}
                >
                  {formatMessage(additionalMessages.about)} v
                  {window.runtime.version}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {login}
            &nbsp; &nbsp;
            {loggedIn ? (
              <Dropdown direction="left" basic text={name}>
                <Dropdown.Menu>
                  {preferences}
                  <Divider />
                  {superuser && adminTools}
                  {superuser && <Divider />}
                  {logout}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </Menu.Item>
        </Menu>
      </Fragment>
    );
  };
}

const HeaderPanel = connect((state, ownProps) => {
  const {
    loggedIn,
    firstName,
    lastName,
    permissionWrite,
    locked,
    projectId,
    projectName,
    projectModified,
    connected,
    superuser,
  } = state.mainFrame;
  return {
    ...ownProps,
    loggedIn,
    firstName,
    lastName,
    permissionWrite,
    locked,
    projectId,
    projectName,
    projectModified,
    connected,
    superuser,
  };
})(HeaderPanelContainer);

function WrapHeader(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return <HeaderPanel {...props} pathname={pathname} navigate={navigate} />;
}

export default WrapHeader;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Message } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { store } from '../../store';
import additionalMessages from '../../messages';
import { postFileWithAuth } from '../../api';
import { uiSet, loadProject } from '../mainframe/mainframe.reducer';
import { clearSocketMessages } from '../../reducers/socket_reducer';
import {
  DESCRIPTION_MAX_LENGTH,
  NAME_MAX_LENGTH,
} from 'src/app.constants';

class ImportProjectContainer extends Component {
  state = {
    busy: false,
    importFile: null,
    message: null,
  };

  doImport = (e) => {
    const { formatMessage } = this.props.intl;
    if (this.state.importFile) {
      this.setState({ busy: true });
      postFileWithAuth('projects/linkplanner', this.state.importFile)
        .then((res) => {
          // TODO: Check for error messages
          // Leave busy running until the task is complete
        })
        .catch((e) => {
          if (e === 'Invalid file')
            this.setState({
              busy: false,
              message: formatMessage(additionalMessages.invalidFileError),
            });
          else
            this.setState({
              busy: false,
              message: formatMessage(additionalMessages.fileError),
            });
        });
    }
  };

  onClose = () => {
    store.dispatch(clearSocketMessages());
    this.setState({ busy: false, importFile: null, message: null });
    store.dispatch(uiSet({ showLPImportDialog: false }));
  };

  onOpen = () => {
    store.dispatch(clearSocketMessages());
    this.setState({ busy: false, importFile: null, message: null });
  };

  onImportFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const ctrl = this;

    const body = new FormData();
    body.append('file', file);
    body.append('filename', file.name);
    ctrl.setState({ importFile: body, message: null });
  };

  static getDerivedStateFromProps = (props, state) => {
    // when the file has been imported, the websocket connection
    // receives a message to indicate success (or failure).
    // Reset the socket message and close this dialog
    const { formatMessage } = props.intl;
    const { projectId, status, action, message } = props;
    if (status === 'success' && action === 'import') {
      store.dispatch(clearSocketMessages());
      store.dispatch(uiSet({ showLPImportDialog: false }));
      store.dispatch(
        loadProject({
          projectId: projectId,
        })
      );
      props.navigate('/');
      return { ...state, busy: false };
    } else if (status === 'busy') {
      return {
        ...state,
        busy: true,
        message: message,
      };
    } else if (status === 'failed' && action === 'import') {
      const displayMessage =
        message ?? formatMessage(additionalMessages.fileError);
      store.dispatch(clearSocketMessages());
      return {
        ...state,
        busy: false,
        message: displayMessage,
      };
    }
    return null;
  };

  getWarningMessage = (formatMessage) => {
    const msg = this.state.message;
    if (msg != null) {
      return msg;
    } else {
      return formatMessage(additionalMessages.fileError);
    }
  }

  render = () => {
    /*
     * Requires the following properties:
     *   show - boolean to control if the Modal visibility
     *   onClose - action called when the Modal is closed/cancelled
     */
    const { formatMessage } = this.props.intl;
    const { show } = this.props;
    const { busy, message } = this.state;
    const importRef = React.createRef();

    const okButton = busy ? (
      <Button icon="sync" color="blue" loading />
    ) : (
      <Button
        onClick={this.doImport}
        color="blue"
        disabled={!this.state.importFile || busy || !!message}
      >
        {formatMessage(additionalMessages.ok)}
      </Button>
    );

    return (
      <Modal
        open={show}
        onClose={this.onClose}
        onOpen={this.onOpen}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {formatMessage(additionalMessages.importLINKPlannerProject)}
          <Button
            circular
            icon="close"
            title="Close"
            floated="right"
            onClick={this.onClose}
          />
        </Modal.Header>
        <Modal.Content>
          <Button
            content={formatMessage(additionalMessages.selectLPFile)}
            labelPosition="left"
            icon={{ className: 'linkplanner-icon-coloured' }}
            onClick={() => importRef.current.click()}
            disabled={busy}
          />
          <input
            ref={importRef}
            type="file"
            accept=".ptpprj,.lpp"
            hidden
            onChange={this.onImportFileChange}
          />
          {this.state.importFile && this.state.importFile.get('filename')}

          {this.state.message ? (
            <Message
              error={!busy}
              onDismiss={
                busy
                  ? null
                  : () => {
                      this.setState({ message: null });
                    }
              }
              header={
                busy
                  ? formatMessage(additionalMessages.importProgress)
                  : formatMessage(additionalMessages.importError)
              }
              content={
                busy
                  ? this.state.message
                  : this.getWarningMessage(formatMessage)
              }
            />
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          {busy ? null : (
            <Button onClick={this.onClose}>
              {formatMessage(additionalMessages.cancel)}
            </Button>
          )}
          {okButton}
        </Modal.Actions>
      </Modal>
    );
  };
}

const ImportProjectPanel = connect((state, ownProps) => {
  return {
    ...ownProps,
    show: state.mainFrame.showLPImportDialog,
    projectId: state.sockets.project_id,
    status: state.sockets.status,
    action: state.sockets.action,
    message: state.sockets.message,
  };
})(ImportProjectContainer);

function WrapImportProjectPanel(props) {
  const navigate = useNavigate();
  return <ImportProjectPanel {...props} navigate={navigate} />;
}

export default injectIntl(WrapImportProjectPanel);

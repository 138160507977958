import { Form } from 'semantic-ui-react';
import NDEquipmentPanel from '../../nd/NDEquipmentPanel';
import { PMPFormPanel } from '../../nd/utils';
import {
  band,
  product,
  regulation,
  smRangeBaseConfig,
} from '../../config-utils';
import NDAntennaPanel from '../../nd/NDAntennaPanel';
import NDPowerPanel from '../../nd/NDPowerPanel';
import { syncFormState } from '../utils';
import { antenna } from './PMP450ViewConfig';
import {
  smEirpConfig,
  smInterferenceFields,
  smPowerConfig,
  smUserPowerFields,
} from './config-utils';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDEquipmentPanel,
  fields: [band, product, regulation],
};

const productPanel: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'sm_product',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'radios.0.equipment.bandwidth',
    },
    {
      attrName: 'tx_channel',
      getter: 'radios.0.equipment.tx_channel',
    },
    {
      attrName: 'link_golay',
      getter: 'radios.0.equipment.link_golay',
    },
    {
      attrName: 'polarity',
      getter: 'radios.0.equipment.polarity',
    },
    {
      ...smRangeBaseConfig,
      editable: false,
    },
    {
      attrName: 'pmp_max_mod_mode',
      getter: 'radios.0.equipment.pmp_max_mod_mode',
    },
    {
      attrName: 'dl_ul_ratio',
      getter: 'radios.0.equipment.dl_ul_ratio',
    },
  ],
};

const antennaPanelConfig: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'sm_antenna',
  component: NDAntennaPanel,
  fields: [
    ...antenna.fields.filter((c) => c.attrName !== 'ap_antenna_gain'),
    {
      label: 'Sector',
      attrName: 'sector',
      getter: 'radios.0.antennas.0.sector',
      choicesGetter: 'radios.0.antennas.0.sector_choices',
      component: Form.Select,
      defaultValue({ formGetter }) {
        return (
          formGetter('radios.0.antennas.0.sector') ||
          formGetter('radios.0.antennas.0.default_sector')
        );
      },
    },
    ...antenna.fields.filter((c) => c.attrName === 'ap_antenna_gain'),
  ],
};

const power: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'sm_power',
  component: NDPowerPanel,
  fields: [
    smEirpConfig,
    smPowerConfig,
    smUserPowerFields,
    smInterferenceFields,
    {
      label: 'ND Power to SM',
      attrName: 'ap_power_to_sm',
      getter: 'radios.0.power.ap_power_to_sm',
      units: 'dBm',
      component: GenericScaledField,
      editable: false,
      precision: 1,
    },
  ],
};

export const cnWave60ViewConfig = {
  syncFormState,
  equipment,
  product: productPanel,
  antenna: antennaPanelConfig,
  power,
};

import React, { useState } from 'react';
import _ from 'lodash';
import {
  Sidebar,
  Label,
  Menu,
  Icon,
  Loader,
  Divider,
  Segment,
  Search,
  Popup,
} from 'semantic-ui-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toggleSidebar } from './mainframe.actions';
import { uiSet } from './mainframe.reducer';
import additionalMessages from '../../messages';
import { store } from '../../store';

const featureMatch = (exp, result, f, category, baseUrl) => {
  if (exp.test(f.properties.name)) {
    if (!result[category]) {
      result[category] = { name: category, results: [] };
    }
    result[category].results.push({
      title: f.properties.name,
      // PMP Links have an ID for the link and the SM ID
      url: `/${baseUrl}/${f.properties.sm_id || f.properties.id}`,
    });
  }
};

const configMatch = (exp, result, f, category, baseUrl) => {
  if (exp.test(f.name)) {
    if (!result[category]) {
      result[category] = { name: category, results: [] };
    }
    result[category].results.push({
      title: f.name,
      url: `/${baseUrl}/${f.id}`,
    });
  }
};

const doSearch = (state, searchStr) => {
  const {
    networkSites,
    subscriberSites,
    accessPoints,
    ptpLinks,
    pmpLinks,
    meshLinks,
  } = state.mainFrame;
  const { equipmentConfigs } = state.equipment;
  const result = {};

  let exp = null;
  try {
    exp = new RegExp(searchStr, 'i');
  } catch (e) {
    console.error(e);
    return result;
  }

  if (!networkSites) {
    return result;
  }

  networkSites.features.forEach((f) => {
    featureMatch(exp, result, f, 'Network Sites', 'network_sites');
  });
  subscriberSites.features.forEach((f) => {
    featureMatch(exp, result, f, 'Subscriber Sites', 'subscriber_sites');
  });
  equipmentConfigs.forEach((f) => {
    configMatch(exp, result, f, 'PMP Equipment', 'equipment');
  });
  accessPoints.features.forEach((f) => {
    featureMatch(exp, result, f, 'PMP Network Devices', 'aps');
  });
  ptpLinks.features.forEach((f) => {
    featureMatch(exp, result, f, 'PTP Links', 'ptp');
  });
  pmpLinks.features.forEach((f) => {
    featureMatch(exp, result, f, 'PMP Links', 'subscriber');
  });
  meshLinks.features.forEach((f) => {
    featureMatch(exp, result, f, 'Mesh Links', 'mesh');
  });

  // sort the results
  for (let category in result) {
    result[category].results.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      } else if (a.title > b.title) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  return result;
};

/*
 * Search rendering
 */
const categoryLayoutRenderer = ({ categoryContent, resultsContent }) => (
  <div>
    <div className="name">{categoryContent}</div>
    <div className="results">{resultsContent}</div>
  </div>
);

const FeatureSearch = ({ formatMessage, size = 'mini' }) => {
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState({});
  const [searchValue, setSearchvalue] = useState('');
  const currentState = useSelector((state) => state);

  return (
    <Search
      placeholder="Search for a feature..."
      category
      aligned="left"
      size={size}
      title={formatMessage(additionalMessages.searchHelp)}
      onResultSelect={(e, { result }) => navigate(result.url)}
      onSearchChange={_.debounce(
        (e, { value }) => {
          setSearchvalue(value);
          setSearchResults(doSearch(currentState, value));
        },
        500,
        {
          leading: true,
        }
      )}
      results={searchResults}
      categoryLayoutRenderer={categoryLayoutRenderer}
      value={searchValue}
    />
  );
};

const MainMenuContainer = (props) => {
  const {
    expandSidebar,
    networkSiteCount,
    subscriberSiteCount,
    pmpLinksCount,
    ptpLinksCount,
    accessPointsCount,
    meshLinksCount,
    loadingProject,
    projectId,
    permissionWrite,
    hasWritePermission,
    warning,
    locked,
  } = props;
  const { formatMessage } = props.intl;
  const location = useLocation();
  const navigate = useNavigate();

  const showBestServer = warning?.heading != null;

  let sidebarClass = null;
  let iconLabel = 'labeled';
  if (!projectId) {
    sidebarClass = 'hidden';
    iconLabel = false;
  } else if (!expandSidebar) {
    sidebarClass = 'collapsed';
    iconLabel = false;
  }

  if (locked && location.pathname !== '/') {
    navigate('/');
  }

  if (!projectId || locked) {
    return (
      <Sidebar
        as={Menu}
        animation="uncover"
        icon={iconLabel}
        size="small"
        visible
        vertical
        inverted
        className={sidebarClass}
      >
        <Loader
          inverted
          active={locked}
          indeterminate
          content="Please wait..."
        />
      </Sidebar>
    );
  }

  return (
    <Sidebar
      as={Menu}
      animation="uncover"
      icon={iconLabel}
      size="small"
      visible
      vertical
      inverted
      className={sidebarClass}
    >
      <Link
        to="/"
        className="map-icon"
        title={formatMessage(additionalMessages.mapTitle)}
      >
        <Menu.Item name="map" active={false}>
          <Icon name="map outline" size="large" />
          <Loader active={loadingProject} indeterminate content="Loading..." />
        </Menu.Item>
      </Link>

      {expandSidebar ? (
        <>
          {<Divider inverted />}
          <FeatureSearch formatMessage={formatMessage} />
          {<Divider inverted />}
        </>
      ) : (
        <Menu.Item className="collapsed-menu-search">
          <Popup
            trigger={
              <Icon
                name="search"
                title={formatMessage(additionalMessages.searchHelp)}
                circular
              />
            }
            on="click"
            position="right center"
            content={<FeatureSearch formatMessage={formatMessage} />}
          />
        </Menu.Item>
      )}

      <Menu.Item
        name={expandSidebar ? formatMessage(additionalMessages.project) : null}
        active={location.pathname === '/project'}
      >
        <Link
          to="/project"
          title={formatMessage(additionalMessages.projectTitle)}
          data-testid="menuProject"
        >
          <Icon className="setting" />
          {expandSidebar && formatMessage(additionalMessages.project)}
        </Link>
      </Menu.Item>
      <Menu.Item
        name="network_sites"
        active={location.pathname === '/network_sites'}
      >
        <Link
          to="/network_sites"
          title={formatMessage(additionalMessages.networkSitesTitle)}
          data-testid="menuNetworkSites"
        >
          <Icon className="site-icon-inverted" />
          {expandSidebar && (
            <FormattedMessage
              id="mainMenu.networkSites"
              defaultMessage="Network Sites"
              description="Network Sites main menu command"
            />
          )}
          {expandSidebar && (
            <Label className="float-right">{networkSiteCount}</Label>
          )}
        </Link>
      </Menu.Item>
      <Menu.Item
        name="subscriber_sites"
        active={location.pathname === '/subscriber_sites'}
      >
        <Link
          to="/subscriber_sites"
          title={formatMessage(additionalMessages.subscriberSitesTitle)}
          data-testid="menuSubscriberSites"
        >
          <Icon className="subscriber-site-icon-inverted" />
          {expandSidebar && (
            <FormattedMessage
              id="mainMenu.subscriberSites"
              defaultMessage="Subscriber Sites"
              description="Subscriber Sites main menu command"
            />
          )}
          {expandSidebar && (
            <Label className="float-right">{subscriberSiteCount}</Label>
          )}
        </Link>
      </Menu.Item>

      <Menu.Item name="PTP" active={location.pathname === '/ptp'}>
        <Link
          to="/ptp"
          title={formatMessage(additionalMessages.ptpLinksTitle)}
          data-testid="menuPtpLinks"
        >
          <Icon className="ptp-link-icon-inverted" />
          {expandSidebar && (
            <FormattedMessage
              id="common.ptpLinks"
              defaultMessage="PTP Links"
              description="PTP Links main menu command"
            />
          )}
          {expandSidebar && (
            <Label className="float-right">{ptpLinksCount}</Label>
          )}
        </Link>
      </Menu.Item>

      <Menu.Item name="access_points" active={location.pathname === '/aps'}>
        <Link
          to="/aps"
          title={formatMessage(additionalMessages.pmpNetworkDeviceTitle)}
          data-testid="menuPmpNetworkDevices"
        >
          <Icon className="access-point-icon-inverted" />
          {expandSidebar && (
            <FormattedMessage
              id="common.pmpDevices"
              defaultMessage="PMP Network Devices"
              description="PMP network device main menu command"
            />
          )}
          {expandSidebar && (
            <Label className="float-right">{accessPointsCount}</Label>
          )}
        </Link>
      </Menu.Item>

      <Menu.Item name="mesh_links" active={location.pathname === '/mesh'}>
        <Link
          to="/mesh"
          title={formatMessage(additionalMessages.meshLinksTitle)}
          data-testid="menuMeshLinks"
        >
          <Icon className="mesh-link-icon-inverted" />
          {expandSidebar && (
            <FormattedMessage
              id="common.meshLinks"
              defaultMessage="Mesh Links"
              description="Mesh Links main menu command"
            />
          )}
          {expandSidebar && (
            <Label className="float-right">{meshLinksCount}</Label>
          )}
        </Link>
      </Menu.Item>

      <Menu.Item name="pmp" active={location.pathname === '/pmp'}>
        <Link
          to="/pmp"
          title={formatMessage(additionalMessages.pmpLinksTitle)}
          data-testid="menuPnpmLinks"
        >
          <Icon className="pmp-link-icon-inverted" />
          {expandSidebar && (
            <FormattedMessage
              id="common.pmpLinks"
              defaultMessage="PMP Links"
              description="PMP Links main menu command"
            />
          )}
          {expandSidebar && (
            <Label className="float-right">{pmpLinksCount}</Label>
          )}
        </Link>
      </Menu.Item>

      <Menu.Item name="bom" active={location.pathname === '/bom'}>
        <Link
          to="/bom"
          title={formatMessage(additionalMessages.bom)}
          data-testid="menuBillOfMaterials"
        >
          <Icon name="file alternate" />
          {expandSidebar && (
            <FormattedMessage
              id="common.bom"
              defaultMessage="Bill of Materials"
              description="Project BOM main menu command"
            />
          )}
        </Link>
      </Menu.Item>

      {(expandSidebar || showBestServer) && (
        <Menu.Item>
          <Menu.Header>
            <FormattedMessage
              id="common.advancedFeatures"
              defaultMessage="Advanced Features"
            />
          </Menu.Header>
        </Menu.Item>
      )}
      <Menu.Item
        name="antennas"
        active={location.pathname.includes('/antennas')}
      >
        <Link
          to="/antennas/ptp"
          title={formatMessage(additionalMessages.antennas)}
          data-testid="antennas"
        >
          <Icon className="antenna-icon-inverted" />
          {expandSidebar && (
            <FormattedMessage
              id="common.antennas"
              defaultMessage="Antennas"
              description="Antennas"
            />
          )}
        </Link>
      </Menu.Item>
      {(permissionWrite || showBestServer) && (
        <Menu.Item
          name="best_server"
          active={location.pathname === '/best_server'}
        >
          <Link
            to="/best_server"
            title={formatMessage(additionalMessages.bestServerTitle)}
            data-testid="menuBestServer"
          >
            <Icon className="best-server-icon-inverted" />
            {expandSidebar && (
              <FormattedMessage
                id="common.bestServer"
                defaultMessage="Best Server"
                description="Best Server main menu command"
              />
            )}
          </Link>
        </Menu.Item>
      )}

      {hasWritePermission && (
        <Menu.Item
          name="terragraph"
          active={location.pathname === '/terragraph_planner'}
        >
          <Link
            to="/terragraph_planner"
            title={formatMessage(additionalMessages.terragraphTitle)}
            data-testid="terragraphPlanner"
          >
            <Icon className="terragraph-logo-icon-inverted" />
            {expandSidebar && (
              <FormattedMessage
                id="common.terragraph"
                defaultMessage="Terragraph Planner"
                description="terragraph main menu command"
              />
            )}
          </Link>
        </Menu.Item>
      )}
      <div
        style={{
          position: 'absolute',
          bottom: '1em',
          right: '1px',
        }}
      >
        <Segment basic inverted>
          <Icon
            name={expandSidebar ? 'angle double left' : 'angle double right'}
            link
            size="large"
            title={formatMessage(
              expandSidebar
                ? additionalMessages.collapseSidebar
                : additionalMessages.expandSidebar
            )}
            onClick={toggleSidebar}
          />
        </Segment>
      </div>
    </Sidebar>
  );
};

const MainMenu = connect((state) => {
  return {
    expandSidebar: state.mainFrame.expandSidebar,
    project: state.project,
    showHelp: state.mainFrame.showHelp,
    networkSiteCount: state.mainFrame.networkSiteCount,
    subscriberSiteCount: state.mainFrame.subscriberSiteCount,
    ptpLinksCount: state.mainFrame.ptpLinksCount,
    pmpLinksCount: state.mainFrame.pmpLinksCount,
    accessPointsCount: state.mainFrame.accessPointsCount,
    meshLinksCount: state.mainFrame.meshLinksCount,
    smsCount: state.pmp.smsPerProject.count,
    viewshedsCount: state.mainFrame.viewshedsCount,
    loadingProject: state.mainFrame.loadingProject,
    projectId: state.mainFrame.projectId,
    equipmentConfigsCount: state.equipment.equipmentConfigsCount,
    permissionWrite: state.mainFrame.permissionWrite,
    hasWritePermission: state.mainFrame.hasWritePermission,
    warning: state.mainFrame.warning,
    locked: state.mainFrame.locked,
  };
})(MainMenuContainer);

export default injectIntl(MainMenu);

import React from 'react';
import get from 'lodash/get';
import { useFormContext, Controller } from 'react-hook-form';
import { Segment, Grid, Label, Form } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { type PTPFormField, lkAttr } from 'src/pages/ptp/utils';
import { useLinkKind } from 'src/pages/ptp/hooks';

export function OperatingConditions(props: any) {
  const { fields, choices, setModified } = props;
  const { control } = useFormContext();
  const linkKind = useLinkKind();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );

  return (
    <Segment style={{ paddingBottom: '2rem' }}>
      <Grid columns={'equal'}>
        <Grid.Row
          className="custom-row"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {fields?.map((field: PTPFormField<any>) => {
            const choicesGetter = lkAttr(field, "choicesGetter", linkKind);
            const valueGetter = lkAttr(field, "valueGetter", linkKind);
            const level = get(choices, choicesGetter);
            const ctrlChoices =
              level == null
              ? []
              : level.map((choice: string) => ({
                text: choice,
                value: choice,
                key: choice,
              }));

            return (
              <React.Fragment key={field.getter}>
                <Grid.Column textAlign="right">
                  <Label basic className="no-border">
                    {field.label}
                  </Label>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  <Controller
                    control={control}
                    name={field.getter}
                    defaultValue={get(choices, valueGetter)}
                    render={({ field: { ref, onChange, ...rest } }) => (
                      <Form.Select
                        options={ctrlChoices}
                        {...rest}
                        disabled={!permissionWrite}
                        onChange={(e, data) => {
                          onChange(data.value);
                          setModified(true);
                        }}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>{field.units}</Grid.Column>
              </React.Fragment>
            );
          })}
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

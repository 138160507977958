import { meterToAny } from 'src/utils/useful_functions';

export const fixedRenderer = (props) => {
  const { precision } = props;
  if (props?.value?.toFixed) {
    return props.value.toFixed(precision || 1);
  } else {
    return props.value || '';
  }
};

export const HeightRenderer = (props) => {
  const { context, precision } = props;
  return meterToAny(props.value, context.prefs.heightUnits).toFixed(
    precision || 1
  );
};

export const RangeRenderer = (props) => {
  const { rangeUnits, precision } = props;
  return meterToAny(props.value, rangeUnits, 3, false).toFixed(precision || 3);
};

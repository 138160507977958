import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getProductPanels, getSMCacheKeys } from './utils';
import { postWithAuth } from 'src/api';
import { get } from 'lodash';
import { Accordion, Form, Grid, Segment } from 'semantic-ui-react';
import TitleAndActions from '../TitleAndActions';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import messages from 'src/messages';
import NDGeneralPanel from '../nd/NDGeneralPanel';
import NDEquipmentPanel from '../nd/NDEquipmentPanel';
import NDAntennaPanel from '../nd/NDAntennaPanel';
import WarningAccordion from '../WarningAccordion';
import NDPowerPanel from '../nd/NDPowerPanel';
import { PMP450V } from 'src/app.constants';
import StoredAccordion from 'src/components/StoredAccordion';
import SubscriberComponentCarrier from '../SubscriberComponentCarrier';
import PMPProfileChart from './PMPProfileChart';
import SMPerformanceSummary from './SMPerformanceSummary';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import AntennaFormContainer from 'src/pages/antennas/AntennaFormContainer';
import { PerformanceDetailsTabs } from 'src/pages/ptp/PerformanceDetailsTabs';

function SMForm({ projectId, id, sm, ap, choices, profile }) {
  const [modified, setModified] = useState<boolean>(false);
  const { key, choicesKey, profileKey } = getSMCacheKeys(projectId, id);
  const { formatMessage } = useIntl();
  const predictionModel = useSelector(
    (state) => state.mainFrame.predictionModel
  );
  const formMethods = useForm({
    defaultValues: { ...sm['subscriber'], results: sm.results },
  });
  const apProduct = get(ap, 'radios.0.equipment.product');
  const { handleSubmit, getValues, setValue, reset, formState } = formMethods;
  const { isSubmitting, errors } = formState;
  const product = getValues('radios.0.equipment.product');
  const qc = useQueryClient();
  const panels = getProductPanels(product);
  const onSubmit = async (formData: FieldValues) => {
    const { results, ...subscriber } = formData;
    await postWithAuth(
      `project/${projectId}/subscriber/${id}`,
      {
        subscriber: subscriber,
        local: sm.local,
        remote: sm.remote,
        access_point: { id: ap.id, name: ap.name },
        results: results,
      },
      'PATCH'
    );
    setModified(false);
  };

  const clearCache = () => {
    reset();
    qc.invalidateQueries({
      queryKey: key,
    });
    qc.invalidateQueries({
      queryKey: profileKey,
      exact: true,
    });
  };

  // we need to clear the react query cache on component
  // unmount to avoid issues like rendering the stale data
  // when we search from menu and navigate to the page
  useEffect(() => {
    return () => {
      reset();
      qc.removeQueries({
        queryKey: key,
      });
      qc.removeQueries({
        queryKey: profileKey,
        exact: true,
      });
    };
  }, [projectId, id]);

  const refreshChoices = ({ field }) => {
    if (!field) {
      // should not occur in production (programmer error)
      throw new Error('Refresh choices requested without field info');
    }

    const mergedFormData = getValues(); //merge(ap, getValues());
    // request new choices based on the updated form state (i.e.
    // in response to a user action: onChange)
    updateChoices.mutate({
      sm: mergedFormData,
      attr: field.attrName,
    });
  };

  const updateChoices = useMutation(
    ({ sm, attr }: { sm: any; attr: string }) => {
      const equipment = get(sm, 'radios.0.equipment');
      if (attr !== 'product') {
        setValue('product.prev', equipment.product);
      }
      return Promise.allSettled([
        postWithAuth(`equipment/subscriber/choices`, {
          project_id: projectId,
          sm,
          changed: attr,
        }),
        Promise.resolve(attr),
      ]);
    },
    {
      onSuccess: (updateChoicesResponse) => {
        const choicesResponse = (
          updateChoicesResponse[0] as PromiseFulfilledResult<any>
        ).value;

        const attr = (updateChoicesResponse[1] as PromiseFulfilledResult<any>)
          .value;
        // TODO after changing the backend api we can remove the below code
        // from 84 to 88 which changes equipment choices array to object
        const equipmentChoiceMap = {};
        choicesResponse['equipment'].forEach((attr) => {
          equipmentChoiceMap[attr.attr_name] = attr;
        });
        choicesResponse['equipment'] = equipmentChoiceMap;
        // update choices query data to cause form to rerender
        // with updated correct choices
        qc.setQueryData(choicesKey, () => ({
          ...choicesResponse,
        }));
        // update react hook form internal state based on
        // new choices on a field by field basis (e.g. when form
        // values fall out of sync with choices like changing product
        // updating the antenna choices)
        const product = choicesResponse.equipment['product']['value'];
        const newPanels = getProductPanels(product);
        const choices = {
          ...choicesResponse,
        };
        newPanels.syncFormState(getValues, setValue, newPanels, choices, attr);
      },
    }
  );

  return (
    <Segment className="detailPanel pmplayout" floated="left" basic compact>
      <FormProvider {...formMethods}>
        <Form method="post" onSubmit={handleSubmit(onSubmit)}>
          <Grid stackable columns={2}>
            <Grid.Column id="subscriber-details" width={5}>
              <TitleAndActions
                localProduct={product}
                projectId={projectId}
                kind={'subscriber'}
                siteId={sm.remote.site_id}
                powerFromSwitch={ap.properties.power_from_switch}
                modified={modified}
                isSubmitting={isSubmitting}
                id={id}
                name={sm.subscriber.name}
                dirty={sm.dirty}
                errors={errors}
                formatMessage={formatMessage}
              >
                <Link
                  title={`${formatMessage(messages.viewAccessPoint)}`}
                  to={`/aps/${ap.id}`}
                >
                  {ap.name}
                </Link>{' '}
                to{' '}
                <Link
                  title={`${formatMessage(messages.viewSite)}`}
                  to={`/subscriber_sites/${sm.remote.site_id}`}
                >
                  {sm.subscriber.name}
                </Link>
              </TitleAndActions>
              <Accordion
                exclusive={false}
                fluid
                className="product-family-panels"
              >
                <WarningAccordion
                  obj={{
                    warnings: sm.warnings,
                    ...sm.subscriber,
                  }}
                />
                <NDGeneralPanel
                  ap={ap}
                  sm={sm.subscriber}
                  isAp={false}
                  title={'General'}
                  setModified={setModified}
                ></NDGeneralPanel>
                <NDEquipmentPanel
                  ap={ap}
                  sm={sm.subscriber}
                  modified={modified}
                  setModified={setModified}
                  refreshChoices={refreshChoices}
                  choices={choices}
                  panel={panels.equipment}
                ></NDEquipmentPanel>
                <NDEquipmentPanel
                  ap={ap}
                  sm={sm.subscriber}
                  modified={modified}
                  setModified={setModified}
                  refreshChoices={refreshChoices}
                  choices={choices}
                  panel={panels.product}
                ></NDEquipmentPanel>
                <NDAntennaPanel
                  ap={ap}
                  sm={sm.subscriber}
                  modified={modified}
                  panel={panels.antenna}
                  choices={choices}
                  setModified={setModified}
                  refreshChoices={refreshChoices}
                ></NDAntennaPanel>
                <NDPowerPanel
                  ap={ap}
                  sm={sm.subscriber}
                  modified={modified}
                  panel={panels.power}
                  choices={choices}
                  setModified={setModified}
                  refreshChoices={refreshChoices}
                ></NDPowerPanel>
              </Accordion>
            </Grid.Column>
            <Grid.Column id="link-performance-details" width={11}>
              <Accordion
                exclusive={false}
                fluid
                className="product-page-right-panels"
              >
                {apProduct === PMP450V && (
                  <StoredAccordion
                    name="sm_componen_carrier"
                    title="Component Carrier"
                  >
                    <SubscriberComponentCarrier
                      subscriber={sm.subscriber}
                      ap={ap}
                    ></SubscriberComponentCarrier>
                  </StoredAccordion>
                )}
                <PMPProfileChart
                  profile={profile}
                  sm={sm.subscriber}
                  local={sm.local}
                  remote={sm.remote}
                  parentFormSubmitHandler={handleSubmit(onSubmit)}
                  modified={modified}
                  setModified={setModified}
                ></PMPProfileChart>

                <StoredAccordion
                  name="SubscriberPerformanceSummary"
                  title={`${formatMessage(
                    messages.apPanelPerformance
                  )} (${predictionModel})`}
                >
                  <SMPerformanceSummary
                    modified={modified}
                    setModified={setModified}
                    sm={sm.subscriber}
                    local={sm.local}
                    remote={sm.remote}
                    results={sm.results}
                    choices={choices}
                  />
                </StoredAccordion>
                <StoredAccordion
                  name="SubscriberPerformanceDetails"
                  title={formatMessage(messages.apPanelPerformanceDetails)}
                >
                  <PerformanceDetailsTabs
                    details={sm.results.details}
                    localEndName={sm.local.site_name}
                    remoteEndName={sm.remote.site_name}
                    disabled={modified}
                    kind="pmp"
                    needsRefresh={sm.dirty}
                  />
                </StoredAccordion>
              </Accordion>
            </Grid.Column>
          </Grid>
        </Form>
      </FormProvider>
      <RouteLeavingGuard
        when={modified}
        shouldBlockNavigation={() => modified}
        yes="Yes"
        no="No"
        title={`PMP Link: ${ap.name} to ${sm.remote.site_name}`}
        content={formatMessage(messages.warning)}
        callback={() => {
          clearCache();
        }}
      />
      <AntennaFormContainer
        path={sm.subscriber}
        kind="sm"
        refetch={(field) => refreshChoices({ field })}
      />
    </Segment>
  );
}

export default SMForm;

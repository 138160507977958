import { METER_TO_FEET } from 'src/app.constants';
import { store } from '../../store';
import { makePlural } from '../../utils/useful_functions';
import { setBulkEditSelectedRows } from '../mainframe/mainframe.reducer';
import { ICellEditorParams, NewValueParams } from 'ag-grid-community';
import { valueGetter } from 'src/components/controls/lpgrid/common/columnTypes';
import { set } from 'lodash';
import { postWithAuth } from 'src/api';
import { VALID_PMP_BANDS } from 'src/model/BandDefinition';

function tooltip(params) {
  const count =
    params.data.connected_subscribers + params.data.unconnected_subscribers;
  const text = makePlural(count, 'module', 'modules');
  let result = `${count} subscriber ${text}`;
  const warnings = params.data.warnings?.hover;
  if (warnings?.length > 0) {
    result = `${result} - Reason for error: ${warnings.join(', ')}`;
  }
  return result;
}
const postNDUpdates = (projectId, ids, data, gridApi) => {
  const url = `project/${projectId}/access_points/bulk`;

  const postObj = {
    ids: ids,
    data: data,
    bulk: 'edit',
  };
  gridApi.showLoadingOverlay();
  postWithAuth(url, postObj, 'PATCH')
    .then(() => {})
    .catch((err) => {})
    .finally(() => {
      gridApi.hideOverlay();
    });
};

type OnCellChangedFunction = (event: NewValueParams) => void;

const bulkEditer = (
  projectId: string,
  attrPath: string,
  rowAttr: string,
  parser?: (event: NewValueParams) => any,
  formatter?: (value: any) => any,
  dispatch?: boolean
): OnCellChangedFunction => {
  return (event) => {
    if (dispatch === undefined) {
      dispatch = true;
    }

    const { newValue, api, data, oldValue } = event;
    let parsedValue = newValue;

    // Take advantage of "==" here rather than "==="
    if (oldValue == newValue) {
      return;
    }

    if (!api) {
      return;
    }

    let selectedIds = [data.id];
    let selectedRows = api.getSelectedRows();
    if (!selectedRows?.length) {
      selectedRows = [data];
    }
    if (selectedRows?.length) {
      selectedIds = [];
      selectedRows.forEach((row) => {
        const { id } = row;
        if (parser) {
          parsedValue = parser(event);
        }
        if (formatter) {
          row[rowAttr] = formatter(parsedValue);
        } else {
          row[rowAttr] = parsedValue;
        }
        row.updated = false;
        selectedIds.push(id);
      });
      api.applyTransaction({ update: selectedRows });
    }
    if (dispatch) {
      store.dispatch(
        setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
      );
    }

    let postData = set({}, attrPath, parsedValue);
    postNDUpdates(projectId, selectedIds, postData, api);
  };
};

const HeightParser = (event: NewValueParams): number => {
  const { newValue, context } = event;
  const { prefs } = context;
  if (prefs.heightUnits === 'ft') {
    return parseFloat(newValue) / METER_TO_FEET;
  } else {
    return parseFloat(newValue);
  }
};

const YesNoParser = (event: NewValueParams): boolean => {
  const { newValue } = event;
  return newValue === 'Yes';
};

const YesNoFormatter = (value: any): string => {
  if (value) {
    return 'Yes';
  } else {
    return 'No';
  }
};

export const getAccessPointColumns = (projectId) => {
  let cols = [];
  cols.push(
    ...[
      {
        field: 'name',
        sort: 'asc',
        headerName: 'Name',
        minWidth: 120,
        cellClass: ['ag-grid-cell-truncate-text'],
        pinned: 'left',
        tooltipValueGetter: tooltip,
        getQuickFilterText: (params) => {
          return (
            params.value &&
            params.value.toString().toLowerCase().replaceAll(' ', '')
          );
        },
        cellRenderer: 'linkRenderer',
        cellRendererParams: {
          getHref: (data) => `/aps/${data.id}`,
        },
        isComparatorRequired: true,
        cellStyle: (params) => {
          if (params.data.radio_number && params.data.radio_number !== 1) {
            return { paddingLeft: '45px' };
          }
          return {};
        },
        valueGetter: (params) => {
          if (params.data.radio_number) {
            return `${params.data.name} (${params.data.radio_number})`;
          } else {
            return params.data.name;
          }
        },
      },

      {
        field: 'band',
        headerName: 'Band',
        minWidth: 120,
        tooltipValueGetter: tooltip,
        cellEditor: 'agSelectCellEditor',
        cellClass: ['select-cell'],
        editable: ({ data }) => {
          return data.radio_number !== 2;
        },
        onCellValueChanged: bulkEditer(
          projectId,
          'radios[0].equipment.band',
          'band'
        ),
        cellEditorParams: {
          values: VALID_PMP_BANDS,
        },
        isComparatorRequired: true,
      },
      {
        field: 'product',
        headerName: 'Product',
        minWidth: 150,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },
      {
        field: 'regulation',
        headerName: 'Country',
        minWidth: 120,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },

      {
        field: 'bandwidth',
        headerName: 'Bandwidth',
        minWidth: 120,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },

      {
        field: 'height_m',
        headerName: 'Height',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        headerValueGetter: ({ context }) => {
          return `Height (${context.prefs.heightUnits})`;
        },
        cellRenderer: 'heightRenderer',
        cellEditor: 'numberEditor',
        cellClass: ['number-cell'],
        cellEditorParams: {
          precision: 1,
          min: 0,
          max: 3000,
          step: 0.1,
        },
        filterParams: {
          valueGetter: (params) => {
            if (store.getState().mainFrame.prefs.heightUnits === 'ft')
              return Number(params.data.height_m * METER_TO_FEET).toFixed(1);
            else return Number(params.data.height_m).toFixed(1);
          },
        },
        getQuickFilterText: (params) => {
          if (store.getState().mainFrame.prefs.heightUnits === 'ft')
            return Number(params.data.height_m * METER_TO_FEET).toFixed(1);
          else return Number(params.data.height_m).toFixed(1);
        },
        editable: ({ data }) => {
          return data.radio_number !== 2;
        },
        onCellValueChanged: bulkEditer(
          projectId,
          'radios[0].antennas[0].height',
          'height_m',
          HeightParser
        ),
      },
      {
        field: 'sm_range',
        headerName: 'SM Range',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellEditor: 'numberEditor',
        cellClass: ['number-cell'],
        cellEditorParams: {
          precision: 3,
          min: 0.009,
          max: 300,
          step: 0.001,
        },
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 3,
        },
        filterParams: {
          valueGetter: (params) => {
            return parseFloat(params.data.sm_range).toFixed(3);
          },
        },
        getQuickFilterText: (params) => {
          return parseFloat(params.data.sm_range).toFixed(3);
        },
        editable: ({ data }) => {
          return data.radio_number !== 2;
        },
        onCellValueChanged: bulkEditer(
          projectId,
          'radios[0].equipment.sm_range',
          'sm_range'
        ),
      },
      {
        field: 'range_units',
        headerName: 'Range Units',
        minWidth: 120,
        tooltipValueGetter: tooltip,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['kilometers', 'miles'],
        },
        cellClass: ['select-cell'],
        editable: ({ data }) => {
          return data.radio_number !== 2;
        },
        onCellValueChanged: bulkEditer(
          projectId,
          'radios[0].equipment.range_units',
          'range_units'
        ),
        isComparatorRequired: true,
      },
      {
        field: 'eirp',
        headerName: 'EIRP (dBm)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
        filterParams: {
          valueGetter: (params) => {
            return parseFloat(params.data.eirp).toFixed(1);
          },
        },
        getQuickFilterText: (params) => {
          return parseFloat(params.data.eirp).toFixed(1);
        },
      },
      {
        field: 'connected_subscribers',
        headerName: 'Connected Subscribers',
        minWidth: 80,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'unconnected_subscribers',
        headerName: 'Unconnected Subscribers',
        minWidth: 80,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'dl_throughput',
        headerName: 'DL Throughput',
        minWidth: 140,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },
      {
        field: 'ul_throughput',
        headerName: 'UL Throughput',
        minWidth: 140,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },
      {
        field: 'total_throughput',
        headerName: 'Total Throughput',
        minWidth: 140,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },
      {
        field: 'node_type',
        headerName: 'Node Type',
        cellEditor: 'agSelectCellEditor',
        cellClass: ['select-cell'],
        cellEditorParams: {
          values: ['', 'DN', 'POP'],
        },
        editable: ({ data }) => {
          return data.radio_number !== 2;
        },
        onCellValueChanged: bulkEditer(projectId, 'node_type', 'node_type'),
      },
      {
        field: 'power_from_switch',
        headerName: 'Power from Switch?',
        minWidth: 130,
        cellRenderer: 'yesNoRenderer',
        cellEditor: 'agSelectCellEditor',
        cellClass: ['select-cell'],
        cellEditorParams: {
          values: ['Yes', 'No'],
        },
        editable: ({ data }) => {
          return data.radio_number !== 2;
        },
        onCellValueChanged: bulkEditer(
          projectId,
          'properties.power_from_switch.checked',
          'power_from_switch',
          YesNoParser,
          YesNoFormatter,
          false
        ),
      },
    ]
  );
  return cols;
};

export const getAdditionalAccessPointColumns = (projectId) => {
  let cols = [];
  cols.push(
    ...[
      {
        field: 'network_site',
        headerName: 'Network Site',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'identifier',
        sort: 'asc',
        headerName: 'Carrier Name',
        cellRenderer: 'naStringRenderer',
        minWidth: 60,
        pinned: 'left',
      },
      {
        field: 'carrier_qty',
        headerName: 'Number of Carriers',
        minWidth: 60,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
        cellRenderer: 'naStringRenderer',
        // This is a hack and needs proper bulk editing capabilities
        // coming from the backend
        editable: ({ data }) => {
          return false;
          //   return (
          //     data.radio_number !== 2 &&
          //     (data.carrier_qty === 1 || data.carrier_qty === 2)
          //   );
        },
        cellEditorSelector: (params: ICellEditorParams) => {
          return {
            component: 'agSelectCellEditor',
            params: {
              values: [1, 2],
            },
          };
        },
        onCellValueChanged: bulkEditer(
          projectId,
          'radios[0].equipment.carrier_qty',
          'carrier_qty'
        ),
      },
      {
        field: 'component_carrier_qty',
        headerName: 'Component Carriers',
        minWidth: 60,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },
      {
        field: 'transmit_frequency',
        headerName: 'Tx Frequency (MHz)',
        cellRenderer: 'fixedRenderer',
        valueGetter: (params) => valueGetter(params, 3, ''),
      },
      {
        field: 'bandwidth_sector_2',
        headerName: 'Bandwidth - Sector 2',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'antenna',
        headerName: 'Antenna',
        minWidth: 300,
        cellClass: ['ag-grid-cell-truncate-text'],
        tooltipValueGetter: tooltip,
      },
      {
        field: 'antenna_azimuth',
        headerName: 'Antenna Azimuth, True N (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'antenna_beamwidth',
        headerName: 'Antenna Beamwidth (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'antenna_tilt',
        headerName: 'Antenna Elevation (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'latitude',
        headerName: 'Latitude',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'longitude',
        headerName: 'Longitude',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'mac_address',
        headerName: 'MAC Address',
        minWidth: 100,
        tooltipValueGetter: tooltip,
        cellRenderer: 'macRenderer',
      },
      {
        field: 'modelled_beamwidth',
        headerName: 'Modeled Beamwidth (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'connected_subscribers_sector_1',
        headerName: 'Connected Subscribers - Sector 1',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'connected_subscribers_sector_2',
        headerName: 'Connected Subscribers - Sector 2',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'sector_1_links_with',
        headerName: 'Sector 1 Links with',
        minWidth: 200,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'sector_2_links_with',
        headerName: 'Sector 2 Links with',
        minWidth: 200,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'mesh_links_sector_1',
        headerName: 'Mesh Links - Sector 1',
        tooltipValueGetter: tooltip,
      },
      {
        field: 'mesh_links_sector_2',
        headerName: 'Mesh Links - Sector 2',
        tooltipValueGetter: tooltip,
      },
      {
        field: 'dl_throughput_sector_1',
        headerName: 'DL Throughput - Sector 1',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'ul_throughput_sector_1',
        headerName: 'UL Throughput - Sector 1',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'total_throughput_sector_1',
        headerName: 'Total Throughput - Sector 1',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'dl_throughput_sector_2',
        headerName: 'DL Throughput - Sector 2',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'ul_throughput_sector_2',
        headerName: 'UL Throughput - Sector 2',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'total_throughput_sector_2',
        headerName: 'Total Throughput - Sector 2',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        minWidth: 150,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'msn',
        headerName: 'MSN',
        minWidth: 150,
        tooltipValueGetter: tooltip,
      },
    ]
  );
  return cols;
};

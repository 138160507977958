import { get } from 'lodash';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { createRoot } from 'react-dom/client';
import ProviderWrapper from '../ProviderWrapper';
import eskape from 'eskape';
import L from 'leaflet';
import { featureCollection, lineString, point } from '@turf/helpers';
import { store, history } from '../store';
import { getWithAuth, postWithAuth } from '../api';
import { uiConfirmAction, uiSet } from '../pages/mainframe/mainframe.reducer';
import {
  getSessionJSON,
  setSessionJSON,
  encodeLatLong,
  isEmptyObject,
  desktopDistance,
  meterToAny,
} from './useful_functions';
import AttachSubscribersProject from '../pages/pmp/AttachSubscribersDialog';
import { toast } from 'react-toastify';
import { Message } from 'semantic-ui-react';
import { states } from 'src/pages/map/leafletCanvas';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_SM_RANGE } from 'src/app.constants';
import debounce from 'lodash/debounce';
import { setMapSiteName } from 'src/pages/map/map.reducer';

const mapPopupCloseHandler = () => {
  document.getElementById('map-popup-container').style.display = 'none';
};

const getPopupInfo = ({ header = null, body = null, table = null }) => {
  let description = eskape`<div id="map-popup">`;
  window.mapPopupCloseHandler = () => {
    mapPopupCloseHandler();
  };
  if (header)
    description = eskape`
        <div id="map-popup">
          <div class="header">
            <div>${header}</div>
            <button class="ui circular icon right floated button ml-4" onclick="window.mapPopupCloseHandler()">
              <i id="map-popup-close"
                class="close icon"
                title="${'Click to Close'}">
              </i>
            </button
          </div>
        </div>
    `;
  if (table && !isEmptyObject(table)) {
    description += ReactDOMServer.renderToStaticMarkup(table);
  }
  if (body && !isEmptyObject(body)) {
    description += '<div class="ui segment basic no-border">';
    for (const [key, value] of Object.entries(body)) {
      description += `<div class="item"><div class="ui label basic no-border tiny">${key}:</div><span class="value mr-1">${value}</span></div>`;
    }
    description += '</div></div>';
  }
  description += '</div>';
  return description;
};

/**
 * Return a string containing the site pop-up information
 *
 * @param {Object} obj - GeoJSON feature instance
 */
const getSiteInfo = (obj) => {
  const [lng, lat] = obj.geometry.coordinates;
  const { latLngFormat } = store.getState().mainFrame.prefs;
  const popupInfo = {
    header: obj.properties.name,
    body: {
      Location: `${encodeLatLong(lat, latLngFormat, true)}, ${encodeLatLong(
        lng,
        latLngFormat,
        false
      )}`,
    },
  };
  return getPopupInfo(popupInfo);
};

/**
 * Return a string containing the PMP link pop-up information
 *
 * @param {Object} obj - GeoJSON feature instance
 */
const getPMPLinkInfo = async (obj) => {
  const { properties } = obj;
  const state = store.getState();
  const { projectId, prefs } = state.mainFrame;
  const response = await getWithAuth(
    `project/${projectId}/subscriber/${properties.sm_id}/results`
  );
  const performanceToND = get(response, 'summary.local.3.value');
  const performanceToSM = get(response, 'summary.remote.3.value');
  const product = get(response, 'equipment.product');

  const units = prefs.rangeUnits;
  const { loc_lat, loc_lng, rem_lat, rem_lng } = obj.properties;
  const linkLength_m = desktopDistance(loc_lat, loc_lng, rem_lat, rem_lng);
  const popupInfo = {
    header: obj.properties.name,
    body: {
      Product: product,
      'Path Length': `${meterToAny(linkLength_m, units, 3)} ${units}`,
      'DL Max Usable Mode': performanceToSM,
      'UL Max Usable Mode': performanceToND,
    },
  };
  return getPopupInfo(popupInfo);
};

const toGolay = (end) => {
  const golay = end?.link_golay;
  if (golay === 'Same as DN') {
    return `${golay} (${end.dn_golay})`;
  }
  return golay;
};

/**
 * Return a string containing the mesh link pop-up information
 *
 * @param {Object} obj - GeoJSON feature instance
 */
const getMeshLinkInfo = async (obj) => {
  const { properties } = obj;
  const state = store.getState();
  const { projectId, prefs } = state.mainFrame;
  const response = await getWithAuth(
    `project/${projectId}/mesh_link/${properties.id}`
  );
  const units = prefs.rangeUnits;
  const perfSummary = response?.summary;
  const eqSummary = perfSummary?.equipment_summary;
  const local = eqSummary?.local ?? {};
  const remote = eqSummary?.remote ?? {};
  const [localName, remoteName] = obj.properties.name.split(' to ');
  const { loc_lat, loc_lng, rem_lat, rem_lng } = obj.properties;
  const linkLength_m = desktopDistance(loc_lat, loc_lng, rem_lat, rem_lng);

  const popupInfo = {
    body: {
      'Path Length': `${meterToAny(linkLength_m, units, 3)} ${units}`,
      //   'Total Path Loss': `${(perfSummary?.link?.total_path_loss || 0).toFixed(
      //     2
      //   )} dB`,
    },
    table: (
      <table>
        <tr>
          <th>
            {' '}
            <span className="ui label basic no-border tiny">{localName}</span>
          </th>
          <th>
            {' '}
            <i className="ui icon arrow right"></i>
          </th>
          <th>
            {' '}
            <span className="ui label basic no-border tiny">{remoteName}</span>
          </th>
        </tr>
        <tr>
          <td className="value mr-1">{local?.eq_summary}</td>
          <td></td>
          <td className="value mr-1">{remote?.eq_summary}</td>
        </tr>
        <tr>
          <td className="item">
            <b className="ui label basic no-border tiny">Sector:</b>{' '}
            <span className="value mr-1">{local?.sector}</span>
          </td>
          <td></td>
          <td className="item">
            <b className="ui label basic no-border tiny">Sector:</b>{' '}
            <span className="value mr-1">{remote?.sector}</span>
          </td>
        </tr>
        <tr>
          <td>
            <b className="ui label basic no-border tiny">Channel:</b>{' '}
            <span className="value mr-1">{local?.channel}</span>
          </td>
          <td></td>
          <td>
            <b className="ui label basic no-border tiny">Channel:</b>{' '}
            <span className="value mr-1">{remote?.channel}</span>
          </td>
        </tr>
        <tr>
          <td>
            <b className="ui label basic no-border tiny">Golay: </b>
            <span className="value mr-1">{toGolay(local)}</span>
          </td>
          <td></td>
          <td>
            <b className="ui label basic no-border tiny">Golay:</b>{' '}
            <span className="value mr-1">{toGolay(remote)}</span>
          </td>
        </tr>
        <tr>
          <td>
            <b className="ui label basic no-border tiny">Polarity:</b>{' '}
            <span className="value mr-1">{local?.polarity}</span>
          </td>
          <td></td>
          <td>
            <b className="ui label basic no-border tiny">Polarity:</b>{' '}
            <span className="value mr-1">{remote?.polarity}</span>
          </td>
        </tr>
      </table>
    ),
    header: 'Mesh Link Details',
  };
  return getPopupInfo(popupInfo);
};

/**
 * Return a string containing the PTP link pop-up information
 *
 * @param {Object} obj - GeoJSON feature instance
 */
const getPTPLinkInfo = async (obj) => {
  const state = store.getState();
  const { prefs } = state.mainFrame;
  //   const response = await getWithAuth(
  //     `project/${projectId}/ptp_link/${properties.id}`
  //   );
  const { properties } = obj;
  const { projectId } = state.mainFrame;
  let response = await getWithAuth(`project/${projectId}/ptp/${properties.id}`);
  const band = get(response, 'local.radios.0.equipment.band');
  const localProduct = get(response, 'local.radios.0.equipment.product');
  const remoteProduct = get(response, 'remote.radios.0.equipment.product');
  const units = prefs.rangeUnits;
  const { loc_lat, loc_lng, rem_lat, rem_lng } = obj.properties;
  const linkLength_m = desktopDistance(loc_lat, loc_lng, rem_lat, rem_lng);
  const popupInfo = {
    header: obj.properties.name,
    body: {
      Band: band,
      Product:
        localProduct.toLowerCase() === remoteProduct.toLowerCase()
          ? localProduct
          : `${localProduct}/${remoteProduct}`,
      'Path Length': `${meterToAny(linkLength_m, units, 3)} ${units}`,
    },
  };
  return getPopupInfo(popupInfo);
};

/**
 * Return a string containing the access point pop-up information
 *
 * @param {Object} obj - GeoJSON feature instance
 */
const getAccessPointInfo = async (obj) => {
  const { properties } = obj;
  const state = store.getState();
  const { projectId } = state.mainFrame;
  let response = await getWithAuth(
    `project/${projectId}/access_point/${properties.id}`
  );
  const band = get(response, 'radios.0.equipment.band');
  const product = get(response, 'radios.0.equipment.product');
  const popupInfo = {
    header: obj.properties.name,
    body: {
      Band: band,
      Product: product,
    },
  };
  if (properties.connected_subscribers)
    popupInfo.body['Connected SMs'] = properties.connected_subscribers;
  if (properties.unconnected_subscribers)
    popupInfo.body['Unconnected SMs'] = properties.unconnected_subscribers;
  return getPopupInfo(popupInfo);
};

const getInfo = async (o) => {
  const kind = o.properties.kind;
  if (kind === 'network_site' || kind === 'subscriber_site') {
    return getSiteInfo(o);
  } else if (kind === 'pmp_link') {
    return getPMPLinkInfo(o);
  } else if (kind === 'ptp_link') {
    return getPTPLinkInfo(o);
  } else if (kind === 'mesh_link') {
    return getMeshLinkInfo(o);
  } else {
    return getAccessPointInfo(o);
  }
};

export const editObject = (kind, id) => {
  const rootURL = {
    network_site: 'network_sites',
    subscriber_site: 'subscriber_sites',
    ptp_link: 'ptp',
    access_point: 'aps',
    pmp_link: 'subscriber',
    mesh_link: 'mesh',
  }[kind];

  // Hide/remove the feature popup when editing or deleting a network or susbcriber sites
  if (['network_site', 'subscriber_site'].includes(kind)) {
    const editDeletePopup = document.getElementById('feature-popup-content');
    if (editDeletePopup) {
      editDeletePopup.remove();
    }
  }

  const navigate = store.getState().mainFrame.navigate;
  if (kind === 'pmp_link') {
    navigate(`/${rootURL}/${id}`);
  } else {
    navigate(`/${rootURL}/${id}`);
  }
};

export const deleteObject = (kind, id) => {
  const projectId = store.getState().mainFrame.projectId;
  const dialogInfo = {
    network_site: {
      url: `project/${projectId}/sites`,
      isSite: true,
    },
    subscriber_site: {
      url: `project/${projectId}/sites`,
      isSite: true,
    },
    // ptp_link: 'ptp',
    access_point: {
      url: `project/${projectId}/access_points`,
      isSite: false,
    },
    pmp_link: {
      url: `project/${projectId}/subscribers`,
      isSite: false,
    },
    mesh_link: {
      url: `project/${projectId}/mesh_links`,
      isSite: false,
    },
    ptp_link: {
      url: `project/${projectId}/ptp`,
      isSite: false,
    },
  }[kind];
  const payload = dialogInfo.isSite
    ? {
        kind: kind,
        ids: [id],
      }
    : [id];

  store.dispatch(
    uiConfirmAction({
      // We should use formatMessage here really,
      // but there is no easy way to get it
      header: 'Delete',
      message: 'Are you sure?',
      size: 'mini',
      onConfirm: () => {
        postWithAuth(dialogInfo.url, payload, 'DELETE');

        // Hide/remove the feature popup when editing or deleting a network or susbcriber sites
        if (['network_site', 'subscriber_site'].includes(kind)) {
          const editDeletePopup = document.getElementById(
            'feature-popup-content'
          );
          if (editDeletePopup) {
            editDeletePopup.remove();
          }
        }
      },
    })
  );
};

export const validateSubscriberSites = (value) => {
  const { userLimits, pmpLinksCount } = store.getState().mainFrame;
  const pmpLinksLimit = userLimits?.pmp_links;
  if (pmpLinksLimit < pmpLinksCount + value?.length) {
    return `Creating this PMP Link would exceed your maximum number of PMP Link(s) (${pmpLinksLimit})`;
  } else {
    return '';
  }
};

export const attachSm = (id) => {
  const modalRoot = createRoot(document.getElementById('modal-root'));
  modalRoot.render(
    <ProviderWrapper
      children={
        <AttachSubscribersProject
          apId={id}
          validateSubscriberSites={validateSubscriberSites}
          onClose={() => {
            modalRoot.unmount(document.getElementById('modal-root'));
          }}
        ></AttachSubscribersProject>
      }
      store={store}
      history={history}
    />
  );
};

/**
 * Return the info/pop-up window content for the object.
 *
 * This also attaches the pop-up link events to the window.
 */
export const createPopupContent = async (obj, msg = '') => {
  let info = '';
  if (msg) {
    info = msg;
  } else {
    info = await getInfo(obj);
  }
  if (obj) {
    // There must be a better way to do this.
    // Attach the click function to the window so that
    // we can call it in the plain JS that we have
    // to use for the info window.
    const { kind, id } = obj.properties;
    if (kind === 'pmp_link') {
      const { sm_id } = obj.properties;
      window.__LP_map_edit = () => editObject(kind, sm_id);
    } else {
      window.__LP_map_edit = () => editObject(kind, id);
    }
    info += `<i id="map-popup-edit" class="edit icon" title="Edit" onclick="window.__LP_map_edit();" />`;
  }
  return info;
};

const getDefaultSiteName = ({
  isNetwork,
  networkSiteCount,
  subscriberSiteCount,
  increment = 1,
}) => {
  const prefix = isNetwork ? 'Network Site' : 'Subscriber Site';
  const count = isNetwork ? networkSiteCount : subscriberSiteCount;
  const number = `${count + increment}`.padStart(3, '0');
  return `${prefix} ${number}`;
};

export const generateSiteNameFromTemplate = ({
  isNetwork,
  siteNameTemplate,
  networkSiteCount,
  subscriberSiteCount,
  increment = 1,
}) => {
  // Determine the total site count based on whether it's a network or subscriber site
  const totalSiteCount = isNetwork ? networkSiteCount : subscriberSiteCount;
  // Replace the '#' in the template with the site count
  return siteNameTemplate.replace(/#+/g, (match) => {
    return String(+totalSiteCount + increment).padStart(match.length, '0');
  });
};

const getSiteNames = ({
  isNetwork,
  siteNameTemplate,
  networkSiteCount,
  subscriberSiteCount,
  increment = 1,
}) => {
  const siteNameFromTemplate = generateSiteNameFromTemplate({
    isNetwork,
    siteNameTemplate,
    networkSiteCount,
    subscriberSiteCount,
    increment,
  });
  const defaultSiteName = getDefaultSiteName({
    isNetwork,
    networkSiteCount,
    subscriberSiteCount,
    increment,
  });

  return { siteNameFromTemplate, defaultSiteName };
};

export const createMapSite = debounce((lat, lng, kind, callback) => {
  const state = store.getState();
  const { projectId, networkSiteCount, subscriberSiteCount } = state.mainFrame;
  const {
    userName,
    defaultHeight,
    mapSiteName,
    networkSiteNameTemplate,
    subscriberSiteNameTemplate,
  } = state.map;

  const isNetwork = kind === 'network_site';
  const siteNameTemplate = isNetwork
    ? networkSiteNameTemplate
    : subscriberSiteNameTemplate;

  const { siteNameFromTemplate, defaultSiteName } = getSiteNames({
    isNetwork,
    siteNameTemplate,
    networkSiteCount,
    subscriberSiteCount,
  });
  const name =
    mapSiteName || siteNameFromTemplate || userName || defaultSiteName;

  if (defaultHeight >= 0 && defaultHeight <= 3000) {
    // for invalid height stoped to create access point on map.
    postWithAuth(`project/${projectId}/site`, {
      name,
      latitude: lat,
      longitude: lng,
      is_network_site: isNetwork,
      maximum_height: defaultHeight,
    })
      .then((data) => {
        const {
          siteNameFromTemplate: nextSiteNameFromTemplate,
          defaultSiteName: nextDefaultSiteName,
        } = getSiteNames({
          isNetwork,
          siteNameTemplate,
          networkSiteCount,
          subscriberSiteCount,
          increment: 2,
        });
        const nextSiteName = nextSiteNameFromTemplate || nextDefaultSiteName;
        store.dispatch(setMapSiteName(nextSiteName));
        callback && callback(data);
      })
      .catch((err) => {
        if (err.detail) {
          toast(<Message error>{err.detail}</Message>, {
            autoClose: false,
          });
        }
      });
  }
}, 200);

export const createNetworkSiteAndAP = debounce((lat, lng, siteId) => {
  const state = store.getState();
  const { projectId } = state.mainFrame;
  const { defaultHeight, useSiteMaxHeight } = state.map;
  if (defaultHeight >= 0 && defaultHeight <= 3000) {
    // for invalid height stoped to create access point on map.
    postWithAuth(`project/${projectId}/access_point/map`, {
      latitude: lat,
      longitude: lng,
      site_id: siteId,
      height: defaultHeight,
      use_site_max_height: useSiteMaxHeight,
      sm_range:
        Number(localStorage.getItem('cn.lp.cap.sm_range_value')) ||
        DEFAULT_SM_RANGE,
      range_units:
        localStorage.getItem('cn.lp.cap.sm_range_unit') == 'km'
          ? 'kilometers'
          : 'miles',
    })
      .then(() => {
        store.dispatch(uiSet({ mapState: states.MAP_CREATE_ACCESS_POINT }));
      })
      .catch((err) => {
        if (err.detail) {
          toast(<Message error>{err.detail}</Message>, {
            autoClose: false,
          });
        }
      });
  }
}, 200);

function setCanvasandShowMessage(postFix) {
  let message = 'The link you tried to create was too ';
  const canvas = getCanvas();
  canvas.ptpMode.remoteSiteId = null;
  toast(<Message error>{message + postFix}</Message>, {
    autoClose: false,
  });
}

const pathLengthOK = (locLat, locLng, remLat, remLng) => {
  const linkLength = desktopDistance(locLat, locLng, remLat, remLng);
  if (linkLength <= 1) {
    setCanvasandShowMessage('short');
    return false;
  } else if (linkLength > 250000) {
    setCanvasandShowMessage('long');
    return false;
  }
  return true;
};

export const createPTPLink = debounce(() => {
  const canvas = getCanvas();

  const localId = canvas.ptpMode.localSiteId;
  const remoteId = canvas.ptpMode.remoteSiteId;
  if (localId === remoteId) {
    canvas.ptpMode.remoteSiteId = null;
    toast(<Message error>Cannot link to the same site.</Message>, {
      autoClose: false,
    });
    return;
  }

  // Check the link length
  const { localSiteCoords, remoteSiteCoords } = canvas.ptpMode;
  if (
    !pathLengthOK(
      localSiteCoords.lat,
      localSiteCoords.lng,
      remoteSiteCoords.lat,
      remoteSiteCoords.lng
    )
  ) {
    return;
  }

  const state = store.getState();
  const { projectId } = state.mainFrame;
  canvas.handlePTPRubberBandEvents(false);

  canvas.ptpMode.reset();

  postWithAuth(`project/${projectId}/ptp`, {
    local_site_id: localId,
    remote_site_ids: [remoteId],
  }).catch((err) => {
    if (err.detail) {
      toast(<Message error>{err.detail}</Message>, {
        autoClose: false,
      });
    }
  });
}, 200);

export const createPTPLinkFromSiteLatLng = debounce(
  (localLat, localLng, remoteLat, remoteLng) => {
    // Check the link length
    if (!pathLengthOK(localLat, localLng, remoteLat, remoteLng)) {
      return;
    }

    const state = store.getState();
    const { projectId, networkSiteCount, subscriberSiteCount } =
      state.mainFrame;
    const { defaultHeight, mapSiteName } = state.map;
    const defaultSiteName = getDefaultSiteName({
      isNetwork: true,
      networkSiteCount,
      subscriberSiteCount,
    });
    const localSiteObj = {
      name: mapSiteName || defaultSiteName,
      latitude: localLat,
      longitude: localLng,
      is_network_site: true,
      maximum_height: defaultHeight,
    };
    const number = `${networkSiteCount + 2}`.padStart(3, '0');
    const remoteSiteName = `Network Site ${number}`;
    const remoteSiteObj = {
      name: remoteSiteName,
      latitude: remoteLat,
      longitude: remoteLng,
      is_network_site: true,
      maximum_height: defaultHeight,
    };
    postWithAuth(`project/${projectId}/ptp/map`, {
      local_site_data: localSiteObj,
      remote_site_data: remoteSiteObj,
    })
      .then(() => {})
      .catch((err) => {
        if (err.detail) {
          toast(<Message error>{err.detail}</Message>, {
            autoClose: false,
          });
        }
      });
  },
  200
);

export const createSiteAndPTPLink = debounce((lat, lng) => {
  const canvas = getCanvas();

  const state = store.getState();
  const { projectId } = state.mainFrame;
  const { defaultHeight } = state.map;
  const name = ''; // Let the name gets generated in backend using template if any.
  const sitePostObj = {
    name,
    latitude: lat,
    longitude: lng,
    is_network_site: true,
    maximum_height: defaultHeight,
  };

  if (canvas.ptpMode.localSiteId) {
    // Check the link length before creating the site
    const { localSiteCoords, remoteSiteCoords } = canvas.ptpMode;
    if (!pathLengthOK(localSiteCoords.lat, localSiteCoords.lng, lat, lng)) {
      return;
    }

    // we need to unbind the mouse move event after
    // link created
    canvas.handlePTPRubberBandEvents(false);
    const localId = canvas.ptpMode.localSiteId;
    canvas.ptpMode.reset();

    postWithAuth(`project/${projectId}/ptp/map`, {
      local_site_id: localId,
      remote_site_data: sitePostObj,
    }).catch((err) => {
      if (err.detail) {
        toast(<Message error>{err.detail}</Message>, {
          autoClose: false,
        });
      }
    });
  } else {
    postWithAuth(`project/${projectId}/site`, sitePostObj)
      .then(({ id }) => {
        if (!canvas.ptpMode.localSiteId) {
          //Bind measure handler only if local site is created
          canvas.handlePTPRubberBandEvents();
          canvas.ptpMode.localSiteId = id;
          canvas.ptpMode.localSiteCoords = { lng, lat };
        } else {
          canvas.ptpMode.remoteSiteId = id;
          canvas.ptpMode.remoteSiteCoords = { lng, lat };
        }
      })
      .catch((err) => {
        if (err.detail) {
          toast(<Message error>{err.detail}</Message>, {
            autoClose: false,
          });
        }
      });
  }
}, 200);

export const createSiteAndPMPLink = debounce((lat, lng, siteId) => {
  const state = store.getState();
  const { projectId } = state.mainFrame;
  const { defaultHeight, useSiteMaxHeight } = state.map;
  if (defaultHeight >= 0 && defaultHeight <= 3000) {
    // for invalid height stoped to create access point on map.
    postWithAuth(`project/${projectId}/subscriber/map`, {
      latitude: lat,
      longitude: lng,
      height: defaultHeight,
      use_site_max_height: useSiteMaxHeight,
      site_id: siteId,
    })
      .then((res) => {
        //   store.dispatch(uiSet({ mapState: states.MAP_SELECT }));
        if (res.status === 'success') {
          const aps = res.in_range;
          const features = aps.map((row) => {
            const geom = lineString(
              [
                [row.loc_lng, row.loc_lat],
                [row.rem_lng, row.rem_lat],
              ],
              { kind: 'tempLink', ...row }
            );
            return geom;
          });
          if (aps.length >= 1) {
            features.push(point([aps[0].rem_lng, aps[0].rem_lat]));
            const canvas = getCanvas();
            canvas.drawTemporaryFeature(featureCollection(features));
            // TODO: Use react-intl
            canvas.message('Calculating best server...');
          } else {
            toast(
              <Message warning>
                <FormattedMessage
                  id="map.noSectors"
                  defaultMessage="No sectors in range"
                />
              </Message>
            );
          }
        }
      })
      .catch((err) => {
        if (err.detail) {
          toast(<Message error>{err.detail}</Message>, {
            autoClose: false,
          });
        }
      });
  }
}, 200);

/**
 * Return an object containing the layer visibility status
 */
export const getLayers = () => {
  return getSessionJSON('cn.lp.MAP_VISIBLE_LAYERS', {
    access_point: true,
    pmp_link: true,
    ptp_link: true,
    mesh_link: true,
    subscriber_site: true,
    network_site: true,
    network_site_label: true,
    subscriber_site_label: false,
  });
};

export const setLayers = (layers) => {
  setSessionJSON('cn.lp.MAP_VISIBLE_LAYERS', layers);
  getCanvas().setVisibleLayers(layers);
};

/**
 * Return the current map canvas object (Leaflet or Google)
 */
export const getCanvas = () => {
  // This is currently stored as a window attribute, but we
  // could use the singleton class and create a "new" instance
  // every time
  return window.__LP_map;
};

/**
 * Cancel any open confirmation pop-ups and optionally run the undo function
 *
 * @param {Boolean} runUndo - Call the map undo function before removing it.
 */
export const cancelPopup = (runUndo = true) => {
  const canvas = getCanvas();
  canvas.map.eachLayer((l) => {
    l.closePopup();
  });
  if (window.__LP_map_undo) {
    if (runUndo) {
      window.__LP_map_undo();
    }
    delete window.__LP_map_undo;
  }
  if (window.__LP_map_accept) {
    delete window.__LP_map_accept;
  }
};

/**
 * Display the undo/apply pop-up for map edits
 *
 * @param {Object} layer  - Leaflet layer object
 * @param {resetCallback} resetCallback - Callback function to reset the object
 *      to the original state
 * @param {applyCallback} applyCallback - Callback function to accept the changes
 *      and update the object in the database.
 */
export const confirmPopup = (layer, resetCallback, applyCallback) => {
  cancelPopup();
  const reset = () => {
    // reset to the original state
    resetCallback();
    layer.off('popupclose', reset);
    layer.closePopup();
    cancelPopup(false);
  };

  window.__LP_map_undo = reset;
  layer.on('popupclose', reset);

  window.__LP_map_accept = () => {
    layer.off('popupclose', reset);
    layer.closePopup();
    cancelPopup(false);
    applyCallback();
  };

  layer.bindPopup(
    `
    <button
      title="Revert to the original location"
      class="ui mini icon button"
      onclick="window.__LP_map_undo && window.__LP_map_undo();"
    >
        <i class="undo icon large"></i>
    </button>
    <button
      title="Accept this new location"
      class="ui mini icon button"
      onclick="window.__LP_map_accept && window.__LP_map_accept();"
    >
        <i class="check icon large"></i>
    </button>
    `,
    {
      className: 'map-feature-popup',
      closeButton: false,
      autoClose: false,
      closeOnClick: false,
      autoPan: false,
    }
  );
  layer.openPopup();
};

/**
 * Displays a popup with edit and delete buttons on a given layer
 * The popup is automatically hidden after a few seconds
 *
 * @param {Object} layer - The Leaflet layer object on which the popup will be displayed
 * @param {string} id - The feature (site, link, network device) id associated with the layer
 * @param {string} kind - The feature type associated with the layer
 */
export const showEditDeletePopup = (layer, id, kind) => {
  cancelPopup();

  window.__LP_feature_edit = (e) => {
    L.DomEvent.stopPropagation(e);
    layer.closePopup();
    cancelPopup(false);
    editObject(kind, id);
  };

  window.__LP_feature_delete = (e) => {
    L.DomEvent.stopPropagation(e);
    layer.closePopup();
    cancelPopup(false);
    deleteObject(kind, id);
  };

  layer.bindPopup(
    `
    <div id="feature-popup-content">
      <button
        title="Delete this site"
        class="ui mini icon button"
        onclick="window.__LP_feature_delete && window.__LP_feature_delete(event);">
          <i class="undo icon large"></i>
      </button>
      <button
        title="Edit site"
        class="ui mini icon button"
        onclick="window.__LP_feature_edit && window.__LP_feature_edit(event);">
          <i class="edit icon large"></i>
      </button>
    </div>
    `,
    {
      className: 'map-feature-popup',
      closeButton: false,
      autoClose: false,
      closeOnClick: false,
      autoPan: false,
    }
  );

  layer.openPopup();
  let popupCloseTimeout;
  const popupContentElement = document.getElementById('feature-popup-content');

  // If mouse enters the popup content, clear the timeout to prevent closing
  popupContentElement.addEventListener('mouseenter', () => {
    clearTimeout(popupCloseTimeout);
  });

  // If mouse leaves the popup content, reset the timeout to close the popup
  popupContentElement.addEventListener('mouseleave', () => {
    resetPopupCloseTimeout();
  });

  const resetPopupCloseTimeout = () => {
    // Hide popup after 5 seconds of creating a feature
    // but can be prevented by hovering on the popup
    clearTimeout(popupCloseTimeout);
    popupCloseTimeout = setTimeout(() => {
      layer.closePopup();
      layer.unbindPopup();
    }, 5000);
  };

  resetPopupCloseTimeout();
};

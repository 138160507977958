import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import additionalMessages from '../../messages';

const getRoot = (pathSplit) => {
  const root = pathSplit[0].replace(/subscriber\b/, 'pmp');
  if (root === 'antennas') {
    return 'antennas/ptp';
  } else {
    return root;
  }
};

const getLocationLabel = (location, props) => {
  const { formatMessage } = props.intl;
  return {
    '': '',
    project: formatMessage(additionalMessages.project),
    network_sites: formatMessage(additionalMessages.networkSites),
    subscriber_sites: formatMessage(additionalMessages.subscriberSites),
    ptp: formatMessage(additionalMessages.ptpLinks),
    equipment: formatMessage(additionalMessages.pmpEquipment),
    aps: formatMessage(additionalMessages.accessPoints),
    mesh: formatMessage(additionalMessages.meshLinks),
    pmp: formatMessage(additionalMessages.pmpLinks),
    'antennas/ptp': formatMessage(additionalMessages.antennas),
    best_server: formatMessage(additionalMessages.bestServer),
    terragraph_planner: formatMessage(additionalMessages.terragraph),
  }[location];
};

class BreadCrumbContainer extends Component {
  render() {
    const { projectName, location, loggedIn, permissionWrite, projectId } =
      this.props;
    const { formatMessage } = this.props.intl;
    const pathSplit = location.pathname.replace(/^\//, '').split('/');
    // We mix up the subscriber url and the pmp url
    const root = getRoot(pathSplit);
    const locationLabel = getLocationLabel(root, this.props);
    const isOnProject = false; //location === '/projects';
    const isReadOnly = projectId && !permissionWrite;
    if (loggedIn) {
      return (
        <Breadcrumb
          size="mini"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          &nbsp; &nbsp;
          <Breadcrumb.Section active={isOnProject}>
            <Link
              to="/projects"
              title={formatMessage(additionalMessages.projectsTitle)}
            >
              <FormattedMessage
                id="common.projects"
                defaultMessage="Projects"
              />
            </Link>
          </Breadcrumb.Section>
          {projectName ? <Breadcrumb.Divider icon="right angle" /> : null}
          <Breadcrumb.Section active={isOnProject}>
            <Link
              to="/project"
              title={formatMessage(additionalMessages.project)}
            >
              {isReadOnly
                ? ` (${formatMessage(additionalMessages.readOnly)})`
                : null}
              {projectName}
            </Link>
          </Breadcrumb.Section>
          {locationLabel && (
            <>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active={false}>
                <Link to={`/${root}`} title={locationLabel}>
                  {locationLabel}
                </Link>
              </Breadcrumb.Section>
            </>
          )}
        </Breadcrumb>
      );
    } else {
      return null;
    }
  }
}

const BreadcrumbCtrl = injectIntl(
  connect((state, ownProps) => {
    const { projectName, loggedIn, permissionWrite, projectId } =
      state.mainFrame;
    return {
      ...ownProps,
      projectName,
      loggedIn,
      permissionWrite,
      projectId,
    };
  })(BreadCrumbContainer)
);

function WrapBreadcrumbCtrl(props) {
  const location = useLocation();
  return <BreadcrumbCtrl {...props} location={location} />;
}

export default WrapBreadcrumbCtrl;

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Segment } from 'semantic-ui-react';
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic';
import messages from 'src/messages';
import { ObjectLiteral } from 'src/utils/types/commonTypes';
import { PerformanceDetailsViewTabs } from './PerformanceDetailsViewTabs';

export type PerformanceDetailsType = {
  perfChart: Entities;
  local: ObjectLiteral[];
  remote: ObjectLiteral[];
  common: ObjectLiteral[];
  localEndName?: string;
  remoteEndName?: string;
  kind: string;
  disabled: boolean;
  needsRefresh: boolean;
} & WrappedComponentProps;

export type Entities = ObjectLiteral[][];

function PerformanceDetails({
  perfChart,
  common,
  local,
  remote,
  localEndName = 'ND',
  remoteEndName = 'SM',
  kind,
  intl,
  disabled,
  needsRefresh,
}: PerformanceDetailsType) {
  const { formatMessage } = intl;

  let titles = [
    formatMessage(messages.performanceTo, { name: localEndName }),
    formatMessage(messages.performanceTo, { name: remoteEndName }),
  ];

  let widths: SemanticWIDTHS[] = [8, 8];
  let gridColumns: 2 | 3 = 2;
  let entities = [local, remote] as Entities;

  if (common && Object.keys(common).length > 0) {
    gridColumns = 3;
    entities = [common, local, remote];
    titles.unshift(formatMessage(messages.common));
    widths = [5, 5, 6];
  }

  return (
    <>
      {disabled && (
        <Segment inverted className="update-required">
          Apply your changes to update the performance summary
        </Segment>
      )}
      <Segment basic disabled={disabled} loading={needsRefresh}>
        <PerformanceDetailsViewTabs
          perfChart={perfChart}
          localEndName={localEndName}
          remoteEndName={remoteEndName}
          entities={entities}
          kind={kind}
          titles={titles}
        />
      </Segment>
    </>
  );
}
export default injectIntl(PerformanceDetails);

import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { injectIntl } from 'react-intl';
import { Form, Modal, Button } from 'semantic-ui-react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFeederLossValues } from './FeederLoss.service';
import FeederLossPopupContent from './FeederLossPopupContent';
import messages from 'src/messages';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { getEquipmentFromChoices, getCablingFromChoices } from './utils';
import { RootStateOrAny } from 'src/store';

function getLabel(endName, remoteMount, getValues) {
  const otherLosses = getValues(
    `${endName}.radios.0.antennas.0.cabling.other_losses`
  );
  const useWgLoss = getValues(
    `${endName}.radios.0.antennas.0.cabling.use_waveguide_loss`
  );

  // note: remoteMount is lower case here
  if (remoteMount === 'both' || remoteMount === endName) {
    if (!useWgLoss || otherLosses !== 0) {
      return 'Maximum Feeder Loss';
    }
  }

  return 'Feeder Loss';
}

function getCouplerLoss(pathIndex, loss) {
  if (isArray(loss)) {
    if (pathIndex == null || pathIndex >= loss.length) {
      return loss[0];
    } else {
      return loss[pathIndex];
    }
  } else {
    return loss;
  }
}

function getTotalLoss(
  pathIndex,
  endCalculatedLosses,
  otherLosses,
  useWaveguideLoss,
  hasCoupler,
  isRemoteMount,
  requiresDiplexer
) {
  if (endCalculatedLosses == null) {
    return null;
  }

  let total = 0;
  if (!isRemoteMount) {
    total = getCouplerLoss(pathIndex, endCalculatedLosses.coupler_loss_direct);
  } else {
    if (useWaveguideLoss) {
      total = endCalculatedLosses.flex_waveguide_loss;
    }

    if (hasCoupler) {
      total += getCouplerLoss(pathIndex, endCalculatedLosses.coupler_loss);
    }

    if (typeof otherLosses === 'number') {
      total += otherLosses;
    }
  }

  if (requiresDiplexer) {
    total += endCalculatedLosses.diplexer_loss;
  }

  return total.toFixed(1);
}

function cablingPath(endName, attrName) {
  return `${endName}.radios.0.antennas.0.cabling.${attrName}`;
}

function FeederLossField(props: any) {
  const { id } = useParams();
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const permissionWrite = useSelector(
    (state: RootStateOrAny) => state.mainFrame.permissionWrite
  );

  const {
    getValues,
    setValue,
    formState: { dirtyFields, errors },
  } = useFormContext();

  const { choices, endName, units, setModified, intl, pathIndex } = props;
  const { formatMessage } = intl;

  // some useful getters for rhf
  const useWaveguideLossPath = cablingPath(endName, 'use_waveguide_loss');
  const otherLossesPath = cablingPath(endName, 'other_losses');

  // keep track of user editable values so we can revert back to them
  // when the user presses cancel on the modal
  const [useWaveguideLoss, otherLosses] = getValues([
    useWaveguideLossPath,
    otherLossesPath,
  ]);
  const [prevValues, setPrevValues] = useState({
    useWaveguideLoss,
    otherLosses,
  });

  // tracks whether modal is open or closed
  const [open, setOpen] = useState(false);

  const eqChoices = getEquipmentFromChoices(choices);
  const band = eqChoices.band.value;
  const product = eqChoices.product.value;
  const antennaProt = getValues(
    `${endName}.radios.0.antennas.0.config.antenna_protection`
  );

  // calculated feeder loss values we retrieve from the backend
  // will re-request if band or product change
  const { data: calculatedFeederLosses } = useQuery({
    queryKey: [projectId, 'ptp', id, band, product, antennaProt],
    queryFn: () => getFeederLossValues(projectId, id, getValues),
  });

  const remoteMount = eqChoices.remote_mount?.value.toLowerCase();
  const isRemoteMount = remoteMount === 'both' || remoteMount === endName;

  const label = getLabel(endName, remoteMount, getValues);

  // logic to turn label blue
  const isDirty =
    get(dirtyFields, otherLossesPath, false) ||
    get(dirtyFields, useWaveguideLossPath, false) ||
    get(dirtyFields, 'local.radios.0.equipment.remote_mount', false);

  // disable ok button within modal if other losses is ever invalid
  const disableOk = otherLosses === '' || get(errors, otherLossesPath) != null;

  const cablingChoices = getCablingFromChoices(choices, endName);
  const hasCoupler = cablingChoices.has_coupler;
  const requiresDiplexer = cablingChoices.requires_diplexer;

  // total loss value shown in ptp end panel and within the modal
  const totalLoss = getTotalLoss(
    pathIndex,
    calculatedFeederLosses?.[endName],
    getValues(otherLossesPath),
    useWaveguideLoss,
    hasCoupler,
    isRemoteMount,
    requiresDiplexer
  );

  const togglePopup = () => {
    if (!open) {
      // we are opening the modal
      // store current values so we can reset if needed
      setPrevValues({
        useWaveguideLoss,
        otherLosses,
      });
    } else {
      // we are closing the modal
      // revert to previous values
      setValue(useWaveguideLossPath, prevValues.useWaveguideLoss, {
        shouldDirty: true,
      });
      setValue(otherLossesPath, prevValues.otherLosses, {
        shouldDirty: true,
      });
    }

    setOpen((prev) => !prev);
  };

  const handleOk = () => {
    // only set the panel modified if the user changed anything in the modal
    const wasModified =
      get(dirtyFields, otherLossesPath, false) ||
      get(dirtyFields, useWaveguideLossPath, false);
    setModified((prev) => prev || wasModified);
    setOpen(false);
  };

  return (
    <Form.Field>
      <FeederLossPrimaryDisplay
        label={label}
        totalLoss={totalLoss}
        units={units}
        isDirty={isDirty}
        isRemoteMount={isRemoteMount}
        togglePopup={togglePopup}
        editable={true}
        disabled={!permissionWrite}
      />

      <Modal size="mini" open={open} onClose={togglePopup}>
        <Modal.Header>
          Losses
          <Button
            circular
            icon="close"
            title={formatMessage(messages.close)}
            floated="right"
            onClick={togglePopup}
          />
        </Modal.Header>

        <Modal.Content>
          <FeederLossPopupContent
            totalLoss={totalLoss}
            calculatedFeederLosses={calculatedFeederLosses?.[endName]}
            useWaveguideLossGetter={cablingPath(endName, 'use_waveguide_loss')}
            useWaveguideLoss={useWaveguideLoss}
            otherLossesGetter={cablingPath(endName, 'other_losses')}
            hasCoupler={hasCoupler}
            requiresDiplexer={requiresDiplexer}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button type="button" onClick={togglePopup}>
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            type="button"
            color="blue"
            disabled={disableOk}
            onClick={handleOk}
          >
            {formatMessage(messages.ok)}
          </Button>
        </Modal.Actions>
      </Modal>
    </Form.Field>
  );
}

export function SecondaryFeederLossField(props: any) {
  // Easier to write a specific component for this so that we can
  // match the behaviour of the main feeder loss (to avoid UI inconsistency)
  // (but we don't need as much functionality here)

  const { id } = useParams();
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const permissionWrite = useSelector(
    (state: RootStateOrAny) => state.mainFrame.permissionWrite
  );

  const { getValues, formState: { dirtyFields } } = useFormContext();
  const isDirty = get(dirtyFields, 'local.radios.0.equipment.remote_mount', false);

  const { endName, choices } = props;
  const eqChoices = getEquipmentFromChoices(choices);
  const band = eqChoices.band.value;
  const product = eqChoices.product.value;

  const antennaProt = getValues(
    `${endName}.radios.0.antennas.0.config.antenna_protection`
  );

  const remoteMount = eqChoices.remote_mount?.value.toLowerCase();
  const isRemoteMount = remoteMount === 'both' || remoteMount === endName;

  const { data } = useQuery({
    queryKey: [projectId, 'ptp', id, band, product, antennaProt],
    queryFn: () => getFeederLossValues(projectId, id, getValues),
  });

  let totalLoss;
  if (isRemoteMount) {
    totalLoss = data?.[endName].secondary_feeder_loss;
  } else {
    totalLoss = data?.[endName].secondary_feeder_loss_direct;
  }

  return (
    <Form.Field>
      <FeederLossPrimaryDisplay
        label="Secondary Feeder Loss"
        totalLoss={totalLoss?.toFixed(1)}
        units="dB"
        isDirty={isDirty}
        editable={false}
        disabled={!permissionWrite}
      />
    </Form.Field>
  );
}

function FeederLossPrimaryDisplay(props: any) {
  // Component that is rendered in PTPEndPanel and allows user to launch modal
  const {
    label,
    totalLoss,
    units,
    isDirty,
    isRemoteMount,
    editable,
    togglePopup,
    disabled,
  } = props;

  return (
    <div className={isDirty ? 'field end-attr-recalc' : 'field'}>
      <label>{label}</label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p className="ui disabled input" style={{ width: '8rem', margin: 0 }}>
          {totalLoss ?? 'Loading...'}
        </p>
        <p className="ui label" style={{ padding: '0.6rem' }}>
          {units}
        </p>
        {editable && isRemoteMount ? (
          <Button
            type="button"
            color="blue"
            onClick={togglePopup}
            disabled={disabled}
          >
            Edit
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default injectIntl(FeederLossField);

import React from 'react';
import { Segment } from 'semantic-ui-react';
import ProjectDetailsPanel from './ProjectDetails';

const ProjectPanel = (props) => {
  return (
    <Segment basic style={{ height: "100%" }}>
      <ProjectDetailsPanel />
    </Segment>
  );
};

export default ProjectPanel;

import React, { useEffect, useRef, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
  Button,
  ButtonProps,
  Grid,
  Icon,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import { get } from 'lodash';
import { getWithAuth } from '../../../api';
import messages from '../../../messages';
import { adjustPrecision } from '../../../utils/useful_functions';
import { PERCENT, THROUGHPUT } from '../../../app.constants';
import FloodTestReport from './floodtest/FloodTestReport';
import { store } from 'src/store';
import { panelNeedsRefresh } from 'src/pages/mainframe/mainframe.reducer';
import AccessPointPerformanceSector from './AccessPointPerformanceSector';
import TabbedAPPerformanceSummary from './TabbedAPPerformanceSummary';
import MuMIMOReport from './floodtest/MuMIMOReport';

export type ModeType = {
  mode: string;
  throughput: string;
  percent?: string;
  quantity?: number;
};

export type APPerformanceType = {
  upLink?: ModeType[];
  downLink?: ModeType[];
  mean?: ModeType[];
  projectId: string;
  apId: string;
  needsRefresh: boolean;
  disabled: boolean;
  equipOperMode: string;
} & WrappedComponentProps;

const getAggregateValues = (link) => {
  let percent = 0;
  if (link[0]['percent'] != null)
    percent = link
      .map((item) => item.percent)
      .reduce((prev, next) => prev + next);
  const throughput = link
    .map((item) => item.throughput)
    .reduce((prev, next) => prev + next);
  let quantity = 0;
  if (link[0]['quantity'] != null)
    quantity = link
      .map((item) => item.quantity)
      .reduce((prev, next) => prev + next);
  return { percent, quantity, throughput };
};

type WarningType = {
  aggregate?: string;
  radio_1?: string;
  radio_2?: string;
};

export const parsePerformanceData = (result) => {
  const data = {};
  const upLinkAgg = getAggregateValues(result.sms_per_ul_modulation);
  const downLinkAgg = getAggregateValues(result.sms_per_dl_modulation);
  const meanAgg = getAggregateValues(result.total_mean_predicted_throughput);
  data['aggregates'] = {
    upLink: upLinkAgg,
    downLink: downLinkAgg,
    mean: meanAgg,
  };
  const [downLink, upLink, mean] = [
    result.sms_per_dl_modulation,
    result.sms_per_ul_modulation,
    result.total_mean_predicted_throughput,
  ];
  const modes = downLink.map((obj) => obj.mode);
  const modifiedData = [];
  modes.forEach((mode) => {
    //Filter the uplink downlink mean by modes
    let [upLinkObj, downLinkObj, meanObj] = [upLink, downLink, mean].map(
      (list) => list.filter((obj) => obj.mode === mode)[0]
    );

    modifiedData.push({
      mode: mode,
      upLink: upLinkObj,
      downLink: downLinkObj,
      mean: meanObj,
    });
  });
  data['data'] = modifiedData;
  return data;
};

const getPerformanceWarning = (warning?: WarningType) => {
  if (!warning) {
    return '';
  }
  if (warning?.aggregate) {
    return warning.aggregate;
  } else if (warning?.radio_1) {
    return warning.radio_1;
  } else {
    return '';
  }
};

const aggregateDefaultValue = {
  upLink: { percent: 0, quantity: 0, throughput: 0 },
  downLink: { percent: 0, quantity: 0, throughput: 0 },
  mean: { throughput: 0 },
};

const getReportModal = (reportData, hideModal) => {
  if (reportData.heading === 'Flood Test Report') {
    return (
      <FloodTestReport
        report={reportData}
        closeHandler={hideModal}
      ></FloodTestReport>
    );
  } else {
    return (
      <MuMIMOReport report={reportData} closeHandler={hideModal}></MuMIMOReport>
    );
  }
};

function AccessPointPerformance({
  projectId,
  apId,
  intl,
  disabled,
  needsRefresh,
}: APPerformanceType) {
  const { formatMessage } = intl;
  // eslint-disable-next-line
  const [force, setForce] = useState(0);
  const [data, setData] = useState([]);
  const [aggregates, setAggregates] = useState(aggregateDefaultValue);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [floodTestReport, setFloodTestReport] = useState(null);
  const [showFloodTest, setShowFloodTest] = useState(false);
  const [warning, setWarning] = useState(null);
  const useTabbedSummary = useRef(false);
  const [cnWaveResult, setCnWaveResult] = useState(null);

  useEffect(() => {
    setLoader(true);
    getWithAuth(`project/${projectId}/access_point/${apId}/results`)
      .then((result) => {
        setLoader(false);
        setFloodTestReport(get(result, 'flood_test'));
        setWarning(get(result, 'warning'));
        if (result != null) {
          if (
            get(result, 'summary.aggregate') &&
            get(result, 'summary.radio_2')
          ) {
            useTabbedSummary.current = true;
            setCnWaveResult(result);
          } else {
            useTabbedSummary.current = false;
          }
        }
        if (get(result, 'summary.radio_1.sms_per_ul_modulation', []).length) {
          const parsedData = parsePerformanceData(result.summary.radio_1);
          setAggregates(parsedData['aggregates']);
          setData(parsedData['data']);
        } else {
          setData([]);
          setAggregates(aggregateDefaultValue);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoader(false);
        store.dispatch(
          panelNeedsRefresh({ panels: ['accessPointPanel'], status: false })
        );
      });
  }, [projectId, apId, force, needsRefresh]);

  const handleFloodTestReport = () => {
    setShowFloodTest(true);
  };

  const hideFloodTestReport = (event: any, data: ButtonProps) => {
    setShowFloodTest(false);
  };

  const saveInfo =
    disabled || needsRefresh ? (
      <Segment inverted className="update-required">
        {formatMessage(messages.saveChangesPanel, {
          panelName: 'performance summary',
        })}
      </Segment>
    ) : null;

  if (loader) {
    return <Loader active inline></Loader>;
  } else if (useTabbedSummary.current) {
    return (
      <>
        {saveInfo}
        <TabbedAPPerformanceSummary
          data={cnWaveResult}
          disabled={disabled}
        ></TabbedAPPerformanceSummary>
      </>
    );
  } else {
    const performanceWarning = getPerformanceWarning(warning);

    return (
      <>
        {saveInfo}
        <Segment basic disabled={disabled}>
          <div style={{ width: '55vw' }}>
            {floodTestReport && (
              <>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Icon name="file" />
                      <Button
                        type="button"
                        onClick={handleFloodTestReport}
                        disabled={!floodTestReport.enable}
                      >
                        {floodTestReport.heading === 'Flood Test Report'
                          ? formatMessage(messages.floodTestReport)
                          : formatMessage(messages.mumimoReport)}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </>
            )}
            {showFloodTest &&
              getReportModal(floodTestReport, hideFloodTestReport)}
            <AccessPointPerformanceSector
              aggregates={aggregates}
              data={data}
              warning={performanceWarning}
              floodTestReport={floodTestReport}
            ></AccessPointPerformanceSector>
            {error && (
              <Grid>
                <Grid.Row>
                  <Grid.Column width="16">
                    <Message negative size="small">
                      <p>{error}</p>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </div>
        </Segment>
      </>
    );
  }
}

export default injectIntl(AccessPointPerformance);

import React, { Fragment, useState } from 'react';
import { updatePreferencesApi } from '../../api';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import * as Yup from 'yup';
import SemanticField from '../../components/controls/SemanticField';
import CustomAccordion from '../../components/controls/CustomAccordion';
import additionalMessages from '../../messages';
import {
  LENGTH_OPTIONS,
  HEIGHT_OPTIONS,
  MAC_ADDRESS_OPTIONS,
  LAT_LNG_OPTIONS,
  LANGUAGES_PREFERENCES,
} from '../../app.constants';
import { trimObject } from '../../utils/useful_functions';
import { Formik, Form as FormFormik } from 'formik';
import { store } from '../../store';
import { updatePreferences } from '../mainframe/mainframe.reducer';
import { convertObjectKeys, toSnake } from '../../utils/change-case';
import OrderableCheckListControl from 'src/components/controls/OrderableCheckListControl';

function Preferences(props) {
  const [open, setOpen] = useState(true);
  const [updatingPreferences, setUpdatingPreferences] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const {
    firstName,
    lastName,
    email,
    phone,
    language,
    companyName,
    optIn,
    prefs,
  } = useSelector((state) => state.mainFrame, shallowEqual);
  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    language: language,
    companyName: companyName,
    phone: phone,
    optOut: !optIn,
    rangeUnits: prefs.rangeUnits,
    heightUnits: prefs.heightUnits,
    latLngFormat: prefs.latLngFormat,
    macAddressFormat: prefs.macAddressFormat,
    bom: prefs.bom,
  };
  const { formatMessage } = props.intl;

  const onCloseHandler = () => {
    setOpen(false);
    props.onClose();
  };
  const onSubmit = (values) => {
    trimObject(values);
    const snakeVals = convertObjectKeys(values, toSnake);
    snakeVals.opt_in = !snakeVals.opt_out;
    setUpdatingPreferences(true);
    updatePreferencesApi(snakeVals)
      .then((res) => {
        setUpdatingPreferences(false);
        if (res) {
          store.dispatch(
            updatePreferences({ ...values, optIn: !values.optOut })
          );
        }
        onCloseHandler();
      })
      .catch((err) => {
        setUpdatingPreferences(false);
        setErrMsg(err.message);
        console.error('Error while updating preferences', err);
      });
  };
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'first name',
        })
      ),
    lastName: Yup.string()
      .trim()
      .required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'last name',
        })
      ),
    email: Yup.string()
      .email(
        formatMessage(additionalMessages.validFieldError, {
          fieldName: 'Email',
        })
      )
      .required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'Email',
        })
      ),
  });

  const panels = [
    {
      key: 'PersonalInformation',
      titleProps: {
        content: formatMessage(additionalMessages.personalInformation),
      },
      contentProps: {
        content: (
          <>
            <Form.Group widths="equal">
              <SemanticField
                label={formatMessage(additionalMessages.firstName)}
                component={Form.Input}
                checkPermissions={false}
                id="firstName"
                name="firstName"
              ></SemanticField>
              <SemanticField
                label={formatMessage(additionalMessages.lastName)}
                component={Form.Input}
                id="lastName"
                name="lastName"
                checkPermissions={false}
              ></SemanticField>
            </Form.Group>
            <Form.Group widths="equal">
              <SemanticField
                label={formatMessage(additionalMessages.email)}
                component={Form.Input}
                type="email"
                checkPermissions={false}
                id="email"
                name="email"
              ></SemanticField>
              <SemanticField
                label={formatMessage(additionalMessages.contact)}
                component={Form.Input}
                id="phone"
                name="phone"
                checkPermissions={false}
              ></SemanticField>
            </Form.Group>
            <Form.Group widths="equal">
              <SemanticField
                label={formatMessage(additionalMessages.companyName)}
                component={Form.Input}
                id="companyName"
                name="companyName"
                checkPermissions={false}
              ></SemanticField>
              <SemanticField
                component={Form.Select}
                label={formatMessage(additionalMessages.language)}
                options={LANGUAGES_PREFERENCES}
                name="language"
                checkPermissions={false}
                placeholder={formatMessage(additionalMessages.language)}
              />
            </Form.Group>
            <SemanticField
              component={Form.Checkbox}
              label={formatMessage(additionalMessages.promotionalMails)}
              name="optOut"
              checkPermissions={false}
            />
          </>
        ),
      },
    },
    {
      key: 'UnitPreferences',
      titleProps: {
        content: formatMessage(additionalMessages.unitsAndFormatting),
      },
      contentProps: {
        content: (
          <>
            <Form.Group widths="equal">
              <SemanticField
                component={Form.Select}
                label={formatMessage(additionalMessages.height)}
                options={HEIGHT_OPTIONS}
                name="heightUnits"
                checkPermissions={false}
                placeholder={formatMessage(additionalMessages.height)}
              />
              <SemanticField
                fluid
                component={Form.Select}
                label={formatMessage(additionalMessages.length)}
                options={LENGTH_OPTIONS}
                checkPermissions={false}
                name="rangeUnits"
                placeholder={formatMessage(additionalMessages.length)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <SemanticField
                fluid
                component={Form.Select}
                name="latLngFormat"
                checkPermissions={false}
                label={formatMessage(additionalMessages.latitudeLongitude)}
                options={LAT_LNG_OPTIONS}
                placeholder={formatMessage(
                  additionalMessages.latitudeLongitude
                )}
              />
              <SemanticField
                fluid
                component={Form.Select}
                name="macAddressFormat"
                checkPermissions={false}
                label={formatMessage(additionalMessages.macAddress)}
                options={MAC_ADDRESS_OPTIONS}
                placeholder={formatMessage(additionalMessages.macAddress)}
              />
            </Form.Group>
          </>
        ),
      },
    },
    {
      key: 'BOMPreferences',
      titleProps: {
        content: formatMessage(additionalMessages.bom),
      },
      contentProps: {
        content: (
          <>
            <OrderableCheckListControl label="Power Leads" />
          </>
        ),
      },
    },
  ];

  return (
    <Fragment>
      <Modal onClose={onCloseHandler} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>
          {formatMessage(additionalMessages.accountAndPrefs)}
          <Button
            circular
            icon="close"
            title="Close"
            floated="right"
            onClick={onCloseHandler}
          />
        </Modal.Header>
        <Modal.Content>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => {
              return (
                <FormFormik className="ui form">
                  <CustomAccordion accordions={panels} />
                  <div className="modal-actions">
                    <Button onClick={onCloseHandler}>
                      {formatMessage(additionalMessages.cancel)}
                    </Button>
                    <Button
                      type="submit"
                      disabled={!formik.isValid}
                      loading={updatingPreferences}
                      color="blue"
                    >
                      {formatMessage(additionalMessages.ok)}
                    </Button>
                  </div>
                </FormFormik>
              );
            }}
          </Formik>
        </Modal.Content>
        {errMsg !== '' && (
          <Message negative attached>
            <Message.Header>
              {formatMessage(additionalMessages.preferencesError)}
            </Message.Header>
            <p>{errMsg}</p>
          </Message>
        )}
      </Modal>
    </Fragment>
  );
}

export default Preferences;

import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { filterAntenna } from './configs/config-utils';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import usePrevious from 'src/hooks/usePrevious';
import { PMPChoices, PMPFormField } from './utils';
import { useSelector } from 'react-redux';

type ModeledbeamwidthProps = {
  ap: any;
  choices: PMPChoices;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  field: PMPFormField;
  antennaId: string;
};
function ModeledBeamwidth({
  ap,
  choices,
  setModified,
  field,
  antennaId,
}: ModeledbeamwidthProps) {
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const { getValues, setValue } = useFormContext();
  const [force, setForce] = useState(0);
  const prevData = usePrevious({ choices, antennaId });
  const antennaData = filterAntenna(choices.antennas, antennaId);
  let max = antennaData.data.max_beamwidth;
  useEffect(() => {
    const beamwidth = getValues('radios.0.antennas.0.beamwidth');
    if (prevData != null && prevData.antennaId != antennaId) {
      const { choices: prevChoices, antennaId: prevAntennaId } = prevData;
      const prevAntennaData = filterAntenna(
        prevChoices.antennas,
        prevAntennaId
      );
      const antennaData = filterAntenna(choices.antennas, antennaId);
      const isDefault =
        beamwidth === prevAntennaData.data.beamwidth ||
        beamwidth > antennaData.data.max_beamwidth;
      if (isDefault) {
        setValue('radios.0.antennas.0.beamwidth', antennaData.data.beamwidth, {
          shouldDirty: true,
        });
        setForce((prev) => {
          return prev + 1;
        });
      }
    }
  }, [antennaId, choices.antennas, prevData?.choices, prevData?.antennaId]);

  return (
    <GenericScaledField
      key={field.getter}
      disabled={!permissionWrite}
      watch={force}
      {...field}
      max={max}
      setModified={setModified}
    ></GenericScaledField>
  );
}

export default ModeledBeamwidth;

import React, { Fragment, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import CustomAccordion from '../../components/controls/CustomAccordion';
import additionalMessages from '../../messages';
import UserStatsPanel from '../../components/UserStatsPanel';
import UserInfoSettings from './UserInfoSettings';

function AdminTools(props) {
  const [open, setOpen] = useState(true);
  const { formatMessage } = props.intl;

  const onCloseHandler = () => {
    setOpen(false);
    props.onClose();
  };
  const panels = [
    {
      key: 'UserInformationSetttings',
      titleProps: {
        content: formatMessage(
          additionalMessages.adminToolUserInfoSettingsTitle
        ),
      },
      contentProps: {
        content: <UserInfoSettings />,
      },
    },
    {
      key: 'UserStatsDisplay',
      titleProps: {
        content: 'User Stats Display',
      },
      contentProps: {
        content: <UserStatsPanel />,
      },
    },
  ];

  return (
    <Fragment>
      <Modal onClose={onCloseHandler} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>
          {formatMessage(additionalMessages.adminTools)}
          <Button
            circular
            icon="close"
            title="Close"
            onClick={(e) => onCloseHandler()}
            floated="right"
          />
        </Modal.Header>
        <Modal.Content>
          <CustomAccordion accordions={panels} />
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default AdminTools;

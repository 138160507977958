export default class LocalStorageUtils {
  static getItem(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  static setItem(key: string, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }

  static setItems(items: { key: string; value: any }[]) {
    items.forEach((item) => {
      this.setItem(item.key, item.key);
    });
  }
}

import React from 'react';
import { PMPFormField, PMPPanelProps } from './utils';
import { useFormContext } from 'react-hook-form';
import StoredAccordion from 'src/components/StoredAccordion';
import { Grid, Header } from 'semantic-ui-react';
import { chunk } from 'lodash';
import { checkErrors } from './configs/config-utils';
import NDSectorFormField from './NDSectorFormField';

function NDSectorPanel(props: PMPPanelProps) {
  const { ap, choices, panel, sm } = props;
  const { getValues, setValue, getFieldState } = useFormContext();
  const errors = checkErrors({
    panel,
    getValues,
    getFieldState,
    ap,
    sm,
    setValue,
    choices,
  });

  let sectorElements;
  const {
    fieldLayout,
    fields,
    name,
    title,
    show,
    customClass,
    sectorTitles = ['Sector 1', 'Sector 2'],
  } = panel;
  if (show && !show({ formGetter: getValues, choices })) return <></>;
  if (fieldLayout) {
    sectorElements = fieldLayout.map((row, i) => {
      return (
        <Grid.Row key={i} columns={row.length} centered>
          {row.map((colIndex, j) => {
            const field = fields[colIndex];
            return (
              <Grid.Column key={`${field.attrName}-${j}`}>
                <NDSectorFormField field={field} {...props}></NDSectorFormField>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      );
    });
  } else {
    const chunkedFields = chunk(fields, 2);
    sectorElements = chunkedFields.map((fieldChunk: any, i) => {
      return (
        <Grid.Row key={i} columns={2}>
          {fieldChunk.map((field: PMPFormField, j) => {
            return (
              <Grid.Column key={`${field.attrName}-${j}`}>
                <NDSectorFormField field={field} {...props}></NDSectorFormField>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      );
    });
  }
  return (
    <StoredAccordion
      name={`nd_${name}`}
      title={title}
      className={customClass}
      headerClass={errors ? 'custom-error' : ''}
      contentProps={{ loading: ap.dirty || sm?.dirty }}
    >
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Header as="h5" textAlign="center">
              {' '}
              {sectorTitles[0]}{' '}
            </Header>
          </Grid.Column>
          <Grid.Column>
            {' '}
            <Header as="h5" textAlign="center">
              {' '}
              {sectorTitles[1]}{' '}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {sectorElements}
      </Grid>
    </StoredAccordion>
  );
}

export default NDSectorPanel;

import React from 'react';
import AfcResultsPanel from './AFCResultsPanel';
import AFCModePanel from './AFCModePanel';
import { Header, Icon, Menu } from 'semantic-ui-react';
import messages from 'src/messages';
import { useIntl } from 'react-intl';
import { getCanvas } from 'src/utils/mapUtils';

function AFCPanel() {
  const { formatMessage } = useIntl();
  const isAfcMode = location.pathname === '/afc';
  let style = {};
  if (isAfcMode) {
    style = { width: 'calc(100vw - 38px)' };
  }
  return (
    <div id="afc-mode" style={style}>
      <Menu inverted>
        <Header as="h3" inverted style={{ margin: 0 }}>
          {formatMessage(messages.afcTitle)}
        </Header>
        {isAfcMode ? null : (
          <Menu.Item position="right">
            <Icon
              onClick={() => getCanvas().resetState()}
              name="close"
              title={formatMessage(messages.closeDialog)}
              style={{ cursor: 'pointer' }}
            />
          </Menu.Item>
        )}
      </Menu>
      <AFCModePanel></AFCModePanel>
      <AfcResultsPanel />
    </div>
  );
}

export default AFCPanel;

import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Header, Label, Segment } from 'semantic-ui-react';
import messages from 'src/messages';
import { formatByUnits } from 'src/utils/useful_functions';

function SMPerformanceSummaryLink({ link }) {
  const { formatMessage } = useIntl();
  return (
    <>
      <Header as="h5" textAlign="center">
        {formatMessage(messages.linkSummary)}
      </Header>
      <Segment compact style={{ height: '198px' }}>
        <Grid columns={2}>
          {[...Object.keys(link)].map((key) => {
            const { label, value, units } = link[key];
            return (
              <Grid.Row key={label} className={'custom-row'}>
                <Grid.Column textAlign="right" width={8}>
                  <Label basic className="no-border">
                    {label}
                  </Label>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5}>
                  {formatByUnits(value, units)}
                </Grid.Column>
                <Grid.Column width={2}>{units}</Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </Segment>
    </>
  );
}

export default SMPerformanceSummaryLink;

import React, { useEffect, useState, useRef } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import additionalMessages from '../../messages';
import { getCanvas } from '../../utils/mapUtils';
import { store } from 'src/store';
import { states } from './leafletCanvas';
import { uiSet } from '../mainframe/mainframe.reducer';
import { setApScale } from './map.reducer';
import ConditionalPopup from 'src/components/ConditionalPopup';
import { useNavigate } from 'react-router-dom';

const MapToolbar = (props) => {
  const { mapState, hasAPs, accessPoints, locked } = props;
  const { formatMessage } = props.intl;
  const selectMode = mapState === states.MAP_SELECT;
  const viewshedMode = mapState === states.MAP_VIEWSHED_MODE;
  const networkSiteMode = mapState === states.MAP_CREATE_NETWORK_SITE;
  const afcMode = mapState === states.MAP_AFC_MODE;
  const subscriberSiteMode = mapState === states.MAP_CREATE_SUBSCRIBER_SITE;
  const apMode = mapState === states.MAP_CREATE_ACCESS_POINT;
  const pmpLinkMode = mapState === states.MAP_CREATE_PMP_LINK;
  const measureMode = mapState === states.MAP_DISTANCE_MEASURE;
  const meshLinkMode = mapState === states.MAP_CREATE_MESH_LINK;
  const ptpLinkMode = mapState === states.MAP_CREATE_PTP_LINK;

  let v5000Antennas;
  if (accessPoints) {
    v5000Antennas = accessPoints.features
      .map((ap) => {
        try {
          return ap.properties.radios[0].antennas[0].lp_antenna_id;
        } catch (e) {
          return null;
        }
      })
      .sort();
  } else {
    v5000Antennas = [];
  }

  // must have at least 2 60GHz APs
  const has60GHz =
    v5000Antennas.filter(
      (antenna) => antenna === 'd289296b-f730-41a4-b2c0-0410fb26d76d'
    ).length >= 2;

  useEffect(() => {
    if (meshLinkMode) {
      if (!has60GHz) {
        // quit out of mesh mode if we are in it without any mesh links present
        getCanvas().resetState(true);
      }
    }
  }, [meshLinkMode, has60GHz]);

  const navigate = useNavigate();

  const [limits, setLimits] = useState({
    networkSitesLimitExceeded: false,
    subscriberSitesLimitExceeded: false,
    ptpLinksLimitExceeded: false,
    pmpNetworkDevicesLimitExceeded: false,
    pmpLinksLimitExceeded: false,
  });

  const {
    projectId,
    userLimits,
    networkSiteCount,
    subscriberSiteCount,
    ptpLinksCount,
    accessPointsCount,
    pmpLinksCount,
  } = useSelector((state) => state.mainFrame);

  const validateLimits = () => {
    if (userLimits) {
      const {
        network_sites,
        subscriber_sites,
        ptp_links,
        pmp_links,
        pmp_network_devices,
      } = userLimits;
      setLimitExceededValue({
        networkSitesLimitExceeded: networkSiteCount >= network_sites,
      });
      setLimitExceededValue({
        subscriberSitesLimitExceeded: subscriberSiteCount >= subscriber_sites,
      });
      setLimitExceededValue({
        ptpLinksLimitExceeded: ptpLinksCount >= ptp_links,
      });
      setLimitExceededValue({
        pmpLinksLimitExceeded: pmpLinksCount >= pmp_links,
      });
      setLimitExceededValue({
        pmpNetworkDevicesLimitExceeded:
          accessPointsCount >= pmp_network_devices,
      });
    }
  };

  const setLimitExceededValue = (newState) => {
    setLimits((prevState) => {
      return { ...prevState, ...newState };
    });
  };

  const { apScale } = useSelector((state) => state.map);

  useEffect(() => {
    validateLimits();
  }, [
    userLimits,
    networkSiteCount,
    subscriberSiteCount,
    ptpLinksCount,
    accessPointsCount,
    pmpLinksCount,
  ]);

  const changeMode = (cb) => {
    return () => {
      getCanvas().resetViewshedState();
      cb();
    };
  };

  //   const style = { backgroundColor: 'rgb(27, 28, 29)' };
  const style = { backgroundColor: 'rgb(224, 225, 226)' };
  const plusOffset = { position: 'relative', left: '10px', top: '-4px' };
  return (
    <div style={style}>
      <Button.Group vertical size="huge">
        <Button
          icon="globe"
          onClick={fit}
          title={formatMessage(additionalMessages.zoomFit)}
        />
        <Button
          icon="mouse pointer"
          color={selectMode ? 'black' : null}
          onClick={changeMode(reset)}
          title={formatMessage(additionalMessages.selectItems)}
          disabled={!projectId}
        />
        <ConditionalPopup
          showPopup={limits.networkSitesLimitExceeded}
          message={formatMessage(additionalMessages.maximumNumberError, {
            entityName: 'Network Site',
            limit: userLimits?.network_sites,
          })}
        >
          <Button
            icon
            color={networkSiteMode ? 'black' : null}
            disabled={
              !props.permissionWrite ||
              locked ||
              limits.networkSitesLimitExceeded
            }
            onClick={changeMode(() => createNetworkSite(!networkSiteMode))}
            title={formatMessage(additionalMessages.createNetworkSiteMap)}
            data-test-id="createNetworkSiteMap"
          >
            <Icon.Group>
              <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
              <Icon
                className={networkSiteMode ? 'site-icon-inverted' : 'site-icon'}
              />
            </Icon.Group>
          </Button>
        </ConditionalPopup>
        <ConditionalPopup
          showPopup={limits.subscriberSitesLimitExceeded}
          message={formatMessage(additionalMessages.maximumNumberError, {
            entityName: 'Subscriber Site',
            limit: userLimits?.subscriber_sites,
          })}
        >
          <Button
            icon
            color={subscriberSiteMode ? 'black' : null}
            disabled={
              !props.permissionWrite ||
              locked ||
              limits.subscriberSitesLimitExceeded
            }
            onClick={changeMode(() =>
              createSubscriberSite(!subscriberSiteMode)
            )}
            title={formatMessage(additionalMessages.createSubscriberSiteMap)}
            data-test-id="createSubscriberSiteMap"
          >
            <Icon.Group>
              <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
              <Icon
                className={
                  subscriberSiteMode
                    ? 'subscriber-site-icon-inverted'
                    : 'subscriber-site-icon'
                }
              />
            </Icon.Group>
          </Button>
        </ConditionalPopup>
        <ConditionalPopup
          showPopup={limits.ptpLinksLimitExceeded}
          message={formatMessage(additionalMessages.maximumNumberError, {
            entityName: 'PTP Link',
            limit: userLimits?.ptp_links,
          })}
        >
          <Button
            icon
            color={ptpLinkMode ? 'black' : null}
            disabled={
              !props.permissionWrite || locked || limits.ptpLinksLimitExceeded
            }
            onClick={changeMode(() => createPTPLink(!ptpLinkMode))}
            title={formatMessage(additionalMessages.createPTPLinkMap)}
            data-test-id="createPTPLinkMap"
          >
            <Icon.Group>
              <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
              <Icon
                className={
                  ptpLinkMode ? 'ptp-link-icon-inverted' : 'ptp-link-icon'
                }
              />
            </Icon.Group>
          </Button>
        </ConditionalPopup>
        <ConditionalPopup
          showPopup={limits.pmpNetworkDevicesLimitExceeded}
          message={formatMessage(additionalMessages.maximumNumberError, {
            entityName: 'Access Point',
            limit: userLimits?.pmp_network_devices,
          })}
        >
          <Button
            icon
            color={apMode ? 'black' : null}
            disabled={
              !props.permissionWrite ||
              locked ||
              limits.pmpNetworkDevicesLimitExceeded
            }
            onClick={changeMode(() => createAccessPoint(!apMode))}
            title={formatMessage(additionalMessages.createAccessPointMap)}
            data-test-id="createAccessPointMap"
          >
            <Icon.Group>
              <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
              <Icon
                className={
                  apMode ? 'access-point-icon-inverted' : 'access-point-icon'
                }
              />
            </Icon.Group>
          </Button>
        </ConditionalPopup>
        <Button
          icon
          color={meshLinkMode ? 'black' : null}
          onClick={changeMode(() => createMeshLink(!meshLinkMode))}
          title={formatMessage(additionalMessages.createMeshLinkMap)}
          disabled={!props.permissionWrite || locked || !has60GHz}
        >
          <Icon.Group>
            <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
            <Icon
              className={
                meshLinkMode ? 'mesh-link-icon-inverted' : 'mesh-link-icon'
              }
            />
          </Icon.Group>
        </Button>
        <ConditionalPopup
          showPopup={limits.pmpLinksLimitExceeded}
          message={formatMessage(additionalMessages.maximumNumberError, {
            entityName: 'PMP Link',
            limit: userLimits?.pmp_links,
          })}
        >
          <Button
            icon
            color={pmpLinkMode ? 'black' : null}
            disabled={
              !props.permissionWrite ||
              !hasAPs ||
              locked ||
              limits.pmpLinksLimitExceeded
            }
            onClick={changeMode(() => createPMPLink(!pmpLinkMode))}
            title={formatMessage(additionalMessages.createPMPLinkMap)}
            data-test-id="createPMPLinkMap"
          >
            <Icon.Group>
              <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
              <Icon
                className={
                  pmpLinkMode ? 'pmp-link-icon-inverted' : 'pmp-link-icon'
                }
              />
            </Icon.Group>
          </Button>
        </ConditionalPopup>
        <Button
          icon
          disabled={!props.permissionWrite || locked}
          color={viewshedMode ? 'black' : null}
          onClick={() => {
            toggleViewshedMode(!viewshedMode);
          }}
          title={formatMessage(additionalMessages.toggleViewshedMode)}
        >
          <Icon.Group>
            <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
            <Icon
              className={
                viewshedMode ? 'viewshed-icon-inverted' : 'viewshed-icon'
              }
            />
          </Icon.Group>
        </Button>
        <Button
          icon
          color={measureMode ? 'black' : null}
          disabled={!projectId}
          onClick={changeMode(() => activateMeasureMode(!measureMode))}
          title={formatMessage(additionalMessages.mapMeasure)}
        >
          <Icon
            className={measureMode ? 'measure-icon-inverted' : 'measure-icon'}
          />
        </Button>
        <Popup
          trigger={
            <Button
              icon
              title={formatMessage(additionalMessages.networkDeviceScale)}
              color={apScale === 100 ? null : 'yellow'}
              disabled={!projectId}
            >
              <Icon className="access-point-scale-icon" />
            </Button>
          }
          on="click"
          position="right center"
        >
          <>
            Set a fixed radius for the NDs.
            <br />
            Set to 100% to use the <br />
            SM Range as the ND radius. <br />
            <input
              type="range"
              min="5"
              max="100"
              step="5"
              value={apScale}
              onInput={(event) => {
                const value = event.target.value;
                store.dispatch(setApScale(parseInt(value)));
                getCanvas().setApScale(parseInt(value));
              }}
            />
          </>
        </Popup>
        <Button
          icon
          color={afcMode ? 'black' : null}
          onClick={changeMode(() => setAfcMode(!afcMode))}
          title={'Check available 6 GHz frequencies in the US and Canada (AFC)'}
        >
          <Icon className={afcMode ? 'afc-icon-inverted' : 'afc-icon'} />
        </Button>
      </Button.Group>
    </div>
  );
};

export default connect((state) => {
  const { permissionWrite, mapState, accessPoints, locked } = state.mainFrame;
  const hasAPs = accessPoints?.features.length > 0;
  return {
    permissionWrite,
    mapState,
    accessPoints,
    hasAPs,
    locked,
  };
})(injectIntl(MapToolbar));

/**
 * Zoom the map to fit all features.
 */
const fit = () => {
  getCanvas().fit();
};

/**
 * Set the map state back to "select" mode.
 */
const reset = () => {
  const canvas = getCanvas();
  canvas.resetState(true);
};

/**
 * Start to create network sites in the map.
 */
const createNetworkSite = (enable) => {
  const state = enable ? states.MAP_CREATE_NETWORK_SITE : states.MAP_SELECT;
  reset();
  store.dispatch(uiSet({ mapState: state }));
  if (enable) {
    getCanvas().createNetworkSite();
  }
};

/**
 * Start to create subscriber sites in the map.
 */
const createSubscriberSite = (enable) => {
  const state = enable ? states.MAP_CREATE_SUBSCRIBER_SITE : states.MAP_SELECT;
  reset();
  store.dispatch(uiSet({ mapState: state }));
  if (enable) {
    getCanvas().createSubscriberSite();
  }
};

/**
 * Start to create an access point in the map.
 */
const createAccessPoint = (enable) => {
  const state = enable ? states.MAP_CREATE_ACCESS_POINT : states.MAP_SELECT;
  reset();
  store.dispatch(uiSet({ mapState: state }));
  if (enable) {
    getCanvas().createAccessPoint();
  }
};

/**
 * Start to create an PMP link in the map.
 */
const createPMPLink = (enable) => {
  const state = enable ? states.MAP_CREATE_PMP_LINK : states.MAP_SELECT;
  reset();
  store.dispatch(uiSet({ mapState: state }));
  if (enable) {
    getCanvas().createPMPLink();
  }
};

/**
 * Start to create an PTP link in the map.
 */
const createPTPLink = (enable) => {
  const state = enable ? states.MAP_CREATE_PTP_LINK : states.MAP_SELECT;
  reset();
  store.dispatch(uiSet({ mapState: state }));
  if (enable) {
    getCanvas().createPTPLink();
  }
};
const toggleViewshedMode = (enable) => {
  const state = enable ? states.MAP_VIEWSHED_MODE : states.MAP_SELECT;
  reset();
  getCanvas().setState(state);
};

/**
 * Activate the measure mode.
 */
const activateMeasureMode = (enable) => {
  const state = enable ? states.MAP_DISTANCE_MEASURE : states.MAP_SELECT;
  reset();
  getCanvas().setState(state);
  if (enable) {
    getCanvas().activateMeasure();
  }
};

/**
 * Start to create mesh link in the map.
 */
const createMeshLink = (enable) => {
  const state = enable ? states.MAP_CREATE_MESH_LINK : states.MAP_SELECT;
  if (!enable) {
    reset();
  }
  getCanvas().setState(state);
  if (enable) {
    getCanvas().createMeshLink();
  }
};

/**
 * Start AFC mode in the map.
 */
export function setAfcMode(enable) {
  const state = enable ? states.MAP_AFC_MODE : states.MAP_SELECT;
  reset();
  getCanvas().setState(state);
  if (enable) {
    getCanvas().activateAFCMode();
  }
}

import { postWithAuth } from 'src/api';

export function getFeederLossValues(projectId, ptpId, getValues) {
  const [localRadios, remoteRadios] = getValues([
    'local.radios',
    'remote.radios',
  ]);

  return postWithAuth(
    `project/${projectId}/ptp/${ptpId}/feeder_losses`,
    {
      equipment: localRadios.map((r) => r.equipment),
      remote_product_names: remoteRadios.map((r) => r.equipment?.product),
      local: {
        antenna_id: localRadios[0].antennas[0].lp_antenna_id,
        antenna_height: localRadios[0].antennas[0].height,
        antenna_protection: localRadios[0].antennas[0].config?.antenna_protection,
      },
      remote: {
        antenna_id: remoteRadios[0].antennas[0].lp_antenna_id,
        antenna_height: remoteRadios[0].antennas[0].height,
        antenna_protection: remoteRadios[0].antennas[0].config?.antenna_protection,
      },
    },
    'POST'
  );
}

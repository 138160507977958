import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Header, Segment } from 'semantic-ui-react';
import FilteredList from 'src/components/FilteredList';
import { onSelectItem } from 'src/utils/useful_functions';
import {
  setSelectedPTPLinks,
  uiConfirmAction,
} from '../mainframe/mainframe.reducer';
import CreatePTPLinkModal from './CreatePTPLinkModal';
import messages from '../../messages';
import { AppDispatch, store } from 'src/store';
import { deletePTPLinks } from './ptp.reducer';
import PTPLinksTable from './PTPLinksTable';
import ConditionalPopup from 'src/components/ConditionalPopup';

function PTPLinksPanel({ intl }) {
  const {
    projectId,
    permissionWrite,
    networkSites,
    ptpLinks,
    ptpLinksCount,
    selectedPTPItems,
    userLimits,
  } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const { formatMessage } = intl;
  const [createPTPLinkModal, setCreatePTPLinkModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const showTableView = location.hash === '#table';
  let items = [];
  if (ptpLinks) {
    items = ptpLinks.features.map((link) => {
      return link.properties;
    });
  }
  const clickHandler = (link) => {
    navigate(`/ptp/${link.id}`);
  };

  const getLinksLimitAndError = () => {
    const ptpLinksLimit = userLimits?.ptp_links;
    const exceeded = ptpLinksCount >= ptpLinksLimit;
    return { limit: ptpLinksLimit, exceeded: exceeded };
  };

  const listView = showTableView ? (
    <PTPLinksTable />
  ) : (
    <FilteredList
      items={items || []}
      includeIcon
      displayShowInMap
      clickHandler={clickHandler}
      tooltip={(data) => {
        if (
          !data.warnings ||
          !data.warnings.hover ||
          data.warnings.hover.length === 0
        ) {
          return null;
        }
        return `${data.warnings?.hover}`;
      }}
      selectedItems={selectedPTPItems || []}
      onSelectItem={onSelectItem(selectedPTPItems || [], setSelectedPTPLinks)}
    />
  );
  const tableListIcon = showTableView ? 'list' : 'table';
  const tableListTitle = showTableView
    ? formatMessage(messages.listView)
    : formatMessage(messages.tableView);
  const enableDelete =
    permissionWrite &&
    !showTableView &&
    (selectedPTPItems?.length ? true : false);
  const enableAdd = permissionWrite && networkSites.features.length >= 2;

  const deleteHandler = (
    formatMessage,
    projectId,
    selectedPTPItems,
    tableRef = null
  ) => {
    dispatch(
      uiConfirmAction({
        header: formatMessage(messages.deletePMPLinks),
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          dispatch(
            deletePTPLinks({
              projectId,
              ids: selectedPTPItems,
            })
          )
            .then((res) => {
              if (tableRef) {
                tableRef.current?.refresh();
              }
              store.dispatch(setSelectedPTPLinks([]));
            })
            .catch((err) => {
              //TODO display error
              console.log('Error', err);
            });
        },
      })
    );
  };
  let addBtn: any = (
    <div>
      <ConditionalPopup
        showPopup={getLinksLimitAndError().exceeded}
        message={formatMessage(messages.maximumNumberError, {
          entityName: 'PTP Link',
          limit: getLinksLimitAndError().limit,
        })}
      >
        <Button
          icon="plus"
          disabled={!enableAdd || getLinksLimitAndError().exceeded}
          onClick={() => setCreatePTPLinkModal(true)}
          title={formatMessage(messages.createPTP)}
        />
      </ConditionalPopup>
    </div>
  );
  return (
    <Segment basic>
      {createPTPLinkModal && (
        <CreatePTPLinkModal
          intl={intl}
          onClose={() => setCreatePTPLinkModal(false)}
        ></CreatePTPLinkModal>
      )}
      <Header>
        <FormattedMessage id="common.ptpLinks" defaultMessage="PTP Links" />
        {` (${ptpLinksCount})`}
      </Header>
      <div className="detailWrapper">
        <div>
          <Button.Group vertical size="big">
            <Button
              icon={tableListIcon}
              onClick={(e) => {
                if (showTableView) {
                  navigate('/ptp');
                } else {
                  navigate('/ptp#table');
                }
              }}
              title={tableListTitle}
            />

            {addBtn}
            {showTableView ? null : (
              <Button
                icon="trash alternate"
                disabled={!enableDelete}
                onClick={() => {
                  deleteHandler(formatMessage, projectId, selectedPTPItems);
                }}
                title={formatMessage(messages.deleteSelectedItems)}
              />
            )}
          </Button.Group>
        </div>

        {listView}
      </div>
    </Segment>
  );
}

export default injectIntl(PTPLinksPanel);

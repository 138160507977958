import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'semantic-ui-react';

type Props = {
  label: string;
  list: any[];
  defaultValue: number;
  handleValuesSelection: Function;
  disabled: boolean;
};

export const TxFrequencyEndList: React.FunctionComponent<Props> = ({
  label,
  list,
  defaultValue,
  handleValuesSelection,
  disabled,
}: Props) => {
  const [searchText, setSearchText] = useState('');
  const selectRef = useRef(null);

  let filteredList = list.map((f: number) => f.toFixed(3));

  if (searchText.length > 0) {
    filteredList = filteredList.filter((f: string) => f.includes(searchText));
  }

  useEffect(() => {
    // manually clear selection from elem if somewhere else has
    // set the state value to null
    if (!defaultValue) {
      clearSelection();
    }
  }, [defaultValue]);

  useEffect(() => {
    // clear the select box when searching and none of the values
    // match what is currently selected
    if (
      searchText.length > 0 &&
      !filteredList.includes(searchText) &&
      defaultValue != null &&
      !filteredList.includes(defaultValue.toFixed(3))
    ) {
      clearSelection();
    }
  }, [searchText, filteredList, defaultValue]);

  useEffect(() => {
    // try to keep the select value in view so the user does have to
    // go scrolling to look for it
    const ref: any = selectRef.current;
    if (
      searchText.length === 0 &&
      ref.scrollTop === 0 &&
      defaultValue != null
    ) {
      const idx = filteredList.lastIndexOf(defaultValue.toFixed(3));
      if (ref.options.length > 0 && idx > 20) {
        ref.scrollTop = idx * ref.options[0].offsetHeight;
      }
    }
  }, [selectRef, searchText, defaultValue]);

  function clearSelection() {
    if (selectRef.current != null) {
      selectRef.current.value = undefined;
    }
  }

  return (
    <div className="ui form">
      <div className="mb-1">
        <b>{label}</b>
      </div>
      <div className="searchForm mb-1">
        <Input
          placeholder="Filter..."
          fluid
          icon={{
            name: 'filter',
            onClick: (e) => {
              e.preventDefault();
              if (searchText.length > 0) {
                setSearchText('');
              }
            },
            link: true,
            title: 'Clear filter',
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <select
        className="custom-select"
        size={12}
        value={defaultValue ?? undefined}
        ref={selectRef}
        onChange={(e) => handleValuesSelection(e)}
        disabled={disabled}
      >
        {filteredList.map((li) => {
          return (
            <option key={li} value={parseFloat(li)}>
              {li}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default TxFrequencyEndList;

import React from 'react';
/*
 * See the get_gain_limits.py script to generate the json data
 * using the desktop code.
 */
import ptpGainData from './ptp_antenna_gain_limits.json';
import smGainData from './sm_antenna_gain_limits.json';
import { Table } from 'semantic-ui-react';

/*
 * Reshape an array of cells into 2 column layout
 */
function* reshape(cells) {
  for (let i = 0; i <= cells.length; i += 2) {
    yield <Table.Row>{cells.slice(i, i + 2)}</Table.Row>;
  }
}

const createTable = (limits) => {
  const tableCells = limits.map((bandGroup) => {
    const [band, prodLimits] = bandGroup;
    return (
      <>
        <Table.Cell width={1} textAlign="center">
          {band}
        </Table.Cell>
        <Table.Cell width={3}>
          {prodLimits.map((p) => (
            <div>{p}</div>
          ))}
        </Table.Cell>
      </>
    );
  });

  const tableBody = [];
  for (const row of reshape(tableCells)) {
    tableBody.push(row);
  }

  return (
    <Table columns={4} striped celled compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="center">Band</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Maximum Antenna Gain
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Band</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Maximum Antenna Gain
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
};

export const ptpGainLimits = createTable(ptpGainData);

export const smGainLimits = createTable(smGainData);

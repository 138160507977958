function getEirp(maxPsd, bandwidth) {
  const eirp = 10 * Math.log10(bandwidth) + maxPsd;
  const maxEIRP = 36;
  return Math.min(eirp, maxEIRP);
}

// type AvailableFreq = {
//   low: number,
//   high: Number,
//   eirp: Number,
// };

const cleanAfcData = (afcResponse, bandwidth) => {
  const responses = afcResponse?.availableSpectrumInquiryResponses;
  const availableFreqInfo = responses && responses[0].availableFrequencyInfo;
  if (!responses || !availableFreqInfo) {
    return [];
  }
  return availableFreqInfo.map((freqInfo) => {
    const eirp = getEirp(freqInfo.maxPsd, bandwidth);
    return {
      low: freqInfo.frequencyRange.lowFrequency,
      high: freqInfo.frequencyRange.highFrequency,
      eirp: eirp,
    };
  });
};

/*
 * Return the EIRP value for a give frequency.
 *
 * Returns null if the frequency is not in the ranges.
 */
const findEirp = (freq, eirpRanges) => {
  for (let range of eirpRanges) {
    if (freq >= range.low && freq <= range.high) {
      return range.eirp;
    }
  }
  return null;
};

/*
 * Return the min EIRP if the hittest is successful.
 * Return null if the input box crosses a void.
 */
const hittest = (minFreq, maxFreq, eirpRanges) => {
  // step up in 1 MHz intervals seems safe to me
  const step = 1;
  let minEirp = 36;
  for (let x = minFreq; x <= maxFreq; x += step) {
    const eirp = findEirp(x, eirpRanges);
    if (eirp === null) {
      return null;
    }
    minEirp = Math.min(eirp, minEirp);
  }
  return minEirp;
};

const getXYValues = (eirpRanges, yMin) => {
  let xy = [];
  for (let idx = 0; idx < eirpRanges.length; idx += 1) {
    const currentVals = eirpRanges[idx];
    if (idx > 0) {
      const prevVals = eirpRanges[idx - 1];
      if (prevVals.high != currentVals.low) {
        xy.push({ x: prevVals.high, y: yMin });
        xy.push({ x: currentVals.low, y: yMin });
      }
    }
    xy.push({ x: currentVals.low, y: currentVals.eirp });
    xy.push({ x: currentVals.high, y: currentVals.eirp });
  }
  return xy;
};

const testAFC = () => {
  const testFrequency = (freq, bandwidth, eirpRanges) => {
    console.log('Testing', freq);

    const minFreq = freq - bandwidth / 2;
    const maxFreq = freq + bandwidth / 2;
    const minEirp = hittest(minFreq, maxFreq, eirpRanges);
    if (minEirp === null) {
      console.log('In void');
    } else {
      console.log('Min EIRP', minEirp);
    }
  };

  const bandwidth = 20;
  const afcResponse = {
    version: '1.4',
    availableSpectrumInquiryResponses: [
      {
        response: {
          responseCode: 0,
          shortDescription: 'Success',
        },
        requestId: '123',
        rulesetId: 'US_47_CFR_PART_15_SUBPART_E',
        availabilityExpireTime: '2024-05-23T10:03:50.511Z',
        availableFrequencyInfo: [
          {
            maxPsd: 23,
            frequencyRange: {
              lowFrequency: 5925,
              highFrequency: 6425,
            },
          },
          {
            maxPsd: 20,
            frequencyRange: {
              lowFrequency: 6425,
              highFrequency: 6430,
            },
          },
          {
            maxPsd: 23,
            frequencyRange: {
              lowFrequency: 6525,
              highFrequency: 6875,
            },
          },
        ],
      },
    ],
  };
  const eirpRanges = cleanAfcData(afcResponse, bandwidth);
  console.log(getXYValues(eirpRanges));
  //   testFrequency(6390, bandwidth, eirpRanges);
  //   testFrequency(6415, bandwidth, eirpRanges);
  //   testFrequency(6416, bandwidth, eirpRanges);
  //   testFrequency(6417, bandwidth, eirpRanges);
  //   testFrequency(6418, bandwidth, eirpRanges);
  //   testFrequency(6419, bandwidth, eirpRanges);
  //   testFrequency(6420, bandwidth, eirpRanges);
  //   testFrequency(6450, bandwidth, eirpRanges);
  //   testFrequency(6525, bandwidth, eirpRanges);
  //   testFrequency(6550, bandwidth, eirpRanges);
};

// testAFC();

export { getXYValues, cleanAfcData, hittest };

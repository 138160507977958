import { get } from 'lodash';
import React from 'react';
import { Table } from 'semantic-ui-react';
import messages from 'src/messages';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

function SubscriberComponentCarrier(props) {
  const { getValues } = useFormContext();
  const { ap } = props;
  const { formatMessage } = useIntl();
  const apRadio = getValues('radios.0.equipment.ap_radio_number');
  const band = get(ap, `radios.${apRadio - 1}.equipment.band`);
  const bandwidth = get(ap, `radios.${apRadio - 1}.equipment.bandwidth`);
  const frequency = get(ap, `radios.${apRadio - 1}.frequency.tx_frequency`);

  return (
    <Table>
      <Table.Header style={{ fontSize: '0.818em' }}>
        <Table.Row>
          <Table.HeaderCell>
            {formatMessage(messages.componentCarrier)}
          </Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(messages.carrier)}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(messages.band)}</Table.HeaderCell>
          <Table.HeaderCell>
            {formatMessage(messages.bandwidth)}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {formatMessage(messages.frequency)} (MHz)
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={2}>{apRadio}</Table.Cell>
          <Table.Cell width={2}>Carrier {apRadio}</Table.Cell>
          <Table.Cell width={2}>{band}</Table.Cell>
          <Table.Cell width={3}>{bandwidth}</Table.Cell>
          <Table.Cell width={3}>{frequency || '---'}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export default SubscriberComponentCarrier;

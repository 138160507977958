import React, { useEffect, useRef, useState, useCallback } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ButtonProps, Segment, Loader, Header } from 'semantic-ui-react';
import LPGrid from '../../../components/controls/lpgrid/LPGrid';
import { ToolbarType } from '../../../components/controls/Toolbar';
import AttachSubscribersProject from '../AttachSubscribersDialog';
import { postWithAuth } from '../../../api';
import {
  getPMPLinksColumns,
  getAdditionalPMPLinksColumns,
} from '../pmp_link_columns';
import messages from 'src/messages';
import {
  panelNeedsRefresh,
  uiConfirmAction,
  bulkUpdateRowRemove,
  setBulkEditSelectedRows,
} from 'src/pages/mainframe/mainframe.reducer';
import { store } from 'src/store';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { runWithConfirmation } from '../../../utils/useful_functions';
import debounce from 'lodash/debounce';
import { validateSubscriberSites } from 'src/utils/mapUtils';
import { DELETE_ACTION_TOOLBAR } from 'src/app.constants';

export type SubscriberModulesTableType = {
  apId: number;
  projectId: string;
  prefs: object;
  needsRefresh: boolean;
  disabled: boolean;
  permissionWrite: boolean;
  apsName: string;
} & WrappedComponentProps;

function SubscriberModulesTable({
  apId,
  projectId,
  prefs,
  disabled,
  needsRefresh,
  intl,
  permissionWrite,
  apsName,
}: SubscriberModulesTableType) {
  const tableRef = useRef(null);
  const { formatMessage } = intl;
  const [addSMModalOpen, setAddSMModalOpen] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { projectName, bulkEditSelectedRows } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const { pmpLinksPanel } = useSelector(
    (state: RootStateOrAny) => state.mainFrame.needsRefresh
  );

  const { pmpLinksCount, userLimits } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (bulkEditSelectedRows.length) {
      bulkEditSelectedRows.forEach((data) => {
        if (data.updated) {
          tableRef.current?.api?.getRowNode(data.id)?.setData({ ...data });
          store.dispatch(bulkUpdateRowRemove(data));
        }
      });
    }
  }, [bulkEditSelectedRows]);

  // pmplinkpanel notification comes when we use copy from existing
  // dropdown, so we need to listen to it and refresh the grid
  useEffect(() => {
    if (needsRefresh || pmpLinksPanel) {
      tableRefreshCb();
    }
  }, [needsRefresh, pmpLinksPanel, apId]);

  const addHandler = (event: any, data: ButtonProps, params) => {
    setAddSMModalOpen(true);
  };

  const tableRefreshCb = useCallback(
    debounce(() => tableRef?.current?.refresh(), 3000, {
      maxWait: 20000,
      leading: true,
      trailing: true,
    }),
    [tableRef.current]
  );

  const deleteHandler = (event: any, data: ButtonProps, { gridApi }) => {
    const selectedSmIds = gridApi
      .getSelectedRows()
      .map((row) => row.remote_end_id);

    store.dispatch(
      uiConfirmAction({
        header: formatMessage(messages.deletePMPLinks),
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          gridApi.showLoadingOverlay();
          postWithAuth(
            `project/${projectId}/subscribers`,
            selectedSmIds,
            'DELETE'
          )
            .then((res) => {
              tableRefreshCb();
            })
            .catch((err) => {
              setError(err.detail);
            })
            .finally(() => {
              gridApi.hideOverlay();
            });
        },
      })
    );
  };

  let deleteAction = {
    onClick: deleteHandler,
    disabled: true,
  };

  let actionsInit: ToolbarType[] = [
    {
      icon: 'add',
      label: formatMessage(messages.add),
      onClick: addHandler,
      disabled: !permissionWrite,
    },
    Object.assign(deleteAction, DELETE_ACTION_TOOLBAR),
  ];

  const [actions, setActions] = useState<ToolbarType[]>(actionsInit);

  useEffect(() => {
    const modifiedActions = actions.map((action: ToolbarType) => {
      if (action.label === formatMessage(messages.add))
        action.disabled = !permissionWrite;
      return action;
    });
    setActions(modifiedActions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionWrite]);
  const dropdownOptionsData = [];
  const allColumns = getPMPLinksColumns(prefs, projectId, false).map((col) =>
    col.hasOwnProperty('getQuickFilterText')
      ? { ...col }
      : {
          ...col,
          getQuickFilterText: (params) => {
            return (
              params.value &&
              params.value.toString().toLowerCase().replaceAll(' ', '')
            );
          },
        }
  );
  const additionalColumns = getAdditionalPMPLinksColumns(projectId);

  for (const { remote_end_id, sm_name } of rowData) {
    dropdownOptionsData.push({
      key: remote_end_id,
      value: remote_end_id,
      text: sm_name,
    });
  }
  dropdownOptionsData?.sort((a, b) => (a.text > b.text ? 1 : -1));
  return (
    <>
      {' '}
      {disabled || needsRefresh ? (
        <Segment inverted className="update-required">
          {formatMessage(messages.saveChangesPanel, {
            panelName: 'subscriber modules',
          })}
        </Segment>
      ) : null}
      <Segment basic disabled={disabled}>
        {addSMModalOpen && (
          <AttachSubscribersProject
            apId={apId}
            projectId={projectId}
            onClose={() => {
              setAddSMModalOpen(false);
            }}
            successCallback={() => {
              tableRefreshCb();
            }}
            validateSubscriberSites={validateSubscriberSites}
          ></AttachSubscribersProject>
        )}
        {bulkEditSelectedRows.length > 0 && (
          <Header as="h3">
            <Loader active inline size="tiny" />{' '}
            {formatMessage(messages.calculatingResults)}
          </Header>
        )}
        <div
          className="overlay"
          style={{
            display: `${bulkEditSelectedRows.length > 0 ? 'block' : 'none'}`,
          }}
        ></div>
        <LPGrid
          actions={actions}
          addStatusColor={true}
          callback={(data) => {
            store.dispatch(
              panelNeedsRefresh({
                panels: ['accessPointPanel'],
                status: false,
              })
            );
            setRowData(data);
          }}
          gridRef={tableRef}
          url={`project/${projectId}/access_point/${apId}/subscribers`}
          //refreshOn={needsRefresh}
          defaultCsvExportParams={{
            fileName: `${projectName}_${apsName}_links_to_subscriber_modules.csv`,
          }}
          table_id="links_to_subscriber_table"
          isTableColConfigure={true}
          getRowNodeId={(data) => {
            return data.id;
          }}
          columnDefs={[...allColumns, ...additionalColumns]}
          enableBrowserTooltips
          getRowStyle={(params: any) => {
            if (!!params.data.strokeColor) {
              return { color: params.data.strokeColor };
            }
          }}
          dropdownConfig={{
            optionsData: dropdownOptionsData,
            placeHolderTxt: formatMessage(messages.dropdownPlaceHolderTxt),
            helpText: formatMessage(messages.copyFromHelp, {
              kind: 'link',
            }),
            changeHandler: (event, data, gridApi, setDropdownValue) => {
              const rows = gridApi.getSelectedRows();
              const ids = rows.map((row) => row.remote_end_id);
              const smId = data.value;
              const selectedSMName = data.options.find(
                (e) => e.value === data.value
              );
              const qtyAndKind =
                rows.length === 1
                  ? 'selected link'
                  : `${rows.length} selected links`;
              runWithConfirmation({
                message: `${formatMessage(messages.copyFromConfirmation, {
                  qtyAndKind,
                })} ${selectedSMName.text}`,
                onConfirm: () => {
                  const payload = {
                    ids,
                    copy_id: smId,
                    data: {},
                    bulk: 'copy',
                  };
                  rows.forEach((row) => {
                    row.updated = false;
                  });
                  store.dispatch(
                    setBulkEditSelectedRows(JSON.parse(JSON.stringify(rows)))
                  );
                  postWithAuth(
                    `project/${projectId}/subscribers/bulk`,
                    payload,
                    'PATCH'
                  ).then((res) => {
                    //tableRefreshCb();
                  });
                },
                onCancel: () => {
                  setDropdownValue(null);
                },
              });
            },
          }}
        ></LPGrid>
      </Segment>
    </>
  );
}

export default injectIntl(SubscriberModulesTable);

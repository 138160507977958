import { get, isNumber } from 'lodash';
import productFamily from '../../pmp_families.json';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import AntennaSelection from 'src/pages/equipment/common/AntennaSelection';
import { extractBandwidth } from 'src/utils/useful_functions';
import { PMPFormField } from '../utils';
import { tiltHelpText } from 'src/pages/antennas/antenna-utils';
import LimitControl from 'src/pages/ptp/LimitControl';
import ModeledBeamwidth from '../ModeledBeamwidth';
import { PMP450V } from 'src/app.constants';
import { FieldValues } from 'react-hook-form';
import {
  antennaHeight,
  antennaHeightComponentProps,
  band,
  eirp,
  getUserPower,
  interference,
  product,
  regulation,
  smRangeBaseConfig,
} from '../../config-utils';
import {
  calculateNoiseDensity,
  getBandwidthAdjustment,
  getDefaultNoise,
  interferenceInfoFormatter,
  powerFieldInfoFormatter,
} from '../../utils';

//Equipment panel fields
export const equipmentPanelFields: PMPFormField[] = [
  {
    ...band,
    refreshesChoices: true,
    label({ formGetter }) {
      const carrierQty = formGetter('radios.0.equipment.carrier_qty');
      return carrierQty === 2 ? 'Band - Carrier 1' : 'Band';
    },
  },
  product,
  regulation,
];

// Product panel fields
export const smRangeFields: PMPFormField[] = [
  {
    attrName: 'range_units',
    getter: 'radios.0.equipment.range_units',
  },
  {
    ...smRangeBaseConfig,
    min({ choices, formGetter }) {
      const units = formGetter('radios.0.equipment.range_units');
      return choices['equipment']['sm_range']['extra'][units]['min'];
    },
    max({ choices, formGetter }) {
      const units = formGetter('radios.0.equipment.range_units');
      return choices['equipment']['sm_range']['extra'][units]['max'];
    },
    minPrecision: 3,
    maxPrecision: 0,
  },
];

export const framePeriod: PMPFormField = {
  attrName: 'frame_period',
  getter: 'radios.0.equipment.frame_period',
  refreshesChoices: true,
};

export const smRegistrationLimit: PMPFormField = {
  attrName: 'sm_registration_limit',
  getter: 'radios.0.equipment.sm_registration_limit',
  warning: ({ ap }) => {
    return get(ap, 'radios.0.warnings.sm_quantity');
  },
};

// Antenna panel fields
export const filterAntenna = (antennaChoices, Id) => {
  return antennaChoices.filter((a) => a.id === Id)[0];
};

export const antennaSelection: PMPFormField = {
  label: 'Antenna Selection',
  attrName: 'lp_antenna_id',
  getter: 'radios.0.antennas.0.lp_antenna_id',
  warning({ choices }) {
    return choices.warnings.antenna;
  },
  refreshesChoices: true,
  component: AntennaSelection,
  nextValue: (currentValue, newChoices) => {
    const antennaChoices = newChoices['antennas'];
    const antennaIds = antennaChoices.map((a) => a.id);
    if (antennaIds.includes(currentValue)) {
      return currentValue;
    } else {
      const defaultAntenna = antennaChoices.filter((a) => a.data.is_default)[0];
      return defaultAntenna != null ? defaultAntenna.id : antennaIds[0];
    }
  },
};

export const azimuth: PMPFormField = {
  label: 'Antenna Azimuth',
  attrName: 'azimuth',
  getter: 'radios.0.antennas.0.azimuth',
  component: GenericScaledField,
  min: 0,
  max: 359,
  units: '°',
};

export const tilt: PMPFormField = {
  label: 'Antenna Tilt',
  attrName: 'tilt',
  getter: 'radios.0.antennas.0.tilt',
  component: GenericScaledField,
  max({ choices, formGetter }) {
    const antennaId = formGetter('radios.0.antennas.0.lp_antenna_id');
    const antennaData = filterAntenna(choices.antennas, antennaId);
    return get(antennaData, 'data.max_tilt');
  },
  min({ choices, formGetter }) {
    const antennaId = formGetter('radios.0.antennas.0.lp_antenna_id');
    const antennaData = filterAntenna(choices.antennas, antennaId);
    return get(antennaData, 'data.min_tilt');
  },
  nextValue(currentValue, newChoices, formGetter) {
    const antennaId = formGetter('radios.0.antennas.0.lp_antenna_id');
    const antennaData = filterAntenna(newChoices.antennas, antennaId);
    if (antennaData.data.allow_tilt) {
      return currentValue;
    } else {
      return 0;
    }
  },
  editable({ choices, formGetter }) {
    const antennaId = formGetter('radios.0.antennas.0.lp_antenna_id');
    const antennaData = filterAntenna(choices.antennas, antennaId);
    return get(antennaData, 'data.allow_tilt');
  },
  tooltip({ choices, formGetter }) {
    const antennaId = formGetter('radios.0.antennas.0.lp_antenna_id');
    const antennaData = filterAntenna(choices.antennas, antennaId);
    if (antennaData) return tiltHelpText(antennaData.data);
    else return '';
  },
  precision: 1,
  units: '°',
};

export const antennaHeightND: PMPFormField = {
  ...antennaHeight,
  componentProps({ ap, formGetter }) {
    return antennaHeightComponentProps(ap, formGetter);
  },
};

export const modeledBeamwidth: PMPFormField = {
  label: 'Modeled Beamwidth',
  attrName: 'beamwidth',
  min: 5,
  getter: 'radios.0.antennas.0.beamwidth',
  units: '°',
  component: ModeledBeamwidth,
};

export const cableLoss: PMPFormField = {
  label: 'Cable Loss',
  attrName: 'cable_loss',
  getter: 'radios.0.antennas.0.cabling.cable_loss',
  editable({ formGetter }) {
    return !formGetter('radios.0.antennas.0.cabling.feeder_calculate');
  },
  tooltip({ choices }) {
    const cableLoss = Number(choices.cabling.default_cable_loss).toFixed(1);
    return `Default cable loss is ${cableLoss} dB`;
  },
  component: GenericScaledField,
  nextValue(currentValue, newChoices, formGetter, attr) {
    return newChoices.cabling.cable_loss;
  },
  afterOnChange(newValue, { setValue }) {
    setValue(
      'radios.0.antennas.0.cabling.user_cable_loss',
      parseFloat(newValue)
    );
  },
  min: 0,
  max: 999,
  units: 'dB',
  precision: 1,
  show: ({ choices }) => {
    return !choices.cabling.internal && choices.cabling.allow_user_feeder_loss;
  },
};

// Power panel fields

export const isPMP450m = (formGetter) => {
  const product = formGetter('radios.0.equipment.product');
  return product === 'PMP 450m';
};

export const isPMP450V = (formGetter) => {
  const product = formGetter('radios.0.equipment.product');
  return product === PMP450V;
};

export const iscnWave = (formGetter) => {
  const product = formGetter('radios.0.equipment.product');
  return product === 'V5000';
};

export const getNDUserPower = (isSector, idx = 0): PMPFormField => {
  return {
    ...getUserPower(isSector, idx),
    nextValue(currentValue, newChoices, formGetter, attr) {
      return newChoices.power[idx].user_power.default;
    },
    show({ formGetter }) {
      return !isPMP450m(formGetter);
    },
  };
};

export const getUserEIRP = (isSector): PMPFormField => {
  return {
    label: 'User Limit',
    attrName: `use_user_eirp`,
    component: LimitControl,
    show({ formGetter }) {
      return isPMP450m(formGetter) || iscnWave(formGetter);
    },
    nextValue(currentValue, newChoices, formGetter, attr) {
      return newChoices.power[0].user_eirp.default;
    },
    checkboxGetter: `radios.0.power.use_user_eirp`,
    valueGetter: `radios.0.power.user_eirp`,
    units: 'dBm',
    precision: 1,
    checkboxRefreshChoices: true,
    componentProps({ choices, formGetter, refreshChoices }) {
      const props = {
        onAcceptChange: () => {
          const userPower = formGetter(`radios.0.power.user_eirp`);
          if (isNumber(userPower)) {
            refreshChoices({
              field: {
                attrName: `radios.0.power.user_eirp`,
              },
            });
          }
        },
      };
      return isSector ? { width: 14, ...props } : props;
    },
    defaultValue({ formGetter }) {
      const maxEirp = formGetter('radios.0.power.maximum_eirp');
      if (maxEirp) {
        return maxEirp;
      } else {
        return 30;
      }
    },
    min: ({ choices }) => {
      return choices.power[0].user_eirp.min;
    },
    max: ({ choices }) => {
      return choices.power[0].user_eirp.max;
    },
  };
};

export const smReceiveTargetLevel: PMPFormField = {
  label: 'SM Receive Target Level',
  attrName: 'sm_target_level',
  getter: 'radios.0.power.receive_target_level',
  component: GenericScaledField,
  units: 'dBm',
  min: ({ choices }) => {
    return choices.power[0].sm_receive_target_level?.min;
  },
  max: ({ choices }) => {
    return choices.power[0].sm_receive_target_level?.max;
  },
  nextValue(currentValue, newChoices, formGetter) {
    const prevProduct = formGetter('product.prev');
    const prevProductFamily = productFamily[prevProduct];
    const prevDefault = defaultSmReceiveTargetLevel(
      prevProduct,
      prevProductFamily
    );
    const product = formGetter('radios.0.equipment.product');
    const family = productFamily[product];
    const defaultValue = defaultSmReceiveTargetLevel(product, family);
    const min = newChoices.power[0].sm_receive_target_level?.min;
    const max = newChoices.power[0].sm_receive_target_level?.max;
    if (
      currentValue != prevDefault &&
      currentValue >= min &&
      currentValue < max
    ) {
      return currentValue;
    }
    return defaultValue;
  },
};

export const calculateDefaultNoise = (
  currentValue,
  newChoices,
  formGetter,
  attr,
  index = 0
) => {
  let bandwidth = formGetter(`radios.${index}.equipment.bandwidth`);
  if (attr === 'band') {
    const band = formGetter(`radios.${index}.equipment.band`);
    return getDefaultNoise(band, bandwidth);
  } else {
    return currentValue;
  }
};

/* band changes -> set default noise density on ND, SM
bandwidth changes -> set noise on ND, SM
Noise changes -> set corresponding noise density */

export const ndInterference: PMPFormField = {
  label: 'ND Interference?',
  attrName: `ap_interference`,
  ...interference,
  show({ formGetter }) {
    return !isPMP450V(formGetter);
  },
  nextValue(currentValue, newChoices, formGetter, attr) {
    return calculateDefaultNoise(currentValue, newChoices, formGetter, attr);
  },
};

export const smDefaultInterferenceChangeHandler = ({
  formGetter,
  formSetter,
  idx,
}) => {
  const smNoise = formGetter(`radios.${idx}.power.noise_sm`);
  const bw = formGetter(`radios.${idx}.equipment.bandwidth`);
  const noiseDensity = calculateNoiseDensity(smNoise, bw);
  formSetter(`radios.${idx}.power.noise_density_sm`, noiseDensity);
};

export const smDefaultInterference: PMPFormField = {
  label: 'Default SM Interference?',
  attrName: `default_sm_interference`,
  component: LimitControl,
  checkboxGetter: 'radios.0.power.use_noise_sm',
  valueGetter: 'radios.0.power.noise_sm',
  show({ formGetter }) {
    return !isPMP450V(formGetter);
  },
  componentProps({ formGetter, formSetter, choices }) {
    const unitExtraTooltip = interferenceInfoFormatter(
      formGetter,
      'radios.0.equipment.bandwidth'
    );
    return {
      watch: [
        choices.equipment.band.value,
        // bandwidth doesnt exist in cnreach product, with ISM setting so safety check
        choices.equipment.bandwidth?.value,
      ],
      unitExtraTooltip,
      onAcceptChange: () => {
        smDefaultInterferenceChangeHandler({ formGetter, formSetter, idx: 0 });
      },
    };
  },
  nextValue(currentValue, newChoices, formGetter, attr) {
    let bandwidth = formGetter(`radios.0.equipment.bandwidth`);
    if (attr === 'band') {
      const band = formGetter(`radios.0.equipment.band`);
      return getDefaultNoise(band, bandwidth);
    }
    return currentValue;
  },
  units: 'dBm',
  precision: 1,
  min: -144.0,
  max: -40.0,
};

export const bandwidthChangehandler = (
  newValue,
  { getValues, setValue },
  newChoices,
  idx
) => {
  const bandWidthMHz = extractBandwidth(newValue);
  const bwAdjustment = getBandwidthAdjustment(bandWidthMHz);
  const [apNoise, smNoise, apNoiseDensity, smNoiseDensity] = getValues([
    `radios.${idx}.power.noise`,
    `radios.${idx}.power.noise_sm`,
    `radios.${idx}.power.noise_density`,
    `radios.${idx}.power.noise_density_sm`,
  ]);
  if (apNoise && apNoiseDensity) {
    setValue(`radios.${idx}.power.noise`, apNoiseDensity + bwAdjustment);
  }
  if (smNoise && smNoiseDensity) {
    setValue(`radios.${idx}.power.noise_sm`, smNoiseDensity + bwAdjustment);
  }
};

export const bandwidth: PMPFormField = {
  attrName: 'bandwidth',
  getter: 'radios.0.equipment.bandwidth',
  refreshesChoices: true,
  afterOnChange: (newValue, formMethods, newChoices) => {
    bandwidthChangehandler(newValue, formMethods, newChoices, 0);
  },
  show({ formGetter }) {
    return !isPMP450V(formGetter);
  },
};

const products_11ac_and_11ax = [
  'ePMP 3000',
  'ePMP 3000L',
  'ePMP 4500',
  'ePMP 4500L',
  'ePMP 4600',
  'ePMP 4600L',
  'ePMP Force 300 CSM',
  'ePMP Force 300-13',
  'ePMP Force 300-13L',
  'ePMP Force 300-16',
  'ePMP Force 300-19',
  'ePMP Force 300-19R',
  'ePMP Force 300-25',
  'ePMP Force 300-25L',
  'ePMP Force 400C',
  'ePMP Force 425',
  'ePMP Force 4525',
  'ePMP Force 4525L',
  'ePMP Force 4600C',
  'ePMP Force 4625',
  'ePMP MP3000 MicroPop',
];

export function defaultSmReceiveTargetLevel(product, family) {
  /*
     cnWave 5G Fixed: -50 dBm
     PMP 450v 4x4: -50 dBm
     ePMP 11ac and 11ax: -45 dBm
     PMP 450i, PMP 450m, PMP 450 MicroPoP, PTP700/670 HCMP, ePMP 11n: -56 dBm
   */
  if (family === 'cnWave 28') {
    return -50;
  } else if (product === PMP450V) {
    return -50;
  } else if (products_11ac_and_11ax.includes(product)) {
    return -45;
  } else {
    return -56;
  }
}

export const checkErrors = ({
  panel,
  getFieldState,
  getValues,
  ap,
  sm,
  setValue,
  choices,
}) => {
  return panel.fields.some((field: PMPFormField) => {
    const { show, getter, valueGetter } = field;
    if (
      show != null &&
      !show({ choices, formGetter: getValues, ap, sm, formSetter: setValue })
    ) {
      return false;
    } else {
      const path = valueGetter != null ? valueGetter : getter;
      const { error } = getFieldState(path);
      return error;
    }
  });
};

export const updatePostData = (formData: FieldValues) => {
  const product = get(formData, 'radios.0.equipment.product');
  const radios = get(formData, 'radios');
  const radioQty = get(formData, 'radios.0.equipment.carrier_qty');
  if (product === 'V5000' || (product === PMP450V && radioQty === 2)) {
    const radio1Equipment = get(formData, 'radios.0.equipment');
    const radio1Power = get(formData, 'radios.0.power');
    const radio1Freq = get(formData, 'radios.0.frequency');
    const radio2Equipment = {
      ...radio1Equipment,
      ...get(formData, 'radios.1.equipment'),
    };
    const radio2Power = {
      ...radio1Power,
      ...get(formData, 'radios.1.power'),
    };
    const radio2Freq = {
      ...radio1Freq,
      ...get(formData, 'radios.1.frequency'),
    };
    formData['radios'][1] = {
      equipment: radio2Equipment,
      power: radio2Power,
      antennas: get(formData, 'radios.0.antennas'),
      frequency: radio2Freq,
    };
  } else if (radios.length === 2) {
    radios.pop();
  }
  return formData;
};
export const POWER_PANEL_FIELDS_WIDTH = 12;
export const eirpRowConfig: PMPFormField[] = [
  {
    ...eirp,
    componentProps: ({ choices }) => {
      const unitExtraTooltip = powerFieldInfoFormatter(
        choices,
        'power.0.maximum_eirp'
      );
      return {
        width: POWER_PANEL_FIELDS_WIDTH,
        unitExtraTooltip,
      };
    },
  },
  {
    ...eirp,
    nextValue(currentValue, newChoices, formGetter, attr) {
      return newChoices.power[1].eirp;
    },
    getter: 'radios.1.power.eirp',
    componentProps({ choices }) {
      const unitExtraTooltip = powerFieldInfoFormatter(
        choices,
        'power.1.maximum_eirp'
      );
      return {
        width: POWER_PANEL_FIELDS_WIDTH,
        unitExtraTooltip,
      };
    },
  },
];

import {
  eirp,
  getUserPower,
  interference,
  powerConfig,
} from '../../config-utils';
import { PMPFormField } from '../../nd/utils';
import { powerFieldInfoFormatter } from '../../utils';

export const smEirpConfig: PMPFormField = {
  ...eirp,
  componentProps({ choices }) {
    const unitExtraTooltip = powerFieldInfoFormatter(
      choices,
      'power.0.maximum_eirp'
    );
    return {
      unitExtraTooltip,
    };
  },
  warning({ sm }): string {
    return sm.radios[0].warnings.eirp;
  },
};

export const smPowerConfig: PMPFormField = {
  ...powerConfig,
  componentProps({ choices }) {
    const unitExtraTooltip = powerFieldInfoFormatter(
      choices,
      'power.0.maximum_settable_power'
    );
    return {
      unitExtraTooltip,
    };
  },
};

export const smUserPowerFields: PMPFormField = {
  ...getUserPower(false),
  nextValue(currentValue, newChoices, formGetter, attr) {
    return newChoices.power[0].user_power;
  },
  min: -30,
  max: 40,
};

export const smInterferenceFields: PMPFormField = {
  ...interference,
  label: 'Interference?',
  checkboxGetter: ({ formGetter }) => {
    const apSMInterferenceEnabled = formGetter('radios.0.power.use_noise_sm');
    return apSMInterferenceEnabled
      ? 'radios.0.power.use_noise_sm'
      : 'radios.0.power.use_noise';
  },
  checkboxCompProps({ formGetter }) {
    const apSMInterferenceEnabled = formGetter('radios.0.power.use_noise_sm');
    const checked = apSMInterferenceEnabled
      ? formGetter('radios.0.power.use_noise_sm')
      : formGetter('radios.0.power.use_noise');
    return {
      disabled: !!formGetter('radios.0.power.use_noise_sm'),
      className: 'sm-interference',
      checked: checked,
    };
  },
};

import L from 'leaflet';
import '../viewshed/Leaflet.DistortableImageOverlay';
import { store } from '../../store';
import { desktopDistance } from "../../utils/useful_functions";
import { sortBy } from "lodash";

const DEFAULT_OPTIONS = { radius: 0, color: '#FF0000' };
const DEFAULT_SELECTION = { sites: [], locations: [], markers: [] };

// the distance from a site required to snap a location to the site
// varies for different zoom levels
const ZOOM_DIST_MAX_CONSIDER = 6;
const ZOOM_DIST_MIN_CONSIDER = 21; // map is constrained, but just in case
const ZOOM_DIST_BREAKPOINTS = {
  7: 3000,
  8: 2000,
  9: 1100,
  10: 475,
  11: 160,
  12: 90,
  13: 76,
  14: 40,
  15: 15,
  16: 7,
  17: 5,
  18: 3,
  19: 2,
  20: 1,
  21: 1,
};

function getMaxDistance(zoom) {
  if (zoom <= ZOOM_DIST_MAX_CONSIDER) {
    return 10000;
  } else if (zoom > ZOOM_DIST_MIN_CONSIDER) {
    return 1;
  } else {
    return ZOOM_DIST_BREAKPOINTS[zoom];
  }
}

export default class ViewshedUtils {
  #canvas = null;
  options = { ...DEFAULT_OPTIONS };
  selection = { ...DEFAULT_SELECTION };
  handlers = { map: null, network: null, subscriber: null };
  viewshedOverlays = null;

  constructor(canvas) {
    this.#canvas = canvas;
    this.viewshedOverlays = {};
  }

  #addIndicator = (latlng) => {
    const size = this.options.radius;
    const indicator = new L.circle(latlng, {
      fillColor: this.options.color,
      fillOpacity: 0.3,
      radius: size,
      stroke: false,
    });
    const marker = new L.circleMarker(latlng, {
      fillColor: '#fcba03',
      fillOpacity: 1,
      radius: 5,
      color: '#000000',
      weight: 1,
    });

    this.selection.markers.push(indicator);
    this.selection.markers.push(marker);
    indicator.addTo(this.#canvas.map);
    marker.addTo(this.#canvas.map);
  };

  #handler = (layerName) => {
    return (event) => {
      L.DomEvent.preventDefault(event);
      L.DomEvent.stopPropagation(event);
      this.#canvas.map.doubleClickZoom.disable();

      const { layer, latlng } = event;
      let indicatorLatLng = latlng;
      // there seems to be some state persistence issues here so need to use array
      // spread syntax instead of push (only handles a small number of elements)
      if (layer) {
        const { id, kind } = layer.feature.properties;
        if (kind === `${layerName}_site`) {
          this.selection.sites = [...this.selection.sites, id];
        }
      } else if (layerName === 'map') {
        // check whether the click was within some radius of any sites, if so, use that site instead
        const snapDistance = getMaxDistance(this.#canvas.map.getZoom());
        const sites = sortBy(
          store
            .getState()
            .mainFrame.networkSites.features.map((site) => {
              const distance = desktopDistance(
                site.properties.latitude,
                site.properties.longitude,
                latlng.lat,
                latlng.lng,
              );
              return [distance, site.properties];
            })
            .filter(([distance, _]) => distance < snapDistance),
          ([distance, _]) => distance,
        );

        if (sites.length > 0) {
          // sites are sorted by nearest first
          const chosenSite = sites[0][1];
          this.selection.sites = [...this.selection.sites, chosenSite.id];
          // snap to the nearest chosen site
          indicatorLatLng = { lat: chosenSite.latitude, lng: chosenSite.longitude };
        } else {
          this.selection.locations = [...this.selection.locations, latlng];
        }
      }

      this.#addIndicator(indicatorLatLng);
      this.#canvas.map.doubleClickZoom.enable();
    };
  };

  #addHandlers = () => {
    if (!this.handlers.map) {
      this.handlers.map = this.#handler('map');
      this.#canvas.map.on('click', this.handlers.map);
    }

    const networkLayer = this.#canvas.networkSiteLayer;
    if (networkLayer && !this.handlers.network) {
      this.handlers.network = this.#handler('network');
      networkLayer.on('click', this.handlers.network);
    }

    const subscriberLayer = this.#canvas.subscriberSiteLayer;
    if (subscriberLayer && !this.handlers.subscriber) {
      this.handlers.subscriber = this.#handler('subscriber');
      subscriberLayer.on('click', this.handlers.subscriber);
    }
  };

  #resetHandlers = () => {
    if (this.handlers.map) {
      this.#canvas.map.off('click', this.handlers.map);
      this.handlers.map = null;
    }

    if (this.handlers.network) {
      this.#canvas.networkSiteLayer?.off('click', this.handlers.network);
      this.handlers.network = null;
    }

    if (this.handlers.subscriber) {
      this.#canvas.subscriberSiteLayer?.off('click', this.handlers.subscriber);
      this.handlers.subscriber = null;
    }
  };

  activateSelectLocations = () => {
    this.#canvas.resetState();
    this.#canvas.setState('select location'); // VIEWSHED_SELECT_LOCATION
    L.DomUtil.addClass(this.#canvas.map._container, 'leaflet-crosshair');
    this.#addHandlers();
  };

  pauseSelectLocations = () => {
    L.DomUtil.removeClass(this.#canvas.map._container, 'leaflet-crosshair');
    this.#resetHandlers();
    // TODO add drag handler to viewshed indicators
    // TODO fix cursor type
  };

  resumeSelectLocations = () => {
    L.DomUtil.addClass(this.#canvas.map._container, 'leaflet-crosshair');
    this.#addHandlers();
  };

  resetSelection = () => {
    for (const marker of this.selection.markers) {
      marker.remove();
    }

    this.#resetHandlers();
    this.options = { ...DEFAULT_OPTIONS };
    this.selection = { ...DEFAULT_SELECTION };
  };

  removeMarker = (locationIndex, lat, lng) => {
    let indexsToRemove = new Set();
    this.selection.markers.forEach((marker, i) => {
      if (marker.getLatLng().equals(new L.LatLng(lat, lng), 0.00001))
      {
        indexsToRemove.add(i);
        marker.remove();
      }
    });

    this.selection.locations = this.selection.locations.filter((_, i) => {
      return i !== locationIndex;
    });
  }

  show = (id, image, bounds) => {
    let overlay;
    if (bounds['north'] && bounds['south']) {
      // low res view shed
      overlay = new L.imageOverlay(image, [
        [bounds.north, bounds.west],
        [bounds.south, bounds.east],
      ]);
    } else {
      // high res view shed
      const url = `${window.runtime.lidarViewshedURL}/${image}`;
      console.log(url);
      console.log(`https://viewshed.lp.cambiumnetworks.com/${image}`);
      overlay = new L.distortableImageOverlay(url, {
        mode: 'lock',
        suppressToolbar: true,
        crossOrigin: false,
        corners: [
          L.latLng(bounds.nw_lat, bounds.nw_lng),
          L.latLng(bounds.ne_lat, bounds.ne_lng),
          L.latLng(bounds.sw_lat, bounds.sw_lng),
          L.latLng(bounds.se_lat, bounds.se_lng),
        ],
      });
    }
    this.viewshedOverlays[id] = overlay;
    overlay.addTo(this.#canvas.map);
  };

  hide = (id) => {
    if (this.viewshedOverlays[id]) {
      this.viewshedOverlays[id].remove();
    }
  };

  removeAll = () => {
    for (const overlay of Object.values(this.viewshedOverlays)) {
      if (overlay) {
        overlay.remove();
      }
    }
    this.viewshedOverlays = {};
  };
}

import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import * as d3 from 'd3';

import { availabilityAsTimeString } from 'src/utils/useful_functions';

import { Y_AXIS_MAP, MARGIN } from './PerformanceChart.data';
import { YaxisMap, PerformanceChartProps } from './PerformanceChart.types';
import {
  formatCapacityLabel,
  formatAvailabilityLabel,
  getMaxFilteredAvailabilityAndCapacity,
  getAvailabilityChartValue,
  getCapacityValue,
  highlightChartStep,
  showChartCrosshairs,
} from './PerformanceChart.utils';

const PerformanceChart = ({
  apiData,
  title,
  type,
  kind,
}: PerformanceChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null!);

  // Get api data
  const { units: unit = 'Mbps', capacity, availability } = apiData ?? {};

  const { availabilityList, capacityList } =
    getMaxFilteredAvailabilityAndCapacity({
      availability,
      capacity,
    });

  // Build chart data structure based from API data
  const chartData = useMemo(() => {
    return (
      availabilityList
        // Filter out empty availability list
        .filter((subArray) => subArray.length > 0)
        .map((item, index) => {
          const layer = index + 1;

          // Calculate the gap/white space on the right side of the step chart
          const lastCapacityItem = capacityList[index][0];
          const rightCapacityGap =
            index === 0
              ? lastCapacityItem + lastCapacityItem * 0.0525
              : lastCapacityItem;

          const steps = item
            .map((_, i) => {
              return {
                availabilityRealValue: item[i],
                availabilityChartValue: getAvailabilityChartValue(item[i]),
                // Set a fixed value of 0 to capacityA if it's the last capacity item on the list
                capacityA:
                  i === item.length - 1
                    ? 0
                    : getCapacityValue(capacityList[index][i + 1], unit),
                capacityB: getCapacityValue(capacityList[index][i], unit),
                layer,
              };
            })
            .reverse()
            // Add a gap/empty white space on the right side of the step chart
            .concat({
              availabilityRealValue: 0,
              availabilityChartValue: 0,
              capacityA: getCapacityValue(lastCapacityItem, unit),
              capacityB: getCapacityValue(rightCapacityGap, unit),
              layer,
            });

          return {
            layer,
            steps,
          };
        })
    );
  }, [availabilityList, capacityList, unit]);

  const steps = chartData.map(({ steps }) => steps).flat();
  const sortedAvailability = steps.sort(
    ({ availabilityChartValue: a }, { availabilityChartValue: b }) => a - b
  );
  const sortedCapacityA = steps
    .filter(({ availabilityRealValue }) => availabilityRealValue > 0)
    .sort(({ capacityA: a }, { capacityA: b }) => a - b);

  // Note: I did not remove and just commented out the implementation below
  // if we need to filter the Y axis values based on min and max availabilities in the future

  // const yAxisValues = Object.values(Y_AXIS_MAP);
  // const minAvailability = Math.min(...availabilityList.flatMap((item) => item));
  // const effectiveMinAvailability = minAvailability < 90 ? 0 : minAvailability;

  // const maxAvailability = Math.max(...availabilityList.flatMap((item) => item));

  // const lowestYBound = yAxisValues.reduce((prev, curr) =>
  //   Math.abs(curr - effectiveMinAvailability) <
  //   Math.abs(prev - effectiveMinAvailability)
  //     ? curr
  //     : prev
  // );
  // const highestYBound = yAxisValues.reduce((prev, curr) =>
  //   Math.abs(curr - maxAvailability) < Math.abs(prev - maxAvailability)
  //     ? curr
  //     : prev
  // );

  // const lowestYBoundKey = Object.keys(Y_AXIS_MAP).find(
  //   (key) => Y_AXIS_MAP[key] === lowestYBound
  // );
  // const highestYBoundKey = Object.keys(Y_AXIS_MAP).find(
  //   (key) => Y_AXIS_MAP[key] === highestYBound
  // );

  // const filteredYAxisMap: YaxisMap = Object.entries(Y_AXIS_MAP)
  //   .filter(([key, value]) => value <= highestYBound && value >= lowestYBound)
  //   .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

  const filteredYAxisMap = Y_AXIS_MAP;
  const yAxisMapKeys = Object.keys(filteredYAxisMap);
  const yTickValues = yAxisMapKeys.map(Number);
  const yTicksCount = yAxisMapKeys.length - 1;
  const isAdaptiveSymmetry = chartData.length === 2;
  const maxXTicks = 6;
  const maxXTicksCount = isAdaptiveSymmetry ? maxXTicks * 2 : maxXTicks;

  const getAdaptiveSymmetryText = useCallback(
    (layer: number) => {
      if (!isAdaptiveSymmetry) return '';

      if (layer === 2) {
        return 'Assumes saturated load in other direction';
      } else {
        return 'Assumes no load in other direction';
      }
    },
    [isAdaptiveSymmetry]
  );

  // Build tooltip content based on chart data
  const tooltipContent = useCallback(
    (capacity: number, availability: number, layer: number) =>
      `
  <p>${formatCapacityLabel(
    capacity,
    unit
  )} ${unit} at ${formatAvailabilityLabel(availability)}%</p>
  <p>(not available for ${availabilityAsTimeString(availability)})</p> 
  <p>${getAdaptiveSymmetryText(layer)}</p>
  `,
    [getAdaptiveSymmetryText, unit]
  );

  const drawChart = useCallback(() => {
    const width = 600 - MARGIN.LEFT - MARGIN.RIGHT;
    const height = 300 - MARGIN.TOP - MARGIN.BOTTOM;

    const x = d3.scaleLinear().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    const xAxis = d3
      .axisBottom(x)
      .ticks(maxXTicksCount)
      .tickSize(-height)
      .tickFormat((d) => formatCapacityLabel(Number(d), unit, 1));

    // Y Axis for grid lines
    const yAxisGrid = d3
      .axisLeft(y)
      .ticks(yTicksCount)
      .tickSize(-width)
      .tickFormat(() => '');

    // Y Axis for ticks
    const yAxisTicks = d3
      .axisLeft(y)
      .ticks(yTicksCount)
      .tickSize(7)
      .tickFormat(() => '');

    // Y Axis for label
    const yAxisLabel = d3
      .axisLeft(y)
      .tickValues(yTickValues)
      .tickFormat((d) => `${filteredYAxisMap[d as keyof YaxisMap]}%`);

    // X and Y domains
    x.domain([0, d3.max(sortedAvailability, (d) => d?.capacityB) as number]);
    // y.domain([lowestYBoundKey, highestYBoundKey]);
    y.domain([0, yAxisMapKeys[yAxisMapKeys.length - 1]]);

    // Chart
    let svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr(
        'viewBox',
        `0 0 ${width + MARGIN.LEFT + MARGIN.RIGHT} ${
          height + MARGIN.TOP + MARGIN.BOTTOM
        }`
      )
      .append('g')
      .attr('transform', `translate(${MARGIN.LEFT},${MARGIN.TOP})`);

    // X Axis (Bottom)
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis);

    // Y Axis (Left)
    // Append the Y Axis for grid lines
    svg.append('g').attr('class', 'y axis grid').call(yAxisGrid);

    // Append the Y Axis for ticks
    svg.append('g').attr('class', 'y axis ticks').call(yAxisTicks);

    // Append the Y Axis for label
    svg.append('g').attr('class', 'y axis labels').call(yAxisLabel);

    // Tooltip
    const tooltip = d3
      .select(chartRef.current)
      .append('div')
      .attr('class', `${type} tooltip`)
      .style('display', 'none');

    chartData.forEach(({ layer, steps }) => {
      let line = 'M';
      let fill = `M0,${height}`;

      steps.forEach((d, i) => {
        let y0 = y(d.availabilityChartValue),
          x0 = x(d.capacityB);
        if (i === 0) {
          line += `${x(d.capacityA)},${y0}H${x0}`;
        } else {
          line += `H${x0}`;
        }

        fill += `V${y0}H${x0}`;

        if (steps[i + 1]) {
          line += `V${y(steps[i + 1].availabilityChartValue)}`;
        }
      });

      fill += `V${height}Z`;

      // Line
      svg.append('path').attr('class', 'line').attr('d', line);

      // Area Fill
      svg
        .append('path')
        .attr('class', `path-fill path-${layer}`)
        .attr('d', fill);
    });

    // Focus
    const focus = svg
      .append('g')
      .attr('class', 'focus')
      .style('display', 'none');

    // Step Highlight
    focus.append('rect').attr('class', 'highlight');

    // X Crosshair
    focus
      .append('line')
      .attr('class', 'crosshair crosshair-x')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height)
      .attr('y2', height);

    // Y Crosshair
    focus
      .append('line')
      .attr('class', 'crosshair crosshair-y')
      .attr('y1', 0)
      .attr('y2', height);

    // Chart rectangle to handle mouse events
    svg
      .append('rect')
      .attr('class', `${type} rect-events`)
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseover', () => {
        focus.style('display', 'block');
      })
      .on('mouseout', () => {
        focus.style('display', 'none');
        tooltip.style('display', 'none');
      })
      .on('mousemove', (e) => {
        const [xPos, yPos] = d3.pointer(e);

        const x0 = x.invert(xPos) as number;
        const y0 = y.invert(yPos) as number;

        if (yPos < 0 || y0 < 0 || xPos < 0 || x0 < 0) return;

        showChartCrosshairs({ focus, xPos, yPos });

        highlightChartStep({
          sortedCapacityA,
          tooltipContent,
          tooltip,
          chartRef,
          height,
          focus,
          type,
          xPos,
          yPos,
          x0,
          y0,
          x,
          y,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, unit]);

  useEffect(() => {
    // Remove existing chart before rendering a new one
    // when switching between different links
    d3.select(chartRef.current).selectAll('*').remove();

    // Draw and render performance chart
    drawChart();
  }, [drawChart]);

  return (
    <div className={`performance-details-chart ${kind}`}>
      <h3 className="chart-title">Performance to {title}</h3>
      <div className="chart-wrapper">
        <p className="left-label">Availability</p>
        <div className="chart" ref={chartRef}></div>
        <p className="bottom-label">Capacity ({unit})</p>
      </div>
    </div>
  );
};

export default PerformanceChart;

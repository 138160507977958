import { eirp, powerConfig } from '../../config-utils';
import NDAntennaPanel from '../NDAntennaPanel';
import NDEquipmentPanel from '../NDEquipmentPanel';
import NDPowerPanel from '../NDPowerPanel';
import { PMPFormPanel, syncFormState } from '../utils';
import {
  antennaHeightND,
  antennaSelection,
  cableLoss,
  equipmentPanelFields,
  modeledBeamwidth,
  smRangeFields,
  smReceiveTargetLevel,
  smRegistrationLimit,
  ndInterference,
  smDefaultInterference,
  bandwidth,
  azimuth,
  tilt,
  getNDUserPower,
} from './config-utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'nd_equipment',
  component: NDEquipmentPanel,
  fields: [...equipmentPanelFields],
};

const product: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'nd_Product',
  component: NDEquipmentPanel,
  fields: [
    bandwidth,
    ...smRangeFields,
    smRegistrationLimit,
    {
      attrName: 'symmetry',
      getter: 'radios.0.equipment.symmetry',
    },
    {
      attrName: 'polarization',
      getter: 'radios.0.equipment.polarization',
    },
  ],
};

const antenna: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'nd_antenna',
  component: NDAntennaPanel,
  fields: [
    antennaSelection,
    modeledBeamwidth,
    antennaHeightND,
    azimuth,
    tilt,
    cableLoss,
  ],
};

const power: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'nd_power',
  component: NDPowerPanel,
  fields: [
    eirp,
    powerConfig,
    getNDUserPower(false),
    smReceiveTargetLevel,
    ndInterference,
    smDefaultInterference,
  ],
};

export const cnWave28ViewConfig = {
  syncFormState,
  equipment,
  product,
  antenna,
  power,
};

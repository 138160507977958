import React, { useRef, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { Segment } from 'semantic-ui-react';
import { postWithAuth } from 'src/api';
import messages from 'src/messages';
import { store } from 'src/store';
import LPGrid from '../../components/controls/lpgrid/LPGrid';
import { runWithConfirmation } from '../../utils/useful_functions';
import {
  panelNeedsRefresh,
  setBulkEditSelectedRows,
  uiConfirmAction,
} from '../mainframe/mainframe.reducer';
import { getPTPColumns } from './PTPTableColumns';
import { DELETE_ACTION_TOOLBAR } from 'src/app.constants';

function PTPLinksTable({ intl }) {
  const { formatMessage } = intl;
  const tableRef = useRef(null);

  const dispatch = useDispatch();
  const { projectName, projectId } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const needsRefresh = useSelector(
    (state: RootStateOrAny) => state.mainFrame.needsRefresh.ptpLinksTable
  );

  const [rowData, setRowData] = useState([]);
  const dropdownOptionsData = [];

  useEffect(() => {
    if (needsRefresh) {
      tableRef.current?.refresh();
      dispatch(
        panelNeedsRefresh({
          panels: ['ptpLinksTable'],
          status: false,
        })
      );
    }
  }, [needsRefresh]);

  const deleteSelection = (
    formatMessage,
    projectId,
    selectedItems,
    tableRef
  ) => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(messages.deletePTPLinks),
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(`project/${projectId}/ptp`, selectedItems, 'DELETE')
            .then((res) => {
              if (tableRef) {
                tableRef.current?.refresh();
              }
              /* if (res) {
                store.dispatch(fetchAccessPoints(projectId));
              } else {
                console.error('Failed to delete access points');
              } */
            })
            .catch(console.error);
        },
      })
    );
  };

  for (const row of rowData) {
    const { id, name, path_index } = row;
    if (path_index === 0) {
      dropdownOptionsData.push({
        key: id,
        value: id,
        text: name,
      });
    }
  }
  dropdownOptionsData?.sort((a, b) => (a.text > b.text ? 1 : -1));

  const actions = [
    Object.assign({
      icon: 'trash alternate',
      label: formatMessage(messages.delete),
      onClick: (event, data, params) => {
        const selectedRows = params.gridApi.getSelectedRows();
        const selectedSiteIds = selectedRows.map((row) => row.id);
        deleteSelection(formatMessage, projectId, selectedSiteIds, tableRef);
      },
      disabled: true,
      ...DELETE_ACTION_TOOLBAR,
    }),
  ];

  return (
    <Segment basic style={{ width: '100%' }}>
      <LPGrid
        actions={actions}
        addStatusColor={true}
        callback={(data) => {
          store.dispatch(
            panelNeedsRefresh({
              panels: ['accessPointPanel'],
              status: false,
            })
          );
          setRowData(data);
        }}
        dropdownConfig={{
          optionsData: dropdownOptionsData,
          placeHolderTxt: formatMessage(messages.dropdownPlaceHolderTxt),
          helpText: formatMessage(messages.copyFromHelp, {
            kind: 'link',
          }),
          changeHandler: (event, data, gridApi, setDropdownValue) => {
            const rows = gridApi.getSelectedRows();
            const ids = [...new Set(rows.map((row) => row.id))];
            const smId = data.value;
            const selectedSMName = data.options.find(
              (e) => e.value === data.value
            );
            const qtyAndKind =
              rows.length === 1
                ? 'selected link'
                : `${rows.length} selected links`;
            runWithConfirmation({
              message: `${formatMessage(messages.copyFromConfirmation, {
                qtyAndKind,
              })} ${selectedSMName.text}`,
              onConfirm: () => {
                gridApi.showLoadingOverlay();
                const payload = {
                  ids,
                  copy_id: smId,
                  data: {},
                  bulk: 'copy',
                };
                rows.forEach((row) => {
                  row.updated = false;
                });
                store.dispatch(
                  setBulkEditSelectedRows(JSON.parse(JSON.stringify(rows)))
                );
                postWithAuth(`project/${projectId}/ptp/bulk`, payload, 'PATCH')
                  .then((res) => {})
                  .finally(() => {
                    gridApi.hideOverlay();
                  });
              },
              onCancel: () => {
                setDropdownValue(null);
              },
            });
          },
        }}
        gridRef={tableRef}
        url={`project/${projectId}/ptp/table`}
        //refreshOn={needsRefresh}
        defaultCsvExportParams={{
          fileName: `${projectName}_ptp_links.csv`,
        }}
        table_id="ptp_links_table"
        isTableColConfigure={true}
        getRowNodeId={(data) => {
          return `${data.id}-${data.path_index}`;
        }}
        columnDefs={getPTPColumns(projectId)}
        enableBrowserTooltips
        getRowStyle={(params: any) => {
          if (!!params.data.strokeColor) {
            return { color: params.data.strokeColor };
          }
        }}
      ></LPGrid>
    </Segment>
  );
}

export default injectIntl(PTPLinksTable);

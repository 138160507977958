import React from 'react';
import { Accordion, Header, Icon, Segment } from 'semantic-ui-react';
import { STORE_PREFIX } from '../app.constants';
import { useLocalStorage } from '../hooks/useLocalStorage';

export default function StoredAccordion({
  name,
  title,
  children,
  contentProps,
  headerClass = '',
  className,
  ...rest
}: any) {
  const [active, setActive] = useLocalStorage(
    `${STORE_PREFIX}.${name}_acc`,
    true
  );

  const handleClick = () => {
    setActive((a) => !a);
  };
  return (
    <>
      <Accordion.Title
        size="tiny"
        as={Header}
        attached="top"
        className={headerClass}
        block
        {...rest}
        onClick={handleClick}
      >
        {title}
        <Icon
          name={active ? 'chevron down' : 'chevron left'}
          style={{ float: 'right' }}
          title={active ? 'Collapse panel' : 'Expand panel'}
        />
      </Accordion.Title>
      <Accordion.Content
        as={Segment}
        attached
        active={active}
        className={className}
        {...contentProps}
      >
        {children}
      </Accordion.Content>
    </>
  );
}

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { getCanvas } from '../../utils/mapUtils';
import { Menu } from 'semantic-ui-react';

const removeMenu = () => {
  const menuDiv = document.getElementById('map-context-menu');
  if (menuDiv) {
    getCanvas().map.off('mousemove');
    // All modern browsers remove the event handlers when
    // the node is removed from the DOM. IE doesn't, but we
    // don't care about that.
    menuDiv.remove();
  }
};

/**
 * Display a context sensitive pop-up menu over the map
 *
 * @param {Object} event - Leaflet event object passed from the "contextmenu" event.
 * @param {Object[]} items - Array of menu items containing the "key" and "content".
 *      See the React Semanic UI menu items for more information. This can include
 *      icons, etc. Keys must be unique.
 * @param {onClickCallback} onclick - Callback function, called when an iten is clicked.
 *      The function must accept a single parameter, which is the key of the selected item.
 */
export const showPopupMenu = (event, items, onclick) => {
  const mapDiv = document.getElementById('map');
  if (!mapDiv) {
    return;
  }
  const { clientX, clientY } = event.originalEvent;

  //   const menu = ReactDOMServer.renderToStaticMarkup(
  const menu = ReactDOMServer.renderToString(
    <div className="ui container">
      <Menu items={items} onItemClick={console.log} vertical />
    </div>
  );

  const { x, y } = mapDiv.getBoundingClientRect();
  let menuDiv = document.getElementById('map-context-menu');
  if (!menuDiv) {
    menuDiv = document.createElement('div');
    menuDiv.setAttribute('id', 'map-context-menu');
    getCanvas().map.on('mousemove', removeMenu);
  }
  menuDiv.style.position = 'fixed';
  // move the top corner of the menu so that
  // the menu appears below the cursor
  const fudgeFactor = 10;
  menuDiv.style.left = `${clientX - x - fudgeFactor}px`;
  menuDiv.style.top = `${clientY - y - fudgeFactor}px`;
  menuDiv.style.zIndex = 1;
  menuDiv.innerHTML = menu;
  mapDiv.insertAdjacentElement('beforebegin', menuDiv);

  // Attach the click events to the child nodes.
  // For some reason onItemClick and onClick don't work in here.
  // Use the original menu item keys and then call the "onclick" event
  // with the key as the parameter.
  const domItems = menuDiv.childNodes[0].childNodes[0].childNodes;
  domItems.forEach((item, i) => {
    item.addEventListener('click', () => {
      removeMenu();
      onclick(items[i].key);
    });
  });
};

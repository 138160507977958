import React, { useState } from 'react';
import { Button, Modal, Message, Popup } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { postWithAuth } from 'src/api';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import TiltInputPanel from './TiltInputPanel';
import TiltOutputPanel from './TiltOutputPanel';
import { calcTiltCancel } from '../../reducers/calc_tilt.reducer';
import messages from 'src/messages';
import { RootStateOrAny } from 'src/store';

function CalcTiltModal(props) {
  const { modified, intl, applyTiltCallback } = props;
  const { formatMessage } = intl;
  const { message, status } = useSelector(
    (state: RootStateOrAny) => state.calcTilt
  );
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const project_id = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const { id } = useParams();

  function cancel() {
    postWithAuth(`project/${project_id}/${id}/calc_tilt/cancel`).then(() => {
      dispatch(calcTiltCancel(null));
    });
  }

  function closeHandler() {
    cancel();
    setOpen(false);
  }
  let calcTiltButton = (
    <Button type="button" onClick={() => setOpen(true)}>
      {formatMessage(messages.calcTilt)}
    </Button>
  );
  if (modified) {
    const tiltButton = (
      <Button
        type="button"
        className="recalculate"
        basic
        color="blue"
        style={{ opacity: 0.5, cursor: 'default' }}
      >
        {formatMessage(messages.calcTilt)}
      </Button>
    );
    calcTiltButton = (
      <Popup
        content={formatMessage(messages.saveChangesCalcTilt)}
        trigger={tiltButton}
      ></Popup>
    );
  }
  return (
    <Modal
      trigger={calcTiltButton}
      open={open}
      className="calctilt-modal"
      closeOnDimmerClick={true}
      size="tiny"
    >
      <Modal.Header>
        <FormattedMessage
          id="calcTilt.calculateTilt"
          defaultMessage="Calculate Tilt"
        />
        <Button
          circular
          icon="close"
          onClick={() => closeHandler()}
          floated="right"
        />
      </Modal.Header>
      <Modal.Content>
        <TiltInputPanel
          minTilt={props.min}
          maxTilt={props.max}
          cancel={cancel}
        />
        {status === 'running' ? <Message>Progress: {message}</Message> : null}
        {status === 'completed' ? (
          <>
            <hr className="lp-divider" />
            <TiltOutputPanel
              setValues={props.setValues}
              closeHandler={closeHandler}
              values={props.values}
              panels={props.panels}
              applyTiltCallback={applyTiltCallback}
            />
          </>
        ) : null}
      </Modal.Content>
    </Modal>
  );
}

export default injectIntl(React.memo(CalcTiltModal));

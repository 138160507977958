import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Segment, Header, Popup, Loader } from 'semantic-ui-react';
import LPGrid from '../../components/controls/lpgrid/LPGrid';
import FilteredList from '../../components/FilteredList';
import {
  onSelectItem,
  runWithConfirmation,
} from '../../utils/useful_functions';
import { store } from '../../store';
import { postWithAuth } from 'src/api';
import { getMeshLinksColumns } from './mesh_links_columns';
import additionalMessages from '../../messages';
import {
  uiConfirmAction,
  setSelectedMeshLinks,
  panelNeedsRefresh,
  setBulkEditSelectedRows,
} from '../mainframe/mainframe.reducer';
import MeshLinkDialog from './MeshLinkDialog';
import debounce from 'lodash/debounce';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DELETE_ACTION_TOOLBAR } from 'src/app.constants';

function MeshListPanel(props) {
  const { formatMessage } = props.intl;
  const [showAttachML, setShowAttachML] = useState(false);
  const {
    projectId,
    projectName,
    permissionWrite,
    accessPointsCount,
    meshLinks,
    meshLinksCount,
    selectedMeshItems,
    prefs,
    bulkEditSelectedRows,
  } = useSelector((state) => state.mainFrame);
  const needsRefresh = useSelector(
    (state) => state.mainFrame.needsRefresh.meshLinksPanel
  );

  const navigate = useNavigate();
  const location = useLocation();
  const showTableView = location.hash === '#table';

  const tableRef = useRef();
  const tableRefreshCb = useCallback(
    debounce(() => tableRef?.current?.refresh(), 3000, {
      maxWait: 20000,
      leading: true,
      trailing: true,
    }),
    [tableRef.current]
  );

  useEffect(() => {
    if (needsRefresh) {
      tableRefreshCb();
      store.dispatch(
        panelNeedsRefresh({ panels: ['meshLinksPanel'], status: false })
      );
    }
  }, [needsRefresh]);

  let items = [];
  if (meshLinks) {
    items = meshLinks.features.map((link) => {
      return link.properties;
    });
  }

  let deleteAction = {
    onClick: (event, data, { gridApi }) => {
      const selectedRows = gridApi.getSelectedRows();
      const selectedSiteIds = selectedRows.map((row) => row.id);
      deleteHandler(
        formatMessage,
        projectId,
        selectedSiteIds,
        tableRef,
        gridApi
      );
    },
    disabled: true,
  };

  let actionsInit = [Object.assign(deleteAction, DELETE_ACTION_TOOLBAR)];

  // eslint-disable-next-line
  const [actions, setActions] = useState(actionsInit);

  const clickHandler = (ml) => {
    navigate(`/mesh/${ml.id}`);
  };

  const listView = showTableView ? null : (
    <div style={{ display: 'flex' }} class="flex-1">
      <FilteredList
        style={{ width: '23vw' }}
        items={items || []}
        displayShowInMap
        clickHandler={clickHandler}
        tooltip={(data) => {
          if (
            !data.warnings ||
            !data.warnings.hover ||
            data.warnings.hover.length === 0
          ) {
            return null;
          }
          return `${data.warnings?.hover}`;
        }}
        selectedItems={selectedMeshItems || []}
        onSelectItem={onSelectItem(
          selectedMeshItems || [],
          setSelectedMeshLinks
        )}
      />
      <Outlet context={props} />
    </div>
  );

  const tableListIcon = showTableView ? 'list' : 'table';
  const tableListTitle = showTableView
    ? formatMessage(additionalMessages.listView)
    : formatMessage(additionalMessages.tableView);

  const defaultColumns = getMeshLinksColumns(prefs, projectId).map((col) =>
    col.hasOwnProperty('getQuickFilterText')
      ? { ...col }
      : {
          ...col,
          getQuickFilterText: (params) => {
            return (
              params.value &&
              params.value.toString().toLowerCase().replaceAll(' ', '')
            );
          },
        }
  );

  const dropdownOptionsData = [];
  for (const { id, name } of items) {
    dropdownOptionsData.push({
      key: id,
      value: id,
      flag: name,
      text: name,
    });
  }
  dropdownOptionsData?.sort((a, b) => (a.text > b.text ? 1 : -1));
  const tableView = showTableView ? (
    <Segment basic style={{ width: '100%' }}>
      {bulkEditSelectedRows.length > 0 && (
        <Header as="h3">
          <Loader active inline size="tiny" />{' '}
          {formatMessage(additionalMessages.calculatingResults)}
        </Header>
      )}
      <div style={{ position: 'relative' }}>
        <div
          className="overlay"
          style={{
            display: `${bulkEditSelectedRows.length > 0 ? 'block' : 'none'}`,
          }}
        ></div>
        <LPGrid
          url={`project/${projectId}/mesh_links`}
          gridRef={tableRef}
          actions={actions}
          callback={(data) => {
            tableRef.current?.api.setRowData(data);
          }}
          addStatusColor={true}
          defaultCsvExportParams={{ fileName: `${projectName}_mesh_links.csv` }}
          columnDefs={defaultColumns}
          enableBrowserTooltips
          getRowStyle={(params) => {
            if (!!params.data.strokeColor) {
              return { color: params.data.strokeColor };
            }
          }}
          table_id="mesh_links_table"
          isTableColConfigure={true}
          getRowNodeId={(data) => {
            return data.id;
          }}
          dropdownConfig={{
            optionsData: dropdownOptionsData,
            placeHolderTxt: formatMessage(
              additionalMessages.dropdownPlaceHolderTxt
            ),
            helpText: formatMessage(additionalMessages.copyFromHelp, {
              kind: 'link',
            }),
            changeHandler: (event, data, gridApi, setDropdownValue) => {
              const rows = gridApi.getSelectedRows();
              const ids = rows.map((row) => row.id);
              const smId = data.value;
              const selectedSMName = data.options.find(
                (e) => e.value === data.value
              );
              const qtyAndKind =
                rows.length === 1
                  ? 'selected link'
                  : `${rows.length} selected links`;
              runWithConfirmation({
                message: `${formatMessage(
                  additionalMessages.copyFromConfirmation,
                  { qtyAndKind }
                )} ${selectedSMName.text}`,
                onConfirm: () => {
                  const payload = {
                    ids,
                    copy_id: smId,
                    data: {},
                    bulk: 'copy',
                  };
                  rows.forEach((row) => {
                    row.updated = false;
                  });
                  store.dispatch(
                    setBulkEditSelectedRows(JSON.parse(JSON.stringify(rows)))
                  );
                  postWithAuth(
                    `project/${projectId}/mesh_links/bulk`,
                    payload,
                    'PATCH'
                  ).then((res) => {
                    //tableRefreshCb();
                  });
                },
                onCancel: () => {
                  setDropdownValue(null);
                },
              });
            },
          }}
        ></LPGrid>
      </div>
    </Segment>
  ) : null;

  const deleteHandler = (
    formatMessage,
    projectId,
    selectedPMPItems,
    tableRef,
    gridApi
  ) => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(additionalMessages.deleteMeshLinks),
        message: formatMessage(additionalMessages.confirm),
        size: 'mini',
        onConfirm: () => {
          gridApi?.showLoadingOverlay();
          // If any of the ids are for the currently displayed object
          // then we need to navigate away from the page as soon as the object
          // has been removed
          const requiresNavigate =
            !showTableView &&
            selectedPMPItems.some((id) => location.pathname.includes(id));
          postWithAuth(
            `project/${projectId}/mesh_links`,
            selectedPMPItems,
            'DELETE'
          )
            .then((res) => {
              if (tableRef) {
                tableRef.current?.refresh();
              }
              if (!gridApi) store.dispatch(setSelectedMeshLinks([]));
              if (requiresNavigate) {
                navigate('/mesh');
              }
            })
            .catch((err) => {
              //TODO display error
              console.log('Error', err);
            })
            .finally(() => {
              gridApi?.hideOverlay();
            });
        },
      })
    );
  };

  const enableDelete =
    permissionWrite &&
    !showTableView &&
    (selectedMeshItems?.length ? true : false);
  const enableAdd = permissionWrite && accessPointsCount;

  let addBtn = (
    <div>
      <Button
        icon="plus"
        disabled={!enableAdd}
        onClick={() => setShowAttachML(true)}
        title={formatMessage(additionalMessages.attachMeshLink)}
      />
    </div>
  );

  // Similar tooltip text is there in AP panel..
  // we can make generic function
  if (!enableAdd) {
    let createApBtnHelp = '';
    if (!permissionWrite) {
      createApBtnHelp = formatMessage(additionalMessages.readOnly);
    } else if (!accessPointsCount) {
      createApBtnHelp = formatMessage(additionalMessages.atleastOneAPMsg);
    }
    addBtn = <Popup trigger={addBtn} content={createApBtnHelp} />;
  }

  return (
    <Segment basic>
      {showAttachML && (
        <MeshLinkDialog
          intl={props.intl}
          onClose={() => setShowAttachML(false)}
        ></MeshLinkDialog>
      )}
      <Header>
        <FormattedMessage id="common.meshLinks" defaultMessage="Mesh Links" />
        {` (${meshLinksCount})`}
      </Header>
      <div className="detailWrapper">
        <div>
          <Button.Group vertical size="big">
            <Button
              icon={tableListIcon}
              onClick={(e) => {
                if (showTableView) {
                  navigate('/mesh');
                } else {
                  navigate('/mesh#table');
                }
              }}
              title={tableListTitle}
            />

            {addBtn}
            {showTableView ? null : (
              <Button
                icon="trash alternate"
                disabled={!enableDelete}
                onClick={() =>
                  deleteHandler(formatMessage, projectId, selectedMeshItems)
                }
                title={formatMessage(additionalMessages.deleteSelectedItems)}
              />
            )}
          </Button.Group>
        </div>

        {listView}
        {tableView}
      </div>
    </Segment>
  );
}

export default injectIntl(MeshListPanel);

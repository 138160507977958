import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { postWithAuth } from 'src/api';
import BomModal from 'src/components/BomModal';
import { useSelector } from 'react-redux';
import { RootStateOrAny, store } from 'src/store';
import { panelNeedsRefresh } from 'src/pages/mainframe/mainframe.reducer';

function AccessPointBomModal(props) {
  const { siteId, powerFromSwitch, ...restProps } = props;
  const { projectId } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const [refreshHack, setRefreshHack] = useState(false);

  const tsActions = [];

  if (powerFromSwitch.visible)
    tsActions.push({
      type: 'checkbox',
      label: 'Power from switch?',
      title: 'Configure switch equipment at network site',
      onChange: (event, data) => {
        postWithAuth(
          `project/${projectId}/access_point/${props.objId}/props`,
          { power_from_switch: { checked: data.checked } },
          'PATCH'
        )
          .then(() => {
            // This is a hack to force the UI to refresh and request
            // the updated BOM when the checkbox changes state
            setRefreshHack(!refreshHack);
          })
          .catch((err) => {});
      },
      defaultChecked: powerFromSwitch.checked,
      disabled: powerFromSwitch.enabled ? false : true,
    });

  return (
    <BomModal
      {...restProps}
      bomRefresh={{ status: refreshHack }}
      tsActions={tsActions}
      onCloseCallback={() => {
        // This is another hack to force the AP panel to reload the
        // AP so that it now contains the updated "power_from_switch"
        // settings
        store.dispatch(
          panelNeedsRefresh({
            panels: ['accessPointPanel'],
            status: true,
          })
        );
      }}
    />
  );
}

export default injectIntl(AccessPointBomModal);

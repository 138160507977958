import React, { useEffect, useState } from 'react';
import { Dropdown, Checkbox, Input } from 'semantic-ui-react';
import { ColDef } from 'ag-grid-community';
import sortBy from 'lodash/sortBy';

function ColumnSelect({
  columns,
  setColumns,
  allColumns,
  setIsColumnsUpdate,
  getColumnDef,
  displayColumnList,
  doAutoSize,
}) {
  const [filteredColumns, setFilteredColumns] = useState([]);

  const [searchText, setSearchText] = useState('');

  const toggleSelection = (event, { label, checked }) => {
    setIsColumnsUpdate({ status: true });
    const selectedItem = allColumns.find(
      ({ headerName }) => headerName === label
    ).field;
    if (checked) {
      displayColumnList.current = [...columns, selectedItem];
    } else {
      displayColumnList.current = columns.filter((col) => col !== selectedItem);
    }
    const columnArr: ColDef[] = getColumnDef(displayColumnList.current);
    setColumns(columnArr);
    doAutoSize();
  };

  useEffect(() => {
    resetColumns();
  }, []);

  const resetColumns = () => {
    setFilteredColumns(allColumns);
  };

  const searchChange = ($event) => {
    let textValue = $event.target.value;
    let fColumns = [];
    fColumns = allColumns.filter(
      (el) => el.headerName && el.headerName.toLowerCase().includes(textValue.toLowerCase())
    );
    setFilteredColumns(fColumns);
    setSearchText(textValue);
  };

  return (
    <Dropdown item simple text="Select Columns" className="select-columns">
      <Dropdown.Menu>
        <Input
          type="text"
          onChange={($event) => {
            searchChange($event);
          }}
          value={searchText}
          placeholder={'Filter Columns'}
          icon={{
            name: 'filter',
            onClick: (e) => {
              searchChange({ target: { value: '' } });
            },
            link: true,
          }}
        />
        {sortBy(filteredColumns, ({ headerName }) => headerName?.toLowerCase()).map(
          ({ field, headerName }) => (
            <Dropdown.Item key={field}>
              <Checkbox
                label={headerName}
                onChange={(event, { label, checked }) =>
                  toggleSelection(event, { label, checked })
                }
                checked={columns?.includes(field) ? true : false}
              />
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ColumnSelect;

import PTPEquipmentPanel from '../PTPEquipmentPanel';
import {
  PTPFormPanel,
  PTPPanelConfigImpl,
  PTPShowProps,
  PTPChoices,
  syncFormState,
  getEquipmentFromChoices,
} from '../utils';
import {
  genericEndConfig,
  genericEndPerformanceSummary,
  genericLinkSummary,
  remoteProductNextValue,
} from './config-utils';

const equipment: PTPFormPanel<PTPShowProps> = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'equipment_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'band',
      getter: 'local.radios.0.equipment.band',
      lk: {
        shared({ choices }) {
          const eq = getEquipmentFromChoices(choices);
          const band = eq.band.value;
          return !(band === '7 GHz' || band === '8 GHz');
        },
        "2plus0": {
          getter: [
            'local.radios.0.equipment.band',
            'local.radios.1.equipment.band'
          ],
        },
      },
    },
    {
      attrName: 'product',
      getter: 'local.radios.0.equipment.product',
    },
    {
      attrName: 'remote_product',
      getter: 'remote.radios.0.equipment.product',
      nextValue: remoteProductNextValue,
      // NOTE not sure whether to include this as I think it leads
      // to unintuitive UX
      // show({ choices }) {
      //   const eq = getEquipmentFromChoices(choices);
      //   if (!eq.hasOwnProperty('link_protection')) {
      //     // happens when local and remote product are different
      //     return true;
      //   }
      //   return eq.link_protection.value !== 'unlicensed_2plus0';
      // },
    },
    {
      attrName: 'antenna_color',
      getter: 'local.radios.0.equipment.antenna_color',
    },
    {
      // this isn't supported on PTP-700 BSA
      attrName: 'capacity_variant',
      getter: 'local.radios.0.equipment.capacity_variant',
    },
    {
      attrName: 'regulation',
      getter: 'local.radios.0.equipment.regulation',
    },
    {
      attrName: 'precise_network_timing',
      getter: 'local.radios.0.equipment.precise_network_timing',
    },
    {
      attrName: 'link_protection',
      getter: 'local.radios.0.equipment.link_protection',
      afterOnChange(value, { getValues, setValue }) {
        // NOTE hacky sync, probably a better way to do this.
        // It worked for 8X0 for whatever reason but when it comes
        // to PTP-700 1+1, the payload we submit needs to be
        // consistent between all paths/ends
        const radioCount = getValues('local.radios').length;
        for (let i = 0; i < radioCount; i++) {
          setValue(`local.radios.${i}.equipment.link_protection`, value);
          setValue(`remote.radios.${i}.equipment.link_protection`, value);
        }
      }
    },
  ],
};

const config: PTPFormPanel<any> = {
  kind: 'config',
  title: 'Product Configuration',
  name: 'equipment_config_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'local.radios.0.equipment.bandwidth',
    },
    {
      attrName: 'tdm',
      getter: 'local.radios.0.equipment.tdm',
      show({ choices }): boolean {
        return !choices.equipment.precise_network_timing.value;
      },
      info({ choices }): string {
        // LPWEB-641 - was observed that the warning could appear even if the
        // setting was NONE, so enforce that behaviour in the UI too
        if (choices.equipment.tdm.value === 'NONE') {
          return null;
        }

        if (choices.local.end.e1t1_warning) {
          return choices.local.end.e1t1_warning;
        } else if (choices.remote.end.e1t1_warning) {
          return choices.remote.end.e1t1_warning;
        }
      },
    },
    {
      attrName: 'optimisation',
      getter: 'local.radios.0.equipment.optimisation',
    },
    {
      attrName: 'sync',
      getter: 'local.radios.0.equipment.sync',
    },
    {
      attrName: 'symmetry',
      getter: 'local.radios.0.equipment.symmetry',
    },
    {
      attrName: 'dual_payload',
      getter: 'local.radios.0.equipment.dual_payload',
    },
    {
      attrName: 'highest_mod_mode',
      getter: 'local.radios.0.equipment.highest_mod_mode',
    },
    {
      attrName: 'lowest_ethernet_mode',
      getter: 'local.radios.0.equipment.lowest_ethernet_mode',
    },
    {
      attrName: 'lowest_telecoms_mode',
      getter: 'local.radios.0.equipment.lowest_telecoms_mode',
    },
    {
      attrName: 'master',
      getter: 'local.radios.0.equipment.master',
    },
  ],
};

const tdd: PTPFormPanel<any> = {
  kind: 'tdd',
  title: 'TDD Synchronization',
  name: 'tdd_sync',
  component: PTPEquipmentPanel,
  show({ choices }) {
    return getEquipmentFromChoices(choices).sync?.value !== 'NONE';
  },
  fields: [
    {
      attrName: 'sync_params.long_frame_duration',
      getter: 'local.radios.0.equipment.sync_params.long_frame_duration',
    },
    {
      attrName: 'sync_params.configured_burst_duration_p1',
      getter: 'local.radios.0.equipment.sync_params.configured_burst_duration_p1',
    },
    {
      attrName: 'sync_params.frame_duration',
      getter: 'local.radios.0.equipment.sync_params.frame_duration',
      nextValue(currentValue, newChoices) {
        const eq = getEquipmentFromChoices(newChoices);
        if (eq.sync_params == null) {
          return currentValue;
        }

        const values = eq.sync_params.frame_duration.choices.map((c) => {
          return c.value;
        });

        if (!values.includes(currentValue)) {
          return values[0];
        }

        return currentValue;
      },
    },
    {
      attrName: 'sync_params.slave_rx_tx_gap',
      getter: 'local.radios.0.equipment.sync_params.slave_rx_tx_gap',
    },
    {
      attrName: 'sync_params.phase1_end',
      getter: 'local.radios.0.equipment.sync_params.phase1_end',
    },
    {
      attrName: 'sync_params.tdd_frame_offset',
      getter: 'local.radios.0.equipment.sync_params.tdd_frame_offset',
    },
  ],
};

const endPerformanceOpts = {
  showE1T1: true,
  aggregateReadonly: ['minimum_data_rate_requirement'],
  aggregateHidden: ['minimum_reliability_requirement'],
};

const ptp700PanelConfig: PTPPanelConfigImpl = {
  syncFormState,
  equipment: [equipment, config, tdd],
  performance: {
    summary: {
      link: [genericLinkSummary({
        showE1T1: true,
      })],
      local: genericEndPerformanceSummary('local', endPerformanceOpts),
      remote: genericEndPerformanceSummary('remote', endPerformanceOpts),
    },
  },
  ends: {
    title: 'Configuration at Each End',
    local: genericEndConfig('local', {
      spatialDiversity: true,
      cableChoices: true,
      scanLoss: true,
      couplerLoss: true,
      diversitySpacing: true,
      reflectionMitigation: true,
    }),
    remote: genericEndConfig('remote', {
      spatialDiversity: true,
      cableChoices: true,
      scanLoss: true,
      couplerLoss: true,
      diversitySpacing: true,
      reflectionMitigation: true,
    }),
  },
};

export default ptp700PanelConfig;

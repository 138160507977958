import { get, merge } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Label } from 'semantic-ui-react';
import StoredAccordion from 'src/components/StoredAccordion';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import AntennaSelection from 'src/pages/equipment/common/AntennaSelection';
import { PMPPanelProps } from './utils';
import CalcTiltModal from 'src/components/controls/CalcTiltModal';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { postWithAuth } from 'src/api';
import { makeChoices } from 'src/utils/useful_functions';
import { checkErrors } from './configs/config-utils';
import ModeledBeamwidth from './ModeledBeamwidth';
import CustomTextField from 'src/components/controls/rhf/CustomTextField';
import { CUSTOM_ANTENNA_OTHER } from 'src/app.constants';
import { RootStateOrAny, store } from 'src/store';
import {
  openAntennaModal,
  setAntennaPolarities,
} from 'src/pages/antennas/antenna.reducer';

export const getWarningLabel = (content, modified) => {
  return modified ? (
    <Label pointing color={modified ? 'blue' : ''} basic={modified}>
      {content}
    </Label>
  ) : (
    <Label pointing prompt>
      {content}
    </Label>
  );
};

function NDAntennaPanel({
  ap,
  sm,
  intl,
  panel,
  choices,
  setModified,
  modified,
  refreshChoices,
}: PMPPanelProps & WrappedComponentProps) {
  const { control, getValues, setValue, getFieldState } = useFormContext();
  const { antennas } = choices;
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const errors = checkErrors({
    panel,
    getValues,
    getFieldState,
    ap,
    sm,
    setValue,
    choices,
  });
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const antennaId = getValues('radios.0.antennas.0.lp_antenna_id');
  return (
    <StoredAccordion
      name={`nd_${panel.name}`}
      title={panel.title}
      key={`nd_${panel.name}`}
      headerClass={errors ? 'custom-error' : ''}
      contentProps={{ loading: ap.dirty || sm?.dirty }}
    >
      {panel.fields.map((field) => {
        const {
          attrName,
          label,
          getter,
          component,
          max,
          min,
          reCalculate = false,
          choicesGetter,
          show,
          afterOnChange,
          tooltip,
          warning,
          refreshesChoices,
          defaultValue,
          editable = true,
          componentProps,
        } = field;
        if (
          show != null &&
          !show({ choices, formGetter: getValues, ap, formSetter: setValue })
        ) {
          return null;
        }

        let ctrl: any = [];
        let maxValue = max;
        let minValue = min;
        let derivedVal = sm != null ? get(sm, getter) : get(ap, getter);
        const warningContent =
          warning && warning({ ap, sm, choices, formGetter: getValues });
        let tooltipText =
          tooltip != null
            ? tooltip({ choices, formGetter: getValues, ap })
            : null;
        let editableField = editable;
        if (typeof max === 'function') {
          maxValue = max({ choices, formGetter: getValues, ap });
        }
        if (typeof min === 'function') {
          minValue = min({ choices, formGetter: getValues, ap });
        }
        if (typeof editable === 'function') {
          editableField = editable({ choices, formGetter: getValues, ap });
        }
        if (typeof defaultValue === 'function') {
          derivedVal = defaultValue({ choices, ap, formGetter: getValues });
        }
        let cprops: Record<string, any>;
        if (typeof componentProps === 'function') {
          cprops = componentProps({
            ap,
            sm,
            choices,
            formGetter: getValues,
            refreshChoices: refreshChoices,
            formSetter: setValue,
          });
        } else {
          cprops = componentProps;
        }
        if (component === Form.Select) {
          let choiceList = get(choices, choicesGetter);
          if (choiceList == null) {
            choiceList = getValues(field.choicesGetter);
          }
          choiceList = makeChoices(choiceList);
          if (choiceList.length > 1) {
            ctrl.push(
              <Controller
                key={getter}
                name={getter}
                render={({ field: { onChange, value, ...rest } }) => (
                  <Form.Select
                    label={label}
                    value={value != null ? value : derivedVal}
                    options={choiceList}
                    {...rest}
                    disabled={!permissionWrite}
                    onChange={(e, data) => {
                      onChange(data.value);
                      setModified(true);
                      if (refreshesChoices) {
                        refreshChoices({ field });
                      }
                      if (afterOnChange) {
                        afterOnChange(
                          data.value,
                          { getValues, setValue },
                          choices
                        );
                      }
                    }}
                  ></Form.Select>
                )}
              ></Controller>
            );
          } else if (choiceList.length === 1) {
            ctrl.push(
              <CustomTextField
                label={label}
                value={choiceList[0]['text']}
              ></CustomTextField>
            );
          }
        } else if (component === ModeledBeamwidth) {
          ctrl.push(
            <ModeledBeamwidth
              ap={ap}
              choices={choices}
              field={field}
              antennaId={antennaId}
              setModified={setModified}
            ></ModeledBeamwidth>
          );
        } else if (component === Form.Checkbox) {
          ctrl.push(
            <Controller
              key={getter}
              name={getter}
              defaultValue={derivedVal}
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Form.Checkbox
                  label={label}
                  checked={value}
                  onChange={(e, { checked }) => {
                    setModified(true);
                    onChange(checked);
                    if (refreshesChoices) {
                      refreshChoices({ field });
                    }
                    if (afterOnChange)
                      afterOnChange(checked, { getValues, setValue }, choices);
                  }}
                ></Form.Checkbox>
              )}
            ></Controller>
          );
        } else if (component === GenericScaledField) {
          ctrl.push(
            <GenericScaledField
              key={getter}
              disabled={!editableField || !permissionWrite}
              {...field}
              {...cprops}
              hoverMessage={tooltipText}
              max={maxValue}
              min={minValue}
              recalc={reCalculate && modified}
              onChange={(value) => {
                if (afterOnChange) {
                  afterOnChange(value, { getValues, setValue }, choices);
                }
              }}
              defaultValue={derivedVal}
              setModified={setModified}
              units={
                field.usePrefs != null ? prefs[field.usePrefs] : field.units
              }
            ></GenericScaledField>
          );
        } else if (component == AntennaSelection) {
          //Because of re-renders "other" is adding twice
          // so checking it before push
          if (
            choices.allow_custom_antennas &&
            antennas.filter((obj) => obj['id'] == CUSTOM_ANTENNA_OTHER.id)
              .length === 0
          ) {
            antennas.push(CUSTOM_ANTENNA_OTHER);
            store.dispatch(
              setAntennaPolarities({
                polarities: choices.supported_polarities,
              })
            );
          }

          ctrl.push(
            <Controller
              key={getter}
              control={control}
              name={getter}
              render={({ field: { onChange, value, ...rest } }) => (
                <AntennaSelection
                  kind={location.pathname.includes('/ap') ? 'ap' : 'sm'}
                  choices={antennas}
                  value={value}
                  onChange={(e, data) => {
                    onChange(data.value);
                    setModified(true);
                    if (data.value === CUSTOM_ANTENNA_OTHER.id) {
                      store.dispatch(openAntennaModal(null));
                    }
                    if (refreshesChoices) {
                      refreshChoices({ field });
                    }
                  }}
                  values={{}}
                  panels={[]}
                  warning={''}
                  modified={true}
                ></AntennaSelection>
              )}
            ></Controller>
          );
        } else if (component == CustomTextField) {
          ctrl.push(
            <CustomTextField
              modified={modified}
              {...field}
              {...cprops}
            ></CustomTextField>
          );
        }
        if (attrName === 'tilt' && minValue !== maxValue && sm == null) {
          ctrl.push(
            <CalcTiltModal
              intl={intl}
              min={minValue}
              max={maxValue}
              modified={modified}
              values={getValues()}
              applyTiltCallback={(selectedTilt, closeHandler) => {
                const tilt = getValues('radios[0].antennas[0].tilt');
                if (parseFloat(selectedTilt) === tilt) {
                  closeHandler();
                } else {
                  setValue(
                    'radios[0].antennas[0].tilt',
                    parseFloat(selectedTilt)
                  );
                  const formData = getValues();
                  /* if (
                    ap.site.latitude ==
                      get(formData, 'radios.0.antennas.0.latitude') &&
                    ap.site.longitude ==
                      get(formData, 'radios.0.antennas.0.longitude')
                  ) {
                    set(
                      formData,
                      'radios.0.antennas.0.latitude',
                      ap.site.latitude
                    );
                    set(
                      formData,
                      'radios.0.antennas.0.longitude',
                      ap.site.longitude
                    );
                  } */
                  const finalData = merge(ap, formData);
                  postWithAuth(
                    `project/${projectId}/access_point/${ap.id}`,
                    finalData,
                    'PUT'
                  ).then(() => {
                    setModified(false);
                    closeHandler();
                  });
                }
              }}
            />
          );
        }
        // we need to show the field in red color when we have tilt warning,
        // other cases we display the error popup

        return (
          <Form.Field key={field.getter} error={warningContent === true}>
            {ctrl}
            {warningContent != null &&
              warningContent != '' &&
              warningContent != true &&
              // Always set modified to false here so that the
              // antenna warning label does not appear blue
              getWarningLabel(warningContent, false)}
          </Form.Field>
        );
      })}
    </StoredAccordion>
  );
}

export default injectIntl(NDAntennaPanel);

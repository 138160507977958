import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, Segment, Step, Icon } from 'semantic-ui-react';
import messages from './../../../messages';
import _ from 'lodash';

function StepsWizard(props: {
  steps: {
    title: string;
    description: string;
    validated: boolean;
    content: JSX.Element;
  }[];
  createEntity(): Promise<any>;
  nextStepHandler(curStep): Promise<any>;
  prevStepHandler(curStep): Promise<any>;
  finalStepButtonName: string;
  intl: any;
  loading: boolean;
  editCase: any;
  setEditCase: Function;
  setCancelAction: Function;
}) {
  const {
    steps,
    createEntity,
    nextStepHandler,
    prevStepHandler,
    finalStepButtonName,
    loading,
    editCase,
    setEditCase,
    setCancelAction,
  } = props;

  const { formatMessage } = props.intl;

  const [step, setStep] = useState(1);

  const [loaders, setLoaders] = useState({
    nextButton: false,
    createButton: false,
  });

  let stepsLength: any = steps.length;

  const stepsTemplate = (
    <div>
      <Step.Group widths={stepsLength} ordered>
        {steps.map((curStep, index) => {
          return (
            <Step
              key={curStep.title}
              completed={step > index + 1}
              active={step === index + 1}
            >
              <Step.Content title={curStep.title} description="" />
            </Step>
          );
        })}
      </Step.Group>
    </div>
  );

  const nextStep = () => {
    if (step <= steps.length - 1) {
      setLoaders((prevState) => {
        return { ...prevState, nextButton: true };
      });
      nextStepHandler(step)
        .then((el) => {
          setStep(step + 1);
          setLoaders((prevState) => {
            return { ...prevState, nextButton: false };
          });
        })
        .catch(() =>
          setLoaders((prevState) => {
            return { ...prevState, nextButton: false };
          })
        );
    }
  };

  const prevStep = () => {
    if (step !== 1) {
      prevStepHandler(step).then((el) => {
        setStep(step - 1);
      });
    }
  };

  const create = () => {
    setLoaders((prevState) => {
      return { ...prevState, createButton: true };
    });
    createEntity()
      .then((resp) => {
        // setLoaders((prevState) => {
        //   return { ...prevState, createButton: false };
        // });
      })
      .catch((er) => {
        setLoaders((prevState) => {
          return { ...prevState, createButton: false };
        });
      });
  };

  const currentStep = steps.filter((el, index) => index + 1 === step)[0];

  const nextAndPrevButtons = (
    <Grid textAlign="right">
      <Grid.Column>
        <div>
          {editCase.value && (
            <Button
              icon
              onClick={_.debounce(
                () => {
                  setEditCase({ value: false, buttonName: 'Create' })
                
                  if(setCancelAction != null){
                    setCancelAction()
                  }
                },
                100
              )}
              
            >
              <Icon name="cancel" /> Cancel
            </Button>
          )}
          <Button
            labelPosition="left"
            icon="left chevron"
            content={formatMessage(messages.previous)}
            onClick={_.debounce(prevStep, 100)}
            disabled={step === 1 || loaders.createButton || loading}
          />
          {step !== steps.length ? (
            <Button
              labelPosition="right"
              loading={loaders.nextButton}
              primary
              icon="right chevron"
              content={formatMessage(messages.next)}
              disabled={!currentStep.validated}
              onClick={_.debounce(nextStep, 100)}
            />
          ) : (
            <Button
              labelPosition="right"
              loading={loaders.createButton}
              color="blue"
              icon="check"
              content={finalStepButtonName}
              disabled={loaders.createButton || !currentStep.validated}
              onClick={_.debounce(create, 200)}
            />
          )}
        </div>
      </Grid.Column>
    </Grid>
  );

  return (
    <div>
      {stepsTemplate}
      <Segment
        basic
        style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}
      >
        {currentStep.content}
      </Segment>
      {nextAndPrevButtons}
    </div>
  );
}

export default injectIntl(StepsWizard);

import { Form } from 'semantic-ui-react';
import { PMPFormField } from './nd/utils';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import {
  calculateNoiseDensity,
  interferenceInfoFormatter,
  powerFieldInfoFormatter,
} from './utils';
import LimitControl from '../ptp/LimitControl';
import { meterToAny } from 'src/utils/useful_functions';
import { isNumber } from 'lodash';
import { POWER_PANEL_FIELDS_WIDTH } from './nd/configs/config-utils';
import { bands } from 'src/model/BandDefinition';

export const pmp450iHAZLOC = [
  {
    key: 'A2',
    text: 'HAZLOC A / ATEX IIC  [Max. EIRP : 33.0 dBm]',
    value: 'A2',
  },
  {
    key: 'B2',
    text: 'HAZLOC B / ATEX IIC  [Max. EIRP : 33.0 dBm]',
    value: 'B2',
  },
  {
    key: 'C2',
    text: 'HAZLOC C / ATEX IIB  [Max. EIRP : 35.0 dBm]',
    value: 'C2',
  },
  {
    key: 'D2',
    text: 'HAZLOC D / ATEX IIA  [Max. EIRP : 37.5 dBm]',
    value: 'D2',
  },
];

export const band: PMPFormField = {
  attrName: 'band',
  getter: 'radios.0.equipment.band',
  afterOnChange(newValue, formMethods) {
    const { setValue } = formMethods;
    const noiseDensity = bands[newValue]['noiseDensity'];
    setValue('radios.0.power.noise_density', noiseDensity);
    setValue('radios.0.power.noise_density_sm', noiseDensity);
  },
};

export const product: PMPFormField = {
  attrName: 'product',
  getter: 'radios.0.equipment.product',
  refreshesChoices: true,
  warning({ sm, choices }): string {
    if (sm && choices) {
      if (
        choices.equipment.band.value === 'Lower 6 GHz' &&
        choices.equipment.product.value === 'PMP 450b6'
      ) {
        return `The maximum frequency at which the PMP 450b6 SM
                can operate is 6300 MHz, configure the product
                with appropriate channels during installation`;
      }
    }
  },
};

export const regulation: PMPFormField = {
  attrName: 'regulation',
  getter: 'radios.0.equipment.regulation',
  refreshesChoices: true,
};

export const smRangeBaseConfig: PMPFormField = {
  attrName: 'sm_range',
  getter: 'radios.0.equipment.sm_range',
  component: GenericScaledField,
  units(getValues) {
    return getValues('radios.0.equipment.range_units');
  },
  precision: 3,
};

export const gasGroup: PMPFormField = {
  label: 'Gas Group',
  attrName: 'gas_group',
  getter: 'radios.0.power.gas_group',
  component: Form.Select,
  show({ formGetter }) {
    const isHAZLOC =
      formGetter('radios.0.equipment.product') === 'PMP 450i ATEX/HAZLOC'
        ? true
        : false;
    return isHAZLOC;
  },
  componentProps({ formGetter }) {
    const gasGroup = formGetter('radios.0.power.gas_group') || 'A2';
    return { options: pmp450iHAZLOC, value: gasGroup };
  },
};

export const eirp: PMPFormField = {
  label: 'EIRP',
  attrName: 'eirp',
  getter: 'radios.0.power.eirp',
  component: GenericScaledField,
  nextValue(currentValue, newChoices, formGetter, attr) {
    return newChoices.power[0].eirp;
  },
  componentProps({ choices }) {
    const unitExtraTooltip = powerFieldInfoFormatter(
      choices,
      'power.0.maximum_eirp'
    );
    return {
      unitExtraTooltip,
    };
  },
  precision: 1,
  units: 'dBm',
  editable: false,
};

export const powerConfig: PMPFormField = {
  label: 'Power',
  attrName: 'power',
  getter: 'radios.0.power.power',
  component: GenericScaledField,
  nextValue(currentValue, newChoices, formGetter, attr) {
    return newChoices.power[0].power;
  },
  componentProps({ choices }) {
    const unitExtraTooltip = powerFieldInfoFormatter(
      choices,
      '0.power.maximum_power'
    );
    const cProps = {
      unitExtraTooltip,
    };
    return cProps;
  },
  precision: 1,
  units: 'dBm',
  editable: false,
};

// This config is used across ND, SM panels except the cnreach family
// so any changes here we need to do in cnreach config as well
export const getUserPower = (isSector, idx = 0): PMPFormField => {
  return {
    label: 'User Limit',
    attrName: `use_user_power`,
    component: LimitControl,
    checkboxGetter: `radios.${idx}.power.use_user_power`,
    valueGetter: `radios.${idx}.power.user_power`,
    checkboxRefreshChoices: true,
    componentProps({ choices, formGetter, refreshChoices }) {
      const props = {
        onAcceptChange: () => {
          const userPower = formGetter(`radios.${idx}.power.user_power`);
          if (isNumber(userPower)) {
            refreshChoices({
              field: {
                attrName: `radios.${idx}.power.user_power`,
              },
            });
          }
        },
      };
      return isSector ? { width: POWER_PANEL_FIELDS_WIDTH, ...props } : props;
    },
    min: ({ choices }) => {
      return choices.power[idx]?.user_power.min;
    },
    max: ({ choices }) => {
      return choices.power[idx]?.user_power.max;
    },
    units: 'dBm',
    precision: 1,
  };
};

export const interferenceChangeHandler = ({ formGetter, formSetter, idx }) => {
  const noise = formGetter(`radios.${idx}.power.noise`);
  const bw = formGetter(`radios.${idx}.equipment.bandwidth`);
  const noiseDensity = calculateNoiseDensity(noise, bw);
  formSetter(`radios.${idx}.power.noise_density`, noiseDensity);
};

export const interference: PMPFormField = {
  checkboxGetter: 'radios.0.power.use_noise',
  valueGetter: 'radios.0.power.noise',
  component: LimitControl,
  componentProps({ formGetter, formSetter, choices }) {
    const unitExtraTooltip = interferenceInfoFormatter(
      formGetter,
      'radios.0.equipment.bandwidth'
    );
    return {
      unitExtraTooltip,
      watch: [
        choices.equipment.band.value,
        // bandwidth doesnt exist in cnreach product, with ISM setting so safety check
        choices.equipment.bandwidth?.value,
      ],
      onAcceptChange: () => {
        interferenceChangeHandler({ formGetter, formSetter, idx: 0 });
      },
    };
  },
  units: 'dBm',
  precision: 1,
  min: -144.0,
  max: -40.0,
};

export const antennaHeight: PMPFormField = {
  label: 'Antenna Height',
  attrName: 'height',
  getter: 'radios.0.antennas.0.height',
  component: GenericScaledField,
  usePrefs: 'heightUnits',
  precision: 1,
  min: 0,
  max: 3000,
};

export const antennaHeightComponentProps = (apOrsm, formGetter) => {
  const siteMaxHeight = apOrsm.site.maximum_height;
  const result = {
    unitExtraTooltip: ({ units }) => {
      let height: number;
      if (units !== 'm') {
        height = meterToAny(siteMaxHeight, units, 1, false);
      } else {
        height = siteMaxHeight;
      }
      return ` (Max height at site is ${height.toFixed(1)}${units})`;
    },
  };
  let formHeight = formGetter(`radios.0.antennas.0.height`);
  if (typeof formHeight === 'string') {
    formHeight = parseFloat(formHeight);
  }

  if (siteMaxHeight != null && formHeight > siteMaxHeight) {
    result.textColor = 'red';
  }
  return result;
};

export const calculateLoss: PMPFormField = {
  label: 'Calculate Loss?',
  attrName: 'calculate_loss',
  getter: 'radios.0.antennas.0.cabling.feeder_calculate',
  component: Form.Checkbox,
  refreshesChoices: true,
  afterOnChange(checked, { setValue, getValues }, choices) {
    if (checked) {
      setValue(
        'radios.0.antennas.0.cabling.feeder_type',
        choices.cabling.default_feeder_type
      );
    }
  },
  show: ({ choices }) => {
    return !choices.cabling.internal && choices.cabling.allow_feeder_calculate;
  },
};

export const cableChoices: PMPFormField = {
  attrName: 'cable_choices',
  getter: 'radios.0.antennas.0.cabling.feeder_type',
  choicesGetter: 'cabling.cable_choices',
  refreshesChoices: true,
  component: Form.Select,
  show: ({ choices, formGetter }) => {
    return (
      !choices.cabling.internal &&
      choices.cabling.allow_feeder_calculate &&
      formGetter('radios.0.antennas.0.cabling.feeder_calculate')
    );
  },
  nextValue(currentValue, newChoices, formGetter) {
    return currentValue != null
      ? currentValue
      : newChoices.cabling.default_feeder_type;
  },
};

export const cableLength: PMPFormField = {
  attrName: 'cable_length',
  label: 'Cable Length',
  getter: 'radios.0.antennas.0.cabling.feeder_length',
  refreshesChoices: true,
  component: GenericScaledField,
  min: 0,
  max: 100,
  usePrefs: 'heightUnits',
  precision: 1,
  editable({ choices }) {
    return choices.cabling.fixed_feeder_length.length == 0;
  },
  show({ formGetter, choices }) {
    return (
      !choices.cabling.internal &&
      choices.cabling.allow_feeder_calculate &&
      formGetter('radios.0.antennas.0.cabling.feeder_calculate')
    );
  },
  componentProps({ formGetter, refreshChoices }) {
    return {
      onAcceptChange: () => {
        const cableLength = formGetter(
          'radios.0.antennas.0.cabling.feeder_length'
        );
        if (isNumber(cableLength)) {
          refreshChoices({
            field: {
              attrName: 'radios.0.antennas.0.cabling.feeder_length',
            },
          });
        }
      },
    };
  },
};

export const cnReachPowerConfig: PMPFormField = {
  ...powerConfig,
  units: 'mW',
  precision: 0,
  getter: 'radios.0.power.power_mw',
  attrName: 'power_mw',
  nextValue(currentValue, newChoices, formGetter, attr) {
    return newChoices.power[0].power_mw;
  },
};

export const cnReachUserPowerConfig: PMPFormField = {
  label: 'User Limit',
  component: LimitControl,
  attrName: 'use_user_power_mw',
  checkboxGetter: 'radios.0.power.use_user_power_mw',
  valueGetter: 'radios.0.power.user_power_mw',
  checkboxRefreshChoices: true,
  units: 'mW',
  min({ choices }) {
    return choices.power[0].user_power_mw?.min;
  },
  max({ formGetter }) {
    return formGetter('radios.0.power.maximum_power_mw');
  },
  defaultValue({ formGetter }) {
    const powermw = formGetter('radios.0.power.power_mw');
    const maxPower = formGetter('radios.0.power.maximum_power_mw');
    return powermw != null ? powermw : maxPower;
  },
  componentProps({ choices, formGetter, refreshChoices }) {
    return {
      onAcceptChange: () => {
        const userPower = formGetter('radios.0.power.user_power_mw');
        if (isNumber(userPower)) {
          refreshChoices({
            field: {
              attrName: 'radios.0.power.user_power_mw',
            },
          });
        }
      },
    };
  },
};

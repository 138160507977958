import { get } from 'lodash';
import { PMPFormPanel, syncFormState } from '../utils';
import NDEquipmentPanel from '../NDEquipmentPanel';
import NDAntennaPanel from '../NDAntennaPanel';
import NDPowerPanel from '../NDPowerPanel';
import {
  antennaHeightND,
  antennaSelection,
  cableLoss,
  equipmentPanelFields,
  framePeriod,
  smRegistrationLimit,
  modeledBeamwidth,
  smRangeFields,
  smReceiveTargetLevel,
  ndInterference,
  smDefaultInterference,
  bandwidth,
  azimuth,
  tilt,
  getNDUserPower,
} from './config-utils';
import { eirp, powerConfig } from '../../config-utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'nd_equipment',
  component: NDEquipmentPanel,
  fields: [
    ...equipmentPanelFields,
    {
      attrName: 'operating_mode',
      getter: 'radios.0.equipment.operating_mode',
    },
  ],
};

const product: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'nd_Product',
  component: NDEquipmentPanel,
  fields: [
    bandwidth,
    ...smRangeFields,
    {
      attrName: 'pmp_max_mod_mode',
      getter: 'radios.0.equipment.pmp_max_mod_mode',
    },
    {
      attrName: 'dl_ul_ratio',
      getter: 'radios.0.equipment.dl_ul_ratio',
    },
    framePeriod,
    smRegistrationLimit,
    {
      attrName: 'guard_interval',
      getter: 'radios.0.equipment.guard_interval',
    },
    {
      label: 'Synchronization Source',
      attrName: 'sync_source',
      getter: 'radios.0.equipment.sync_source',
      refreshesChoices: true,
      warning: ({ ap, formGetter }) => {
        const syncSource = formGetter('radios.0.equipment.sync_source');
        if (!get(ap, 'site.has_tx2000_switch') && syncSource === 'TX2000')
          return 'Please add a TX2000 switch to the site.';
        return '';
      },
    },
  ],
};

const antenna: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'nd_antenna',
  component: NDAntennaPanel,
  fields: [
    antennaSelection,
    modeledBeamwidth,
    antennaHeightND,
    azimuth,
    tilt,
    cableLoss,
  ],
};

const power: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'nd_power',
  component: NDPowerPanel,
  fields: [
    eirp,
    powerConfig,
    getNDUserPower(false),
    smReceiveTargetLevel,
    ndInterference,
    smDefaultInterference,
  ],
};

export const ePMPViewConfig = {
  syncFormState,
  equipment,
  product,
  antenna,
  power,
};

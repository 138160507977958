import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useFormContext, useWatch } from 'react-hook-form';
import { merge, range } from 'lodash';
import { FormField, Table } from 'semantic-ui-react';
import ComponentCarrier from './ComponentCarrier';
import messages from 'src/messages';
import StoredAccordion from 'src/components/StoredAccordion';
import { PMPPanelProps } from './utils';

type NDComponentCarriersPanelProps = {
  intl: any;
  isTemplate: boolean;
} & PMPPanelProps;

function NDComponentCarriersPanel(props: NDComponentCarriersPanelProps) {
  const { panel, ap, intl, isTemplate, choices } = props;
  const { formatMessage } = intl;
  const { title, name } = panel;
  const { getValues, setValue } = useFormContext();
  const carrierQty = useWatch({ name: 'radios.0.equipment.carrier_qty' });
  const bandCarrier2 = useWatch({ name: 'radios.0.equipment.band_carrier_2' });
  const componentCarriers = range(carrierQty);

  useEffect(() => {
    setValue('radios.1.equipment.band', bandCarrier2);
  }, [bandCarrier2]);

  useEffect(() => {
    if (carrierQty > 1) {
      // When we switch from 60 GHz band to pmp450v product
      // radio1 exists, so we need to reset/merge with radio0
      // data.
      //TODO: we need to extend this when go beyond 2 carrier
      // we should handle this at root level like NDPanel or sync
      // from state etc
      const radio0 = getValues('radios.0');
      let radio1 = getValues('radios.1.equipment');
      let radio1band = getValues('radios.1.equipment.band');
      if (radio1band === '60 GHz' || radio1band == null) {
        radio1 = null;
      }
      if (!radio1) {
        radio1 = {};
        radio1 = merge(radio1, radio0);
        setValue('radios.1', radio1);
        setValue('radios.1.frequency.tx_frequency', null);
      }
    } else if (carrierQty == 1) {
      setValue('radios', [getValues('radios.0')]);
    }
  }, [carrierQty, JSON.stringify(choices)]);

  return (
    <StoredAccordion
      name={name}
      title={title}
      contentProps={{ loading: ap.dirty }}
    >
      <FormField inline>
        <label>Component Carriers</label>
        <label>{carrierQty}</label>
        <Table>
          <Table.Header style={{ fontSize: '0.818em' }}>
            <Table.Row>
              <Table.HeaderCell>
                Component <br></br> Carrier
              </Table.HeaderCell>
              <Table.HeaderCell>
                {formatMessage(messages.carrier)}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {formatMessage(messages.band)}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {formatMessage(messages.bandwidth)}
              </Table.HeaderCell>
              {!isTemplate && (
                <Table.HeaderCell>
                  {formatMessage(messages.frequency)}
                  <br></br>(MHz)
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>
                ND Interference <br></br>(dBm in b/w)
              </Table.HeaderCell>
              <Table.HeaderCell>
                Default SM Interference <br></br>(dBm in b/w)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {componentCarriers.map((cc, index) => {
              return (
                <ComponentCarrier
                  key={`cc-${index}`}
                  {...props}
                  index={index}
                  isTemplate={isTemplate}
                ></ComponentCarrier>
              );
            })}
          </Table.Body>
        </Table>
      </FormField>
    </StoredAccordion>
  );
}

export default injectIntl(NDComponentCarriersPanel);

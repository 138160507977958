import React from 'react';
import { injectIntl } from 'react-intl';
import { Label, Table } from 'semantic-ui-react';
import { PERCENT, THROUGHPUT } from 'src/app.constants';
import messages from 'src/messages';
import { adjustPrecision } from 'src/utils/useful_functions';

function AccessPointPerformanceSector({
  intl,
  aggregates,
  data,
  warning = null,
  throughputUnits = 'Mbps',
  floodTestReport = null,
}) {
  const { formatMessage } = intl;
  const showDryMuMIMO = floodTestReport && 'mumimo_total' in floodTestReport;
  return (
    <>
      <Table celled structured compact size="small">
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell rowSpan="2">Mode</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">
              {formatMessage(messages.smsPerDLModulation)}
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="3">
              {formatMessage(messages.smsPerULModulation)}
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="1">
              {formatMessage(messages.apMeanThroughput)}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Table.HeaderCell>
              {formatMessage(messages.quantity)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {formatMessage(messages.percent)} (%)
            </Table.HeaderCell>
            <Table.HeaderCell>
              {formatMessage(messages.throughtput)} ({throughputUnits})
            </Table.HeaderCell>
            <Table.HeaderCell>
              {formatMessage(messages.quantity)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {formatMessage(messages.percent)} (%)
            </Table.HeaderCell>
            <Table.HeaderCell>
              {formatMessage(messages.throughtput)} ({throughputUnits})
            </Table.HeaderCell>
            <Table.HeaderCell>
              {formatMessage(messages.throughtput)} ({throughputUnits})
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((obj) => {
            return (
              <Table.Row textAlign="center">
                <Table.Cell width={2}>{obj.mode}</Table.Cell>
                <Table.Cell width={1}>{obj.downLink.quantity}</Table.Cell>
                <Table.Cell width={2}>
                  {adjustPrecision(obj.downLink.percent, PERCENT)}
                </Table.Cell>
                <Table.Cell width={2}>
                  {adjustPrecision(obj.downLink.throughput, THROUGHPUT)}
                </Table.Cell>
                <Table.Cell width={1}>{obj.upLink.quantity}</Table.Cell>
                <Table.Cell width={2}>
                  {adjustPrecision(obj.upLink.percent, PERCENT)}
                </Table.Cell>
                <Table.Cell width={2}>
                  {adjustPrecision(obj.upLink.throughput, THROUGHPUT)}
                </Table.Cell>
                <Table.Cell width={2}>
                  {adjustPrecision(obj.mean.throughput, THROUGHPUT)}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Table attached="bottom" singleLine>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={2}>Total</Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {aggregates.downLink.quantity}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {adjustPrecision(aggregates.downLink.percent, PERCENT)} %
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {adjustPrecision(aggregates.downLink.throughput, THROUGHPUT)}{' '}
              {throughputUnits}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {aggregates.upLink.quantity}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {adjustPrecision(aggregates.upLink.percent, PERCENT)} %
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {adjustPrecision(aggregates.upLink.throughput, THROUGHPUT)}{' '}
              {throughputUnits}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {adjustPrecision(aggregates.mean.throughput, THROUGHPUT)}{' '}
              {throughputUnits}
            </Table.HeaderCell>
          </Table.Row>
          {showDryMuMIMO && (
            <Table.Row textAlign="center">
              <Table.HeaderCell colSpan="3" textAlign="left">
                Instantaneous Dry Mu-MIMO
              </Table.HeaderCell>
              <Table.HeaderCell>
                {floodTestReport.mumimo_dl}&nbsp;Mbps
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="2">&nbsp;</Table.HeaderCell>
              <Table.HeaderCell>
                {floodTestReport.mumimo_ul}&nbsp;Mbps
              </Table.HeaderCell>
              <Table.HeaderCell>
                {floodTestReport.mumimo_total}&nbsp;Mbps
              </Table.HeaderCell>
            </Table.Row>
          )}
        </Table.Header>
      </Table>
      {warning != null && warning !== '' && (
        <Label
          basic
          style={{
            display: 'block',
            color: '#9F3A38',
            border: '1px solid #E0B4B4',
            marginTop: '20px',
          }}
        >
          {warning}
        </Label>
      )}
    </>
  );
}

export default injectIntl(AccessPointPerformanceSector);

import { get } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ProfileEditor from 'src/components/profilechart/ProfileEditorModal';

function PMPProfileChart({
  profile,
  sm,
  local,
  remote,
  setModified,
  parentFormSubmitHandler,
  modified,
}) {
  const { getValues } = useFormContext();
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const remoteHeight = getValues('radios.0.antennas.0.height');
  const profileProps = {
    accordionName: 'SubscriberPanelProfile',
    profileData: profile,
    localHeight: local.height,
    remoteHeight,
    sm,
    // Unlike on PTP, we don't have a path here (but we should).
    // Hack one in for now so that the profile works.
    path: {},
    frequencyGHz: get(sm, 'radios.0.frequency.frequency_mhz', 5800) / 1000,
    rangeUnits: prefs.rangeUnits,
    heightUnits: prefs.heightUnits,
    localSiteName: local.site_name,
    remoteSiteName: remote.site_name,
    //errMsg: profileData.errMsg,
    loading: false,
    kind: 'pmp',
    modified,
    setModified,
    parentFormSubmitHandler,
  };
  return <ProfileEditor {...profileProps} />;
}

export default PMPProfileChart;

import * as d3 from 'd3';

import { pyround } from 'src/utils/useful_functions';
import { Capacity, Y_AXIS_MAP } from './PerformanceChart.data';
import { Unit } from './PerformanceChart.types';

const truncateDecimal = (value: number, decimal: number): number => {
  if (Number.isInteger(value)) return value;

  const valueString = value.toString();
  return parseFloat(valueString.slice(0, valueString.indexOf('.') + decimal));
};

export const formatCapacityLabel = (
  capacity: number,
  unit: string,
  decimal: number = 2
): string => {
  const capacityValue =
    unit === Capacity.Gbps ? capacity : pyround(capacity, 0);
  const capacityWithDecimal = capacityValue.toFixed(decimal);

  return Number.isInteger(capacityValue)
    ? capacityValue.toString()
    : capacityWithDecimal;
};

export const formatAvailabilityLabel = (availability: number): string => {
  // Make sure availability has always 4 decimal places
  return availability.toFixed(4);
};

export const getMaxFilteredAvailabilityAndCapacity = ({
  availability,
  capacity,
}) => {
  const availabilityList = [];
  const capacityList = [];

  availability.forEach((subArray, i) => {
    const maxCapacityForAvailability = {};
    subArray.forEach((avail, index) => {
      const truncatedAvail = truncateDecimal(avail, 6);
      const capacityItem = capacity[i][index];
      maxCapacityForAvailability[truncatedAvail] = Math.max(
        maxCapacityForAvailability[truncatedAvail] || 0,
        capacityItem
      );
    });

    const subArrayFilteredAvailability = Object.keys(maxCapacityForAvailability)
      .map(Number)
      .sort((a, b) => a - b);
    const subArrayFilteredCapacity = subArrayFilteredAvailability.map(
      (avail) => maxCapacityForAvailability[avail]
    );

    availabilityList.push(subArrayFilteredAvailability);
    capacityList.push(subArrayFilteredCapacity);
  });

  return { availabilityList, capacityList };
};

// The Y_AXIS_MAP values are used for the chart Y tick marks.
// the chart uses a 10 "value" spacing for Y and the tick labels
// show the expected log values for 90.0%, 99.0%, 99.9%, etc.
// When we convert the availability to a Y value for display on the
// chart we need to scale the value into a log scale between 0 and 70,
// since this is the current range of values that are used for the Y axis.
const maxYValue = Math.max(...Object.keys(Y_AXIS_MAP).map(parseFloat));

export const getAvailabilityChartValue = (availability: number): number => {
  // The desktop chart uses an inverted logarithmic scale for the Y axis.
  // This results in minor tick marks that are closer together at smaller values
  // within a tick block compared to a standard logarithmic scale which has
  // minor ticks that are closer together at higher values within a major block.

  // Since the axis values need to be inverted, convert the availability
  // to an unavailability. Ensure that the value doesn't stray out of the bounds.
  const minAvailability = 0.00001;
  const maxAvailability = 100;

  const unavailability = Math.min(
    maxAvailability,
    Math.max(minAvailability, maxAvailability - availability)
  );
  // Use scaling to get the appropriate Y value (between 0 and 70) for the unavailability.
  return (
    maxYValue -
    d3.scaleLog(
      [minAvailability, maxAvailability],
      [0, maxYValue]
    )(unavailability)
  );
};

export const getCapacityValue = (capacity: number, unit: Unit): number => {
  return unit === 'Gbps' ? +capacity.toFixed(2) : truncateDecimal(capacity, 2);
};

export const showChartCrosshairs = ({ focus, xPos, yPos }) => {
  // X Crosshair
  focus.select('line.crosshair-x').attr('y1', yPos).attr('y2', yPos);

  // Y Crosshair
  focus.select('line.crosshair-y').attr('x1', xPos).attr('x2', xPos);
};

export const showChartTooltip = ({
  chartRef,
  highlightedStep,
  tooltip,
  tooltipContent,
  xPos,
  yPos,
  type,
}) => {
  // Y tick labels width
  const yTickLabelsNode = d3.select(`.y.axis.labels`).node();
  const yTickLabelsWidth = yTickLabelsNode.getBoundingClientRect().width;

  // Rect events height
  const rectEventsNode = d3.select(`.y.axis.labels`).node();
  const rectEventsHeight = rectEventsNode.getBoundingClientRect().height;

  const { offsetWidth: chartWidth, offsetHeight: chartHeight } =
    chartRef.current ?? {};
  const tooltipNode = d3.select(`.${type}.tooltip`).node();
  const tooltipRect = tooltipNode.getBoundingClientRect();
  const tooltipMinDimensions = { width: 170, height: 30 };
  const tooltipWidth = tooltipRect.width || tooltipMinDimensions.width;
  const tooltipHeight = tooltipRect.height || tooltipMinDimensions.height;

  // Calculate tooltip x position
  const xPadding = 30;
  const xRemainder = (-yTickLabelsWidth / chartWidth) * xPos + yTickLabelsWidth;
  const tooltipLeftPos = xPos + xRemainder + xPadding;
  const tooltipLeft =
    xPos >= chartWidth * 0.4 && type === 'remote'
      ? tooltipLeftPos - (tooltipWidth + xPadding)
      : tooltipLeftPos;

  // Calculate tooltip y position
  const yPadding = 10;
  const chartYSpaceHeight = chartHeight - rectEventsHeight;
  const yRemainder =
    (-chartYSpaceHeight / chartHeight) * yPos + chartYSpaceHeight;
  const tooltipTop = yPos + yRemainder - yPadding - tooltipHeight;

  tooltip
    .html(
      tooltipContent(
        highlightedStep.capacityB,
        highlightedStep.availabilityRealValue,
        highlightedStep.layer
      )
    )
    .style('left', `${tooltipLeft}px`)
    .style('top', `${tooltipTop}px`)
    .style('display', 'block')
    .style('pointer-events', 'none');
};

export const highlightChartStep = ({
  sortedCapacityA,
  tooltipContent,
  tooltip,
  chartRef,
  height,
  focus,
  type,
  xPos,
  yPos,
  x0,
  y0,
  x,
  y,
}) => {
  // Filter steps where mouse position is within the capacity range
  // and is less than or equal to availabilityChartValue
  const filteredSteps = sortedCapacityA.filter(
    (d) =>
      x0 >= d.capacityA && x0 <= d.capacityB && y0 <= d.availabilityChartValue
  );

  // Find the closest step based on the closest
  // higher availabilityChartValue first and capacityB second
  let highlightedStep = null;
  let minYDistance = Infinity;
  let minXDistance = Infinity;

  filteredSteps.forEach((step) => {
    const yDistance = step.availabilityChartValue - y0;
    const xDistance = step.capacityB - x0;

    if (
      yDistance < minYDistance ||
      (yDistance === minYDistance && xDistance < minXDistance)
    ) {
      minYDistance = yDistance;
      minXDistance = xDistance;
      highlightedStep = step;
    }
  });

  if (highlightedStep) {
    // Highlight step chart
    focus
      .select('rect.highlight')
      .attr(
        'width',
        `${x(highlightedStep.capacityB) - x(highlightedStep.capacityA)}`
      )
      .attr('height', `${height - y(highlightedStep.availabilityChartValue)}`)
      .attr(
        'transform',
        `translate(${x(highlightedStep.capacityA)}, ${y(
          highlightedStep.availabilityChartValue
        )})`
      );

    // Display chart highlight tooltip
    showChartTooltip({
      chartRef,
      highlightedStep,
      tooltip,
      tooltipContent,
      xPos,
      yPos,
      type,
    });
  } else {
    // Hide the step highlight and tooltip
    focus.select('rect.highlight').attr('width', 0).attr('height', 0);
    tooltip.style('display', 'none');
  }
};

import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContentProps,
  AccordionProps,
  AccordionTitleProps,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { STORE_PREFIX } from '../../app.constants';
import LocalStorageUtils from '../../utils/LocalStorageUtils';

export type AccordionType = {
  titleProps: AccordionTitleProps;
  contentProps: AccordionContentProps;
  key: string;
};

export type CustomAccordionType = {
  accordions: AccordionType[];
  accordionProps?: AccordionProps;
  defaultOpen?: string[];
  customClass?: string;
  saveSettings?: boolean;
};

function CustomAccordion({
  accordions,
  accordionProps,
  defaultOpen = ['ALL'],
  customClass,
  saveSettings = true,
}: CustomAccordionType) {
  const [active, setActive] = useState([]);
  const click = (e, titleProps) => {
    const { index } = titleProps;
    const storeKey = `${STORE_PREFIX}.${index}.collapsed`;

    const updated = Array.from(active);
    const currentIndexPosition = active.indexOf(index);
    // Update state & localstore
    if (currentIndexPosition > -1) {
      saveSettings && LocalStorageUtils.setItem(storeKey, true);
      updated.splice(currentIndexPosition, 1);
    } else {
      saveSettings && LocalStorageUtils.removeItem(storeKey);
      updated.push(index);
    }
    if (!saveSettings)
      sessionStorage.setItem(
        'create_pmp_ND_expanded_panels',
        JSON.stringify(updated)
      );
    setActive(updated);
  };

  const isPrevStateExists = (keys) => {
    let found = false;
    keys.forEach((key) => {
      if (LocalStorageUtils.getItem(`${STORE_PREFIX}.${key}.collapsed`)) {
        found = true;
      }
    });
    return found;
  };

  useEffect(() => {
    const keys = accordions.map(({ key }) => key);
    if (saveSettings && isPrevStateExists(keys)) {
      const prevOpened = [];
      keys.forEach((key) => {
        const storeKey = `${STORE_PREFIX}.${key}.collapsed`;
        if (!LocalStorageUtils.getItem(storeKey)) {
          prevOpened.push(key);
        }
      });
      setActive(prevOpened);
    } else if (
      !saveSettings &&
      sessionStorage.getItem('create_pmp_ND_expanded_panels') !== null
    ) {
      setActive(
        JSON.parse(sessionStorage.getItem('create_pmp_ND_expanded_panels'))
      );
    } else {
      defaultOpen.includes('ALL') ? setActive(keys) : setActive(defaultOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordions]);

  return (
    <Accordion
      exclusive={false}
      {...accordionProps}
      fluid
      className={customClass}
    >
      {accordions.map((obj: AccordionType, index: number) => {
        const { content, ...rest } = obj.titleProps;
        return (
          <React.Fragment key={index}>
            <Accordion.Title
              index={obj.key}
              size={'tiny'}
              as={Header}
              attached="top"
              block
              {...rest}
              onClick={click}
            >
              {content}
              <Icon
                name={
                  active.includes(obj.key) ? 'chevron down' : 'chevron left'
                }
                style={{ float: 'right' }}
                title={active ? 'Collapse panel' : 'Expand panel'}
              />
            </Accordion.Title>
            <Accordion.Content
              as={Segment}
              active={active.includes(obj.key)}
              attached
              {...obj.contentProps}
            ></Accordion.Content>
          </React.Fragment>
        );
      })}
    </Accordion>
  );
}

export default CustomAccordion;

import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import SMPerformanceSummaryEnds from './SMPerformanceSummaryEnds';
import SMPerformanceSummaryLink from './SMPerformanceSummaryLink';

function SMPerformanceSummary({
  modified,
  setModified,
  sm,
  local,
  remote,
  results,
  choices,
}) {
  const { summary, warnings } = results;
  const { local: summaryL, remote: summaryR, link } = summary;
  return (
    <>
      {modified && (
        <Segment inverted className="update-required">
          Apply your changes to update the performance summary
        </Segment>
      )}
      <Segment basic disabled={sm.dirty} loading={sm.dirty}>
        <Grid style={{ columnGap: '1rem' }} stackable>
          <Grid.Column width={9}>
            <SMPerformanceSummaryEnds
              local={summaryL}
              localSiteName={local.site_name}
              remoteSiteName={remote.site_name}
              remote={summaryR}
              warnings={warnings}
              choices={choices}
              setModified={setModified}
            ></SMPerformanceSummaryEnds>
          </Grid.Column>
          <Grid.Column width={6}>
            <SMPerformanceSummaryLink link={link}></SMPerformanceSummaryLink>
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
}

export default SMPerformanceSummary;

import React from 'react';
import {
  Button,
  ButtonProps,
  Icon,
  Checkbox,
  CheckboxProps,
  Dropdown,
  Popup,
} from 'semantic-ui-react';
import { GridApi } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { GREY_COLOR } from 'src/app.constants';
import { RootStateOrAny } from 'src/store';

export interface ToolbarType {
  icon: any;
  label?: any;
  title?: string;
  type?: string;
  disabled?: boolean;
  id?: string;
  defaultChecked?: boolean;
  onClick?: (
    event: any,
    data: ButtonProps,
    clickHandlerParams: ToolbarsType['clickHandlerParams']
  ) => void;
  onChange?: (
    event: any,
    data: CheckboxProps,
    changeHandlerParams: ToolbarsType['changeHandlerParams']
  ) => void;
  // Define items for drop-down menus
  items?: ToolbarType[];
}

export interface ToolbarsType {
  actions: ToolbarType[];
  extraActions?: ToolbarType[];
  clickHandlerParams?: { gridApi: GridApi };
  changeHandlerParams?: { gridApi: GridApi };
  rowDataLength?: number;
}

function Toolbar({
  actions = [],
  extraActions = [],
  clickHandlerParams,
  changeHandlerParams,
}: ToolbarsType) {
  const { created } = useSelector((state: RootStateOrAny) => state?.bestServer);
  const isCreatingLinks = created !== null;
  const { accessPoints } = useSelector(
    (state: RootStateOrAny) => state?.mainFrame
  );
  const anyDirtyAPs = accessPoints?.features.some(
    (f) => f.properties.strokeColor === GREY_COLOR // this is dirty gray color
  );

  let allActions = actions;
  if (extraActions?.length > 0) {
    allActions = allActions.concat(extraActions);
  }

  const controls = allActions.map((action: ToolbarType) => {
    switch (action.type) {
      case 'checkbox':
        return (
          <Checkbox
            key={action.label}
            label={<label title={action.title}>{action.label}</label>}
            title={action.title}
            disabled={action.disabled ?? false}
            onChange={(event, data) =>
              action.onChange(event, data, changeHandlerParams)
            }
            defaultChecked={action.defaultChecked ?? false}
          />
        );

      case 'dropdown':
        // Dropdown menu
        return (
          <Popup
            content={action.title}
            disabled={!action.title}
            trigger={
              <span>
                <Dropdown
                  key={action.label}
                  button
                  text={action.label}
                  disabled={action.disabled}
                >
                  <Dropdown.Menu>
                    {action.items.map((item) => (
                      <Dropdown.Item onClick={item.onClick} title={item.title}>
                        {item.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            }
          />
        );

      default:
        if (action.label) {
          // Button with icon and a label
          return (
            <Popup
              content={action.title}
              disabled={!action.title}
              trigger={
                <span>
                  <Button
                    type="button"
                    key={action.label}
                    icon
                    labelPosition="left"
                    onClick={(event, data) =>
                      action.onClick(event, data, clickHandlerParams)
                    }
                    disabled={
                      anyDirtyAPs ||
                      isCreatingLinks ||
                      (action.disabled ?? false)
                    }
                  >
                    <Icon name={action.icon} />
                    {action.label}
                  </Button>
                </span>
              }
            />
          );
        } else {
          // Icon button with no label
          return (
            <Popup
              content={action.title}
              disabled={!action.title}
              trigger={
                <span>
                  <Button
                    type="button"
                    key={action.icon}
                    icon={action.icon}
                    onClick={(event, data) =>
                      action.onClick(event, data, clickHandlerParams)
                    }
                    disabled={
                      anyDirtyAPs ||
                      isCreatingLinks ||
                      (action.disabled ?? false)
                    }
                  />
                </span>
              }
            />
          );
        }
    }
  });

  return <div className="mb-2">{controls}</div>;
}

export default Toolbar;

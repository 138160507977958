import React, { useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import additionalMessages from '../../messages';
import CustomAccordion from '../../components/controls/CustomAccordion';
import SiteProperties from './SiteProperties';
import LPGrid from 'src/components/controls/lpgrid/LPGrid';
import { GridRefObject } from 'src/utils/types/commonTypes';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import SitePerformanceSummary from './SitePerformanceSummary';

/*
 * Return the sub panels for the Network Sites
 */
const getNetworkSitePanels = (
  props,
  ptpLinksGridRef,
  accessPointsGridRef,
  prefs,
  projectName
) => {
  const { siteId, name } = props;
  const { formatMessage } = props.intl;
  const { aps, paths } = props.graph;
  let ptpLinks = [];
  if (paths) {
    ptpLinks = paths.filter((path) => {
      return path.kind == 'ptp';
    });
  }
  const ptpTableData = ptpLinks.map((link) => {
    const otherName =
      link.local_site_id === siteId
        ? link.remote_site_name
        : link.local_site_name;
    return {
      otherName,
      id: link.id,
      band: link.band,
    };
  });
  return [
    {
      key: 'ptpLinks',
      titleProps: {
        content: formatMessage(additionalMessages.ptpLinks),
      },
      contentProps: {
        content: (
          <LPGrid
            autoSize={false}
            gridRef={ptpLinksGridRef}
            rowData={ptpTableData}
            defaultCsvExportParams={{
              fileName: `${projectName}_network_sites_${name}_ptp_links.csv`,
            }}
            addStatusColor={true}
            columnDefs={[
              {
                headerName: 'Remote End',
                field: 'otherName',
                sort: 'asc',
                cellRenderer: 'linkRenderer',
                cellRendererParams: {
                  getHref: (data) => `/ptp/${data.id}`,
                },
              },
              {
                field: 'band',
              },
            ]}
          ></LPGrid>
        ),
      },
    },
    {
      key: 'PerformanceSummaryOfNetworkSite',
      titleProps: {
        content: formatMessage(additionalMessages.performanceSummaryOfND),
      },
      contentProps: { content: <SitePerformanceSummary {...props} /> },
    },
    {
      key: 'NetworkSiteAccessPoints',
      titleProps: {
        content: formatMessage(additionalMessages.accessPoints),
      },
      contentProps: {
        content: (
          <LPGrid
            autoSize={false}
            gridRef={accessPointsGridRef}
            rowData={aps || []}
            defaultCsvExportParams={{
              fileName: `${projectName}_network_sites_${name}_pmp_network_devices.csv`,
            }}
            addStatusColor={true}
            columnDefs={[
              {
                field: 'name',
                sort: 'asc',
                cellRenderer: 'linkRenderer',
                cellRendererParams: {
                  getHref: (data) => `/aps/${data.id}`,
                },
              },
              {
                field: 'product',
              },
            ]}
          ></LPGrid>
        ),
      },
    },
  ];
};

/*
 * Return the sub panels for the Subscriber Sites
 */
const getSubscriberSitePanels = (props, ref, prefs, projectName) => {
  const { name } = props;
  const { formatMessage } = props.intl;
  const { paths } = props.graph;
  if (paths) {
    const pmpLinks = paths.filter((p) => p.kind === 'pmp');
    return [
      {
        key: 'SubscriberSitePMPLinks',
        titleProps: {
          content: formatMessage(additionalMessages.pmpLinks),
        },
        contentProps: {
          content: (
            <LPGrid
              gridRef={ref}
              autoSize={false}
              rowData={pmpLinks || []}
              defaultCsvExportParams={{
                fileName: `${projectName}_subscriber_sites_${name}_pmp_links.csv`,
              }}
              addStatusColor={true}
              //   onSelectionChanged={onSelectionChanged}
              columnDefs={[
                {
                  field: 'local_name',
                  headerName: formatMessage(
                    additionalMessages.pmpNetworkDevice
                  ),
                  sort: 'asc',
                  cellRenderer: 'linkRenderer',
                  cellRendererParams: {
                    getHref: (data) => `/aps/${data.local_id}`,
                  },
                },
                {
                  field: 'remote_name',
                  headerName: formatMessage(additionalMessages.subscriber),
                  cellRenderer: 'linkRenderer',
                  cellRendererParams: {
                    getHref: (data) => `/subscriber/${data.remote_id}`,
                  },
                },
              ]}
            ></LPGrid>
          ),
        },
      },
    ];
  }
  return [];
};

const SiteDetailsPanel = (props) => {
  const { isNetworkSite } = props;
  const { projectName, prefs } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const linksGridRef = useRef<GridRefObject>();
  const acGridRef = useRef<GridRefObject>();

  const accordions = [
    {
      key: isNetworkSite ? 'NetworkSiteDetails' : 'SubscriberSiteDetails',
      titleProps: {
        content: (
          <FormattedMessage id="common.details" defaultMessage="Details" />
        ),
      },
      contentProps: { content: <SiteProperties {...props} /> },
    },
    ...(isNetworkSite
      ? getNetworkSitePanels(props, linksGridRef, acGridRef, prefs, projectName)
      : getSubscriberSitePanels(props, linksGridRef, prefs, projectName)),
  ];
  return (
    <CustomAccordion
      customClass="site-details-panels"
      accordions={accordions}
    ></CustomAccordion>
  );
};

export default injectIntl(SiteDetailsPanel);
